import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Callout from './Callout';
import EditorDot from './EditorDot';
import FloorplanGaugeBody from './FloorplanGaugeBody';

const styles = theme => ({
  hide: {
    opacity: 0,
  },
  callout: {
    zIndex: -1,
  },
  hoverCalloutDot: {
    '&:hover ~ $dot': {
      opacity: 1,
    },
  },
  dot: {
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    '&:hover': {
      opacity: 1,
    },
  },
});

class FloorplanGauge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bodyWidth: 0,
      bodyHeight: 0,
      hidden: false,
    };

    this.bodyCoordinatesRef = null;

    this.handleBodyResize = this.handleBodyResize.bind(this);
    this.handleBodyCoordinatesRef = this.handleBodyCoordinatesRef.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleBodyResize(bodyWidth, bodyHeight) {
    this.setState((prevState) => {
      if (prevState.bodyWidth !== bodyWidth) {
        return { bodyWidth };
      }

      return null;
    });

    this.setState((prevState) => {
      if (prevState.bodyHeight !== bodyHeight) {
        return { bodyHeight };
      }

      return null;
    });
  }

  handleBodyCoordinatesRef(ref) {
    this.bodyCoordinatesRef = ref;
  }

  handleHide() {
    this.setState({ hidden: true });
  }

  handleShow() {
    this.setState({ hidden: false });
  }

  render() {
    const {
      classes,
      theme,
      index,
      x,
      y,
      originX,
      originY,
      location,
      scale: scaleProp,
      draggable,
      handleRemoveClick,
      handleClick,
    } = this.props;
    const { bodyWidth, bodyHeight, hidden } = this.state;

    const isHeater = location.heater !== null;

    const scale = scaleProp > 1 ? 1 : scaleProp;
    const alert = location.sensors.some(
      item => item.value.inError || item.value.isMissing || item.value.thresholds.some(thresh => thresh.exceeded),
    )
      || (isHeater && location.heater.isMissing);

    const calloutBehindBody = originX >= x && originX <= x + bodyWidth * scale && originY >= y && originY <= y + bodyHeight * scale; // eslint-disable-line max-len
    const color = isHeater ? theme.floorplan.heater.border : theme.floorplan.gauge.border;

    return (
      <div className={classNames({ [classes.hide]: hidden })}>
        {!calloutBehindBody && (
          <Callout
            className={classNames(classes.callout, classes.hoverCalloutDot)}
            from={{
              x: x + (bodyWidth * scale) / 2,
              y: y + (bodyHeight * scale) / 2,
            }}
            to={{ x: originX, y: originY }}
            scale={scale}
            fill={alert ? theme.palette.error.main : color}
            stroke={alert ? theme.palette.error.main : color}
          />
        )}
        <FloorplanGaugeBody
          className={classes.hoverCalloutDot}
          index={index}
          x={x}
          y={y}
          originX={originX}
          originY={originY}
          location={location}
          scale={scale}
          draggable={draggable}
          onResize={this.handleBodyResize}
          coordinatesRef={this.handleBodyCoordinatesRef}
          handleRemoveClick={
            handleRemoveClick && index !== -1
              ? () => {
                handleRemoveClick(index);
              }
              : undefined
          }
          handleClick={
            draggable && handleClick && index !== -1
              ? () => {
                handleClick(index);
              }
              : (!draggable && handleClick) || undefined
          }
          handleHide={this.handleHide}
          handleShow={this.handleShow}
        />
        {draggable && (
          <EditorDot
            className={classes.dot}
            index={index}
            originX={calloutBehindBody ? x + (bodyWidth * scale) / 2 : originX}
            originY={calloutBehindBody ? y + (bodyHeight * scale) / 2 : originY}
            x={x}
            y={y}
            scale={scale}
            location={location}
            bodyCoordinates={this.bodyCoordinatesRef ? this.bodyCoordinatesRef() : { x: 0, y: 0 }}
            handleHide={this.handleHide}
            handleShow={this.handleShow}
          />
        )}
      </div>
    );
  }
}

FloorplanGauge.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  index: PropTypes.number,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  originX: PropTypes.number.isRequired,
  originY: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  scale: PropTypes.number.isRequired,
  draggable: PropTypes.bool,
  handleRemoveClick: PropTypes.func,
  handleClick: PropTypes.func,
};

FloorplanGauge.defaultProps = {
  index: -1,
  draggable: false,
  handleRemoveClick: undefined,
  handleClick: undefined,
};

export default withStyles(styles, { withTheme: true })(FloorplanGauge);
