import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import equals from 'fast-deep-equal';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import uuidv4 from 'uuid/v4';
import googleApiKeys from '../constants/googleApiKeys';
import CrosshairIcon from '../images/icons/Crosshair';
import {
  myLocationIcon, placeIcon, routerAlertIcon, routerIcon,
} from '../images/icons/googleMaps';
import { withScript } from './withScript';

const styles = {
  map: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  thumbnailMap: {
    width: '100%',
    height: '150%',
    top: '-25%',
    bottom: '25%',
    position: 'absolute',
  },
  mapContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
    overflowY: 'hidden',
  },
  crosshair: {
    position: 'absolute',
    fontSize: 48,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  liteCrosshair: {
    '& > path': {
      stroke: 'white',
      strokeWidth: 5,
    },
  },
};

const baseOptions = {
  clickableIcons: true,
  mapTypeId: 'roadmap',
  rotateControl: false,
  fullscreenControl: false,
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
};

const mapOptions = {
  draggableCursor: 'move',
  draggingCursor: 'move',
  gestureHandling: 'auto',
  keyboardShortcuts: true,
  mapTypeControl: true,
  scaleControl: true,
  streetViewControl: true,
  zoomControl: true,
  zoom: 15,
  ...baseOptions,
};

const mapOptionsThumbnail = {
  draggableCursor: 'pointer',
  gestureHandling: 'none',
  keyboardShortcuts: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  zoomControl: false,
  zoom: 13,
  ...baseOptions,
};

function createMapClickEventListener(map, handleMapClick) {
  return window.google.maps.event.addListener(map, 'click', (event) => {
    handleMapClick();
    event.stop();
  });
}

function removeMarker(item, index, collection) {
  item.setMap(null);
  collection.splice(index, 1);
}

function createMyLocationMarker(item, map) {
  return new window.google.maps.Marker({
    clickable: true,
    icon: {
      // eslint-disable-next-line no-new
      anchor: new window.google.maps.Point(20, 20),
      url: `data:image/svg+xml;charset=utf-8, ${encodeURIComponent(myLocationIcon)}`,
    },
    position: item,
    map,
    title: 'My location',
  });
}

function setMapHandlers(map, onMapReady) {
  onMapReady(() => {
    if (map) {
      return {
        lat: map.getCenter().lat(),
        lon: map.getCenter().lng(),
      };
    }

    return undefined;
  });
}

class GoogleMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = { satellite: false };

    this.map = undefined;
    this.siteMarkers = [];
    this.cagMarkers = [];
    this.missingCagMarkers = [];
    this.myLocationMarker = undefined;
    this.mapClickEventListener = undefined;
    this.displayElementId = uuidv4();
    this.createMarker = this.createMarker.bind(this);
    this.addSiteMarkers = this.addSiteMarkers.bind(this);
    this.addCagMarkers = this.addCagMarkers.bind(this);
    this.addMissingCagMarkers = this.addMissingCagMarkers.bind(this);
  }

  componentDidMount() {
    const {
      center,
      sites,
      cags,
      missingCags,
      myLocation,
      isThumbnail,
      handleMapClick,
      onMapReady,
      centerMapToMyLocation,
    } = this.props;

    const map = new window.google.maps.Map(
      document.getElementById(this.displayElementId),
      Object.assign(isThumbnail ? mapOptionsThumbnail : mapOptions, { center }),
    );

    this.addSiteMarkers(sites, map);
    this.addCagMarkers(cags, map);
    this.addMissingCagMarkers(missingCags, map);

    if (myLocation) {
      this.myLocationMarker = createMyLocationMarker(myLocation, map);
    }

    if (handleMapClick) {
      this.mapClickEventListener = createMapClickEventListener(map, handleMapClick);
    }

    if (onMapReady) setMapHandlers(map, onMapReady);

    this.mapTypeChangeListener = window.google.maps.event.addListener(map, 'maptypeid_changed', () => {
      const mapTypeId = map.getMapTypeId();
      if (mapTypeId === window.google.maps.MapTypeId.HYBRID || mapTypeId === window.google.maps.MapTypeId.SATELLITE) {
        this.setState({ satellite: true });
      } else {
        this.setState({ satellite: false });
      }
    });

    if (centerMapToMyLocation && myLocation) {
      map.panTo(myLocation);
    }

    this.map = map;
  }

  componentWillUpdate(nextProps) {
    const {
      center,
      sites,
      cags,
      missingCags,
      myLocation,
      handleMapClick,
      onMapReady,
      centerMapToMyLocation,
    } = nextProps;

    /* eslint-disable react/destructuring-assignment */

    if (this.map) {
      if (!equals(center, this.props.center)) {
        this.map.panTo(center);
      }

      if (!equals(sites, this.props.sites)) {
        this.siteMarkers.forEach(removeMarker);
        this.addSiteMarkers(sites, this.map);
      }

      if (!equals(cags, this.props.cags)) {
        this.cagMarkers.forEach(removeMarker);
        this.addCagMarkers(cags, this.map);
      }

      if (!equals(missingCags, this.props.missingCags)) {
        this.missingCagMarkers.forEach(removeMarker);
        this.addMissingCagMarkers(missingCags, this.map);
      }

      if (!equals(myLocation, this.props.myLocation)) {
        if (this.myLocationMarker) {
          this.myLocationMarker.setMap(null);
          this.myLocationMarker = undefined;
        }

        if (myLocation) {
          this.myLocationMarker = createMyLocationMarker(myLocation, this.map);
        }
      }

      if (!equals(handleMapClick, this.props.handleMapClick)) {
        if (this.mapClickEventListener) {
          this.mapClickEventListener.remove();
          this.mapClickEventListener = undefined;
        }

        if (handleMapClick) {
          this.mapClickEventListener = createMapClickEventListener(this.map, handleMapClick);
        }
      }

      if (!equals(onMapReady, this.props.onMapReady)) {
        if (this.props.onMapReady) setMapHandlers(undefined, this.props.onMapReady);
        if (onMapReady) setMapHandlers(this.map, onMapReady);
      }

      if (!equals(centerMapToMyLocation, this.props.centerMapToMyLocation) && myLocation) {
        this.map.panTo(myLocation);
      }
    }

    /* eslint-enable react/destructuring-assignment */
  }

  componentWillUnmount() {
    const { onMapReady } = this.props;

    if (this.mapTypeChangeListener) {
      this.mapTypeChangeListener.remove();
    }

    if (this.mapClickEventListener) {
      this.mapClickEventListener.remove();
    }

    if (this.myLocationMarker) {
      this.myLocationMarker.setMap(null);
    }

    if (onMapReady) {
      setMapHandlers(undefined, onMapReady);
    }

    this.missingCagMarkers.forEach(removeMarker);
    this.cagMarkers.forEach(removeMarker);
    this.siteMarkers.forEach(removeMarker);

    this.map = null;
  }

  createMarker(item, anchorCoords, color, path, map) {
    const { isThumbnail } = this.props;

    return new window.google.maps.Marker({
      clickable: !isThumbnail,
      icon: {
        // eslint-disable-next-line no-new
        anchor: new window.google.maps.Point(anchorCoords.x, anchorCoords.y),
        fillColor: color,
        fillOpacity: 1,
        path,
        scale: 1.5,
        strokeColor: color,
        strokeWeight: 0.1,
      },
      position: {
        lat: item.lat,
        lng: item.lng,
      },
      map,
      title: item.title,
    });
  }

  addSiteMarkers(items, map) {
    const { theme } = this.props;

    items.forEach((item) => {
      this.siteMarkers.push(
        this.createMarker(
          item,
          {
            x: 12,
            y: 22,
          },
          theme.palette.secondary.main,
          placeIcon,
          map,
        ),
      );
    });
  }

  addCagMarkers(items, map) {
    const { theme } = this.props;

    items.forEach((item) => {
      this.cagMarkers.push(
        this.createMarker(
          item,
          {
            x: 15,
            y: 22,
          },
          theme.palette.tertiary.main,
          routerIcon,
          map,
        ),
      );
    });
  }

  addMissingCagMarkers(items, map) {
    const { theme } = this.props;

    items.forEach((item) => {
      this.missingCagMarkers.push(
        this.createMarker(
          item,
          {
            x: 15,
            y: 22,
          },
          theme.palette.error.main,
          routerAlertIcon,
          map,
        ),
      );
    });
  }

  render() {
    const { classes, isThumbnail, drawCrosshair } = this.props;
    const { satellite } = this.state;

    return (
      <div className={classes.mapContainer}>
        <div className={isThumbnail ? classes.thumbnailMap : classes.map} id={this.displayElementId} />
        {drawCrosshair && (
          <CrosshairIcon className={classNames(classes.crosshair, { [classes.liteCrosshair]: satellite })} />
        )}
      </div>
    );
  }
}

GoogleMap.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
  ),
  cags: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
  ),
  missingCags: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
  ),
  myLocation: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  isThumbnail: PropTypes.bool,
  drawCrosshair: PropTypes.bool,
  centerMapToMyLocation: PropTypes.string,
  onMapReady: PropTypes.func,
  handleMapClick: PropTypes.func,
};

GoogleMap.defaultProps = {
  sites: [],
  cags: [],
  missingCags: [],
  myLocation: undefined,
  isThumbnail: false,
  drawCrosshair: false,
  centerMapToMyLocation: undefined,
  onMapReady: undefined,
  handleMapClick: undefined,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withScript(`https://maps.googleapis.com/maps/api/js?key=${googleApiKeys.mapsJavaScript}`),
)(GoogleMap);
