import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MuiSelect from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  label: {
    '&$focused': {
      color: theme.palette.secondary.dark,
    },
  },
  focused: {},
  underline: {
    '&:after': {
      borderBottomColor: theme.palette.secondary.dark,
    },
  },
});

function Select(props) {
  const {
    classes, children, id, label, value, margin, fullWidth, disabled, readOnly, onChange,
  } = props;

  return (
    <FormControl margin={margin} fullWidth={fullWidth} disabled={disabled}>
      {label !== undefined ? (
        <InputLabel
          FormLabelClasses={{
            root: classes.label,
            focused: classes.focused,
          }}
          htmlFor={id}
        >
          {label}
        </InputLabel>
      ) : null}
      <MuiSelect
        value={value}
        onChange={onChange}
        // prettier-ignore
        input={(
          <Input
            classes={{
              underline: classes.underline,
            }}
            id={id}
            readOnly={readOnly}
          />
        )}
      >
        {children}
      </MuiSelect>
    </FormControl>
  );
}

Select.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

Select.defaultProps = {
  label: undefined,
  value: undefined,
  margin: 'none',
  fullWidth: false,
  disabled: false,
  readOnly: false,
  onChange: undefined,
};

export default withStyles(styles)(Select);
