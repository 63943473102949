import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import compose from 'recompose/compose';
import dragAndDrop from '../constants/dragAndDrop';
import CalloutDot from './CalloutDot';

const styles = {
  root: {
    position: 'absolute',
    cursor: 'grab',
  },
  dot: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  dragging: {
    cursor: 'grabbing',
  },
};

function EditorDot(props) {
  const {
    classes, connectDragSource, connectDragPreview, isDragging, className, originX, originY, scale,
  } = props;

  connectDragPreview(getEmptyImage());

  const dot = (
    <div
      className={classNames(classes.root, { [classes.dragging]: isDragging }, className)}
      style={{ transform: `translate(${originX}px, ${originY}px) scale(${scale})` }}
    >
      <CalloutDot className={classes.dot} />
    </div>
  );
  return connectDragSource(dot);
}

EditorDot.propTypes = {
  classes: PropTypes.object.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  className: PropTypes.string,
  originX: PropTypes.number,
  originY: PropTypes.number,
  scale: PropTypes.number.isRequired,
};

EditorDot.defaultProps = {
  className: '',
  originX: 0,
  originY: 0,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  DragSource(
    dragAndDrop.widgetCallout,
    {
      beginDrag(props) {
        const { handleHide } = props;

        if (handleHide) {
          handleHide();
        }

        return { ...props };
      },
      endDrag(props) {
        const { handleShow } = props;

        if (handleShow) {
          handleShow();
        }
      },
      canDrag(props) {
        const { edit } = props;

        return !edit;
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    }),
  ),
)(EditorDot);
