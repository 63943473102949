import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';
import filterSearch from '../utils/filterSearch';
import searchGetIcon from '../utils/search';

const styles = theme => ({
  paper: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
  padding: {
    paddingLeft: theme.spacing.unit * 9,
  },
});

function Search(props) {
  const { classes, query, handleListItemClick } = props;

  const results = filterSearch(query);

  return (
    <Paper className={classes.paper}>
      {query !== '' && (
        <List>
          {results.length !== 0 ? (
            results.map(item => (
              <ListItem button divider key={item.id} onClick={() => handleListItemClick(item)}>
                <ListItemIcon>{searchGetIcon(item.type)}</ListItemIcon>
                <ListItemText primary={item.name} secondary={item.site ? `${item.type} • ${item.site}` : item.type} />
              </ListItem>
            ))
          ) : (
            <ListItem className={classes.padding}>
              <ListItemText primary="No results" />
            </ListItem>
          )}
        </List>
      )}
    </Paper>
  );
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired,
  handleListItemClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(Search);
