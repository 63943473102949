import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { DropTarget } from 'react-dnd';
import compose from 'recompose/compose';
import dragAndDrop from '../constants/dragAndDrop';
import getOS from '../utils/getOS';
import Annotation from './Annotation';
import FloorplanGauge from './FloorplanGauge';

const borderWidth = 3;

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: 'dashed',
    borderColor: theme.palette.background.paper,
    borderWidth,
    borderRadius: 10,
    transition: theme.transitions.create('border-color', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  dragOver: {
    borderColor: theme.palette.tertiary.light,
  },
  container: {
    '-webkit-filter': 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3))',
    filter:
      'drop-shadow(0px 1px 5px 0px rgba(0, 0, 0, 0.2)) drop-shadow(0px 2px 2px 0px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 1px -2px rgba(0, 0, 0, 0.12))',
  },
  containerIOS: {
    '-webkit-filter': 'unset',
  },
});

class FloorplanImage extends React.Component {
  constructor(props) {
    super(props);

    this.imgRef = null;

    this.getImgDOMRect = this.getImgDOMRect.bind(this);
    this.handleLocationDrop = this.handleLocationDrop.bind(this);
    this.handleAnnotationDrop = this.handleAnnotationDrop.bind(this);
    this.handleWidgetMove = this.handleWidgetMove.bind(this);
    this.handleCalloutMove = this.handleCalloutMove.bind(this);
    this.handleImgRef = this.handleImgRef.bind(this);
    this.renderImage = this.renderImage.bind(this);
  }

  getImgDOMRect() {
    if (this.imgRef) {
      return this.imgRef.getBoundingClientRect();
    }

    return null;
  }

  handleLocationDrop(location, bodyWidth, bodyHeight, initialClientOffset, initialSourceClientOffset, clientOffset) {
    const { handleLocationDrop } = this.props;

    const domRect = this.getImgDOMRect();
    const imageWidth = domRect.width - 2 * borderWidth;
    const imageHeight = domRect.height - 2 * borderWidth;

    const maxOriginWidthPercent = ((imageWidth - bodyWidth / 2) / imageWidth) * 100;
    const maxOriginHeightPercent = ((imageHeight - bodyHeight / 2) / imageHeight) * 100;
    const minOriginWidthPercent = (bodyWidth / 2 / imageWidth) * 100;
    const minOriginHeightPercent = (bodyHeight / 2 / imageHeight) * 100;

    handleLocationDrop(
      location,
      {
        x: Math.min(
          Math.max(
            ((clientOffset.x
              - domRect.left
              - borderWidth
              - initialClientOffset.x
              + initialSourceClientOffset.x
              + bodyWidth / 2)
              / imageWidth)
              * 100,
            minOriginWidthPercent,
          ),
          maxOriginWidthPercent,
        ),
        y: Math.min(
          Math.max(
            ((clientOffset.y
              - domRect.top
              - borderWidth
              - initialClientOffset.y
              + initialSourceClientOffset.y
              + bodyHeight / 2)
              / imageHeight)
              * 100,
            minOriginHeightPercent,
          ),
          maxOriginHeightPercent,
        ),
      },
      {
        x: (-bodyWidth / 2 / imageWidth) * 100,
        y: (-bodyHeight / 2 / imageHeight) * 100,
      },
    );
  }

  handleAnnotationDrop(bodyWidth, bodyHeight, initialClientOffset, initialSourceClientOffset, clientOffset) {
    const { handleAnnotationDrop } = this.props;

    const domRect = this.getImgDOMRect();
    const imageWidth = domRect.width - 2 * borderWidth;
    const imageHeight = domRect.height - 2 * borderWidth;

    const maxOriginWidthPercent = ((imageWidth - bodyWidth / 2) / imageWidth) * 100;
    const maxOriginHeightPercent = ((imageHeight - bodyHeight / 2) / imageHeight) * 100;
    const minOriginWidthPercent = (bodyWidth / 2 / imageWidth) * 100;
    const minOriginHeightPercent = (bodyHeight / 2 / imageHeight) * 100;

    handleAnnotationDrop(
      (bodyWidth / imageWidth) * 100,
      (bodyHeight / imageHeight) * 100,
      {
        x: Math.min(
          Math.max(
            ((clientOffset.x
              - domRect.left
              - borderWidth
              - initialClientOffset.x
              + initialSourceClientOffset.x
              + bodyWidth / 2)
              / imageWidth)
              * 100,
            minOriginWidthPercent,
          ),
          maxOriginWidthPercent,
        ),
        y: Math.min(
          Math.max(
            ((clientOffset.y
              - domRect.top
              - borderWidth
              - initialClientOffset.y
              + initialSourceClientOffset.y
              + bodyHeight / 2)
              / imageHeight)
              * 100,
            minOriginHeightPercent,
          ),
          maxOriginHeightPercent,
        ),
      },
      {
        x: (-bodyWidth / 2 / imageWidth) * 100,
        y: (-bodyHeight / 2 / imageHeight) * 100,
      },
    );
  }

  handleWidgetMove(index, initialBodyX, initialBodyY, bodyWidth, bodyHeight, differenceFromInitialOffset) {
    const { handleWidgetMove } = this.props;

    const domRect = this.getImgDOMRect();
    const imageWidth = domRect.width - 2 * borderWidth;
    const imageHeight = domRect.height - 2 * borderWidth;

    const maxWidthPercent = ((imageWidth - bodyWidth) / imageWidth) * 100;
    const maxHeightPercent = ((imageHeight - bodyHeight) / imageHeight) * 100;

    handleWidgetMove(index, {
      x: Math.max(Math.min(((initialBodyX + differenceFromInitialOffset.x) / imageWidth) * 100, maxWidthPercent), 0),
      y: Math.max(Math.min(((initialBodyY + differenceFromInitialOffset.y) / imageHeight) * 100, maxHeightPercent), 0),
    });
  }

  handleCalloutMove(index, initialOriginX, initialOriginY, differenceFromInitialOffset) {
    const { handleCalloutMove } = this.props;

    const domRect = this.getImgDOMRect();
    const imageWidth = domRect.width - 2 * borderWidth;
    const imageHeight = domRect.height - 2 * borderWidth;

    handleCalloutMove(index, {
      x: Math.min(Math.max(((initialOriginX + differenceFromInitialOffset.x) / imageWidth) * 100, 0), 100),
      y: Math.min(Math.max(((initialOriginY + differenceFromInitialOffset.y) / imageHeight) * 100, 0), 100),
    });
  }

  handleImgRef(ref) {
    if (ref) {
      this.imgRef = ref;

      this.imgRef.onload = () => {
        const { onDimensionChange } = this.props;

        onDimensionChange(ref.naturalWidth, ref.naturalHeight);
      };
    }
  }

  renderImage() {
    const {
      classes,
      isOver,
      image,
      name,
      width,
      height,
      widgets,
      locations,
      scale,
      handleWidgetMove,
      handleCalloutMove,
      handleRemoveClick,
      handleTextChange,
      handleWidgetClick,
    } = this.props;

    const draggable = handleWidgetMove !== undefined && handleCalloutMove !== undefined;

    return (
      <div>
        <img
          alt={name}
          className={classes.image}
          ref={ref => this.handleImgRef(ref)}
          src={image.url}
          style={{ width, height }}
        />
        <div className={classNames(classes.image, { [classes.dragOver]: isOver })} style={{ width, height }}>
          <div className={classNames(classes.container, { [classes.containerIOS]: getOS() === 'iOS' })}>
            {width > 0
              && height > 0
              && widgets.map((item, index) => {
                const x = ((item.origin.x + item.offset.x) / 100) * (width - 2 * borderWidth);
                const y = ((item.origin.y + item.offset.y) / 100) * (height - 2 * borderWidth);
                const originX = (item.origin.x / 100) * (width - 2 * borderWidth);
                const originY = (item.origin.y / 100) * (height - 2 * borderWidth);

                if (item.type === 'sensor') {
                  const location = locations.find(value => value.id === item.locationConfigId);

                  if (location) {
                    return (
                      <FloorplanGauge
                        key={item.locationConfigId}
                        index={index}
                        x={x}
                        y={y}
                        originX={originX}
                        originY={originY}
                        location={locations.find(value => value.id === item.locationConfigId)}
                        scale={scale}
                        draggable={draggable}
                        handleRemoveClick={handleRemoveClick}
                        handleClick={handleWidgetClick}
                      />
                    );
                  }
                  return null;
                }
                if (item.type === 'textbox') {
                  return (
                    <Annotation
                      key={index.toString()}
                      index={index}
                      x={x}
                      y={y}
                      originX={originX}
                      originY={originY}
                      text={item.text}
                      scale={scale}
                      draggable={draggable}
                      handleRemoveClick={handleRemoveClick}
                      handleClick={handleWidgetClick}
                      handleChange={handleTextChange}
                    />
                  );
                }
                return null;
              })}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { classes, connectDropTarget } = this.props;

    return <div className={classes.root}>{connectDropTarget(this.renderImage())}</div>;
  }
}

FloorplanImage.propTypes = {
  classes: PropTypes.object.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  image: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  widgets: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  scale: PropTypes.number.isRequired,
  onDimensionChange: PropTypes.func.isRequired,
  handleLocationDrop: PropTypes.func,
  handleAnnotationDrop: PropTypes.func,
  handleWidgetMove: PropTypes.func,
  handleCalloutMove: PropTypes.func,
  handleRemoveClick: PropTypes.func,
  handleTextChange: PropTypes.func,
  handleWidgetClick: PropTypes.func,
};

FloorplanImage.defaultProps = {
  handleLocationDrop: undefined,
  handleAnnotationDrop: undefined,
  handleWidgetMove: undefined,
  handleCalloutMove: undefined,
  handleRemoveClick: undefined,
  handleTextChange: undefined,
  handleWidgetClick: undefined,
};

export default compose(
  withStyles(styles),
  DropTarget(
    [dragAndDrop.paletteGauge, dragAndDrop.paletteAnnotation, dragAndDrop.widgetBody, dragAndDrop.widgetCallout],
    {
      drop(_, monitor, component) {
        switch (monitor.getItemType()) {
          case dragAndDrop.widgetBody: {
            const {
              index, x, y, bodyWidth, bodyHeight, scale,
            } = monitor.getItem();

            component.handleWidgetMove(
              index,
              x,
              y,
              bodyWidth * scale,
              bodyHeight * scale,
              monitor.getDifferenceFromInitialOffset(),
            );
            break;
          }
          case dragAndDrop.widgetCallout: {
            const { index, originX, originY } = monitor.getItem();

            component.handleCalloutMove(index, originX, originY, monitor.getDifferenceFromInitialOffset());
            break;
          }
          case dragAndDrop.paletteGauge: {
            const {
              bodyWidth, bodyHeight, location, scale,
            } = monitor.getItem();

            component.handleLocationDrop(
              location,
              bodyWidth * scale,
              bodyHeight * scale,
              monitor.getInitialClientOffset(),
              monitor.getInitialSourceClientOffset(),
              monitor.getClientOffset(),
            );
            break;
          }
          case dragAndDrop.paletteAnnotation: {
            const { bodyWidth, bodyHeight, scale } = monitor.getItem();

            component.handleAnnotationDrop(
              bodyWidth * scale,
              bodyHeight * scale,
              monitor.getInitialClientOffset(),
              monitor.getInitialSourceClientOffset(),
              monitor.getClientOffset(),
            );
            break;
          }
          default:
        }
      },
      canDrop(props) {
        return (
          props.handleLocationDrop && props.handleAnnotationDrop && props.handleWidgetMove && props.handleCalloutMove
        );
      },
    },
    (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver() && monitor.getItemType() !== dragAndDrop.image,
    }),
  ),
)(FloorplanImage);
