import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import Select from './Select';

class LocationsAddHeater extends React.Component {
  constructor(props) {
    super(props);

    const availableDevices = props.cags
      .reduce((accumulator, item) => accumulator.concat(item.devices.filter(device => device.type === 'HMC')), [])
      .filter(
        item => !props.locations.some(
          location => location.id !== props.location.id && location.heater !== null && location.heater.barcode === item.barcode, // eslint-disable-line max-len
        ),
      );

    this.state = {
      barcode: availableDevices.length === 1 ? availableDevices[0].barcode : '',
    };

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleBarcodeChange = this.handleBarcodeChange.bind(this);
  }

  handleAddClick() {
    const { handleAddClick } = this.props;
    const { barcode } = this.state;

    handleAddClick(barcode);
    this.handleClose();
  }

  handleClose() {
    const { handleClose } = this.props;

    this.setState({ barcode: '' });
    handleClose();
  }

  handleBarcodeChange(event) {
    this.setState({ barcode: event.target.value });
  }

  render() {
    const {
      open, locations, location, cags,
    } = this.props;
    const { barcode } = this.state;

    const availableDevices = cags.reduce(
      (accumulator, item) => accumulator.concat(item.devices.filter(device => device.type === 'HMC')),
      [],
    );

    return (
      <Dialog maxWidth="xs" fullWidth open={open} onClose={this.handleClose}>
        <>
          <DialogTitle>Add a smart heater</DialogTitle>
        </>
        <DialogContent>
          <Select
            id="device-barcode"
            label="Barcode"
            value={barcode}
            margin="normal"
            fullWidth
            onChange={this.handleBarcodeChange}
          >
            {availableDevices.map((item) => {
              const heaterHasLocation = locations.some(
                other => other.id !== location.id && other.heater !== null && other.heater.barcode === item.barcode,
              );

              return (
                <MenuItem key={item.barcode} value={item.barcode} disabled={heaterHasLocation}>
                  {item.barcode}
                </MenuItem>
              );
            })}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.handleClose}>
            Cancel
          </Button>
          <Button color="secondary" autoFocus onClick={this.handleAddClick} disabled={barcode === ''}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

LocationsAddHeater.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  location: PropTypes.object.isRequired,
  cags: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
  handleAddClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default LocationsAddHeater;
