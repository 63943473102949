import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import PropTypes from 'prop-types';
import React from 'react';
import uuidv4 from 'uuid/v4';
import { mixinHeightWithToolbar } from '../constants/theme';
import HumidityIcon from '../images/icons/Humidity';
import TemperatureIcon from '../images/icons/Temperature';
import deepCopy from '../utils/deepCopy';
import LocationsAddHeater from './LocationsAddHeater';
import LocationsAddSensor from './LocationsAddSensor';
import TextField from './TextField';
import Tooltip from './Tooltip';

const styles = theme => ({
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
  spacing: {
    marginTop: theme.spacing.unit,
  },
  color: {
    color: theme.palette.secondary.main,
  },
});

class LocationsEdit extends React.Component {
  constructor(props) {
    super(props);

    const { location } = props;

    this.state = {
      openAddSensor: false,
      openAddHeater: false,
      name: location.name,
      notes: location.notes,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAddSensorClick = this.handleAddSensorClick.bind(this);
    this.handleAddHeaterClick = this.handleAddHeaterClick.bind(this);
    this.handleNewSensor = this.handleNewSensor.bind(this);
    this.handleNewHeater = this.handleNewHeater.bind(this);
    this.handleDeleteSensor = this.handleDeleteSensor.bind(this);
    this.handleDeleteHeater = this.handleDeleteHeater.bind(this);
  }

  handleChange(event, param) {
    const { location: locationProp, onChange } = this.props;

    const location = deepCopy(locationProp);
    this.setState({ [param]: event.target.value });
    location[param] = event.target.value;
    onChange(location);
  }

  handleClose() {
    this.setState({ openAddSensor: false, openAddHeater: false });
  }

  handleAddSensorClick() {
    this.setState({ openAddSensor: true });
  }

  handleAddHeaterClick() {
    this.setState({ openAddHeater: true });
  }

  handleNewHeater(barcode) {
    const { location: locationProp, onChange } = this.props;

    const location = deepCopy(locationProp);
    location.heater = { barcode, heaterSetTo: 24, enabled: false };
    location.sensors = [];
    location.newSensors = [];
    onChange(location);
  }

  handleNewSensor(sensor) {
    const { location: locationProp, onChange } = this.props;

    const location = deepCopy(locationProp);
    location.newSensors.push({ id: uuidv4(), ...sensor });
    location.heater = null;
    onChange(location);
  }

  handleDeleteSensor(id) {
    const { location: locationProp, onChange } = this.props;

    const location = deepCopy(locationProp);
    location.sensors = location.sensors.filter(item => item.id !== id);
    location.newSensors = location.newSensors.filter(item => item.id !== id);
    onChange(location);
  }

  handleDeleteHeater() {
    const { location: locationProp, onChange } = this.props;

    const location = deepCopy(locationProp);
    location.heater = null;
    onChange(location);
  }

  render() {
    const {
      classes, locations, location, devices,
    } = this.props;
    const {
      openAddSensor, openAddHeater, name, notes,
    } = this.state;

    const hasHeater = location.heater !== null;

    return (
      <>
        <div className={classes.content}>
          <List>
            <ListItem>
              <TextField
                id="location-name"
                label="Location name"
                value={name}
                margin="normal"
                fullWidth
                onChange={event => this.handleChange(event, 'name')}
              />
            </ListItem>
            <ListItem>
              <TextField
                id="location-notes"
                label="Note"
                value={notes || ''}
                margin="normal"
                fullWidth
                onChange={event => this.handleChange(event, 'notes')}
              />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListSubheader disableSticky>Sensors</ListSubheader>
            {location.sensors.concat(location.newSensors).map(item => (
              <ListItem key={item.id} dense>
                <ListItemIcon>{item.type === 'H' ? <HumidityIcon /> : <TemperatureIcon />}</ListItemIcon>
                <ListItemText primary={item.sensorType} secondary={`WSN ${item.barcode} • ${item.channel}`} />
                <ListItemSecondaryAction>
                  <Tooltip title="Remove">
                    <IconButton onClick={() => this.handleDeleteSensor(item.id)}>
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <ListItem className={classes.spacing} button onClick={this.handleAddSensorClick} disabled={hasHeater}>
              <ListItemIcon className={classes.color}>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Add a sensor" primaryTypographyProps={{ color: 'textSecondary' }} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListSubheader disableSticky>Smart heater</ListSubheader>
            {hasHeater && (
              <ListItem dense>
                <ListItemIcon>
                  <WhatshotIcon />
                </ListItemIcon>
                <ListItemText secondary={`HMC ${location.heater.barcode}`} />
                <ListItemSecondaryAction>
                  <Tooltip title="Remove">
                    <IconButton onClick={() => this.handleDeleteHeater()}>
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            )}
            <ListItem
              className={classes.spacing}
              button
              onClick={() => this.handleAddHeaterClick()}
              disabled={location.sensors.concat(location.newSensors).length > 0 || hasHeater}
            >
              <ListItemIcon className={classes.color}>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Add a smart heater" primaryTypographyProps={{ color: 'textSecondary' }} />
            </ListItem>
          </List>
        </div>
        <LocationsAddSensor
          cags={devices.assignedDevices}
          sensors={location.sensors.concat(location.newSensors)}
          open={openAddSensor}
          handleAddClick={this.handleNewSensor}
          handleClose={this.handleClose}
        />
        <LocationsAddHeater
          open={openAddHeater}
          locations={locations}
          location={location}
          cags={devices.assignedDevices}
          handleAddClick={this.handleNewHeater}
          handleClose={this.handleClose}
          key={openAddHeater.toString()}
        />
      </>
    );
  }
}

LocationsEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  location: PropTypes.object.isRequired,
  devices: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(LocationsEdit);
