import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import MuiMoreVertIcon from '@material-ui/icons/MoreVert';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import Tooltip from './Tooltip';
import withMobile from './withMobile';

const styles = theme => ({
  popper: {
    zIndex: theme.zIndex.appBar + 1,
  },
  paper: {
    width: 'auto',
    minWidth: 128,
    [theme.breakpoints.up('md')]: {
      minWidth: 112,
    },
    border: '1px solid #d9d9d9',
    borderBottomColor: 'rgba(153, 153, 153, 0.6)',
    boxShadow:
      '0 6px 12px -1px rgba(153, 153, 153, 0.2), 0 4px 4px -1px rgba(153, 153, 153, 0.2), 0 2px 2px 0 rgba(153, 153, 153, 0.2)',
  },
  pressed: {
    backgroundColor: theme.palette.action.selected,
  },
});

class MoreVertIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      anchorEl: null,
      tooltipOpen: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleTooltipOpen = this.handleTooltipOpen.bind(this);
    this.handleTooltipClose = this.handleTooltipClose.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleClick(event) {
    const { open } = this.state;

    const { currentTarget } = event;

    if (!open) {
      this.setState({ open: true, anchorEl: currentTarget, tooltipOpen: false });
    } else {
      this.setState({ open: false, anchorEl: null, tooltipOpen: false });
    }
  }

  handleClose() {
    const { open } = this.state;

    if (!open) {
      return;
    }

    // setTimeout to ensure a close event comes after a target click event
    this.timeout = setTimeout(() => {
      this.setState({ open: false, anchorEl: null, tooltipOpen: false });
    });
  }

  handleTooltipOpen() {
    const { open } = this.state;

    this.setState({ tooltipOpen: !open });
  }

  handleTooltipClose() {
    this.setState({ tooltipOpen: false });
  }

  handleMenuItemClick(item) {
    const { handleMenuItemClick: handleMenuItemClickProp } = this.props;

    handleMenuItemClickProp(item);
    this.handleClose();
  }

  render() {
    const {
      classes, widthMobile, menuItems, placement,
    } = this.props;
    const { open, anchorEl, tooltipOpen } = this.state;

    return (
      <>
        <Tooltip
          title="More options"
          open={tooltipOpen}
          onOpen={this.handleTooltipOpen}
          onClose={this.handleTooltipClose}
        >
          <IconButton
            className={classNames({ [classes.pressed]: open })}
            disableRipple={widthMobile === false}
            onClick={this.handleClick}
          >
            <MuiMoreVertIcon />
          </IconButton>
        </Tooltip>
        <Popper className={classes.popper} open={open} anchorEl={anchorEl} placement={placement}>
          <ClickAwayListener onClickAway={this.handleClose}>
            <Grow in={open} id="menu-list" style={{ transformOrigin: '0 0 0' }}>
              <Paper className={classes.paper} elevation={0}>
                <MenuList role="menu">
                  {menuItems.map(item => (
                    <MenuItem key={item} onClick={() => this.handleMenuItemClick(item)}>
                      {item}
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </Grow>
          </ClickAwayListener>
        </Popper>
      </>
    );
  }
}

MoreVertIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  placement: PropTypes.string,
  handleMenuItemClick: PropTypes.func.isRequired,
};

MoreVertIcon.defaultProps = {
  placement: 'bottom-start',
};

export default compose(
  withMobile(),
  withStyles(styles),
)(MoreVertIcon);
