import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { DropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import ReactResizeDetector from 'react-resize-detector';
import compose from 'recompose/compose';
import has from '../utils/has';
import FloorplanImage from './FloorplanImage';
import Spinner from './Spinner';

const styles = theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    border: 'dashed',
    borderColor: theme.palette.background.paper,
    borderRadius: 10,
    transition: theme.transitions.create('border-color', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  dragOver: {
    borderColor: theme.palette.tertiary.light,
  },
  greyBorder: {
    borderColor: theme.palette.grey[400],
  },
  noImage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  icon: {
    fontSize: 56,
    fill: theme.palette.grey[400],
  },
  input: {
    display: 'none',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
});

class FloorplanViewport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rootWidth: 0,
      rootHeight: 0,
      imageWidth: 0,
      imageHeight: 0,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleDivRef = this.handleDivRef.bind(this);
    this.handleImageDrop = this.handleImageDrop.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleImageDimensionChange = this.handleImageDimensionChange.bind(this);
    this.handleImageScale = this.handleImageScale.bind(this);
    this.renderViewport = this.renderViewport.bind(this);
  }

  componentWillUnmount() {
    if (this.divRef) {
      this.divRef.removeEventListener('click', this.handleClick, false);
    }
  }

  handleClick() {
    const { handleFileDialogOpen } = this.props;

    handleFileDialogOpen();
  }

  handleDivRef(ref) {
    if (ref) {
      this.divRef = ref;
      this.divRef.addEventListener('click', this.handleClick, false);
    }
  }

  handleImageDrop(file) {
    const { handleImageDrop: handleImageDropProp } = this.props;

    handleImageDropProp(file);
  }

  handleResize(width, height) {
    const { imageWidth, imageHeight } = this.state;

    this.setState({ rootWidth: width, rootHeight: height });

    this.handleImageScale(width, height, imageWidth, imageHeight);
  }

  handleImageDimensionChange(imageWidth, imageHeight) {
    const { rootWidth, rootHeight } = this.state;

    this.setState({ imageWidth, imageHeight });

    this.handleImageScale(rootWidth, rootHeight, imageWidth, imageHeight);
  }

  handleImageScale(rootWidth, rootHeight, imageWidth, imageHeight) {
    const { onScaleChange } = this.props;

    const rootRatio = rootWidth / rootHeight;
    const imageRatio = imageWidth / imageHeight;

    if (imageRatio < rootRatio) {
      onScaleChange(Math.min(1, rootHeight / ((988 * imageHeight) / imageWidth)));
    } else {
      onScaleChange(Math.min(1, rootWidth / 988));
    }
  }

  renderViewport() {
    const {
      classes,
      isOver,
      image,
      floorplan,
      widgets,
      locations,
      scale,
      handleImageDrop,
      handleLocationDrop,
      handleAnnotationDrop,
      handleWidgetMove,
      handleCalloutMove,
      handleRemoveClick,
      handleTextChange,
      handleWidgetClick,
    } = this.props;
    const {
      rootWidth, rootHeight, imageWidth, imageHeight,
    } = this.state;

    const rootRatio = rootWidth / rootHeight;
    const imageRatio = imageWidth / imageHeight;

    let imgDisplayWidth = rootWidth;
    let imgDisplayHeight = rootHeight;

    if (imageRatio < rootRatio) {
      imgDisplayWidth = (imageRatio / rootRatio) * rootWidth;
    } else {
      imgDisplayHeight = (rootRatio / imageRatio) * rootHeight;
    }

    const loading = !has.call(image, 'url');
    const showImage = has.call(image, 'url') && image.url !== null;
    const hasDimensions = rootWidth > 0 && rootHeight > 0 && imageWidth > 0 && imageHeight > 0;

    return (
      <div
        className={classNames(classes.root, {
          [classes.greyBorder]: !loading && handleImageDrop && !showImage && !isOver,
          [classes.dragOver]: handleImageDrop && isOver,
        })}
      >
        <Spinner show={loading}>
          {!loading
            && handleImageDrop
            && !showImage && (
              <div className={classes.noImage} ref={ref => this.handleDivRef(ref)}>
                <CloudUploadIcon className={classes.icon} />
                <Typography className={classes.text}>Drop image here or click to select</Typography>
              </div>
          )}
          {!loading
            && !handleImageDrop
            && !showImage
            // TODO: show empty state
            && null}
          {!loading
            && showImage && (
              <FloorplanImage
                image={image}
                name={floorplan.name || ''}
                width={hasDimensions ? imgDisplayWidth : 0}
                height={hasDimensions ? imgDisplayHeight : 0}
                widgets={widgets}
                locations={locations}
                scale={scale}
                onDimensionChange={this.handleImageDimensionChange}
                handleLocationDrop={handleLocationDrop}
                handleAnnotationDrop={handleAnnotationDrop}
                handleWidgetMove={handleWidgetMove}
                handleCalloutMove={handleCalloutMove}
                handleRemoveClick={handleRemoveClick}
                handleTextChange={handleTextChange}
                handleWidgetClick={handleWidgetClick}
              />
          )}
          <ReactResizeDetector handleWidth handleHeight onResize={this.handleResize} />
        </Spinner>
      </div>
    );
  }

  render() {
    const { connectDropTarget } = this.props;

    return connectDropTarget(this.renderViewport());
  }
}

FloorplanViewport.propTypes = {
  classes: PropTypes.object.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  image: PropTypes.object,
  floorplan: PropTypes.object.isRequired,
  widgets: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  scale: PropTypes.number.isRequired,
  onScaleChange: PropTypes.func.isRequired,
  handleImageDrop: PropTypes.func,
  handleLocationDrop: PropTypes.func,
  handleAnnotationDrop: PropTypes.func,
  handleWidgetMove: PropTypes.func,
  handleCalloutMove: PropTypes.func,
  handleRemoveClick: PropTypes.func,
  handleTextChange: PropTypes.func,
  handleWidgetClick: PropTypes.func,
  handleFileDialogOpen: PropTypes.func,
};

FloorplanViewport.defaultProps = {
  image: undefined,
  handleImageDrop: undefined,
  handleLocationDrop: undefined,
  handleAnnotationDrop: undefined,
  handleWidgetMove: undefined,
  handleCalloutMove: undefined,
  handleRemoveClick: undefined,
  handleTextChange: undefined,
  handleWidgetClick: undefined,
  handleFileDialogOpen: undefined,
};

export default compose(
  withStyles(styles),
  DropTarget(
    NativeTypes.FILE,
    {
      drop(_, monitor, component) {
        const { files } = monitor.getItem();

        if (files.length === 1) {
          component.handleImageDrop(files[0]);
        }
      },
      canDrop(props) {
        return props.handleImageDrop;
      },
    },
    (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
    }),
  ),
)(FloorplanViewport);
