import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import distanceInWords from 'date-fns/distance_in_words';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import { mixinHeightWithToolbar } from '../constants/theme';
import { formatCellular, formatGps } from '../utils/devices';
import formatValue from '../utils/formatValue';
import GoogleMap from './GoogleMap';
import withMobile from './withMobile';

const styles = {
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
  listItemMap: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 124,
    height: 124, // IE 11
  },
  dialogPaper: {
    minWidth: '75vw',
    maxWidth: '75vw',
    minHeight: '75vh',
    maxHeight: '75vh',
    width: '75vh', // IE 11
    height: '75vh', // IE 11
  },
  dialogMap: {
    display: 'flex',
    flexDirection: 'column',
  },
};

class DevicesDetailsSite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleMapClick() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const {
      classes, widthMobile, site, device,
    } = this.props;
    const { open } = this.state;

    return (
      <>
        <div className={classes.content}>
          <List>
            <ListItem>
              <ListItemText primary="Site" secondary={device.siteName} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Barcode" secondary={device.barcode} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Timestamp" secondary={format(device.timestamp, 'YYYY-MM-DD, h:mm A')} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Version" secondary={device.swVersion} />
            </ListItem>
            <ListItem>
              <ListItemText primary="XBee firmware version" secondary={device.xbeeRadioFw} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Local radio network ID" secondary={device.xbeeNetworkId || 'not set'} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Uptime"
                secondary={distanceInWords(device.uptime * 1000, 0, { includeSeconds: true })}
              />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListSubheader disableSticky>Cellular</ListSubheader>
            <ListItem>
              <ListItemText primary="Cellular signal" secondary={formatCellular(device.modem.csq)} />
            </ListItem>
            <ListItem>
              <ListItemText primary="IP address" secondary={device.ipAddress} />
            </ListItem>
            <ListItem>
              <ListItemText primary="rssi" secondary={device.modem.csq.rssi} />
            </ListItem>
            <ListItem>
              <ListItemText primary="ber" secondary={device.modem.csq.ber} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListSubheader disableSticky>GPS</ListSubheader>
            <ListItem>
              <ListItemText primary="Distance from site" secondary={formatValue(device.distanceFromMainSite, 'm', 0)} />
            </ListItem>
            <ListItem>
              <ListItemText primary="GPS signal" secondary={formatGps(device.modem.gps)} />
            </ListItem>
            <ListItem>
              <ListItemText primary="HDOP" secondary={device.modem.gps.hdop} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Satellite count" secondary={device.modem.gps.nsat} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Latitude" secondary={formatValue(device.modem.gps.lat, '°', 6)} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Longitude" secondary={formatValue(device.modem.gps.lng, '°', 6)} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Altitude" secondary={formatValue(device.modem.gps.alt, 'm', 0)} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListSubheader disableSticky>Geolocation</ListSubheader>
            {device.modem.gps && device.modem.gps.lat && device.modem.gps.lng ? (
              <ListItem className={classes.listItemMap}>
                <GoogleMap
                  center={{
                    lat: device.modem.gps.lat,
                    lng: device.modem.gps.lng,
                  }}
                  cags={[
                    {
                      lat: device.modem.gps.lat,
                      lng: device.modem.gps.lng,
                    },
                  ]}
                  sites={[
                    {
                      lat: site.geolocation.lat,
                      lng: site.geolocation.lon,
                    },
                  ]}
                  isThumbnail
                  handleMapClick={this.handleMapClick}
                />
              </ListItem>
            ) : (
              <ListItem>
                <ListItemText secondary="No available geolocation" />
              </ListItem>
            )}
          </List>
        </div>
        <Dialog
          classes={widthMobile ? undefined : { paper: classes.dialogPaper }}
          fullScreen={widthMobile}
          open={open}
          onClose={this.handleClose}
        >
          <DialogContent className={classes.dialogMap}>
            <GoogleMap
              center={{
                lat: device.modem.gps.lat,
                lng: device.modem.gps.lng,
              }}
              cags={[
                {
                  lat: device.modem.gps.lat,
                  lng: device.modem.gps.lng,
                  title: device.siteName,
                },
              ]}
              sites={[
                {
                  lat: site.geolocation.lat,
                  lng: site.geolocation.lon,
                  title: site.name,
                },
              ]}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

DevicesDetailsSite.propTypes = {
  classes: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  site: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
};

export default compose(
  withMobile(),
  withStyles(styles),
)(DevicesDetailsSite);
