import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import BarChartIcon from '@material-ui/icons/BarChart';
import CloseIcon from '@material-ui/icons/Close';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import LayersIcon from '@material-ui/icons/Layers';
import PeopleIcon from '@material-ui/icons/People';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PlaceIcon from '@material-ui/icons/Place';
import SettingsIcon from '@material-ui/icons/Settings';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import ZoneIcon from '../images/icons/Zone';
import { AbilityContext, Auth } from '../utils/auth';
import { deviceInAlert } from '../utils/devices';
import ListItemLink from './ListItemLink';
import ListItemLinkIcon from './ListItemLinkIcon';

const showPrimary = 0;
const showSecondary = 1;
const swipeRx = window.innerHeight * 0.15;

function setTransform(node, transform) {
  const { style } = node;

  style.webkitTransform = transform;
  style.transform = transform;
}

const styles = theme => ({
  paperWrapper: {
    width: '100%',
    zIndex: theme.zIndex.drawer,
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    maxHeight: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  paper: {
    width: '100%',
    height: '100%',
  },
  paperBorder: {
    borderRadius: '10px 10px 0px 0px',
  },
  scroll: {
    overflowY: 'auto',
  },
  hide: {
    display: 'none',
  },
  count: {
    paddingLeft: 9,
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.secondary,
  },
  alert: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  flex: {
    flex: 1,
  },
  activeRoute: {
    color: theme.palette.secondary.main,
  },
  activeItem: {
    borderRadius: '24px 0px 0px 24px',
    backgroundColor: fade(theme.palette.secondary.light, 0.08),
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.light, 0.08),
    },
  },
  activeCount: {
    color: theme.palette.secondary.main,
    opacity: 0.7,
  },
});

class BottomDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentHeight: -1,
      headerHeight: -1,
      primaryHeight: -1,
      secondaryHeight: -1,
      show: showPrimary,
    };

    this.paperWrapperNode = null;
    this.scrollNode = null;

    this.touchTotalY = 0;
    this.touchLastY = 0;

    this.fullScreen = this.fullScreen.bind(this);
    this.translate = this.translate.bind(this);
    this.handlePaperWrapperRef = this.handlePaperWrapperRef.bind(this);
    this.handleScrollRef = this.handleScrollRef.bind(this);
    this.handleContentHeight = this.handleContentHeight.bind(this);
    this.handleHeaderHeight = this.handleHeaderHeight.bind(this);
    this.handlePrimaryHeight = this.handlePrimaryHeight.bind(this);
    this.handleSecondaryHeight = this.handleSecondaryHeight.bind(this);
    this.handleEntering = this.handleEntering.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderPrimary = this.renderPrimary.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;
    const { open: prevOpen } = prevProps;
    if (!prevOpen && open) {
      this.setState({ show: showPrimary }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  componentWillUnmount() {
    if (this.paperWrapperNode) {
      this.paperWrapperNode.removeEventListener('touchstart', this.handleTouchStart);
    }

    clearAllBodyScrollLocks();
  }

  fullScreen() {
    const {
      contentHeight, headerHeight, primaryHeight, secondaryHeight, show,
    } = this.state;
    return (
      (show === showPrimary && contentHeight < headerHeight + primaryHeight)
      || (show === showSecondary && contentHeight < headerHeight + primaryHeight + secondaryHeight)
    );
  }

  translate(deltaY = 0) {
    const {
      contentHeight, headerHeight, primaryHeight, secondaryHeight, show,
    } = this.state;

    let ty = contentHeight - headerHeight - primaryHeight - deltaY;
    if (this.fullScreen()) {
      ty = Math.max(0, -deltaY);
    } else if (show === showSecondary) {
      ty -= secondaryHeight;
    }

    return `translate(0px, ${ty}px)`;
  }

  handlePaperWrapperRef(ref) {
    if (ref) {
      this.paperWrapperNode = ref;
      this.paperWrapperNode.addEventListener('touchstart', this.handleTouchStart);
    }
  }

  handleScrollRef(ref) {
    if (ref) {
      this.scrollNode = ref;
    }
  }

  handleContentHeight(contentHeight) {
    this.setState({ contentHeight });
  }

  handleHeaderHeight(headerHeight) {
    this.setState({ headerHeight });
  }

  handlePrimaryHeight(primaryHeight) {
    this.setState({ primaryHeight });
  }

  handleSecondaryHeight(secondaryHeight) {
    this.setState({ secondaryHeight });
  }

  handleTouchStart(event) {
    this.touchTotalY = 0;
    this.touchLastY = event.touches[0].pageY;

    this.paperWrapperNode.addEventListener('touchmove', this.handleTouchMove);
    this.paperWrapperNode.addEventListener('touchend', this.handleTouchEnd);
    this.paperWrapperNode.addEventListener('touchcancel', this.handleTouchEnd);
  }

  handleTouchMove(event) {
    const {
      contentHeight, headerHeight, primaryHeight, secondaryHeight, show,
    } = this.state;

    const touchDiff = this.touchLastY - event.touches[0].pageY;

    if (this.fullScreen()) {
      const newTouchTotal = Math.min(this.touchTotalY + touchDiff, show === showPrimary ? secondaryHeight : 0);
      if (this.scrollNode.scrollTop < 1 && touchDiff < 0) {
        this.touchTotalY = newTouchTotal;
      } else if (touchDiff > 0 && this.touchTotalY < 0) {
        this.touchTotalY = newTouchTotal;
        event.preventDefault();
      }
    } else if (show === showSecondary) {
      this.touchTotalY = Math.min(this.touchTotalY + touchDiff, 0);
    } else {
      this.touchTotalY = Math.min(
        this.touchTotalY + touchDiff,
        Math.min(secondaryHeight, contentHeight - headerHeight - primaryHeight),
      );
      if (this.touchTotalY === contentHeight - headerHeight - primaryHeight) {
        this.setState({ show: showSecondary });
      }
    }

    this.touchLastY = event.touches[0].pageY;

    setTransform(this.paperWrapperNode, this.translate(this.touchTotalY));
  }

  handleTouchEnd() {
    const { handleDrawerClose } = this.props;
    const { secondaryHeight } = this.state;

    if (this.touchTotalY < 0 && Math.abs(this.touchTotalY) > swipeRx) {
      handleDrawerClose();
    } else if (
      this.touchTotalY > 0
      && (this.touchTotalY > swipeRx || Math.abs(this.touchTotalY - secondaryHeight) < 1)
    ) {
      this.setState({ show: showSecondary });
    } else {
      setTransform(this.paperWrapperNode, this.translate());
    }

    this.paperWrapperNode.removeEventListener('touchmove', this.handleTouchMove);
    this.paperWrapperNode.removeEventListener('touchend', this.handleTouchEnd);
    this.paperWrapperNode.removeEventListener('touchcancel', this.handleTouchEnd);
  }

  handleEntering(node) {
    setTransform(node, this.translate());
  }

  renderHeader() {
    const { classes, site, handleDrawerClose } = this.props;

    return this.fullScreen() ? (
      <AppBar position="static" color="inherit">
        <Toolbar>
          <Typography className={classes.flex} variant="h6">
            {site.name}
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    ) : (
      <>
        <List>
          <ListItem>
            <ListItemText>{site.name}</ListItemText>
          </ListItem>
        </List>
        <Divider />
      </>
    );
  }

  renderPrimary() {
    const {
      location, classes, siteId, site, handleDrawerClose, devices, invites,
    } = this.props;

    const floorplansCount = site.floorplans.filter(item => item.active).length;
    const zonesCount = site.zones.length;
    const locationsCount = site.locations.length;
    const devicesCount = devices.assignedDevices.reduce(
      (accumulator, item) => accumulator + 1 + item.devices.length,
      0,
    );
    const teamCount = site.contacts.length;
    const invitationsCount = invites.length;

    const zonesAlert = site.locations.some(item => item.sensors.some(sensor => sensor.value.thresholds.some(threshold => threshold.exceeded))); // eslint-disable-line max-len
    const devicesAlert = devices.assignedDevices.some(
      cag => deviceInAlert(cag) || cag.devices.some(device => deviceInAlert(device)),
    );

    return (
      <>
        <List component="nav">
          <Auth do="access" on="floorplans">
            <ListItemLinkIcon
              to="/"
              primary="Floorplans"
              icon={<LayersIcon />}
              count={floorplansCount}
              className={classNames({
                [classes.activeRoute]: location.pathname.indexOf('floorplans') > -1,
              })}
              classNameItem={classNames({
                [classes.activeItem]: location.pathname.indexOf('floorplans') > -1,
              })}
              classNameCount={classNames(
                classes.count,
                {
                  [classes.activeCount]: location.pathname.indexOf('floorplans') > -1,
                },
                { [classes.hide]: floorplansCount === 0 },
              )}
              onClick={handleDrawerClose}
              disableTouchRipple
            />
          </Auth>
          <Auth do="access" on="reports">
            <ListItemLinkIcon
              to={`/site/${siteId}/reports`}
              primary="Reports"
              icon={<BarChartIcon />}
              className={classNames({
                [classes.activeRoute]: location.pathname.indexOf('reports') > -1,
              })}
              classNameItem={classNames({
                [classes.activeItem]: location.pathname.indexOf('reports') > -1,
              })}
              onClick={handleDrawerClose}
              disableTouchRipple
            />
          </Auth>
        </List>
        <Divider />
        <List component="nav">
          <Auth do="access" on="zones">
            <ListItemLinkIcon
              to={`/site/${siteId}/zones`}
              icon={<ZoneIcon />}
              // prettier-ignore
              primary={(
                <Typography>
                  Zones
                  <span
                    className={classNames(classes.count, {
                      [classes.alert]: zonesAlert,
                      [classes.hide]: zonesCount === 0,
                    })}
                  >
                    {zonesCount}
                  </span>
                </Typography>
              )}
              disableTouchRipple
            />
          </Auth>
          <Auth do="access" on="locations">
            <ListItemLinkIcon
              to={`/site/${siteId}/locations`}
              icon={<PlaceIcon />}
              // prettier-ignore
              primary={(
                <Typography>
                  Locations
                  <span
                    className={classNames(classes.count, {
                      [classes.hide]: locationsCount === 0,
                    })}
                  >
                    {locationsCount}
                  </span>
                </Typography>
              )}
              disableTouchRipple
            />
          </Auth>
          <Auth do="access" on="devices">
            <ListItemLinkIcon
              to={`/site/${siteId}/devices`}
              icon={<DeviceHubIcon />}
              // prettier-ignore
              primary={(
                <Typography>
                  Devices
                  <span
                    className={classNames(classes.count, {
                      [classes.alert]: devicesAlert,
                      [classes.hide]: devicesCount === 0,
                    })}
                  >
                    {devicesCount}
                  </span>
                </Typography>
              )}
              disableTouchRipple
            />
          </Auth>
          <Auth do="access" on="team">
            <ListItemLinkIcon
              to={`/site/${siteId}/team`}
              icon={<PeopleIcon />}
              // prettier-ignore
              primary={(
                <Typography>
                  Team
                  <span
                    className={classNames(classes.count, {
                      [classes.hide]: teamCount === 0,
                    })}
                  >
                    {teamCount}
                  </span>
                </Typography>
              )}
              disableTouchRipple
            />
          </Auth>
          <Auth do="access" on="invitations">
            <ListItemLinkIcon
              to={`/site/${siteId}/invitations`}
              icon={<PersonAddIcon />}
              // prettier-ignore
              primary={(
                <Typography>
                  Invitations
                  <span
                    className={classNames(classes.count, {
                      [classes.hide]: invitationsCount === 0,
                    })}
                  >
                    {invitationsCount}
                  </span>
                </Typography>
              )}
              disableTouchRipple
            />
          </Auth>
          <Auth do="access" on="settings">
            <ListItemLinkIcon
              to={`/site/${siteId}/settings`}
              primary="Settings"
              icon={<SettingsIcon />}
              disableTouchRipple
            />
          </Auth>
        </List>
      </>
    );
  }

  renderSecondary = () => (
    <>
      <Divider />
      <List component="nav">
        <ListItemLink to="/profile" primary="View profile" disableTouchRipple />
        <AbilityContext.Consumer>
          {ability => (ability.can('access', 'companies') && ability.can('access', 'users') ? (
            <ListItemLink to="/admin" primary="Admin tools" disableTouchRipple />
          ) : null)
          }
        </AbilityContext.Consumer>
      </List>
    </>
  );

  render() {
    const {
      classes, theme, open, handleDrawerClose,
    } = this.props;
    const {
      contentHeight, headerHeight, primaryHeight, secondaryHeight,
    } = this.state;

    const fullScreen = this.fullScreen();

    const transitionDuration = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen,
    };

    if (this.paperWrapperNode) {
      if (open && !fullScreen) {
        disableBodyScroll(this.paperWrapperNode);
      } else {
        enableBodyScroll(this.paperWrapperNode);
      }
    }

    if (this.scrollNode) {
      if (open && fullScreen) {
        disableBodyScroll(this.scrollNode);
      } else {
        enableBodyScroll(this.scrollNode);
      }
    }

    return (
      <>
        <ReactResizeDetector handleHeight onResize={(_, height) => this.handleContentHeight(height)} />
        <Modal BackdropProps={{ transitionDuration }} open={open} onClose={handleDrawerClose}>
          <Slide
            in={open && contentHeight > 0 && headerHeight > 0 && primaryHeight > 0 && secondaryHeight > 0}
            direction="up"
            onEntering={this.handleEntering}
            timeout={transitionDuration}
          >
            <div
              className={classes.paperWrapper}
              ref={ref => this.handlePaperWrapperRef(ref)}
              style={{ transform: this.translate() }}
            >
              <Paper className={classNames(classes.paper, { [classes.paperBorder]: !fullScreen })} square>
                <div>
                  <ReactResizeDetector handleHeight onResize={(_, height) => this.handleHeaderHeight(height)} />
                  {this.renderHeader()}
                </div>
                <div
                  className={classNames({ [classes.scroll]: fullScreen })}
                  style={{ height: `calc(100% - ${headerHeight}px` }}
                  ref={ref => this.handleScrollRef(ref)}
                >
                  <div>
                    <ReactResizeDetector handleHeight onResize={(_, height) => this.handlePrimaryHeight(height)} />
                    {this.renderPrimary()}
                  </div>
                  <div>
                    <ReactResizeDetector handleHeight onResize={(_, height) => this.handleSecondaryHeight(height)} />
                    {this.renderSecondary()}
                  </div>
                </div>
              </Paper>
            </div>
          </Slide>
        </Modal>
      </>
    );
  }
}

BottomDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  siteId: PropTypes.string.isRequired,
  site: PropTypes.object.isRequired,
  devices: PropTypes.object.isRequired,
  invites: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
)(BottomDrawer);
