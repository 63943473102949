import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import MessageIcon from '@material-ui/icons/Message';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import { mixinHeightWithToolbar } from '../constants/theme';
import SitesCard from './SitesCard';
import withMobile from './withMobile';

const styles = theme => ({
  container: {
    width: '100%',
    overflowY: 'auto',
    padding: `${theme.spacing.unit * 2}px 0px`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing.unit * 3}px 0px`,
    },
    ...mixinHeightWithToolbar(),
    ...theme.mixins.gutters(),
  },
  title: {
    padding: `${theme.spacing.unit * 4}px 0px ${theme.spacing.unit}px`,
  },
  icons: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '16px 24px',
  },
  label: {
    marginTop: -4,
  },
});

class Sites extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false, user: undefined };

    this.handleClose = this.handleClose.bind(this);
    this.handleSiteAdminClick = this.handleSiteAdminClick.bind(this);
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleSiteAdminClick(item) {
    this.setState({ open: true, user: item });
  }

  render() {
    const {
      classes, widthMobile, sitesInAlert, recentSites, otherSites, handleSiteClick,
    } = this.props;

    const { open, user } = this.state;

    return (
      <>
        <div className={classes.container}>
          {sitesInAlert.length !== 0 && (
            <Grid container spacing={widthMobile ? 16 : 24}>
              {sitesInAlert.map(item => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={item.id}>
                  <SitesCard
                    site={item}
                    handleSiteClick={handleSiteClick}
                    handleSiteAdminClick={this.handleSiteAdminClick}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          {recentSites.length !== 0 && (
            <>
              <Typography className={classes.title} color="textSecondary" variant="body2">
                Recent
              </Typography>
              <Grid container spacing={widthMobile ? 16 : 24}>
                {recentSites.map(item => (
                  <Grid item xs={12} md={6} lg={4} xl={3} key={item.id}>
                    <SitesCard
                      site={item}
                      handleSiteClick={handleSiteClick}
                      handleSiteAdminClick={this.handleSiteAdminClick}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {otherSites.length !== 0 && (
            <>
              <Typography className={classes.title} color="textSecondary" variant="body2">
                Other
              </Typography>
              <Grid container spacing={widthMobile ? 16 : 24}>
                {otherSites.map(item => (
                  <Grid item key={item.id} xs={12} md={6} lg={4} xl={3}>
                    <SitesCard
                      site={item}
                      handleSiteClick={handleSiteClick}
                      handleSiteAdminClick={this.handleSiteAdminClick}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </div>
        <Dialog maxWidth="xs" fullWidth open={open} onClose={this.handleClose}>
          {open && (
            <>
              <DialogTitle>{user.name}</DialogTitle>
              <div className={classes.icons}>
                <IconButton color="secondary" href={`tel:${user.mobile}`}>
                  <div>
                    <CallIcon />
                    <Typography className={classes.label} variant="caption" color="secondary">
                      Call
                    </Typography>
                  </div>
                </IconButton>
                <IconButton
                  color="secondary"
                  href={`${window.navigator.platform === 'MacIntel' ? 'imessage' : 'sms'}:${user.mobile}`}
                >
                  <div>
                    <MessageIcon />
                    <Typography className={classes.label} variant="caption" color="secondary">
                      Text
                    </Typography>
                  </div>
                </IconButton>
                <IconButton color="secondary" href={`mailto:${user.email}`}>
                  <div>
                    <EmailIcon />
                    <Typography className={classes.label} variant="caption" color="secondary">
                      Email
                    </Typography>
                  </div>
                </IconButton>
              </div>
              <List>
                <ListItem>
                  <ListItemText primary="Company" secondary={user.company} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Role" secondary={user.role} />
                </ListItem>
              </List>
              <DialogActions>
                <Button color="secondary" autoFocus onClick={this.handleClose}>
                  Close
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </>
    );
  }
}

Sites.propTypes = {
  classes: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  sitesInAlert: PropTypes.arrayOf(PropTypes.object).isRequired,
  recentSites: PropTypes.arrayOf(PropTypes.object).isRequired,
  otherSites: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSiteClick: PropTypes.func.isRequired,
};

export default compose(
  withMobile(),
  withStyles(styles),
)(Sites);
