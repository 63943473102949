import PropTypes from 'prop-types';
import React from 'react';
import ZonesComponent from '../components/Zones';
import {
  apiDeleteThreshold,
  apiDeleteZone,
  apiGetSite,
  apiGetUserAlerts,
  apiModifyThreshold,
  apiModifyZone,
} from '../utils/api';

function Zones(props) {
  const { siteId, handleBackClick } = props;

  return (
    <ZonesComponent
      site={apiGetSite(siteId)}
      alerts={apiGetUserAlerts()}
      handleBackClick={handleBackClick}
      handleDeleteThresholdClick={apiDeleteThreshold}
      handleSaveThresholdClick={apiModifyThreshold}
      handleDeleteZoneClick={apiDeleteZone}
      handleSaveZoneClick={apiModifyZone}
    />
  );
}

Zones.propTypes = {
  siteId: PropTypes.string.isRequired,
  handleBackClick: PropTypes.func.isRequired,
};

export default Zones;
