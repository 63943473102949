import MuiAppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import { Auth } from '../utils/auth';
import MoreVertIcon from './MoreVertIcon';
import NotificationsIcon from './NotificationsIcon';
import SearchIcon from './SearchIcon';
import Tooltip from './Tooltip';
import withMobile from './withMobile';

const styles = theme => ({
  appBar: {
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      boxShadow: '0 2px 2px -1px rgba(153, 153, 153, 0.3), 0 1px 5px -2px rgba(153, 153, 153, 0.3)',
    },
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  backIcon: {
    marginRight: 16,
    marginLeft: -16,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: -12,
    [theme.breakpoints.up('md')]: {
      marginRight: -24,
    },
  },
  iconSpacing: {
    [theme.breakpoints.up('md')]: {
      padding: '0px 40px',
    },
  },
  buttonSave: {
    textTransform: 'none',
  },
  buttonAddSite: {
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      marginRight: 40,
    },
  },
});

class AppBar extends React.Component {
  constructor(props) {
    super(props);

    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
  }

  handleMenuItemClick(item) {
    const { handleEditClick, handleMoveClick, handleDeleteClick } = this.props;

    if (item === 'Edit') {
      handleEditClick();
    } else if (item === 'Move') {
      handleMoveClick();
    } else if (item === 'Delete') {
      handleDeleteClick();
    }
  }

  render() {
    const {
      classes,
      children,
      widthMobile,
      title,
      search,
      alerts,
      handleBackClick,
      handleEditClick,
      handleMoveClick,
      handleDeleteClick,
      handleSaveClick,
      handleAddSiteClick,
    } = this.props;

    return (
      <MuiAppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.titleContainer}>
            {handleBackClick !== undefined && (
              <IconButton className={classes.backIcon} onClick={handleBackClick}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant="h6">{title}</Typography>
          </div>
          <div className={classes.iconContainer}>
            {handleEditClick !== undefined && handleMoveClick !== undefined && handleDeleteClick !== undefined ? (
              <MoreVertIcon
                menuItems={['Edit', 'Delete', 'Move']}
                placement="bottom-end"
                handleMenuItemClick={this.handleMenuItemClick}
              />
            ) : (
              <>
                {handleEditClick !== undefined && (
                  <Tooltip title="Edit">
                    <IconButton onClick={handleEditClick}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {handleDeleteClick !== undefined && (
                  <Tooltip title="Delete">
                    <IconButton onClick={handleDeleteClick}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {handleSaveClick !== undefined && (
                  <Button className={classes.buttonSave} color="secondary" onClick={handleSaveClick}>
                    Save
                  </Button>
                )}
                {widthMobile === false
                  && handleAddSiteClick !== undefined && (
                    <Auth do="modify" on="settings">
                      <Button
                        className={classes.buttonAddSite}
                        variant="outlined"
                        color="secondary"
                        onClick={handleAddSiteClick}
                      >
                        Create a site
                      </Button>
                    </Auth>
                )}
                {search && <SearchIcon color="inherit" />}
                {alerts !== undefined && (
                  <div className={classes.iconSpacing}>
                    <NotificationsIcon alerts={alerts} color="inherit" />
                  </div>
                )}
              </>
            )}
          </div>
        </Toolbar>
        {children}
      </MuiAppBar>
    );
  }
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  widthMobile: PropTypes.bool.isRequired,
  title: PropTypes.string,
  search: PropTypes.bool,
  alerts: PropTypes.arrayOf(PropTypes.object),
  handleBackClick: PropTypes.func,
  handleEditClick: PropTypes.func,
  handleMoveClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleSaveClick: PropTypes.func,
  handleAddSiteClick: PropTypes.func,
};

AppBar.defaultProps = {
  children: null,
  title: null,
  search: false,
  alerts: undefined,
  handleBackClick: undefined,
  handleEditClick: undefined,
  handleMoveClick: undefined,
  handleDeleteClick: undefined,
  handleSaveClick: undefined,
  handleAddSiteClick: undefined,
};

export default compose(
  withMobile(),
  withStyles(styles),
)(AppBar);
