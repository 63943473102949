import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import Amplify, { Signer } from '@aws-amplify/core';
import format from 'date-fns/format';
import { Client, Message } from 'paho-mqtt';
import Pako from 'pako';
import uuidv4 from 'uuid/v4';
import awsExports from '../constants/awsExports';
import deepCopy from './deepCopy';
import {
  sortAlerts, sortByBarcode, sortByDistanceFromMainSite, sortByName, sortSensors, sortUserSites,
} from './sort';

global.Paho = global.Paho || { MQTT: { Client, Message } };

let appHandleAuthentication;
let appHandleNotify;

/* eslint-disable no-console */

function emptyCompany(id, name) {
  return {
    id,
    name,
    sites: [],
  };
}

function emptyDevices() {
  return {
    assignedDevices: [],
    nearbyDevices: [],
    unassignedDevices: [],
  };
}

function emptyFloorplan() {
  return {
    image: {},
    widgets: [],
  };
}

function emptyLocationData() {
  return {
    sensors: [],
    heater: null,
  };
}

function emptySensor() {
  return {
    thresholds: [],
    thumbnail: [],
  };
}

function emptySite() {
  return {
    alerts: { alertingDevices: [] },
    company: {},
    contacts: [],
    floorplans: [],
    key: undefined,
    locations: [],
    zones: [],
  };
}

function emptySiteSummary() {
  return {
    alerts: [],
    devices: [],
    thresholds: [],
    views: [],
  };
}

function emptyUser() {
  return {
    recent: [],
    sites: [],
    roles: [],
    key: undefined,
  };
}

class Session {
  constructor() {
    this.data = {
      companyList: [],
      companies: {},
      signedInUser: undefined,
      devices: undefined,
      images: {},
      invites: {},
      locationData: {},
      permissions: undefined,
      sites: {},
      alerts: undefined,
      userList: [],
      users: {},
    };
    this.subscriptions = {
      companyList: undefined,
      company: undefined,
      signedInUser: undefined,
      site: undefined,
      sites: undefined,
      user: undefined,
      userList: undefined,
    };
    this.companyId = undefined;
    this.currentSiteId = undefined;
    this.signedInUserId = undefined;
    this.userId = undefined;
    this.recent = {
      siteId: 'undefined',
      viewId: 'undefined',
    };
  }
}

let session = new Session();

Amplify.configure(awsExports);

function comparePackets(packet1, packet2) {
  if (!packet1 && !packet2) return undefined;
  if (packet1 && !packet2) return packet1;
  if (!packet1 && packet2) return packet2;

  const { timestamp: packet1Timestamp } = packet1;
  const { timestamp: packet2Timestamp } = packet2;

  return packet1Timestamp >= packet2Timestamp ? packet1 : packet2;
}

function setImageUrl(floorplan, siteId) {
  const { image } = floorplan;

  if (image) {
    const { endpoint } = awsExports.API.endpoints.find(item => item.name === 'APIGatewayAPI');
    image.url = image.id ? `${endpoint}/sites/${siteId}/image/${image.id}` : null;
  }
}

function setWidgetLocations(widgetId, floorplanId, siteId) {
  const site = session.data.sites[siteId] && session.data.sites[siteId].source;
  if (site) {
    const floorplan = site.floorplans.find(item => item.id === floorplanId);
    if (floorplan) {
      for (let i = 0; i < site.locations.length; i += 1) {
        const location = site.locations[i];
        const index = floorplan.widgets.findIndex(item => item.locationConfigId === location.id);

        if (index >= 0) {
          floorplan.widgets[index] = { locationName: location.name, ...floorplan.widgets[index] };
        }
      }
      sortByName(floorplan.widgets, 'locationName');
    }
  }
}

function setWidgetsLocations(floorplanId, siteId) {
  const site = session.data.sites[siteId] && session.data.sites[siteId].source;
  if (site) {
    const floorplan = site.floorplans.find(item => item.id === floorplanId);
    if (floorplan) floorplan.widgets.forEach(item => setWidgetLocations(item.id, floorplanId, siteId));
  }
}

function setZoneLocations(zoneId, siteId) {
  const site = session.data.sites[siteId] && session.data.sites[siteId].source;
  if (site) {
    const zone = site.zones.find(item => item.id === zoneId);
    if (zone) {
      for (let i = 0; i < site.locations.length; i += 1) {
        const location = site.locations[i];
        const index = zone.locations.findIndex(item => item.id === location.id);

        if (index >= 0) {
          zone.locations[index] = { name: location.name, ...zone.locations[index] };
        }
      }
      sortByName(zone.locations);
    }
  }
}

function setZonesLocations(siteId) {
  const site = session.data.sites[siteId] && session.data.sites[siteId].source;
  if (site) site.zones.forEach(item => setZoneLocations(item.id, siteId));
}

function handlePacketCompanyList(packet) {
  if (session.data.companyList.length && session.data.companyList[0].timestamp >= packet[0].timestamp) {
    return;
  }

  session.data.companyList = sortByName(packet.map(company => company.source));
}

function handlePacketCompany(packet) {
  session.data.companies[packet.source.id] = comparePackets(session.data.companies[packet.source.id], packet);
  const { sites } = session.data.companies[packet.source.id].source;
  sortByName(sites);
}

function handlePacketLocation(packet) {
  session.data.locationData[packet.source.locationConfigId] = comparePackets(
    session.data.locationData[packet.source.locationConfigId],
    packet,
  );
}

function handlePacketDevices(packet) {
  session.data.devices = comparePackets(session.data.devices, packet);

  if (session.data.devices) {
    const { assignedDevices, unassignedDevices, nearbyDevices } = session.data.devices.source;
    assignedDevices.forEach(item => sortByBarcode(item.devices));
    sortByBarcode(assignedDevices);
    sortByBarcode(unassignedDevices);
    sortByDistanceFromMainSite(nearbyDevices);
  }
}

function handlePacketSiteConfig(packet) {
  const keyedPacket = packet;
  keyedPacket.source.key = uuidv4();
  session.data.sites[keyedPacket.source.id] = comparePackets(session.data.sites[keyedPacket.source.id], keyedPacket);
  const {
    id, contacts, floorplans, locations, zones,
  } = session.data.sites[keyedPacket.source.id].source;

  sortByName(contacts);
  floorplans.forEach((item) => {
    setImageUrl(item, id);
    setWidgetsLocations(item.id, id);
  });
  sortByName(floorplans);
  setZonesLocations(id);
  locations.forEach(item => sortSensors(item.sensors));
  sortByName(locations);
  zones.forEach(item => sortByName(item.thresholds, 'sensorType'));
  sortByName(zones);

  if (id === session.currentSiteId && session.data.permissions) appHandleAuthentication(true);
}

function handlePacketInvite(packet) {
  session.data.invites[packet.source.id] = comparePackets(session.data.invites[packet.source.id], packet);
}

function handlePacketUserList(packet) {
  if (session.data.userList.length && session.data.userList[0].timestamp >= packet[0].timestamp) {
    return;
  }

  session.data.userList = sortByName(packet.map(user => user.source));
}

function handlePacketUser(packet) {
  const keyedPacket = packet;
  keyedPacket.source.key = uuidv4();
  const { id, recent } = keyedPacket.source;

  const seen = new Set();
  const filteredRecents = recent
    .filter(item => item.site && item.site !== null)
    .filter(item => (seen.has(item.site.id) ? false : seen.add(item.site.id)));
  if (filteredRecents.length > 3) filteredRecents.splice(3, filteredRecents.length - 3);
  keyedPacket.source.recent = filteredRecents;
  sortUserSites(keyedPacket.source.sites);

  if (id === session.signedInUserId) {
    session.data.signedInUser = comparePackets(session.data.signedInUser, keyedPacket);
    if (session.data.permissions) appHandleAuthentication(true);
  }
  session.data.users[id] = comparePackets(session.data.users[id], keyedPacket);
}

function handlePackets(message) {
  try {
    const packetString = Pako.inflate(message.payloadBytes, { to: 'string' });
    let packets = JSON.parse(packetString);

    if (!Array.isArray(packets)) {
      packets = [packets];
    }

    if (packets.length) {
      switch (packets[0].type) {
        case 'COMPANY_PREVIEW':
          console.log(
            packetString.length / message.payloadBytes.length,
            message.payloadBytes.length,
            format(packets[0].timestamp, 'HH:mm:ss'),
            'COMPANY_PREVIEW" ',
            packets,
          );
          handlePacketCompanyList(packets);
          break;
        case 'USER_PREVIEW':
          console.log(
            packetString.length / message.payloadBytes.length,
            message.payloadBytes.length,
            format(packets[0].timestamp, 'HH:mm:ss'),
            'USER_PREVIEW" ',
            packets,
          );
          handlePacketUserList(packets);
          break;
        case 'INVITE':
          session.data.invites = {};
          packets.forEach((packet) => {
            console.log(
              packetString.length / message.payloadBytes.length,
              message.payloadBytes.length,
              format(packet.timestamp, 'HH:mm:ss'),
              packet,
            );
            handlePacketInvite(packet);
          });
          break;
        default:
          packets.forEach((packet) => {
            console.log(
              packetString.length / message.payloadBytes.length,
              message.payloadBytes.length,
              format(packet.timestamp, 'HH:mm:ss'),
              packet,
            );
            const { type } = packet;
            switch (type) {
              case 'LOCATION_DATA':
                handlePacketLocation(packet);
                break;
              case 'ALERTS':
                // Coming soon...
                return;
              case 'DEVICES':
                handlePacketDevices(packet);
                break;
              case 'SITE_CONFIG':
                handlePacketSiteConfig(packet);
                break;
              case 'COMPANY':
                handlePacketCompany(packet);
                break;
              case 'USER':
                handlePacketUser(packet);
                break;
              default:
                console.error('Unknown packet', packet);
            }
          });
      }
      appHandleNotify();
    }
  } catch (err) {
    console.log('packet handler', err);
  }
}

function unsubscribe(topic) {
  if (session.subscriptions[topic]) {
    if (session.subscriptions[topic].isConnected()) session.subscriptions[topic].disconnect();
    session.subscriptions[topic] = undefined;
  }
}

function unsubscribeAll() {
  Object.keys(session.subscriptions).forEach((key) => {
    unsubscribe(key);
  });
}

async function mqttEndpoint() {
  const endpoint = `wss://${awsExports.PubSub.endpoint}/mqtt`;
  const serviceInfo = {
    service: 'iotdevicegateway',
    region: awsExports.PubSub.region,
  };
  const { accessKeyId, secretAccessKey, sessionToken } = await Auth.currentCredentials();

  return Signer.signUrl(
    endpoint,
    { access_key: accessKeyId, secret_key: secretAccessKey, session_token: sessionToken },
    serviceInfo,
  );
}

function connectMqtt(topic, topicPaths) {
  console.log('subscribing', topicPaths);
  unsubscribe(topic);

  return mqttEndpoint().then((endpoint) => {
    const client = new Client(endpoint, uuidv4());
    client.onMessageArrived = message => handlePackets(message);
    client.onConnectionLost = (response) => {
      console.log('Lost PubSub connection', response, topicPaths);
      if (response.errorCode !== 0) connectMqtt(topic, topicPaths);
    };

    client.connect({
      useSSL: true,
      mqttVersion: 3,
      reconnect: false,
      onSuccess: () => {
        let topics = topicPaths;
        if (!Array.isArray(topics)) {
          topics = [topics];
        }

        topics.forEach(topicPath => client.subscribe(topicPath, {
          qos: 1,
          onSuccess: () => {
            session.subscriptions[topic] = client;
          },
          onFailure: (result) => {
            console.log(result);
            session.subscriptions[topic] = undefined;
          },
        }));
      },
      onFailure: result => console.log(result),
    });
    return client;
  });
}

function subscribe(topic, objectId) {
  const { stage } = awsExports.PubSub;

  if (appHandleNotify) {
    switch (topic) {
      case 'companies':
        connectMqtt('companyList', `${stage}/${session.signedInUserId}/companies`);
        break;
      case 'company':
        connectMqtt('company', `${stage}/${session.signedInUserId}/companies/${objectId}`);
        break;
      case 'signedInUser':
        connectMqtt('signedInUser', `${stage}/${session.signedInUserId}/users/${session.signedInUserId}`);
        connectMqtt('sites', `${stage}/${session.signedInUserId}/sites`);
        break;
      case 'site':
        connectMqtt('site', `${stage}/${session.signedInUserId}/sites/${objectId}`);
        break;
      case 'user':
        connectMqtt('user', `${stage}/${session.signedInUserId}/users/${objectId}`);
        break;
      case 'users':
        connectMqtt('userList', `${stage}/${session.signedInUserId}/users`);
        break;
      default:
    }
  }
}

//
// Companies
//

export function apiGetCompanies() {
  return session.data.companyList;
}

export function apiGetCompany(companyId) {
  if (session.companyId !== companyId) {
    session.companyId = companyId;
    subscribe('company', companyId);
  }

  const company = session.data.companies[companyId];
  if (company) return company.source;

  const preview = session.data.companyList.find(item => item.id === companyId);
  if (preview) return emptyCompany(preview.id, preview.name);

  return emptyCompany();
}

function modifyCompany(company) {
  const { id, name } = company;

  const oldCompany = session.data.companies[id] || {
    source: emptyCompany(),
    timestamp: 0,
    type: 'COMPANY',
  };
  session.data.companies[id] = {
    source: { ...oldCompany.source, ...company },
    timestamp: oldCompany.timestamp,
    type: oldCompany.type,
  };

  const companyPreview = { id, name };
  const index = session.data.companyList.findIndex(item => item.id === id);
  if (index >= 0) {
    session.data.companyList[index] = companyPreview;
  } else {
    session.data.companyList.push(companyPreview);
  }
  sortByName(session.data.companyList);

  appHandleNotify();
}

export function apiModifyCompany(company, companyId = undefined) {
  const parameters = {
    body: company,
  };

  if (companyId) {
    return API.put('APIGatewayAPI', `/companies/${companyId}`, parameters).then((reply) => {
      modifyCompany(reply.company);
      return Promise.resolve(reply);
    });
  }
  return API.post('APIGatewayAPI', '/companies', parameters).then((reply) => {
    modifyCompany(reply.company);
    return Promise.resolve(reply);
  });
}

export function apiDeleteCompany(companyId) {
  return API.del('APIGatewayAPI', `/companies/${companyId}`).then((reply) => {
    const index = session.data.companyList.findIndex(item => item.id === companyId);
    if (index >= 0) {
      session.data.companyList.splice(index, 1);
      appHandleNotify();
    }
    return Promise.resolve(reply);
  });
}

//
// Users
//

function getUserDoc(userId) {
  return API.get('APIGatewayAPI', `/users/${userId}`)
    .then((reply) => {
      console.log(reply);
      handlePacketUser(reply);
      return Promise.resolve(true);
    })
    .catch((error) => {
      console.error(`getSignedInUserDoc: ${error}`);
      return Promise.resolve(false);
    });
}

export function apiGetUsers() {
  return session.data.userList;
}

export function apiGetSignedInUser() {
  return session.data.signedInUser ? session.data.signedInUser.source : undefined;
}

export function apiGetUser(userId) {
  if (session.userId !== userId) {
    session.userId = userId;
    getUserDoc(userId).then(() => appHandleNotify());
    subscribe('user', userId);
  }
  return session.data.users[userId] ? session.data.users[userId].source : emptyUser();
}

function modifyUser(user) {
  const { company, id, name } = user;

  const oldUser = session.data.users[id] || {
    source: emptyUser(),
    timestamp: 0,
    type: 'USER',
  };
  const newUser = {
    source: { ...oldUser.source, ...user },
    timestamp: oldUser.timestamp,
    type: oldUser.type,
    key: uuidv4(),
  };

  if (id === session.signedInUserId) session.data.signedInUser = newUser;
  session.data.users[id] = newUser;

  const userPreview = {
    id,
    name,
    company,
    role: oldUser.source.role,
  };
  const index = session.data.userList.findIndex(item => item.id === id);
  if (index >= 0) {
    session.data.userList[index] = userPreview;
  } else {
    session.data.userList.push(userPreview);
  }
  sortByName(session.data.userList);

  appHandleNotify();
}

export function apiModifyUser(user, userId = undefined) {
  const parameters = {
    body: user,
  };

  if (userId) {
    return API.put('APIGatewayAPI', `/users/${userId}`, parameters).then((reply) => {
      modifyUser(reply.user);
      return Promise.resolve(reply);
    });
  }

  return API.post('APIGatewayAPI', '/users', parameters).then((reply) => {
    console.log(reply);
    return Promise.resolve(reply);
  });
}

export function apiDeleteUser(userId) {
  return API.del('APIGatewayAPI', `/users/${userId}`).then((reply) => {
    const index = session.data.userList.findIndex(item => item.id === userId);
    if (index >= 0) {
      session.data.userList.splice(index, 1);
      appHandleNotify();
    }
    return Promise.resolve(reply);
  });
}

//
// Devices
//

export function apiGetDevices() {
  return session.data.devices ? session.data.devices.source : emptyDevices();
}

export function apiAssignDevice(barcode, siteId) {
  return API.post('APIGatewayAPI', `/sites/${siteId}/devices/${barcode}/assign`)
    .then(reply => Promise.resolve(reply))
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

export function apiUnassignDevice(barcode, siteId) {
  return API.post('APIGatewayAPI', `/sites/${siteId}/devices/${barcode}/unassign`)
    .then(reply => Promise.resolve(reply))
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

export function apiFastFindDevice(barcode, siteId) {
  return API.post('APIGatewayAPI', `/sites/${siteId}/devices/${barcode}/fastfind`)
    .then(reply => Promise.resolve(reply))
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

export function apiUpdateDeviceNetwork(barcode, siteId, XBeeNetworkId) {
  const params = {
    body: {
      xbeeNetworkId: XBeeNetworkId,
    },
  };

  return API.post('APIGatewayAPI', `/sites/${siteId}/devices/${barcode}/network`, params)
    .then(reply => Promise.resolve(reply))
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

//
// Locations
//

function findLocationData(locationId) {
  return session.data.locationData[locationId] ? session.data.locationData[locationId].source : emptyLocationData();
}

export function apiGetLocations() {
  const locations = session.data.sites[session.currentSiteId]
    ? session.data.sites[session.currentSiteId].source.locations
    : emptySite().locations;

  for (let i = 0; i < locations.length; i += 1) {
    const location = locations[i];
    const locationData = findLocationData(location.id);
    location.timestamp = locationData.timestamp;
    for (let j = 0; j < location.sensors.length; j += 1) {
      location.sensors[j].value = locationData.sensors.find(item => item.sensorConfigId === location.sensors[j].id) || emptySensor(); // eslint-disable-line max-len
    }
    if (location.heater && location.heater !== null && locationData.heater && locationData.heater !== null) {
      location.heater = { ...location.heater, ...locationData.heater };
    }
  }
  return deepCopy(locations);
}

function modifyLocation(location, siteId) {
  const site = session.data.sites[siteId] && session.data.sites[siteId].source;
  if (site) {
    sortSensors(location.sensors);
    const index = site.locations.findIndex(item => item.id === location.id);

    if (index >= 0) {
      site.locations[index] = location;
    } else {
      site.locations.push(location);
    }
    setZonesLocations(siteId);
    sortByName(site.locations);
    appHandleNotify();
  }
}

export function apiModifyLocation(location, siteId, locationId = undefined) {
  const parameters = {
    body: location,
  };

  if (locationId) {
    return API.put('APIGatewayAPI', `/sites/${siteId}/locations/${locationId}`, parameters)
      .then((reply) => {
        modifyLocation(reply.location, siteId);
        return Promise.resolve(reply);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }
  return API.post('APIGatewayAPI', `/sites/${siteId}/locations`, parameters)
    .then((reply) => {
      modifyLocation(reply.location, siteId);
      return Promise.resolve(reply);
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

export function apiDeleteLocation(siteId, locationId) {
  return API.del('APIGatewayAPI', `/sites/${siteId}/locations/${locationId}`)
    .then((reply) => {
      const site = session.data.sites[siteId] && session.data.sites[siteId].source;
      if (site) {
        const index = site.locations.findIndex(item => item.id === locationId);
        if (index >= 0) {
          site.locations.splice(index, 1);
          appHandleNotify();
        }
      }
      return Promise.resolve(reply);
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

//
// Sites
//

function getSiteSummary(site) {
  const summary = emptySiteSummary();
  const locations = apiGetLocations();

  summary.alerts = (session.data.alerts && session.data.alerts.source.filter(item => item.siteId === site.id)) || [];

  summary.views = site.floorplans.map(item => ({
    viewId: item.id,
    inAlert: item.widgets.some(widget => locations.some(
      loc => loc.id === widget.locationConfigId
          && loc.sensors.some(sensor => sensor.value.thresholds.some(thresh => thresh.exceeded)),
    )),
  }));

  return summary;
}

function findSite(siteId) {
  let site = session.data.sites[siteId] && session.data.sites[siteId].source;
  if (!site) {
    site = emptySite();
    const user = apiGetSignedInUser();
    if (user) {
      const sitePreview = user.sites.find(item => item.site.id === siteId);
      if (sitePreview) {
        site.company = sitePreview.company;
        site.id = sitePreview.site.id;
        site.name = sitePreview.site.name;
      }
    }
  }
  site.summary = getSiteSummary(site);
  return site;
}

export function apiGetSite(siteId) {
  return deepCopy(findSite(siteId));
}

export function apiGetCurrentSite() {
  return deepCopy(findSite(session.currentSiteId));
}

export function apiGetDefaultSiteId() {
  const signedInUser = apiGetSignedInUser();
  if (signedInUser) {
    if (signedInUser.recent.length > 0) {
      return signedInUser.recent[0].site.id;
    }
    if (signedInUser.sites.length > 0) {
      return signedInUser.sites[0].site.id;
    }
    return 'undefined';
  }
  return undefined;
}

export function apiGetSites() {
  const user = apiGetSignedInUser();
  if (user) {
    const sites = [];
    user.sites.forEach(item => sites.push(findSite(item.site.id)));
    return sites;
  }
  return [];
}

export function apiSetCurrentSite(siteId) {
  if (session.currentSiteId !== siteId) {
    session.data.devices = undefined;
    session.data.invites = {};
    session.currentSiteId = siteId;
    subscribe('site', siteId);
    if (session.data.permissions) appHandleAuthentication(true);
  }
}

function modifySite(site) {
  const {
    alerts, id, name, company, geolocation, reportingInterval,
  } = site;
  const key = uuidv4();

  if (session.data.sites[id]) {
    const cachedSite = session.data.sites[id].source;
    cachedSite.company = { id: company };
    cachedSite.geolocation = geolocation;
    cachedSite.key = key;
    cachedSite.name = name;
    cachedSite.reportingInterval = reportingInterval;
  } else {
    const newSite = emptySite();
    newSite.alerts = alerts;
    newSite.company = { id: company };
    newSite.id = id;
    newSite.key = key;
    newSite.name = name;
    newSite.reportingInterval = reportingInterval;

    session.data.sites[id] = {
      source: newSite,
      timestamp: 0,
      type: 'SITE_CONFIG',
    };
  }

  if (!session.data.signedInUser.source.sites.some(item => item.site.id === id)) {
    session.data.signedInUser.source.sites.push({
      company,
      isMember: true,
      role: 'Support',
      site: {
        id,
        name,
      },
    });
  }
  appHandleNotify();
}

export function apiModifySite(site, id = undefined) {
  const parameters = {
    body: site,
  };

  if (id) {
    return API.put('APIGatewayAPI', `/sites/${id}`, parameters)
      .then((reply) => {
        modifySite(reply.site);
        return Promise.resolve(reply.site);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }
  return API.post('APIGatewayAPI', '/sites/', parameters)
    .then((reply) => {
      const { company, id: siteId, name } = reply.site;
      const alertingParameters = {
        body: {},
      };

      return API.post('APIGatewayAPI', `/sites/${siteId}/alerts/`, alertingParameters)
        .then((reply2) => {
          const newSite = emptySite();
          newSite.alerts = reply2.alert;
          newSite.company = { id: company };
          newSite.id = siteId;
          newSite.name = name;

          modifySite(newSite);
          return Promise.resolve(newSite);
        })
        .catch((err) => {
          console.log(err);
          return Promise.reject(err);
        });
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

export function apiDeleteSite(site) {
  const { id } = site;
  return API.del('APIGatewayAPI', `/sites/${id}/`)
    .then((reply) => {
      const currentUser = session.data.signedInUser.source;
      delete session.data.sites[id];
      currentUser.sites = currentUser.sites.filter(item => item.site && item.site.id !== id);
      currentUser.recent = currentUser.recent.filter(item => item.site && item.site.id !== id);

      const keys = Object.keys(session.data.users);
      keys.forEach((key) => {
        const user = session.data.users[key].source;
        user.sites.filter(item => item.site && item.site.id !== id);
        user.recent.filter(item => item.site && item.site.id !== id);
      });

      appHandleNotify();
      return Promise.resolve(reply);
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

function modifyAlerts(alerts, siteId) {
  const key = uuidv4();

  if (session.data.sites[siteId]) {
    const cachedSite = session.data.sites[siteId].source;
    cachedSite.alerts = alerts;
    cachedSite.key = key;
  }
  appHandleNotify();
}

export function apiModifySiteAlerts(alerts, siteId) {
  const parameters = {
    body: alerts,
  };

  parameters.body = alerts;
  return API.put('APIGatewayAPI', `/sites/${siteId}/alerts/${alerts.id}`, parameters)
    .then((reply) => {
      modifyAlerts(reply.alert, siteId);
      return Promise.resolve(reply.alert);
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

//
// Heater
//

export function apiAcceptHeaterWarning() {
  const userId = session.signedInUserId;
  const siteId = session.currentSiteId;

  return API.post('APIGatewayAPI', `/sites/${siteId}/contacts/${userId}/acceptHeaterWarning`)
    .then((reply) => {
      const site = session.data.sites[siteId] && session.data.sites[siteId].source;
      if (site) {
        const contact = site.contacts.find(item => item.userId === userId);
        if (contact) {
          contact.heaterWarningAccepted = true;
          appHandleNotify();
        }
      }
      return Promise.resolve(reply);
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

//
// Invites
//

export function apiGetNameFromEmail(email) {
  return API.get('APIGatewayAPI', `/users/email/${email}`)
    .then(reply => Promise.resolve(reply.user_data))
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

export function apiGetInvites() {
  return sortByName(Object.values(session.data.invites).map(item => item.source), 'email');
}

function modifyInvite(invite) {
  session.data.invites[invite.id] = {
    source: invite,
    timestamp: 0,
    type: 'INVITE',
  };
  appHandleNotify();
}

export function apiGetInvite(inviteId) {
  return API.get('APIGatewayAPI', `/invites/${inviteId}`)
    .then(reply => Promise.resolve(reply))
    .catch((err) => {
      console.log(err.response.status);
      return Promise.reject(err);
    });
}

export function apiSendInvite(siteId, email) {
  const parameters = {
    body: {
      email,
    },
  };

  return API.post('APIGatewayAPI', `/sites/${siteId}/invites`, parameters)
    .then((reply) => {
      const { invite } = reply;
      if (invite) modifyInvite(invite);
      return Promise.resolve(invite);
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

export function apiDeleteInvite(siteId, inviteId) {
  return API.del('APIGatewayAPI', `/sites/${siteId}/invites/${inviteId}`)
    .then(() => {
      delete session.data.invites[inviteId];
      appHandleNotify();
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

//
// Contacts
//

function modifyContact(contact, siteId) {
  const site = session.data.sites[siteId] && session.data.sites[siteId].source;
  if (site) {
    const cachedContact = site.contacts.find(item => item.userId === contact.userId);

    if (cachedContact) {
      cachedContact.role = contact.role;
      cachedContact.notifications = contact.notifications;
    } else {
      site.contacts.push(contact);
    }
    appHandleNotify();
  }
}

export function apiModifyContact(contact, siteId, userId) {
  const parameters = {
    body: contact,
  };

  return API.put('APIGatewayAPI', `/sites/${siteId}/contacts/${userId}`, parameters)
    .then((reply) => {
      modifyContact(reply.contact, siteId);
      return Promise.resolve(reply);
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

export function apiDeleteContact(siteId, userId) {
  return API.del('APIGatewayAPI', `/sites/${siteId}/contacts/${userId}`)
    .then((reply) => {
      const site = session.data.sites[siteId] && session.data.sites[siteId].source;
      if (site) {
        const index = site.contacts.findIndex(item => item.userId === userId);
        if (index >= 0) {
          site.contacts.splice(index, 1);
          appHandleNotify();
        }
      }
      return Promise.resolve(reply);
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

//
// Zones
//

function modifyThreshold(threshold, siteId, zoneId) {
  const site = session.data.sites[siteId] && session.data.sites[siteId].source;
  if (site) {
    const zone = site.zones.find(item => item.id === zoneId);
    if (zone) {
      const index = zone.thresholds.findIndex(item => item.id === threshold.id);

      if (index >= 0) {
        zone.thresholds[index] = threshold;
      } else {
        zone.thresholds.push(threshold);
      }
    }
    appHandleNotify();
  }
}

export function apiModifyThreshold(threshold, siteId, zoneId, thresholdId = undefined) {
  const parameters = {
    body: threshold,
  };

  if (thresholdId) {
    return API.put('APIGatewayAPI', `/sites/${siteId}/zones/${zoneId}/thresholds/${thresholdId}`, parameters)
      .then((reply) => {
        modifyThreshold(reply.threshold);
        return Promise.resolve(reply.threshold, siteId, zoneId);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }
  return API.post('APIGatewayAPI', `/sites/${siteId}/zones/${zoneId}/thresholds`, parameters)
    .then((reply) => {
      modifyThreshold(reply.threshold, siteId, zoneId);
      return Promise.resolve(reply.threshold);
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

export function apiDeleteThreshold(siteId, zoneId, thresholdId) {
  return API.del('APIGatewayAPI', `/sites/${siteId}/zones/${zoneId}/thresholds/${thresholdId}`)
    .then((reply) => {
      const site = session.data.sites[siteId] && session.data.sites[siteId].source;
      if (site) {
        const zone = site.zones.find(item => item.id === zoneId);
        if (zone) {
          const index = zone.thresholds.findIndex(item => item.id === thresholdId);
          if (index >= 0) {
            zone.thresholds.splice(index, 1);
            appHandleNotify();
          }
        }
      }
      return Promise.resolve(reply);
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

function modifyZone(zone, siteId) {
  const site = session.data.sites[siteId] && session.data.sites[siteId].source;
  if (site) {
    setZoneLocations(zone.id, siteId);
    const index = site.zones.findIndex(item => item.id === zone.id);

    if (index >= 0) {
      site.zones[index] = { thresholds: site.zones[index].thresholds, ...zone };
    } else {
      site.zones.push({ thresholds: [], ...zone });
    }
    sortByName(site.zones);
    appHandleNotify();
  }
}

export function apiModifyZone(zone, siteId, zoneId = undefined) {
  const parameters = {
    body: zone,
  };

  if (zoneId) {
    return API.put('APIGatewayAPI', `/sites/${siteId}/zones/${zoneId}`, parameters)
      .then((reply) => {
        modifyZone(reply.zone, siteId);
        return Promise.resolve(reply.zone);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }
  return API.post('APIGatewayAPI', `/sites/${siteId}/zones`, parameters)
    .then((reply) => {
      modifyZone(reply.zone, siteId);
      return Promise.resolve(reply.zone);
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

export function apiDeleteZone(siteId, zoneId) {
  return API.del('APIGatewayAPI', `/sites/${siteId}/zones/${zoneId}`)
    .then((reply) => {
      const site = session.data.sites[siteId] && session.data.sites[siteId].source;
      if (site) {
        const index = site.zones.findIndex(item => item.id === zoneId);
        if (index >= 0) {
          site.zones.splice(index, 1);
          appHandleNotify();
        }
      }
      return Promise.resolve(reply);
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

//
// Floorplans
//

function findViewForLocation(views, locationId) {
  return views.find(item => item.widgets.some(widget => widget.locationConfigId === locationId)) || emptyFloorplan();
}

export function apiGetFloorplans(active = false) {
  return active ? apiGetCurrentSite().floorplans.filter(item => item.active) : apiGetCurrentSite().floorplans;
}

export function apiGetFloorplan(floorplanId, siteId = undefined) {
  const user = apiGetSignedInUser();
  if (siteId && !user.sites.some(item => item.site.id === siteId)) return undefined;

  const site = siteId ? apiGetSite(siteId) : apiGetCurrentSite();
  if (site.key) {
    return site.floorplans.find(item => item.id === floorplanId) || undefined;
  }

  return emptyFloorplan();
}

export function apiGetDefaultFloorplanId(siteId) {
  const signedInUser = apiGetSignedInUser();
  if (signedInUser) {
    const recentSite = signedInUser.recent.find(recent => recent.site.id === siteId);
    if (recentSite && recentSite.view) return recentSite.view.id;
    const site = signedInUser.sites.find(value => value.site.id === siteId);
    if (site && site.view) return site.view.id === null ? undefined : site.view.id;
  }
  return undefined;
}

export function apiGetNextFloorplanId(floorplanId, active = false) {
  const floorplans = apiGetFloorplans(active);
  const floorplanIndex = floorplans.findIndex(item => item.id === floorplanId);
  if (floorplans.length !== 0) {
    if (floorplanIndex !== -1) {
      return floorplans[floorplanIndex === floorplans.length - 1 ? 0 : floorplanIndex + 1].id;
    }
    return floorplans[0].id;
  }
  return apiGetDefaultFloorplanId(session.currentSiteId);
}

export function apiGetPrevFloorplanId(floorplanId, active = false) {
  const floorplans = apiGetFloorplans(active);
  const floorplanIndex = floorplans.findIndex(item => item.id === floorplanId);
  if (floorplans.length !== 0) {
    if (floorplanIndex !== -1) {
      return floorplans[floorplanIndex === 0 ? floorplans.length - 1 : floorplanIndex - 1].id;
    }
    return floorplans[0].id;
  }
  return apiGetDefaultFloorplanId(session.currentSiteId);
}

function modifyFloorplan(floorplan) {
  const { id, siteId } = floorplan;
  const site = findSite(siteId);
  site.key = uuidv4();
  setImageUrl(floorplan, siteId);
  setWidgetsLocations(floorplan.id, siteId);

  const index = site.floorplans.findIndex(item => item.id === id);
  if (index >= 0) {
    site.floorplans[index] = floorplan;
  } else {
    site.floorplans.push(floorplan);
  }
  sortByName(site.floorplans);
  appHandleNotify();
}

export function apiModifyFloorplan(floorplan) {
  const { siteId, id } = floorplan;
  const parameters = {
    body: floorplan,
  };

  setImageUrl(floorplan, siteId);

  if (id) {
    return API.put('APIGatewayAPI', `/sites/${siteId}/views/${id}`, parameters).then((reply) => {
      modifyFloorplan(reply.floorplan);
      return Promise.resolve(reply);
    });
  }
  return API.post('APIGatewayAPI', `/sites/${siteId}/views/`, parameters).then((reply) => {
    modifyFloorplan(reply.floorplan);
    return Promise.resolve(reply);
  });
}

export function apiDeleteFloorplan(floorplan) {
  const { siteId, id } = floorplan;
  return API.del('APIGatewayAPI', `/sites/${siteId}/views/${id}`).then((reply) => {
    const site = findSite(siteId);
    const index = site.floorplans.findIndex(item => item.id === id);
    if (index >= 0) {
      site.floorplans.splice(index, 1);
      site.key = uuidv4();
      appHandleNotify();
    }
    return Promise.resolve(reply);
  });
}

//
// Images
//

export function apiGetImage(siteId, imageId) {
  const initHeaders = {
    headers: {
      Accept: 'image/*',
    },
  };
  return API.get('APIGatewayAPI', `/sites/${siteId}/image/${imageId}`, initHeaders)
    .then(reply => reply)
    .catch((error) => {
      console.error(`apiGetImage: ${error.response}`);
      return Promise.resolve(false);
    });
}

export function apiUploadImage(file, siteId) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const parameters = {
        body: {
          imageName: file.name,
          imageSource: btoa(reader.result),
        },
      };
      API.post('APIGatewayAPI', `/sites/${siteId}/image`, parameters)
        .then(reply => resolve(reply.image))
        .catch(error => reject(error));
    };
    reader.onerror = error => reject(error);
    reader.readAsBinaryString(file);
  });
}

//
// Reporting
//

export function apiGetReportSensors(siteId, startTime, endTime) {
  const parameters = {
    body: {
      endTime,
      siteId,
      startTime,
    },
  };

  return API.post('APIGatewayAPI', '/reporting/sensors', parameters).catch((err) => {
    console.log(err);
    return Promise.reject(err);
  });
}

export function apiRequestReport(request) {
  const parameters = {
    body: request,
  };

  return API.post('APIGatewayAPI', '/reporting/generate', parameters).catch((err) => {
    console.log(err);
    return Promise.reject(err);
  });
}

//
// Miscellaneous
//

export function apiGetRecentSiteIds() {
  return apiGetSignedInUser().recent;
}

export function apiUpdateRecentSite(siteId, floorplanId = 'undefined') {
  if (!(session.data.signedInUser && session.data.signedInUser.source.sites.some(item => item.site.id === siteId))) {
    return;
  }

  const recentList = apiGetRecentSiteIds();

  if (recentList.length === 0 || siteId !== recentList[0].site.id || floorplanId !== recentList[0].view.id) {
    session.recent.siteId = siteId;
    session.recent.viewId = floorplanId;
    const user = apiGetSignedInUser();
    const parameters = {
      body: {
        site: siteId,
        view: floorplanId || apiGetDefaultFloorplanId(siteId),
      },
    };

    const index = recentList.findIndex(item => item.site.id === siteId);
    if (index >= 0) recentList.splice(index, 1);
    if (recentList.length >= 3) recentList.pop();
    recentList.unshift({
      site: {
        id: siteId,
      },
      view: {
        id: floorplanId,
      },
    });

    API.post('APIGatewayAPI', `/users/${user.id}/recent`, parameters)
      .then(() => {})
      .catch((err) => {
        console.log(err, parameters);
      });
  }
}

export function apiGetUserAlerts() {
  return !session.data.alerts
    ? []
    : sortAlerts(
      session.data.alerts.source.map((item) => {
        const site = findSite(item.siteId);
        const location = site.locations.find(item2 => item2.id === item.locationConfigId) || {};
        return {
          ...item,
          siteName: site.name,
          floorplanId: findViewForLocation(site.floorplans, item.locationConfigId).id,
          locationName: location.name,
        };
      }),
    );
}

export function apiGetHistoryData(siteId, type, startTime, endTime, interval) {
  const initHeaders = {
    body: {
      startTime,
      endTime,
      interval,
    },
  };

  return API.post('APIGatewayAPI', `/sites/${siteId}/sensor/${type}`, initHeaders)
    .then(reply => reply)
    .catch((error) => {
      console.error(`apiGetHistoryData: ${error.response}`);
      return Promise.resolve({ data: [] });
    });
}

export function apiGetSearch() {
  const user = apiGetSignedInUser();
  if (user) {
    return user.sites.map(item => ({
      id: item.site.id,
      name: item.site.name,
      defaultFloorplanId: apiGetDefaultFloorplanId(item.site.id),
      floorplans: findSite(item.site.id).floorplans.map(floorplan => ({
        id: floorplan.id,
        name: floorplan.name,
      })),
    }));
  }
  return [];
}

//
// Authorization and authentication
//

function getAuthPermissions() {
  return API.get('APIGatewayAPI', '/permissions')
    .then((reply) => {
      session.data.permissions = {};
      reply.roles.forEach((item) => {
        session.data.permissions[item.role] = item;
      });
      if (session.data.signedInUser) appHandleAuthentication(true);
    })
    .catch((error) => {
      console.error(`getAuthPermissions: ${error}`);
      return Promise.resolve(false);
    });
}

export function apiGetUserPermissions(userId = undefined) {
  const { permissions } = session.data;
  const currentUser = apiGetSignedInUser();
  const searchUserId = userId || currentUser.id;
  const siteContact = apiGetCurrentSite().contacts.find(item => item.userId === searchUserId);
  const siteRole = siteContact && siteContact.role;
  const siteRoleRecord = permissions && permissions[siteRole];
  const permissionList = (siteRoleRecord && siteRoleRecord.permissions) || [];

  if (!userId) {
    const role = currentUser && currentUser.role;
    const roleRecord = permissions && permissions[role];
    return permissionList.concat((roleRecord && roleRecord.permissions) || []);
  }

  return permissionList;
}

export function apiModifyUserRole(userId, role) {
  const roleId = session.data.permissions ? session.data.permissions[role].id : '';
  const parameters = {
    body: {
      roleId,
    },
  };

  return API.post('APIGatewayAPI', `/users/${userId}/role`, parameters)
    .then((reply) => {
      const user = session.data.users[userId];
      if (user) user.role = role;
      return Promise.resolve(reply);
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

export function apiUpdatePassword(password, currentPassword) {
  return Auth.currentAuthenticatedUser()
    .then(user => Auth.changePassword(user, currentPassword, password))
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

export function apiForgotPassword(email) {
  return Auth.forgotPassword(email)
    .then(() => Promise.resolve())
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

export function apiResetPassword(email, code, password) {
  return Auth.forgotPasswordSubmit(email, code, password)
    .then(() => Promise.resolve())
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

export function apiSignIn(username, password) {
  unsubscribeAll();
  return new Promise((resolve, reject) => {
    Auth.signOut()
      .then(() => {
        appHandleAuthentication(false);
        Auth.signIn(username, password)
          .then((awsUser) => {
            if (awsUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
              // TODO: deal with forced password change
              // '/requirenewpassword'
              console.error('NEW_PASSWORD_REQUIRED');
              reject();
            }
            Auth.currentCredentials()
              .then((credentials) => {
                session.signedInUserId = credentials.data.IdentityId;
                const attachParams = {
                  body: {
                    ID: credentials.data.IdentityId,
                  },
                };
                API.post('APIGatewayAPI', '/policy/attach', attachParams)
                  .then(() => {
                    const identityParams = {
                      body: {
                        email: username,
                      },
                    };
                    API.post('APIGatewayAPI', '/users/identity', identityParams)
                      .then(() => getAuthPermissions()
                        .then(() => {
                          getUserDoc(session.signedInUserId)
                            .then(() => {
                              subscribe('signedInUser');
                              subscribe('companies');
                              subscribe('users');
                              appHandleAuthentication(true);
                              resolve();
                            })
                            .catch((err) => {
                              console.error(`SignIn ERR: ${err}`);
                              reject();
                            });
                        })
                        .catch((err) => {
                          console.error(`SignIn ERR: ${err}`);
                          reject();
                        }))
                      .catch((err) => {
                        console.error(`SignIn ERR: ${err}`);
                        reject();
                      });
                  })
                  .catch((err) => {
                    console.error(`SignIn ERR: ${err}`);
                    reject();
                  });
              })
              .catch((err) => {
                console.error(`SignIn ERR: ${err}`);
                reject();
              });
          })
          .catch((err) => {
            const xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = () => {
              if (xhttp.readyState === 4) {
                if (xhttp.status === 200 && xhttp.responseURL === `${awsExports.DGLuxLegacy.endpoint}/dashboard`) {
                  const form = document.createElement('form');
                  form.setAttribute('method', 'post');
                  form.setAttribute('action', `${awsExports.DGLuxLegacy.endpoint}/login`);

                  const userNameField = document.createElement('input');
                  userNameField.setAttribute('name', 'username');
                  userNameField.setAttribute('value', username);
                  form.appendChild(userNameField);

                  const passwordField = document.createElement('input');
                  passwordField.setAttribute('name', 'password');
                  passwordField.setAttribute('value', password);
                  form.appendChild(passwordField);

                  const b64Field = document.createElement('input');
                  b64Field.setAttribute('name', 'b64');
                  b64Field.setAttribute('value', 'no');
                  form.appendChild(b64Field);

                  document.body.appendChild(form);
                  form.submit();
                } else {
                  console.error(`SignIn ERR: ${err}`);
                  reject();
                }
              }
            };
            xhttp.open('POST', `${awsExports.DGLuxLegacy.endpoint}/login`, true);
            xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhttp.send(`username=${username}&password=${password}&b64=no`);
          });
      })
      // TODO: deal with signOut failure (could be a super rare case)
      .catch((err) => {
        console.error(`signIn ERR: ${err}`);
        reject();
      });
  });
}

export function apiSignOut() {
  unsubscribeAll();
  session = new Session();
  Auth.signOut()
    .then(() => {
      if (appHandleAuthentication) appHandleAuthentication(false);
    })
    .catch(err => console.error(`signOut ERR: ${err}`));
}

//
// Session configuration
//

export function apiSetAppHandlers(handleAuthentication, handleNotify) {
  appHandleAuthentication = handleAuthentication;
  appHandleNotify = handleNotify;

  Auth.currentCredentials()
    .then((credentials) => {
      session.signedInUserId = credentials.data.IdentityId;
      getAuthPermissions()
        .then(() => {
          getUserDoc(session.signedInUserId)
            .then(() => {
              subscribe('signedInUser');
              subscribe('companies');
              subscribe('users');
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    })
    .catch(() => {
      session.signedInUserId = undefined;
      appHandleAuthentication(false);
    });
}

export function apiRemoveAppHandlers() {
  appHandleAuthentication = undefined;
  appHandleNotify = undefined;
  unsubscribeAll();
}
