import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import distanceInWords from 'date-fns/distance_in_words';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { mixinHeightWithToolbar } from '../constants/theme';

const styles = theme => ({
  paper: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
  itemText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textSecondary: {
    ...theme.typography.body2Next,
    color: theme.palette.text.secondary,
  },
});

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.handleListItemClick = this.handleListItemClick.bind(this);
  }

  handleListItemClick(item) {
    const { history } = this.props;

    if (item.floorplanId) {
      history.push(`/site/${item.siteId}/floorplans/${item.floorplanId}`);
    } else {
      history.push(`/site/${item.siteId}`);
    }
  }

  render() {
    const { classes, alerts } = this.props;

    return (
      <Paper className={classes.paper}>
        {alerts.length !== 0 && (
          <List>
            {alerts.map(item => (
              <ListItem key={item.id} onClick={() => this.handleListItemClick(item)} divider button>
                <ListItemIcon>
                  <ErrorOutlineIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  // prettier-ignore
                  primary={(
                    <Typography className={classes.itemText} variant="subtitle1">
                      {item.alertText}
                      <span className={classes.textSecondary}>{distanceInWords(item.timestamp, new Date())}</span>
                    </Typography>
                  )}
                  // prettier-ignore
                  secondary={(
                    <Typography variant="body2" color="textSecondary">
                      {item.siteName}
                      {item.locationName ? ` • Location ${item.locationName}` : ''}
                    </Typography>
                  )}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default compose(
  withRouter,
  withStyles(styles),
)(Notifications);
