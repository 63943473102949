import { conformToMask } from 'react-text-mask';

export const mobileMask = [/1/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export function formatMobile(mobile) {
  if (!mobile) {
    return '';
  }

  const conform = conformToMask(mobile, mobileMask);
  if (conform.meta.someCharsRejected === false) {
    return conform.conformedValue;
  }
  return mobile;
}

export function validMobile(mobile) {
  return (
    /^\d+$/.test(mobile)
    && conformToMask(formatMobile(mobile), mobileMask, { guide: false }).meta.someCharsRejected === false
  );
}

export function emptyMobile(mobile) {
  return !mobile || mobile === '  (   )    -    ';
}

export function removeMobileFormat(mobile) {
  return mobile.replace(/\D/g, '');
}
