import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from './TextField';

class CreateEditDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      open: false,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open) {
      if (prevState.open === false) {
        return { name: nextProps.defaultName, open: nextProps.open };
      }
    } else {
      return { name: '', open: nextProps.open };
    }

    return null;
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleSaveClick() {
    const { handleSaveClick: handleSaveClickProp } = this.props;
    const { name } = this.state;

    if (name.length !== 0) handleSaveClickProp(name);
  }

  handleCancelClick() {
    const { handleCancelClick: handleCancelClickProp } = this.props;

    handleCancelClickProp();
  }

  render() {
    const {
      title, save, cancel, open,
    } = this.props;
    const { name } = this.state;

    return (
      <Dialog maxWidth="xs" fullWidth open={open} onClose={this.handleCancelClick}>
        <>
          <DialogTitle>{title}</DialogTitle>
        </>
        <DialogContent>
          <TextField
            id="create-new-name"
            label="Name"
            value={name}
            fullWidth
            onChange={event => this.handleNameChange(event)}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.handleCancelClick}>
            {cancel}
          </Button>
          <Button color="secondary" autoFocus onClick={this.handleSaveClick}>
            {save}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CreateEditDialog.propTypes = {
  title: PropTypes.string.isRequired,
  cancel: PropTypes.string.isRequired,
  save: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  defaultName: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  handleSaveClick: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
};

CreateEditDialog.defaultProps = {
  defaultName: '',
};

export default CreateEditDialog;
