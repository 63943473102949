import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React from 'react';
import { Auth } from '../utils/auth';
import BottomDrawer from './BottomDrawer';
import NotificationsIcon from './NotificationsIcon';
import SearchIcon from './SearchIcon';
import SitesIcon from './SitesIcon';

const styles = theme => ({
  appBar: {
    position: 'relative',
    width: '100%',
    height: theme.bottomAppBar.height,
    color: theme.bottomAppBar.color,
    backgroundColor: theme.bottomAppBar.backgroundColor,
    boxShadow:
      '0px -1px 5px 0px rgba(153, 153, 153, 0.2), 0px -2px 2px 0px rgba(153, 153, 153, 0.14), 0px -3px 1px -2px rgba(153, 153, 153, 0.12)',
  },
  toolbar: {
    justifyContent: 'space-between',
    minHeight: theme.bottomAppBar.height,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      minHeight: theme.bottomAppBar.height,
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.bottomAppBar.height,
    },
  },
  navIcon: {
    marginLeft: -7,
  },
});

class BottomAppBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
  }

  handleDrawerOpen() {
    this.setState({ open: true });
  }

  handleDrawerClose() {
    this.setState({ open: false });
  }

  render() {
    const {
      classes, siteId, site, devices, invites, alerts,
    } = this.props;
    const { open } = this.state;

    return (
      <>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton className={classes.navIcon} color="inherit" onClick={this.handleDrawerOpen}>
              <MenuIcon />
            </IconButton>
            <Auth do="access" on="sites">
              <SitesIcon color="inherit" />
            </Auth>
            <SearchIcon color="inherit" />
            <NotificationsIcon alerts={alerts} color="inherit" />
          </Toolbar>
        </AppBar>
        <BottomDrawer
          siteId={siteId}
          site={site}
          devices={devices}
          invites={invites}
          open={open}
          handleDrawerClose={this.handleDrawerClose}
        />
      </>
    );
  }
}

BottomAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  siteId: PropTypes.string.isRequired,
  site: PropTypes.object.isRequired,
  devices: PropTypes.object.isRequired,
  invites: PropTypes.arrayOf(PropTypes.object).isRequired,
  alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(BottomAppBar);
