export const menuItemsAlertRepeat = [
  {
    label: 'off',
    value: 0,
  },
  {
    label: '15 min',
    value: 15,
  },
  {
    label: '30 min',
    value: 30,
  },
  {
    label: '1 h',
    value: 60,
  },
  {
    label: '2 h',
    value: 120,
  },
  {
    label: '4 h',
    value: 240,
  },
  {
    label: '6 h',
    value: 360,
  },
  {
    label: '12 h',
    value: 720,
  },
  {
    label: '24 h',
    value: 1440,
  },
];

export const menuItemsActivityTimeout = [
  {
    label: '5 min',
    value: 6,
  },
  {
    label: '15 min',
    value: 16,
  },
  {
    label: '30 min',
    value: 31,
  },
  {
    label: '1 hr',
    value: 61,
  },
  {
    label: '2 hr',
    value: 121,
  },
];

export const menuItemsNetworkId = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '11',
    value: 11,
  },
  {
    label: '12',
    value: 12,
  },
  {
    label: '13',
    value: 13,
  },
  {
    label: '14',
    value: 14,
  },
  {
    label: '15',
    value: 15,
  },
  {
    label: '16',
    value: 16,
  },
];

export const menuReportingInterval = [
  {
    label: '5 min',
    value: 5,
  },
  {
    label: '15 min',
    value: 15,
  },
  {
    label: '30 min',
    value: 30,
  },
  {
    label: '60 min',
    value: 60,
  },
];
