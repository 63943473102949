import { withStyles } from '@material-ui/core/styles';
import MuiTooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  tooltipDefault: {
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  tooltipLarge: {
    backgroundColor: theme.palette.grey[900],
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: 8,
  },
});

function Tooltip(props) {
  const {
    classes, children, title, variant, placement, open, onOpen, onClose,
  } = props;

  const classesTooltip = variant === 'default' ? classes.tooltipDefault : classes.tooltipLarge;

  return (
    <MuiTooltip
      classes={{ tooltip: classesTooltip }}
      title={title}
      placement={placement}
      open={open}
      enterDelay={1250}
      leaveDelay={300}
      disableFocusListener
      onOpen={onOpen}
      onClose={onClose}
    >
      {children}
    </MuiTooltip>
  );
}

Tooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'large']),
  placement: PropTypes.string,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

Tooltip.defaultProps = {
  variant: 'default',
  placement: 'bottom',
  open: null,
  onOpen: undefined,
  onClose: undefined,
};

export default withStyles(styles)(Tooltip);
