import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import sensorTypes from '../constants/sensorTypes';
import Select from './Select';

class ZonesAddMonitoring extends React.Component {
  constructor(props) {
    super(props);

    this.state = { sensorType: '' };

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleAddClick() {
    const { handleAddClick: handleAddClickProp } = this.props;
    const { sensorType } = this.state;

    handleAddClickProp({
      sensorType,
      high: null,
      low: null,
      antispamInterval: 30,
      enabled: false,
      contacts: [],
    });
    this.handleClose();
  }

  handleClose() {
    const { handleClose: handleCloseProp } = this.props;

    this.setState({ sensorType: '' });
    handleCloseProp();
  }

  handleChange(event) {
    this.setState({ sensorType: event.target.value });
  }

  render() {
    const { open } = this.props;
    const { sensorType } = this.state;

    return (
      <Dialog maxWidth="xs" fullWidth open={open} onClose={this.handleClose}>
        <DialogTitle>Add a condition to monitor</DialogTitle>
        <DialogContent>
          <Select
            id="monitoring-condition-to-monitor"
            label="Monitored condition"
            value={sensorType}
            margin="normal"
            fullWidth
            onChange={this.handleChange}
          >
            {Object.keys(sensorTypes).map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.handleClose}>
            Cancel
          </Button>
          <Button color="secondary" autoFocus onClick={this.handleAddClick} disabled={sensorType === ''}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ZonesAddMonitoring.propTypes = {
  open: PropTypes.bool.isRequired,
  handleAddClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ZonesAddMonitoring;
