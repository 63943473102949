import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';
import { AbilityContext, Auth } from '../utils/auth';
import Tooltip from './Tooltip';

const styles = theme => ({
  root: {
    position: 'relative',
    ...mixinHeightWithToolbar(48),
  },
  content: {
    height: '100%',
    overflowY: 'auto',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
});

class SettingsFloorplans extends React.Component {
  constructor(props) {
    super(props);

    const { floorplans } = props;

    this.state = {
      switches: floorplans.map(item => ({
        id: item.id,
        name: item.name,
        active: item.active,
      })),
    };

    this.handleSwitchClick = this.handleSwitchClick.bind(this);
  }

  handleSwitchClick(event, floorplanId) {
    const { floorplans, handleSwitchClick } = this.props;
    const { switches } = this.state;
    const floorplan = floorplans.find(item => item.id === floorplanId);
    const active = event.target.checked;
    const index = switches.findIndex(item => item.id === floorplanId);

    switches[index].active = active;
    this.setState({ switches });

    floorplan.active = active;
    handleSwitchClick(floorplan)
      .then(() => {})
      .catch(() => {});
  }

  render() {
    const {
      classes, floorplans, sideSheet, handleListItemClick, handleAddClick,
    } = this.props;
    const { switches } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <List>
            {sideSheet && floorplans.length !== 0 && <Divider />}
            {switches.map(item => (
              <ListItem key={item.id} onClick={() => handleListItemClick(item.id)} divider button>
                <ListItemText primary={item.name} />
                <ListItemSecondaryAction>
                  <AbilityContext.Consumer>
                    {ability => (
                      <Switch
                        checked={item.active}
                        disabled={ability.cannot('modify', 'floorplans')}
                        onChange={event => this.handleSwitchClick(event, item.id)}
                      />
                    )}
                  </AbilityContext.Consumer>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {sideSheet && (
            <Auth do="modify" on="floorplans">
              <Tooltip title="Create a floorplan" placement="left">
                <Button className={classes.fab} color="secondary" variant="fab" mini onClick={handleAddClick}>
                  <AddIcon />
                </Button>
              </Tooltip>
            </Auth>
          )}
        </div>
      </div>
    );
  }
}

SettingsFloorplans.propTypes = {
  classes: PropTypes.object.isRequired,
  floorplans: PropTypes.array.isRequired,
  sideSheet: PropTypes.bool,
  handleListItemClick: PropTypes.func.isRequired,
  handleSwitchClick: PropTypes.func.isRequired,
  handleAddClick: PropTypes.func,
};

SettingsFloorplans.defaultProps = {
  sideSheet: false,
  handleAddClick: undefined,
};

export default withStyles(styles)(SettingsFloorplans);
