import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import compose from 'recompose/compose';
import Logo from '../components/Logo';
import TextField from '../components/TextField';
import withMobile from '../components/withMobile';
import { mixinHeightWithToolbar } from '../constants/theme';
import has from '../utils/has';
import { apiResetPassword, apiSignIn } from '../utils/api';

const queryString = require('query-string');

const styles = theme => ({
  appBar: {
    position: 'relative',
    width: '100%',
    height: theme.appBar.height,
    boxShadow: '0 2px 2px -1px rgba(153, 153, 153, 0.3), 0 1px 5px -2px rgba(153, 153, 153, 0.3)',
    zIndex: theme.zIndex.appBar + 1,
  },
  logo: {
    padding: '0px 40px',
  },
  divider: {
    width: 2,
    height: theme.appBar.height / 2,
    background: theme.appBar.dividerColor,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: theme.floorplan.width,
    margin: `${theme.spacing.unit * 3}px 0px`,
    ...mixinHeightWithToolbar(theme.spacing.unit * 6),
  },
  contentContainer: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    boxShadow: '0 2px 2px -1px rgba(153, 153, 153, 0.3), 0 1px 5px -2px rgba(153, 153, 153, 0.3)',
    boxSizing: 'border-box',
    padding: 1,
  },
  componentContainer: {
    flexGrow: 1,
    height: '100%',
  },
  componentAppBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: theme.appBar.height,
    ...theme.mixins.gutters(),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titlePadding: {
    marginRight: theme.spacing.unit * 2,
  },
  component: {
    width: '100%',
    height: 'calc(100% - 56px)',
    marginTop: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 'calc(100% - 48px)',
      marginTop: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 64px)',
      marginTop: 64,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      ...mixinHeightWithToolbar(),
    },
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  scroll: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  contentDivider: {
    marginRight: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 3,
  },
  content: {
    margin: theme.spacing.unit * 2,
  },
});

class Reset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      handling: false,
      open: false,
      password: '',
      passwordAgain: '',
      redirect: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  handleChange(event, param) {
    this.setState({ [param]: event.target.value });
  }

  handleNextClick() {
    const { location } = this.props;
    const { handling, password } = this.state;
    const values = queryString.parse(location.search);
    const { email, code } = values;

    if (!handling) {
      this.setState({ handling: true });

      apiResetPassword(email, code, password)
        .then(() => this.setState({ step: 1, handling: false }))
        .catch(() => this.setState({ handling: false }));
    }
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleSignIn() {
    const { location } = this.props;
    const { handling, password } = this.state;
    const values = queryString.parse(location.search);
    const { email } = values;

    if (!handling) {
      this.setState({ handling: true });

      apiSignIn(email, password)
        .then(() => this.setState({ redirect: true, handling: false }))
        .catch(() => this.setState({ handling: false }));
    }
  }

  renderContent() {
    const { step, password, passwordAgain } = this.state;

    return (
      <List>
        {step === 0 && (
          <>
            <ListItem>
              <ListItemText
                disableTypography
                // prettier-ignore
                primary={(
                  <Typography variant="subtitle1">
                    Please enter a new password for your WEDGE account.
                  </Typography>
                )}
              />
            </ListItem>
            <ListItem>
              <TextField
                id="reset-password"
                type="password"
                label="Password"
                value={password}
                fullWidth
                onChange={event => this.handleChange(event, 'password')}
              />
            </ListItem>
            <ListItem>
              <TextField
                id="reset-password-again"
                type="password"
                label="Password (again)"
                value={passwordAgain}
                fullWidth
                onChange={event => this.handleChange(event, 'passwordAgain')}
              />
            </ListItem>
            <ListItem>
              <Button
                color="secondary"
                variant="contained"
                disabled={password === '' || passwordAgain === ''}
                onClick={this.handleNextClick}
              >
                Next
              </Button>
            </ListItem>
          </>
        )}
        {step === 1 && (
          <>
            <ListItem>
              <ListItemText
                disableTypography
                // prettier-ignore
                primary={(
                  <Typography variant="subtitle1">
                    You have successfully reset your password!
                  </Typography>
                )}
              />
            </ListItem>
            <ListItem>
              <Button color="secondary" variant="contained" onClick={this.handleSignIn}>
                Sign in
              </Button>
            </ListItem>
          </>
        )}
      </List>
    );
  }

  render() {
    const { classes, widthMobile, location } = this.props;
    const { open, redirect } = this.state;

    const values = queryString.parse(location.search);
    if (redirect || !has.call(values, 'email') || !has.call(values, 'code')) {
      return <Redirect to="/" />;
    }

    return (
      <>
        {!widthMobile ? (
          <>
            <AppBar className={classes.appBar}>
              <Toolbar disableGutters>
                <div className={classes.logo}>
                  <Logo />
                </div>
                <div className={classes.divider} />
              </Toolbar>
            </AppBar>
            <div className={classes.root}>
              <div className={classes.container}>
                <div className={classes.contentContainer}>
                  <div className={classes.componentContainer}>
                    <div className={classes.componentAppBar}>
                      <div className={classes.titleContainer}>
                        <Typography className={classes.titlePadding} variant="h6">
                          Reset password
                        </Typography>
                      </div>
                    </div>
                    <Divider className={classes.contentDivider} />
                    <div className={classes.component}>
                      <div className={classes.scroll}>
                        <div className={classes.content}>{this.renderContent()}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <AppBar>
              <Toolbar className={classes.toolbar}>
                <div className={classes.titleContainer}>
                  <Typography className={classes.titlePadding} variant="h6">
                    Reset password
                  </Typography>
                </div>
              </Toolbar>
            </AppBar>
            <div className={classes.component}>
              <div className={classes.scroll}>
                <div className={classes.content}>{this.renderContent()}</div>
              </div>
            </div>
          </>
        )}
        <Dialog maxWidth="xs" fullWidth open={open} onClose={this.handleClose}>
          <DialogTitle>Cannot reset password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              There was a problem changing your password. Your link may no longer be valid.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" autoFocus onClick={this.handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

Reset.propTypes = {
  classes: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withMobile(),
)(Reset);
