import PropTypes from 'prop-types';
import React from 'react';
import LocationsComponent from '../components/Locations';
import {
  apiDeleteLocation,
  apiGetDevices,
  apiGetSite,
  apiGetUserAlerts,
  apiModifyLocation,
} from '../utils/api';

function Locations(props) {
  const { siteId, handleBackClick } = props;

  return (
    <LocationsComponent
      site={apiGetSite(siteId)}
      devices={apiGetDevices()}
      alerts={apiGetUserAlerts()}
      handleBackClick={handleBackClick}
      handleDeleteClick={apiDeleteLocation}
      handleSaveClick={apiModifyLocation}
    />
  );
}

Locations.propTypes = {
  siteId: PropTypes.string.isRequired,
  handleBackClick: PropTypes.func.isRequired,
};

export default Locations;
