import PropTypes from 'prop-types';
import React from 'react';
import AppBar from './AppBar';
import CompaniesDetails from './CompaniesDetails';
import CompaniesEdit from './CompaniesEdit';
import ConfirmDialog from './ConfirmDialog';
import SideSheet from './SideSheet';

class Companies extends React.Component {
  constructor(props) {
    super(props);

    const { company } = props;

    this.state = {
      step: 0,
      name: company.name,
      companyId: company.id,
      open: false,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleDeleteConfirmClick = this.handleDeleteConfirmClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { company } = nextProps;
    const { companyId } = prevState;

    if (company.id !== companyId) {
      return {
        step: 0,
        name: company.name,
        companyId: company.id,
      };
    }

    return null;
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleBackClick() {
    // Go to details
    this.setState({ step: 0 });
  }

  handleEditClick() {
    // Go to edit
    this.setState({ step: 1 });
  }

  handleDeleteClick() {
    this.setState({ open: true });
  }

  handleDeleteConfirmClick() {
    const { handleDeleteClick } = this.props;

    handleDeleteClick();
    this.setState({ open: false });
  }

  handleSaveClick() {
    const { handleSaveClick: handleSaveClickProp } = this.props;
    const { name } = this.state;

    handleSaveClickProp(name);
  }

  handleCancelClick() {
    this.setState({ open: false });
  }

  render() {
    const {
      company, sideSheet, handleBackClick, handleClose,
    } = this.props;
    const { step, name, open } = this.state;

    return (
      <>
        {sideSheet ? (
          <>
            {step === 0 && (
              <SideSheet
                title="Company details"
                authSubject="companies"
                handleEditClick={this.handleEditClick}
                handleDeleteClick={company.sites.length === 0 ? this.handleDeleteClick : undefined}
                handleClose={handleClose}
              >
                <CompaniesDetails company={company} />
              </SideSheet>
            )}
            {step === 1 && (
              <SideSheet
                title="Edit company"
                authSubject="companies"
                handleBackClick={this.handleBackClick}
                handleSaveClick={this.handleSaveClick}
              >
                <CompaniesEdit company={company} name={name} handleNameChange={this.handleNameChange} />
              </SideSheet>
            )}
          </>
        ) : (
          <>
            {step === 0 && (
              <>
                <AppBar
                  title="Company details"
                  handleBackClick={handleBackClick}
                  handleEditClick={this.handleEditClick}
                  handleDeleteClick={company.sites.length === 0 ? this.handleDeleteClick : undefined}
                />
                <CompaniesDetails company={company} />
              </>
            )}
            {step === 1 && (
              <>
                <AppBar
                  title="Edit company"
                  handleBackClick={this.handleBackClick}
                  handleSaveClick={this.handleSaveClick}
                />
                <CompaniesEdit company={company} name={name} handleNameChange={this.handleNameChange} />
              </>
            )}
          </>
        )}
        <ConfirmDialog
          open={open}
          title="Delete company?"
          body="This action cannot be undone."
          confirm="Delete"
          cancel="Cancel"
          handleConfirmClick={this.handleDeleteConfirmClick}
          handleCancelClick={this.handleCancelClick}
        />
      </>
    );
  }
}

Companies.propTypes = {
  company: PropTypes.object.isRequired,
  sideSheet: PropTypes.bool,
  handleBackClick: PropTypes.func,
  handleDeleteClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
};

Companies.defaultProps = {
  sideSheet: false,
  handleBackClick: undefined,
  handleClose: undefined,
};

export default Companies;
