import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import sensorTypes from '../constants/sensorTypes';
import { sort, sortByBarcode } from '../utils/sort';
import Select from './Select';

function availableChannels(sensorType, device, sensors) {
  const type = sensorTypes[sensorType];

  return sort(
    device.sensors
      .filter(item => item.type === type)
      .reduce((accumulator, item) => accumulator.concat([item.channel]), [])
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter(
        item => !sensors.some(sensor => sensor.barcode === device.barcode && sensor.type === type && sensor.channel === item), // eslint-disable-line max-len
      ),
  );
}

function availableDevices(sensorType, cags, sensors) {
  const type = sensorTypes[sensorType];

  return sortByBarcode(
    cags.reduce(
      (accumulator, cag) => accumulator.concat(
        cag.devices.filter(
          device => device.type !== 'HMC'
              && device.sensors.some(
                sensor => sensor.type === type
                  && !sensors.some(
                    item => item.barcode === device.barcode && item.type === type && item.channel === sensor.channel, // eslint-disable-line max-len
                  ),
              ),
        ),
      ),
      [],
    ),
  );
}

class LocationsAddSensor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sensorType: '',
      barcode: '',
      channel: '',
    };

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSensorTypeChange = this.handleSensorTypeChange.bind(this);
    this.handleBarcodeChange = this.handleBarcodeChange.bind(this);
    this.handleChannelChange = this.handleChannelChange.bind(this);
  }

  handleAddClick() {
    const { handleAddClick } = this.props;
    const { sensorType, barcode, channel } = this.state;
    const newSensor = {
      sensorType,
      notes: '',
      barcode,
      channel,
      type: sensorTypes[sensorType],
    };

    handleAddClick(newSensor);
    this.handleClose();
  }

  handleClose() {
    const { handleClose } = this.props;

    this.setState({
      sensorType: '',
      barcode: '',
      channel: '',
    });
    handleClose();
  }

  handleSensorTypeChange(event) {
    const { cags, sensors } = this.props;
    const { barcode, channel } = this.state;
    const sensorType = event.target.value;
    const devices = availableDevices(sensorType, cags, sensors);
    const device = devices.length === 1 ? devices[0] : devices.find(item => item.barcode === barcode);
    const newState = {
      sensorType,
    };

    if (!device) {
      newState.barcode = '';
      newState.channel = '';
    } else {
      newState.barcode = device.barcode;
      const channels = availableChannels(sensorType, device, sensors);
      if (channels.length === 0) newState.channel = '';
      else if (channels.length === 1) [newState.channel] = channels;
      else if (!channels.some(item => item === channel)) newState.channel = '';
    }
    this.setState(newState);
  }

  handleBarcodeChange(event) {
    const { cags, sensors } = this.props;
    const { sensorType, channel } = this.state;
    const barcode = event.target.value;
    const devices = availableDevices(sensorType, cags, sensors);
    const device = devices.find(item => item.barcode === barcode);
    const newState = {
      barcode,
    };

    const channels = availableChannels(sensorType, device, sensors);
    if (channels.length === 0) newState.channel = '';
    else if (channels.length === 1) [newState.channel] = channels;
    else if (!channels.some(item => item === channel)) newState.channel = '';

    this.setState(newState);
  }

  handleChannelChange(event) {
    this.setState({ channel: event.target.value });
  }

  render() {
    const { cags, sensors, open } = this.props;
    const { sensorType, barcode, channel } = this.state;

    const devices = availableDevices(sensorType, cags, sensors);
    const device = devices.find(item => item.barcode === barcode) || { sensors: [] };
    const channels = availableChannels(sensorType, device, sensors);

    return (
      <Dialog maxWidth="xs" fullWidth open={open} onClose={this.handleClose}>
        <>
          <DialogTitle>Add a sensor</DialogTitle>
        </>
        <DialogContent>
          <Select
            id="sensor-sensor-type"
            label="Sensor type"
            value={sensorType}
            margin="normal"
            fullWidth
            onChange={this.handleSensorTypeChange}
          >
            {Object.keys(sensorTypes).map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          <Select
            id="sensor-barcode"
            label="Barcode"
            value={barcode}
            margin="normal"
            fullWidth
            disabled={sensorType === ''}
            onChange={this.handleBarcodeChange}
          >
            {devices.map(item => (
              <MenuItem key={item.barcode} value={item.barcode}>
                {item.barcode}
              </MenuItem>
            ))}
          </Select>
          <Select
            id="sensor-channel"
            label="Channel"
            value={channel}
            margin="normal"
            fullWidth
            disabled={sensorType === ''}
            onChange={this.handleChannelChange}
          >
            {channels.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.handleClose}>
            Cancel
          </Button>
          <Button
            color="secondary"
            autoFocus
            onClick={this.handleAddClick}
            disabled={sensorType === '' || barcode === '' || channel === ''}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

LocationsAddSensor.propTypes = {
  cags: PropTypes.arrayOf(PropTypes.object).isRequired,
  sensors: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
  handleAddClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default LocationsAddSensor;
