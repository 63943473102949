import PropTypes from 'prop-types';
import React from 'react';
import AppBar from './AppBar';
import DevicesDetails from './DevicesDetails';
import DevicesDetailsSite from './DevicesDetailsSite';
import DevicesList from './DevicesList';
import SideSheet from './SideSheet';
import Spinner from './Spinner';

class Devices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      item: undefined,
      handling: false,
    };

    this.handleListItemClick = this.handleListItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleAssignClick = this.handleAssignClick.bind(this);
    this.handleUpdateDeviceNetwork = this.handleUpdateDeviceNetwork.bind(this);
    this.handleFastFindClick = this.handleFastFindClick.bind(this);
    this.handleUnassignClick = this.handleUnassignClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.item) {
      const { devices } = nextProps;
      const { barcode } = prevState.item;
      let deviceList = [];
      devices.assignedDevices.forEach((item) => {
        deviceList.push(item);
        deviceList = deviceList.concat(item.devices);
      });
      devices.unassignedDevices.forEach((item) => {
        deviceList.push(item);
        deviceList = deviceList.concat(item.devices);
      });
      devices.nearbyDevices.forEach((item) => {
        deviceList.push(item);
        deviceList = deviceList.concat(item.devices);
      });
      const device = deviceList.find(item => item.barcode === barcode);
      if (device) {
        return { item: device };
      }
      return { step: 0 };
    }

    return { item: undefined };
  }

  handleListItemClick(item) {
    // Go to device details
    this.setState({ step: 1, item });
  }

  handleBackClick() {
    // Go to list
    this.setState({ step: 0 });
  }

  handleAssignClick(barcode, siteId) {
    const { handleAssignClick } = this.props;
    const { handling } = this.state;

    if (handling) return;

    this.setState({ handling: true });

    handleAssignClick(barcode, siteId)
      .then(() => this.setState({ handling: false }))
      .catch(() => this.setState({ handling: false }));
  }

  handleUpdateDeviceNetwork(barcode, siteId, networkId) {
    const { handleUpdateDeviceNetwork } = this.props;
    const { handling } = this.state;

    if (handling) return;

    this.setState({ handling: true });

    handleUpdateDeviceNetwork(barcode, siteId, networkId)
      .then(() => this.setState({ handling: false }))
      .catch(() => this.setState({ handling: false }));
  }

  handleFastFindClick(barcode, siteId) {
    const { handleFastFindClick } = this.props;
    const { handling } = this.state;

    if (handling) return Promise.reject();

    this.setState({ handling: true });

    return handleFastFindClick(barcode, siteId)
      .then(() => this.setState({ handling: false }))
      .catch(() => this.setState({ handling: false }));
  }

  handleUnassignClick(barcode, siteId) {
    const { handleUnassignClick } = this.props;
    const { handling } = this.state;

    if (handling) return;

    this.setState({ handling: true });

    handleUnassignClick(barcode, siteId)
      .then(() => this.setState({ handling: false }))
      .catch(() => this.setState({ handling: false }));
  }

  render() {
    const {
      site, devices, alerts, sideSheet, handleBackClick, handleClose,
    } = this.props;
    const { step, item, handling } = this.state;

    return (
      <>
        {sideSheet ? (
          <>
            {step === 0 && (
              <SideSheet title="Devices" authSubject="devices" handleClose={handleClose}>
                <DevicesList
                  siteId={site.id}
                  assignedDevices={devices.assignedDevices}
                  unassignedDevices={devices.unassignedDevices}
                  nearbyDevices={devices.nearbyDevices}
                  handleListItemClick={this.handleListItemClick}
                />
              </SideSheet>
            )}
            {step === 1 && (
              <>
                {item.siteId && item.siteId !== site.id ? (
                  <SideSheet
                    title="Nearby CAG"
                    authSubject="devices"
                    handleBackClick={this.handleBackClick}
                    handleClose={handleClose}
                  >
                    <DevicesDetailsSite site={site} device={item} />
                  </SideSheet>
                ) : (
                  <SideSheet
                    title={`${item.type || 'CAG'} details`}
                    authSubject="devices"
                    showSpinner={handling}
                    handleBackClick={this.handleBackClick}
                    handleClose={handleClose}
                  >
                    <DevicesDetails
                      device={item}
                      handleAssignClick={this.handleAssignClick}
                      handleUnassignClick={this.handleUnassignClick}
                      handleFastFindClick={this.handleFastFindClick}
                      handleUpdateDeviceNetwork={this.handleUpdateDeviceNetwork}
                    />
                  </SideSheet>
                )}
              </>
            )}
          </>
        ) : (
          <Spinner show={handling}>
            {step === 0 && (
              <>
                <AppBar title="Devices" search alerts={alerts} handleBackClick={handleBackClick} />
                <DevicesList
                  assignedDevices={devices.assignedDevices}
                  unassignedDevices={devices.unassignedDevices}
                  nearbyDevices={devices.nearbyDevices}
                  handleListItemClick={this.handleListItemClick}
                />
              </>
            )}
            {step === 1 && (
              <>
                {item.siteId && item.siteId !== site.id ? (
                  <>
                    <AppBar title="Nearby CAG" handleBackClick={this.handleBackClick} />
                    <DevicesDetailsSite site={site} device={item} />
                  </>
                ) : (
                  <>
                    <AppBar title={`${item.type} details`} handleBackClick={this.handleBackClick} />
                    <DevicesDetails
                      device={item}
                      handleAssignClick={this.handleAssignClick}
                      handleUnassignClick={this.handleUnassignClick}
                      handleFastFindClick={this.handleFastFindClick}
                      handleUpdateDeviceNetwork={this.handleUpdateDeviceNetwork}
                    />
                  </>
                )}
              </>
            )}
          </Spinner>
        )}
      </>
    );
  }
}

Devices.propTypes = {
  site: PropTypes.object.isRequired,
  devices: PropTypes.object.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.object),
  sideSheet: PropTypes.bool,
  handleAssignClick: PropTypes.func.isRequired,
  handleFastFindClick: PropTypes.func.isRequired,
  handleUnassignClick: PropTypes.func.isRequired,
  handleUpdateDeviceNetwork: PropTypes.func.isRequired,
  handleBackClick: PropTypes.func,
  handleClose: PropTypes.func,
};

Devices.defaultProps = {
  alerts: undefined,
  sideSheet: false,
  handleBackClick: undefined,
  handleClose: undefined,
};

export default Devices;
