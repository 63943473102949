import MuiAppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Auth } from '../utils/auth';
import Logo from './Logo';
import NotificationsIcon from './NotificationsIcon';
import SearchIcon from './SearchIcon';
import SitesIcon from './SitesIcon';

const styles = theme => ({
  appBar: {
    position: 'relative',
    width: '100%',
    height: theme.appBar.height,
    boxShadow: '0 2px 2px -1px rgba(153, 153, 153, 0.3), 0 1px 5px -2px rgba(153, 153, 153, 0.3)',
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    paddingLeft: 40,
  },
  logo: {
    padding: '0px 40px',
  },
  iconSpacing: {
    paddingLeft: 40,
  },
  iconSpacingNotifications: {
    padding: '0px 40px',
  },
  divider: {
    width: 2,
    height: theme.appBar.height / 2,
    background: theme.appBar.dividerColor,
  },
});

function AppBarLogo(props) {
  const { classes, title, alerts } = props;

  return (
    <MuiAppBar className={classes.appBar}>
      <Toolbar disableGutters>
        <div className={classNames(classes.logo)}>
          <Logo />
        </div>
        <div className={classes.divider} />
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant="h6" color="inherit">
            {title}
          </Typography>
        </div>
        {alerts && (
          <>
            <div className={classes.divider} />
            <div className={classes.iconSpacing}>
              <SearchIcon color="inherit" />
            </div>
            <Auth do="access" on="sites">
              <div className={classes.iconSpacing}>
                <SitesIcon color="inherit" />
              </div>
            </Auth>
            <div className={classes.iconSpacingNotifications}>
              <NotificationsIcon alerts={alerts} color="inherit" />
            </div>{' '}
          </>
        )}
      </Toolbar>
    </MuiAppBar>
  );
}

AppBarLogo.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  alerts: PropTypes.arrayOf(PropTypes.object),
};

AppBarLogo.defaultProps = {
  title: null,
  alerts: null,
};

export default withStyles(styles)(AppBarLogo);
