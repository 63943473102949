import MuiAppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import AppBar from './AppBar';
import CreateEditDialog from './CreateEditDialog';
import SettingsFloorplanDetails from './SettingsFloorplanDetails';
import SettingsFloorplans from './SettingsFloorplans';
import SettingsGeneral from './SettingsGeneral';
import SideSheet from './SideSheet';

const styles = theme => ({
  tabs: {
    ...theme.mixins.gutters(),
  },
  tabsSideSheet: {
    position: 'relative',
    boxShadow: 'none',
  },
  tab: {
    ...theme.tab,
    [theme.breakpoints.up('md')]: {
      minWidth: 152,
    },
  },
});

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      index: 0,
      id: undefined,
      editor: false,
      open: false,
      handling: false,
    };

    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleListItemClick = this.handleListItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleSaveFloorplanClick = this.handleSaveFloorplanClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { floorplans } = nextProps;
    const { step, id } = prevState;

    if (step === 1 && !floorplans.some(item => item.id === id)) {
      // Go to list
      return { step: 0 };
    }

    return null;
  }

  handleTabClick(_, index) {
    this.setState({ index });
  }

  handleListItemClick(id) {
    // Go to floorplan details
    this.setState({ step: 1, id });
  }

  handleBackClick() {
    // Go to list
    this.setState({ step: 0 });
  }

  handleAddClick() {
    // Go to add
    this.setState({ step: 0, open: true });
  }

  handleEditClick() {
    this.setState({ editor: true });
  }

  handleDeleteClick() {
    const { floorplans, handleDeleteFloorplanClick } = this.props;
    const { id, handling } = this.state;

    if (handling) return;

    this.setState({ handling: true });

    handleDeleteFloorplanClick(floorplans.find(item => item.id === id))
      .then(() => {
        // Go to list
        this.setState({ step: 0, handling: false });
      })
      .catch(() => {
        this.setState({ handling: false });
      });
  }

  handleSaveFloorplanClick(name) {
    const { handleSaveFloorplanClick, siteId } = this.props;
    const { handling } = this.state;

    if (handling) return;

    const floorplan = {
      active: false,
      name,
      scale: 1,
      siteId,
    };

    this.setState({ step: 0, open: false, handling: true });

    handleSaveFloorplanClick(floorplan)
      .then(() => {
        this.setState({ handling: false });
      })
      .catch(() => {
        this.setState({ handling: false });
      });
  }

  handleCancelClick() {
    // Go to list
    this.setState({ step: 0, open: false });
  }

  render() {
    const {
      classes,
      siteId,
      site,
      floorplans,
      companies,
      alerts,
      sideSheet,
      handleSwitchClick,
      handleBackClick,
      handleClose,
      handleDeleteSiteClick,
      handleSaveSite,
      handleSaveSiteAlerts,
    } = this.props;
    const {
      step, index, id, editor, open, handling,
    } = this.state;

    if (editor) {
      return <Redirect to={`/site/${siteId}/floorplans/${id}?edit=true`} />;
    }

    return (
      <>
        {sideSheet ? (
          <>
            {step === 0 && (
              <>
                <SideSheet title="Settings" authSubject="settings" showSpinner={handling} handleClose={handleClose}>
                  <MuiAppBar className={classes.tabsSideSheet}>
                    <Tabs className={classes.tabs} value={index} onChange={this.handleTabClick} fullWidth>
                      {['General', 'Floorplans'].map(item => (
                        <Tab className={classes.tab} label={item} key={item} disableRipple />
                      ))}
                    </Tabs>
                  </MuiAppBar>
                  {index === 0 && (
                    <SettingsGeneral
                      key={site.key}
                      site={site}
                      companies={companies}
                      handleDeleteClick={handleDeleteSiteClick}
                      handleSaveSite={handleSaveSite}
                      handleSaveSiteAlerts={handleSaveSiteAlerts}
                    />
                  )}
                  {index === 1 && (
                    <SettingsFloorplans
                      key={site.key}
                      floorplans={floorplans}
                      sideSheet
                      authSubject="floorplans"
                      handleListItemClick={this.handleListItemClick}
                      handleSwitchClick={handleSwitchClick}
                      handleAddClick={this.handleAddClick}
                      handleCancelClick={this.handleCancelClick}
                    />
                  )}
                </SideSheet>
                <CreateEditDialog
                  title="Create a floorplan"
                  save="Create"
                  cancel="Cancel"
                  open={open}
                  handleSaveClick={this.handleSaveFloorplanClick}
                  handleCancelClick={this.handleCancelClick}
                />
              </>
            )}
            {step === 1 && (
              <SideSheet
                title="Floorplan details"
                authSubject="floorplans"
                showSpinner={handling}
                handleBackClick={this.handleBackClick}
                handleEditClick={this.handleEditClick}
                handleDeleteClick={this.handleDeleteClick}
                handleClose={handleClose}
              >
                <SettingsFloorplanDetails floorplan={floorplans.find(item => item.id === id)} />
              </SideSheet>
            )}
          </>
        ) : (
          <>
            {step === 0 && (
              <>
                <AppBar title="Settings" search alerts={alerts} handleBackClick={handleBackClick}>
                  <Tabs className={classes.tabs} value={index} fullWidth onChange={this.handleTabClick}>
                    {['General', 'Floorplans'].map(item => (
                      <Tab className={classes.tab} label={item} key={item} />
                    ))}
                  </Tabs>
                </AppBar>
                {index === 0 && (
                  <SettingsGeneral
                    key={site.key}
                    site={site}
                    companies={companies}
                    handleDeleteClick={handleDeleteSiteClick}
                    handleSaveSite={handleSaveSite}
                    handleSaveSiteAlerts={handleSaveSiteAlerts}
                  />
                )}
                {index === 1 && (
                  <SettingsFloorplans
                    key={site.key}
                    floorplans={floorplans}
                    handleListItemClick={this.handleListItemClick}
                    handleSwitchClick={handleSwitchClick}
                  />
                )}
              </>
            )}
            {step === 1 && (
              <>
                <AppBar title="Floorplan details" handleBackClick={this.handleBackClick} />
                <SettingsFloorplanDetails floorplan={floorplans.find(item => item.id === id)} />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
  siteId: PropTypes.string.isRequired,
  site: PropTypes.object.isRequired,
  floorplans: PropTypes.arrayOf(PropTypes.object).isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  alerts: PropTypes.arrayOf(PropTypes.object),
  sideSheet: PropTypes.bool,
  handleSwitchClick: PropTypes.func.isRequired,
  handleBackClick: PropTypes.func,
  handleClose: PropTypes.func,
  handleDeleteFloorplanClick: PropTypes.func.isRequired,
  handleDeleteSiteClick: PropTypes.func.isRequired,
  handleSaveFloorplanClick: PropTypes.func.isRequired,
  handleSaveSite: PropTypes.func.isRequired,
  handleSaveSiteAlerts: PropTypes.func.isRequired,
};

Settings.defaultProps = {
  alerts: undefined,
  sideSheet: false,
  handleBackClick: undefined,
  handleClose: undefined,
};

export default withStyles(styles)(Settings);
