import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Redirect, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import AppBarLogo from '../components/AppBarLogo';
import CreateEditDialog from '../components/CreateEditDialog';
import EditorPalette from '../components/EditorPalette';
import FileError from '../components/FileError';
import FloorplanMenu from '../components/FloorplanMenu';
import FloorplansEditorComponent from '../components/FloorplansEditor';
import MoreVertIcon from '../components/MoreVertIcon';
import Spinner from '../components/Spinner';
import { mixinHeightWithToolbar } from '../constants/theme';
import {
  apiGetCurrentSite,
  apiGetDefaultFloorplanId,
  apiGetFloorplan,
  apiGetFloorplans,
  apiGetLocations,
  apiGetNextFloorplanId,
  apiGetPrevFloorplanId,
  apiGetUserAlerts,
  apiModifyFloorplan,
  apiUploadImage,
} from '../utils/api';
import deepCopy from '../utils/deepCopy';
import has from '../utils/has';

const acceptTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/bmp'];

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: theme.sideSheet.width + theme.floorplan.width,
    margin: `${theme.spacing.unit * 3}px 0px`,
    ...mixinHeightWithToolbar(theme.spacing.unit * 6),
  },
  contentContainer: {
    position: 'inherit',
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    boxShadow: '0 2px 2px -1px rgba(153, 153, 153, 0.3), 0 1px 5px -2px rgba(153, 153, 153, 0.3)',
    boxSizing: 'border-box',
    padding: 1,
  },
  showSpinner: {
    position: 'relative',
  },
  componentAppBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: theme.appBar.height,
    ...theme.mixins.gutters(),
  },
  componentContainer: {
    flexGrow: 1,
    height: '100%',
  },
  component: {
    width: '100%',
    height: `calc(100% - ${theme.bottomAppBar.height}px - 56px)`,
    marginTop: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100% - ${theme.bottomAppBar.height}px - 48px)`,
      marginTop: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${theme.bottomAppBar.height}px - 64px)`,
      marginTop: 64,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      ...mixinHeightWithToolbar(),
    },
  },
  sideSheet: {
    width: theme.sideSheet.width,
    height: '100%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  spacing: {
    marginRight: theme.spacing.unit * 2,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function removeOrphanedLocations(widgets, locations) {
  return widgets.filter((item) => {
    if (item.type === 'sensor') {
      return locations.some(value => value.id === item.locationConfigId);
    }
    return true;
  });
}

class FloorplansEditor extends React.Component {
  constructor(props) {
    super(props);

    const { floorplanId, siteId } = this.props;

    const floorplan = apiGetFloorplan(floorplanId, siteId);

    this.state = {
      cancel: false,
      openCreateEdit: false,
      duplicate: false,
      image: floorplan && has.call(floorplan, 'image') ? deepCopy(floorplan.image) : undefined,
      widgets: removeOrphanedLocations(deepCopy(floorplan ? floorplan.widgets : []), apiGetLocations()),
      viewportScale: 1,
      scaleFactor: (floorplan && floorplan.scale) || 1.0,
      openFileError: false,
      filename: '',
      saving: false,
    };

    this.inputRef = null;

    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    this.handleDialogSaveClick = this.handleDialogSaveClick.bind(this);
    this.handleDialogCancelClick = this.handleDialogCancelClick.bind(this);
    this.handleFloorplanBackClick = this.handleFloorplanBackClick.bind(this);
    this.handleFloorplanForwardClick = this.handleFloorplanForwardClick.bind(this);
    this.handleFloorplanMenuClick = this.handleFloorplanMenuClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleImageDrop = this.handleImageDrop.bind(this);
    this.handleFileErrorClose = this.handleFileErrorClose.bind(this);
    this.handleLocationDrop = this.handleLocationDrop.bind(this);
    this.handleAnnotationDrop = this.handleAnnotationDrop.bind(this);
    this.handleWidgetMove = this.handleWidgetMove.bind(this);
    this.handleCalloutMove = this.handleCalloutMove.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleWidgetClick = this.handleWidgetClick.bind(this);
    this.handleViewportScaleChange = this.handleViewportScaleChange.bind(this);
    this.handleScaleFactorChange = this.handleScaleFactorChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputRef = this.handleInputRef.bind(this);
    this.handleFileDialogOpen = this.handleFileDialogOpen.bind(this);
  }

  componentWillUnmount() {
    if (this.imageObjectURL) {
      URL.revokeObjectURL(this.imageObjectURL);
    }
  }

  handleMenuItemClick(item) {
    if (item === 'Upload image') {
      this.handleFileDialogOpen();
    } else if (item === 'Duplicate floorplan') {
      this.setState({ openCreateEdit: true, duplicate: true });
    } else {
      this.setState({ openCreateEdit: true, duplicate: false });
    }
  }

  handleDialogSaveClick(name) {
    const { floorplanId, siteId } = this.props;
    const { duplicate, saving } = this.state;

    if (saving) return;

    const floorplan = apiGetFloorplan(floorplanId, siteId);

    if (duplicate === false) {
      floorplan.name = name;

      this.setState({ openCreateEdit: false, saving: true });

      apiModifyFloorplan(floorplan)
        .then(() => this.setState({ saving: false }))
        .catch(() => this.setState({ saving: false }));
    } else {
      const newFloorplan = {
        active: floorplan.active,
        image: floorplan.image,
        name,
        scale: floorplan.scale,
        siteId: floorplan.siteId,
        widgets: floorplan.widgets,
      };

      this.setState({ openCreateEdit: false, saving: true });

      apiModifyFloorplan(newFloorplan)
        .then(() => this.setState({ saving: false }))
        .catch(() => this.setState({ saving: false }));
    }
  }

  handleDialogCancelClick() {
    this.setState({ openCreateEdit: false });
  }

  handleFloorplanBackClick() {
    const { history, siteId, floorplanId } = this.props;
    history.push(`/site/${siteId}/floorplans/${apiGetPrevFloorplanId(floorplanId)}?edit=true`);
  }

  handleFloorplanForwardClick() {
    const { history, siteId, floorplanId } = this.props;
    history.push(`/site/${siteId}/floorplans/${apiGetNextFloorplanId(floorplanId)}?edit=true`);
  }

  handleFloorplanMenuClick(item) {
    const { history, siteId } = this.props;

    history.push(`/site/${siteId}/floorplans/${item.id}?edit=true`);
  }

  handleCancelClick() {
    this.setState({ cancel: true });
  }

  handleSaveClick() {
    const { floorplanId, siteId } = this.props;
    const {
      file, image, scaleFactor, widgets, saving,
    } = this.state;

    if (saving) return;

    const floorplan = apiGetFloorplan(floorplanId, siteId);
    floorplan.image = image;
    floorplan.scale = scaleFactor;
    floorplan.widgets = widgets;

    this.setState({ saving: true });

    if (file) {
      apiUploadImage(file, siteId)
        .then((result) => {
          floorplan.image.id = result.id;
          apiModifyFloorplan(floorplan)
            .then(() => this.setState({ cancel: true, saving: false }))
            .catch(() => this.setState({ cancel: true, saving: false }));
        })
        .catch(() => this.setState({ cancel: true, saving: false }));
    } else {
      apiModifyFloorplan(floorplan)
        .then(() => this.setState({ cancel: true, saving: false }))
        .catch(() => this.setState({ cancel: true, saving: false }));
    }
  }

  handleImageDrop(file) {
    if (acceptTypes.includes(file.type)) {
      if (this.imageObjectURL) {
        URL.revokeObjectURL(this.imageObjectURL);
      }

      this.imageObjectURL = URL.createObjectURL(file);
      this.setState({ file, image: { url: this.imageObjectURL } });
    } else {
      this.setState({ openFileError: true, filename: file.name });
    }
  }

  handleFileErrorClose() {
    this.setState({ openFileError: false });
  }

  handleLocationDrop(location, origin, offset) {
    const { widgets: widgetsState } = this.state;

    const widgets = deepCopy(widgetsState);

    widgets.push({
      locationConfigId: location.id,
      offset,
      origin,
      type: 'sensor',
    });

    this.setState({ widgets });
  }

  handleAnnotationDrop(width, height, origin, offset) {
    const { widgets: widgetsState } = this.state;

    const widgets = deepCopy(widgetsState);

    widgets.push({
      offset,
      origin,
      text: 'Label',
      type: 'textbox',
      size: { width, height },
    });

    this.setState({ widgets });
  }

  handleWidgetMove(index, bodyPosition) {
    const { widgets: widgetsState } = this.state;

    const widgets = deepCopy(widgetsState);

    const widget = widgets[index];

    widget.offset.x = bodyPosition.x - widget.origin.x;
    widget.offset.y = bodyPosition.y - widget.origin.y;

    widgets.push(widgets.splice(index, 1)[0]);

    this.setState({ widgets });
  }

  handleCalloutMove(index, calloutPosition) {
    const { widgets: widgetsState } = this.state;

    const widgets = deepCopy(widgetsState);

    const widget = widgets[index];

    widget.offset.x += widget.origin.x - calloutPosition.x;
    widget.offset.y += widget.origin.y - calloutPosition.y;
    widget.origin.x = calloutPosition.x;
    widget.origin.y = calloutPosition.y;

    widgets.push(widgets.splice(index, 1)[0]);

    this.setState({ widgets });
  }

  handleRemoveClick(index) {
    const { widgets: widgetsState } = this.state;

    const widgets = deepCopy(widgetsState);

    widgets.splice(index, 1);

    this.setState({ widgets });
  }

  handleTextChange(index, text) {
    const { widgets: widgetsState } = this.state;

    const widgets = deepCopy(widgetsState);

    widgets[index].text = text;

    this.setState({ widgets });
  }

  handleWidgetClick(index) {
    const { widgets: widgetsState } = this.state;

    const widgets = deepCopy(widgetsState);

    widgets.push(widgets.splice(index, 1)[0]);

    this.setState({ widgets });
  }

  handleViewportScaleChange(value) {
    this.setState({ viewportScale: value });
  }

  handleScaleFactorChange(_, value) {
    this.setState({ scaleFactor: Number(value) });
  }

  handleInputChange(event) {
    const { files } = event.target;

    if (files.length === 1) {
      this.handleImageDrop(files[0]);
    }
  }

  handleInputRef(ref) {
    if (ref) {
      this.inputRef = ref;
    }
  }

  handleFileDialogOpen() {
    if (this.inputRef) {
      this.inputRef.click();
    }
  }

  render() {
    const { classes, siteId, floorplanId } = this.props;
    const {
      cancel,
      openCreateEdit,
      duplicate,
      image,
      widgets,
      viewportScale,
      scaleFactor,
      openFileError,
      filename,
      saving,
    } = this.state;

    const site = apiGetCurrentSite();
    const alerts = apiGetUserAlerts();
    const floorplans = apiGetFloorplans();
    const floorplan = apiGetFloorplan(floorplanId, siteId);
    const locations = apiGetLocations();

    const paletteLocations = locations.filter(
      location => !widgets.some(widget => widget.type === 'sensor' && widget.locationConfigId === location.id),
    );

    if (cancel) {
      return <Redirect to={`/site/${siteId}/floorplans/${apiGetDefaultFloorplanId(siteId)}`} />;
    }

    return (
      <>
        <AppBarLogo title={site.name} alerts={alerts} />
        <div className={classes.root}>
          <div className={classes.container}>
            <Spinner className={classNames(classes.contentContainer, { [classes.showSpinner]: saving })} show={saving}>
              <div className={classes.componentContainer}>
                <div className={classes.componentAppBar}>
                  <div className={classes.titleContainer}>
                    <Typography className={classes.spacing} variant="h6">
                      {'Edit floorplan'}
                    </Typography>
                    <FloorplanMenu
                      siteSummary={site.summary}
                      floorplans={floorplans}
                      floorplan={floorplan}
                      handleFloorplanMenuClick={this.handleFloorplanMenuClick}
                    />
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button className={classes.spacing} onClick={this.handleCancelClick}>
                      Cancel
                    </Button>
                    <Button
                      className={classes.spacing}
                      onClick={floorplan ? this.handleSaveClick : () => {}}
                      variant="contained"
                      color="secondary"
                    >
                      Save
                    </Button>
                    <div>
                      <MoreVertIcon
                        menuItems={['Upload image', 'Rename floorplan', 'Duplicate floorplan']}
                        placement="bottom-end"
                        handleMenuItemClick={floorplan ? this.handleMenuItemClick : () => {}}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.component}>
                  <FloorplansEditorComponent
                    floorplans={floorplans}
                    floorplan={floorplan}
                    image={image}
                    widgets={widgets}
                    locations={locations}
                    scale={viewportScale * scaleFactor}
                    onViewportScaleChange={this.handleViewportScaleChange}
                    handleBackClick={this.handleFloorplanBackClick}
                    handleForwardClick={this.handleFloorplanForwardClick}
                    handleImageDrop={this.handleImageDrop}
                    handleLocationDrop={this.handleLocationDrop}
                    handleAnnotationDrop={this.handleAnnotationDrop}
                    handleWidgetMove={this.handleWidgetMove}
                    handleCalloutMove={this.handleCalloutMove}
                    handleRemoveClick={this.handleRemoveClick}
                    handleTextChange={this.handleTextChange}
                    handleWidgetClick={this.handleWidgetClick}
                    handleFileDialogOpen={this.handleFileDialogOpen}
                  />
                </div>
              </div>
              <div className={classes.sideSheet}>
                <EditorPalette
                  locations={paletteLocations}
                  scale={viewportScale * scaleFactor}
                  scaleFactor={scaleFactor}
                  handleScaleFactorChange={this.handleScaleFactorChange}
                />
              </div>
            </Spinner>
          </div>
        </div>
        {floorplan && (
          <CreateEditDialog
            title={duplicate ? 'Create a floorplan' : 'Edit floorplan'}
            save={duplicate ? 'Create' : 'Save'}
            cancel="Cancel"
            open={openCreateEdit}
            defaultName={duplicate ? `Copy of ${floorplan.name}` : floorplan.name}
            handleSaveClick={this.handleDialogSaveClick}
            handleCancelClick={this.handleDialogCancelClick}
          />
        )}
        <input
          className={classes.input}
          onChange={event => this.handleInputChange(event)}
          ref={ref => this.handleInputRef(ref)}
          accept={acceptTypes}
          autoComplete="off"
          type="file"
        />
        <FileError filename={filename} open={openFileError} handleClose={this.handleFileErrorClose} />
      </>
    );
  }
}

FloorplansEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  siteId: PropTypes.string.isRequired,
  floorplanId: PropTypes.string.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles),
  DragDropContext(HTML5Backend),
)(FloorplansEditor);
