import { compareSensors } from '../constants/sensorTypes';

const alphanumSort = require('alphanum-sort');
const arraySort = require('array-sort');

function compare(a, b) {
  return alphanumSort([a, b])[0] === a ? -1 : 1;
}

function withCompare(param, opts) {
  function WithCompare(a, b) {
    return alphanumSort([a[param], b[param]], opts)[0] === a[param] ? -1 : 1;
  }
  return WithCompare;
}

function compareAlerts(a, b) {
  if (a.timestamp === b.timestamp) {
    return alphanumSort([a.siteName, b.siteName], { insensitive: true })[0] === a.siteName ? -1 : 1;
  }
  return alphanumSort([a.timestamp, b.timestamp])[0] === a.timestamp ? 1 : -1;
}

function compareUserSites(a, b) {
  return alphanumSort([a.site.name, b.site.name], { insensitive: true })[0] === a.site.name ? -1 : 1;
}

export function sort(items) {
  return arraySort(items, compare);
}

export function sortByName(items, nameProp = 'name') {
  return arraySort(items, withCompare(nameProp, { insensitive: true }));
}

export function sortByBarcode(items) {
  return arraySort(items, withCompare('barcode'));
}

export function sortByDistanceFromMainSite(items) {
  return arraySort(items, withCompare('distanceFromMainSite'));
}

export function sortAlerts(items) {
  return arraySort(items, compareAlerts);
}

export function sortSensors(items) {
  return arraySort(items, compareSensors);
}

export function sortUserSites(items) {
  return arraySort(items, compareUserSites);
}
