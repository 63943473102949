import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';

const styles = {
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(48),
  },
};

function SettingsFloorplanDetails(props) {
  const { classes, floorplan } = props;

  return (
    <div className={classes.content}>
      <List>
        <ListItem>
          <ListItemText primary="Floorplan name" secondary={floorplan.name} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Status" secondary={floorplan.active ? 'Active' : 'Inactive'} />
        </ListItem>
      </List>
    </div>
  );
}

SettingsFloorplanDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  floorplan: PropTypes.object.isRequired,
};

export default withStyles(styles)(SettingsFloorplanDetails);
