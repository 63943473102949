import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import React from 'react';

const withMobile = () => (Component) => {
  function WithMobile(props) {
    const { width } = props;

    return <Component widthMobile={isWidthDown('sm', width)} {...props} />;
  }

  WithMobile.propTypes = {
    width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']).isRequired,
  };

  return withWidth()(WithMobile);
};

export default withMobile;
