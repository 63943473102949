import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import getOS from '../utils/getOS';
import Crossout from './Crossout';
import TrendArrow from './TrendArrow';

const styles = theme => ({
  root: {
    width: 89,
    [theme.breakpoints.up('md')]: {
      width: 93,
    },
  },
  label: {
    letterSpacing: 0.15,
    padding: '4px 0 0',
  },
  labelSensor: {
    color: theme.floorplan.gauge.color.secondary,
  },
  labelHeater: {
    color: theme.floorplan.heater.color.secondary,
  },
  labelHeating: {
    color: theme.floorplan.heater.heating.color.secondary,
  },
  valueContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  valueArrow: {
    display: 'inline-flex',
  },
  value: {
    height: 26,
    fontSize: 26,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: 'GoogleSans, Roboto, Helvetica, Arial, sans-serif',
    letterSpacing: 0.5,
  },
  valueSensor: {
    color: theme.floorplan.gauge.color.primary,
  },
  valueHeater: {
    color: theme.floorplan.heater.color.primary,
  },
  valueHeating: {
    color: theme.floorplan.heater.heating.color.primary,
  },
  valueDisabled: {
    color: theme.palette.text.disabled,
  },
  arrow: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: 9,
    [theme.breakpoints.up('md')]: {
      marginLeft: 13,
    },
    marginBottom: 1,
  },
  arrowAndroid: {
    marginBottom: 2,
  },
  arrowIOS: {
    marginBottom: 0,
  },
  alert: {
    color: theme.palette.error.main,
  },
});

function FloorplanValue(props) {
  const { classes, sensor, heater } = props;
  const os = getOS();

  if (sensor) {
    return (
      <div className={classes.root}>
        <div className={classes.valueContainer}>
          <div className={classes.valueArrow}>
            {sensor.value && (sensor.missing || sensor.inError) ? (
              <Crossout strokeWidth={4}>
                <div
                  className={classNames(classes.value, classes.valueSensor, {
                    [classes.alert]: sensor.inAlert,
                  })}
                >
                  {sensor.value}
                </div>
              </Crossout>
            ) : (
              <div
                className={classNames(classes.value, classes.valueSensor, {
                  [classes.alert]: sensor.inAlert,
                })}
              >
                {sensor.value}
              </div>
            )}
          </div>
          <div
            className={classNames(classes.arrow, {
              [classes.arrowAndroid]: os === 'Android',
              [classes.arrowIOS]: os === 'iOS',
            })}
          >
            <TrendArrow alert={sensor.inAlert} data={sensor.trendData} />
          </div>
        </div>
        <Typography
          variant="caption"
          className={classNames(classes.label, classes.labelSensor, {
            [classes.alert]: sensor.inAlert,
          })}
          noWrap
        >
          {sensor.label}
        </Typography>
      </div>
    );
  }

  if (heater) {
    return (
      <div className={classes.root}>
        <div className={classes.valueContainer}>
          <div className={classes.valueArrow}>
            {heater.missing ? (
              <Crossout strokeWidth={4}>
                <div
                  className={classNames(classes.value, classes.valueHeater, {
                    [classes.valueHeating]: heater.heating,
                    [classes.valueDisabled]: !heater.enabled,
                  })}
                >
                  {heater.value}
                </div>
              </Crossout>
            ) : (
              <div
                className={classNames(classes.value, classes.valueHeater, {
                  [classes.valueHeating]: heater.heating,
                  [classes.valueDisabled]: !heater.enabled,
                })}
              >
                {heater.value}
              </div>
            )}
          </div>
        </div>
        <Typography
          variant="caption"
          className={classNames(classes.label, classes.labelHeater, {
            [classes.labelHeating]: heater.heating,
          })}
          noWrap
        >
          Heat set to
        </Typography>
      </div>
    );
  }

  return null;
}

FloorplanValue.propTypes = {
  classes: PropTypes.object.isRequired,
  sensor: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    missing: PropTypes.bool,
    inAlert: PropTypes.bool.isRequired,
    inError: PropTypes.bool,
    trendData: PropTypes.arrayOf(PropTypes.object),
  }),
  heater: PropTypes.shape({
    value: PropTypes.string.isRequired,
    missing: PropTypes.bool,
    enabled: PropTypes.bool.isRequired,
    heating: PropTypes.bool,
  }),
};

FloorplanValue.defaultProps = {
  sensor: undefined,
  heater: undefined,
};

export default withStyles(styles)(FloorplanValue);
