import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import ViewListIcon from '@material-ui/icons/ViewList';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import EventListener from 'react-event-listener';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import AppBarLogo from '../components/AppBarLogo';
import BottomAppBar from '../components/BottomAppBar';
import Devices from '../components/Devices';
import FloorplanHeater from '../components/FloorplanHeater';
import FloorplanMenu from '../components/FloorplanMenu';
import FloorplansComponent from '../components/Floorplans';
import FloorplanThumbnails from '../components/FloorplanThumbnails';
import Invitations from '../components/Invitations';
import Locations from '../components/Locations';
import SideSheet from '../components/SideSheet';
import Team from '../components/Team';
import Tooltip from '../components/Tooltip';
import withMobile from '../components/withMobile';
import Zones from '../components/Zones';
import { mixinHeightWithToolbar } from '../constants/theme';
import {
  apiAcceptHeaterWarning,
  apiAssignDevice,
  apiDeleteContact,
  apiDeleteInvite,
  apiDeleteLocation,
  apiDeleteThreshold,
  apiDeleteZone,
  apiFastFindDevice,
  apiGetCurrentSite,
  apiGetDevices,
  apiGetFloorplan,
  apiGetFloorplans,
  apiGetHistoryData,
  apiGetInvites,
  apiGetLocations,
  apiGetNameFromEmail,
  apiGetNextFloorplanId,
  apiGetPrevFloorplanId,
  apiGetSignedInUser,
  apiGetUserAlerts,
  apiModifyContact,
  apiModifyLocation,
  apiModifyThreshold,
  apiModifyZone,
  apiSendInvite,
  apiUnassignDevice,
  apiUpdateDeviceNetwork,
} from '../utils/api';
import Settings from './Settings';
import Sidebar from './Sidebar';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: theme.sideSheet.width + theme.floorplan.width,
    margin: `${theme.spacing.unit * 3}px 0px`,
    ...mixinHeightWithToolbar(theme.spacing.unit * 6),
  },
  contentContainer: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    boxShadow: '0 2px 2px -1px rgba(153, 153, 153, 0.3), 0 1px 5px -2px rgba(153, 153, 153, 0.3)',
    boxSizing: 'border-box',
    padding: 1,
  },
  componentAppBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: theme.appBar.height,
    ...theme.mixins.gutters(),
  },
  componentContainer: {
    flexGrow: 1,
    height: '100%',
  },
  component: {
    width: '100%',
    height: `calc(100% - ${theme.bottomAppBar.height}px - 56px)`,
    marginTop: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100% - ${theme.bottomAppBar.height}px - 48px)`,
      marginTop: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${theme.bottomAppBar.height}px - 64px)`,
      marginTop: 64,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      ...mixinHeightWithToolbar(),
    },
  },
  sideSheet: {
    width: theme.sideSheet.width,
    height: '100%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sideSheetClosed: {
    width: 0,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  divider: {
    marginRight: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 3,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titlePadding: {
    marginRight: theme.spacing.unit * 2,
  },
  viewIcon: {
    marginRight: -15,
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  sideSheetContent: {
    overflowY: 'auto',
    height: `calc(100% - ${theme.appBar.height}px)`,
  },
  paperDrawer: {
    position: 'absolute',
    width: 'calc(100% - 56px)',
    height: '100%',
  },
});

class Floorplans extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: true,
      sideSheet: {
        index: 0,
        locationId: undefined,
      },
      listView: false,
      charts: [],
      tooltipOpen: false,
    };

    this.handleSidebarOpen = this.handleSidebarOpen.bind(this);
    this.handleSideSheetClose = this.handleSideSheetClose.bind(this);
    this.handleZonesClick = this.handleZonesClick.bind(this);
    this.handleLocationsClick = this.handleLocationsClick.bind(this);
    this.handleDevicesClick = this.handleDevicesClick.bind(this);
    this.handleTeamClick = this.handleTeamClick.bind(this);
    this.handleTeamAddClick = this.handleTeamAddClick.bind(this);
    this.handleInvitationsClick = this.handleInvitationsClick.bind(this);
    this.handleSettingsClick = this.handleSettingsClick.bind(this);
    this.handleLocationClick = this.handleLocationClick.bind(this);
    this.handleFloorplanBackClick = this.handleFloorplanBackClick.bind(this);
    this.handleFloorplanForwardClick = this.handleFloorplanForwardClick.bind(this);
    this.handleFloorplanMenuClick = this.handleFloorplanMenuClick.bind(this);
    this.handleFloorplanListViewClick = this.handleFloorplanListViewClick.bind(this);
    this.handleThumbnailClick = this.handleThumbnailClick.bind(this);
    this.handleChartCloseClick = this.handleChartCloseClick.bind(this);
    this.handleChartBackClick = this.handleChartBackClick.bind(this);
    this.handleChartForwardClick = this.handleChartForwardClick.bind(this);
    this.handleChartDataRequest = this.handleChartDataRequest.bind(this);
    this.handleChartClick = this.handleChartClick.bind(this);
    this.handleTooltipOpen = this.handleTooltipOpen.bind(this);
    this.handleTooltipClose = this.handleTooltipClose.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { floorplanId } = this.props;
    const { sideSheet } = this.state;

    if (prevProps.floorplanId !== floorplanId) {
      if (sideSheet.locationId !== undefined) {
        this.handleSidebarOpen();
      }
    }
  }

  getHistoryData(location, sensor, startTime, endTime, interval) {
    // eslint-disable-next-line max-len
    apiGetHistoryData(sensor.value.site, sensor.id, startTime, endTime, interval).then((results) => {
      const { charts } = this.state;
      const chart = charts.find(item => item.location === location);
      if (chart) {
        const newCharts = charts.filter(item => item.location !== location).map(item => ({
          data: item.data,
          location: item.location,
          sensor: item.sensor,
          zIndex: item.zIndex,
        }));

        newCharts.push({
          data: results.data,
          location,
          sensor,
          zIndex: chart.zIndex,
        });

        this.setState({ charts: newCharts });
      }
    });
  }

  handleSidebarOpen() {
    this.setState({ sidebarOpen: true });
  }

  handleSideSheetClose() {
    this.setState({
      sidebarOpen: true,
      sideSheet: { index: 0, locationId: undefined },
      charts: [],
    });
  }

  handleZonesClick() {
    this.setState({ sidebarOpen: false, sideSheet: { index: 1 } });
  }

  handleLocationsClick() {
    this.setState({ sidebarOpen: false, sideSheet: { index: 2 } });
  }

  handleDevicesClick() {
    this.setState({ sidebarOpen: false, sideSheet: { index: 3 } });
  }

  handleTeamClick() {
    this.setState({ sidebarOpen: false, sideSheet: { index: 4 } });
  }

  handleTeamAddClick() {
    this.setState({ sidebarOpen: false, sideSheet: { index: 5 } });
  }

  handleInvitationsClick() {
    this.setState({ sidebarOpen: false, sideSheet: { index: 5 } });
  }

  handleSettingsClick() {
    this.setState({ sidebarOpen: false, sideSheet: { index: 6 } });
  }

  handleLocationClick(event, locationId) {
    event.stopPropagation();
    this.setState({ sidebarOpen: false, sideSheet: { locationId } });
  }

  handleFloorplanBackClick() {
    const { history, siteId, floorplanId } = this.props;

    this.setState({ charts: [] });

    history.push(`/site/${siteId}/floorplans/${apiGetPrevFloorplanId(floorplanId, true)}`);
  }

  handleFloorplanForwardClick() {
    const { history, siteId, floorplanId } = this.props;

    this.setState({ charts: [] });

    history.push(`/site/${siteId}/floorplans/${apiGetNextFloorplanId(floorplanId, true)}`);
  }

  handleFloorplanMenuClick(item) {
    const { history, siteId } = this.props;

    history.push(`/site/${siteId}/floorplans/${item.id}`);
  }

  handleFloorplanListViewClick() {
    const { listView } = this.state;

    this.setState({ listView: !listView, charts: [], tooltipOpen: false });
  }

  handleThumbnailClick(location, sensor) {
    const { charts } = this.state;
    const existingLocation = charts.find(item => item.location.id === location.id);
    const newCharts = charts.filter(item => item.location.id !== location.id).map(item => ({
      data: item.data,
      location: item.location,
      sensor: item.sensor,
      zIndex: existingLocation && item.zIndex > existingLocation.zIndex ? item.zIndex - 1 : item.zIndex,
    }));

    newCharts.push({
      data: existingLocation && sensor.id === existingLocation.sensor.id ? existingLocation.data : undefined,
      location,
      sensor,
      zIndex: newCharts.length,
    });

    this.setState({ charts: newCharts });
  }

  handleChartCloseClick(location) {
    const { charts } = this.state;

    const locationZIndex = charts.find(item => item.location === location).zIndex;

    this.setState({
      charts: charts.filter(item => item.location !== location).map(item => ({
        data: item.data,
        location: item.location,
        sensor: item.sensor,
        zIndex: item.zIndex > locationZIndex ? item.zIndex - 1 : item.zIndex,
      })),
    });
  }

  handleChartBackClick(location, sensor) {
    const { charts } = this.state;

    const newCharts = charts.filter(item => item.location !== location).map(item => ({
      data: item.data,
      location: item.location,
      sensor: item.sensor,
      zIndex: item.zIndex,
    }));
    const index = location.sensors.indexOf(sensor);
    const newSensor = location.sensors[index === location.sensors.length - 1 ? 0 : index + 1];

    newCharts.push({
      location,
      sensor: newSensor,
      zIndex: newCharts.length,
    });

    this.setState({ charts: newCharts });
  }

  handleChartForwardClick(location, sensor) {
    const { charts } = this.state;

    const newCharts = charts.filter(item => item.location !== location).map(item => ({
      data: item.data,
      location: item.location,
      sensor: item.sensor,
      zIndex: item.zIndex,
    }));
    const index = location.sensors.indexOf(sensor);
    const newSensor = location.sensors[index === location.sensors.length - 1 ? 0 : index + 1];

    newCharts.push({
      location,
      sensor: newSensor,
      zIndex: newCharts.length,
    });

    this.setState({ charts: newCharts });
  }

  handleChartDataRequest(location, sensor, startTime, endTime, interval) {
    const { charts } = this.state;

    const newCharts = charts.filter(item => item.location !== location).map(item => ({
      data: item.data,
      location: item.location,
      sensor: item.sensor,
      zIndex: item.zIndex,
    }));

    newCharts.push({
      location,
      sensor,
      zIndex: newCharts.length,
    });
    this.getHistoryData(location, sensor, startTime, endTime, interval);
    this.setState({ charts: newCharts });
  }

  handleChartClick(location) {
    const { charts } = this.state;

    const locationZIndex = charts.find(item => item.location === location).zIndex;

    const newCharts = charts.map((item) => {
      let zIndex = item.zIndex > locationZIndex ? item.zIndex - 1 : item.zIndex;
      if (item.location === location) {
        zIndex = charts.length - 1;
      }

      return {
        data: item.data,
        location: item.location,
        sensor: item.sensor,
        zIndex,
      };
    });
    this.setState({ charts: newCharts });
  }

  handleTooltipOpen() {
    this.setState({ tooltipOpen: true });
  }

  handleTooltipClose() {
    this.setState({ tooltipOpen: false });
  }

  handleKeyDown(event) {
    const { sidebarOpen } = this.state;

    if (event.key === 'Escape' && sidebarOpen === false) {
      event.preventDefault();
      this.handleSideSheetClose();
    }
  }

  render() {
    const {
      classes, widthMobile, siteId, floorplanId,
    } = this.props;
    const {
      sidebarOpen, sideSheet, charts, listView, tooltipOpen,
    } = this.state;

    const site = apiGetCurrentSite();
    const alerts = apiGetUserAlerts();
    const floorplans = apiGetFloorplans(true);
    const floorplan = apiGetFloorplan(floorplanId, siteId);
    const { zones } = site;
    const devices = apiGetDevices();
    const invites = apiGetInvites();
    const locations = apiGetLocations();
    const selectedLocation = locations.find(item => item.id === sideSheet.locationId);
    const userId = apiGetSignedInUser().id;

    return (
      <>
        {!widthMobile ? (
          <>
            <EventListener target="window" onKeyDown={this.handleKeyDown} />
            <AppBarLogo title={site.name} alerts={alerts} />
            <div className={classes.root}>
              <div className={classes.container}>
                <Sidebar
                  siteId={siteId}
                  siteSummary={site.summary}
                  open={sidebarOpen}
                  handleZonesClick={this.handleZonesClick}
                  handleLocationsClick={this.handleLocationsClick}
                  handleDevicesClick={this.handleDevicesClick}
                  handleTeamClick={this.handleTeamClick}
                  handleInvitationsClick={this.handleInvitationsClick}
                  handleSettingsClick={this.handleSettingsClick}
                />
                <div className={classes.contentContainer}>
                  <div className={classes.componentContainer}>
                    <div className={classes.componentAppBar}>
                      <div className={classes.titleContainer}>
                        <Typography className={classes.titlePadding} variant="h6">
                          {listView ? 'Floorplan list view' : 'Floorplan'}
                        </Typography>
                        {!listView && (
                          <FloorplanMenu
                            siteSummary={site.summary}
                            floorplans={floorplans}
                            floorplan={floorplan}
                            handleFloorplanMenuClick={this.handleFloorplanMenuClick}
                          />
                        )}
                      </div>
                      <Tooltip
                        title={listView ? 'Go to plan view' : 'Go to list view'}
                        placement="left"
                        open={tooltipOpen}
                        onOpen={this.handleTooltipOpen}
                        onClose={this.handleTooltipClose}
                      >
                        <IconButton className={classes.viewIcon} onClick={this.handleFloorplanListViewClick}>
                          {listView ? <ViewCarouselIcon /> : <ViewListIcon />}
                        </IconButton>
                      </Tooltip>
                    </div>
                    {listView && <Divider className={classes.divider} />}
                    <div className={classes.component}>
                      <FloorplansComponent
                        floorplans={floorplans}
                        floorplan={floorplan}
                        locations={locations}
                        selectedLocationId={sideSheet.locationId}
                        listView={listView}
                        charts={charts}
                        handleBackClick={this.handleFloorplanBackClick}
                        handleForwardClick={this.handleFloorplanForwardClick}
                        handleLocationClick={this.handleLocationClick}
                        handleChartCloseClick={this.handleChartCloseClick}
                        handleChartBackClick={this.handleChartBackClick}
                        handleChartForwardClick={this.handleChartForwardClick}
                        handleChartDataRequest={this.handleChartDataRequest}
                        handleChartClick={this.handleChartClick}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames(classes.sideSheet, {
                      [classes.sideSheetClosed]: sidebarOpen,
                    })}
                  >
                    {!sidebarOpen && (
                      <>
                        {sideSheet.index === 1 && (
                          <Zones
                            site={site}
                            sideSheet
                            handleClose={this.handleSideSheetClose}
                            handleDeleteThresholdClick={apiDeleteThreshold}
                            handleSaveThresholdClick={apiModifyThreshold}
                            handleDeleteZoneClick={apiDeleteZone}
                            handleSaveZoneClick={apiModifyZone}
                          />
                        )}
                        {sideSheet.index === 2 && (
                          <Locations
                            site={site}
                            devices={devices}
                            sideSheet
                            handleDeleteClick={apiDeleteLocation}
                            handleSaveClick={apiModifyLocation}
                            handleClose={this.handleSideSheetClose}
                          />
                        )}
                        {sideSheet.index === 3 && (
                          <Devices
                            site={site}
                            devices={devices}
                            alerts={apiGetUserAlerts()}
                            sideSheet
                            handleAssignClick={apiAssignDevice}
                            handleFastFindClick={apiFastFindDevice}
                            handleUnassignClick={apiUnassignDevice}
                            handleUpdateDeviceNetwork={apiUpdateDeviceNetwork}
                            handleClose={this.handleSideSheetClose}
                          />
                        )}
                        {sideSheet.index === 4 && (
                          <Team
                            site={site}
                            sideSheet
                            handleTeamDelete={apiDeleteContact}
                            handleTeamUpdate={apiModifyContact}
                            handleTeamAddClick={this.handleTeamAddClick}
                            handleClose={this.handleSideSheetClose}
                          />
                        )}
                        {sideSheet.index === 5 && (
                          <Invitations
                            siteId={site.id}
                            invites={invites}
                            contacts={site.contacts}
                            sideSheet
                            getNameFromEmail={apiGetNameFromEmail}
                            handleClose={this.handleSideSheetClose}
                            handleDeleteInvite={apiDeleteInvite}
                            handleSendInvite={apiSendInvite}
                          />
                        )}
                        {sideSheet.index === 6 && (
                          <Settings siteId={siteId} sideSheet handleClose={this.handleSideSheetClose} />
                        )}
                        {sideSheet.locationId && (
                          <SideSheet
                            title={selectedLocation ? selectedLocation.name : ''}
                            handleClose={this.handleSideSheetClose}
                          >
                            <div className={classes.sideSheetContent}>
                              {selectedLocation
                                && selectedLocation.heater === null && (
                                  <FloorplanThumbnails
                                    location={selectedLocation}
                                    handleClick={this.handleThumbnailClick}
                                  />
                              )}
                              {selectedLocation
                                && selectedLocation !== null
                                && selectedLocation.heater !== null && (
                                  <FloorplanHeater
                                    siteId={siteId}
                                    zones={zones}
                                    location={selectedLocation}
                                    locations={site.locations}
                                    contacts={site.contacts}
                                    userId={userId}
                                    key={selectedLocation.id}
                                    handleAcceptHeaterWarning={apiAcceptHeaterWarning}
                                    handleModifyLocation={apiModifyLocation}
                                  />
                              )}
                            </div>
                          </SideSheet>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <AppBar>
              <Toolbar className={classes.toolbar}>
                <div className={classes.titleContainer}>
                  <Typography className={classes.titlePadding} variant="h6">
                    {listView ? 'Floorplan list view' : 'Floorplan'}
                  </Typography>
                  {!listView && (
                    <FloorplanMenu
                      siteSummary={site.summary}
                      floorplans={floorplans}
                      floorplan={floorplan}
                      handleFloorplanMenuClick={this.handleFloorplanMenuClick}
                    />
                  )}
                </div>
                <Tooltip
                  title={listView ? 'Go to plan view' : 'Go to list view'}
                  placement="left"
                  open={tooltipOpen}
                  onOpen={this.handleTooltipOpen}
                  onClose={this.handleTooltipClose}
                >
                  <IconButton className={classes.viewIcon} onClick={this.handleFloorplanListViewClick}>
                    {listView ? <ViewCarouselIcon /> : <ViewListIcon />}
                  </IconButton>
                </Tooltip>
              </Toolbar>
            </AppBar>
            <div className={classes.component}>
              <FloorplansComponent
                floorplans={floorplans}
                floorplan={floorplan}
                locations={locations}
                selectedLocationId={sideSheet.locationId}
                listView={listView}
                charts={charts}
                handleBackClick={this.handleFloorplanBackClick}
                handleForwardClick={this.handleFloorplanForwardClick}
                handleLocationClick={this.handleLocationClick}
                handleChartCloseClick={this.handleChartCloseClick}
                handleChartBackClick={this.handleChartBackClick}
                handleChartForwardClick={this.handleChartForwardClick}
                handleChartDataRequest={this.handleChartDataRequest}
                handleChartClick={this.handleChartClick}
              />
            </div>
            <SwipeableDrawer
              anchor="right"
              classes={{
                paper: classes.paperDrawer,
              }}
              disableSwipeToOpen
              open={selectedLocation !== undefined}
              onClose={this.handleSideSheetClose}
              onOpen={() => {}}
              variant="temporary"
            >
              {selectedLocation !== undefined && (
                <SideSheet
                  title={selectedLocation ? selectedLocation.name : ''}
                  handleClose={this.handleSideSheetClose}
                >
                  <div className={classes.sideSheetContent}>
                    {selectedLocation.heater === null ? (
                      <FloorplanThumbnails
                        location={selectedLocation}
                        handleClick={(...args) => {
                          this.handleSideSheetClose();
                          this.handleThumbnailClick(...args);
                        }}
                        handleClose={this.handleSideSheetClose}
                      />
                    ) : (
                      <FloorplanHeater
                        siteId={siteId}
                        zones={zones}
                        location={selectedLocation}
                        locations={site.locations}
                        contacts={site.contacts}
                        userId={userId}
                        key={selectedLocation.id}
                        handleAcceptHeaterWarning={apiAcceptHeaterWarning}
                        handleModifyLocation={apiModifyLocation}
                      />
                    )}
                  </div>
                </SideSheet>
              )}
            </SwipeableDrawer>
            <BottomAppBar siteId={siteId} site={site} devices={devices} invites={invites} alerts={alerts} />
          </>
        )}
      </>
    );
  }
}

Floorplans.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  siteId: PropTypes.string.isRequired,
  floorplanId: PropTypes.string.isRequired,
};

export default compose(
  withRouter,
  withMobile(),
  withStyles(styles),
  DragDropContext(HTML5Backend),
)(Floorplans);
