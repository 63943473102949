import { createMuiTheme } from '@material-ui/core/styles';
import shadows from './shadows';

const baseTheme = {
  palette: {
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
    },
    secondary: {
      light: '#4ab66b',
      main: '#00853f',
      dark: '#005715',
    },
    tertiary: {
      light: '#2f92d4',
      main: '#1163a4',
      dark: '#004785',
    },
    error: {
      light: '#e94948',
      main: '#b00020',
      dark: '#790000',
    },
    divider: 'rgba(0, 0, 0, 0.08)',
    action: {
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.04)',
    },
  },
  floorplan: {
    width: 998,
    annotation: {
      background: '#ffffff',
      border: '#444041',
      color: '#444041',
    },
    gauge: {
      background: '#ffffff',
      border: '#00853f',
      color: {
        primary: '#231f20',
        secondary: 'rgba(0, 0, 0, 0.54)',
      },
    },
    heater: {
      border: '#ff7100',
      background: '#ffffff',
      color: {
        primary: '#231f20',
        secondary: 'rgba(0, 0, 0, 0.54)',
      },
      heating: {
        border: '#ff7100',
        background: '#ffffff',
        color: {
          primary: '#ff7100',
          secondary: '#ff7100',
        },
      },
    },
  },
  thumbnail: {
    axis: {
      shapeRendering: 'crisp',
      stroke: '#e0e0e0',
      strokeWidth: 0.5,
    },
    labels: {
      fill: '#9e9e9e',
      fontFamily: 'GoogleSans, Roboto, Helvetica, Arial, sans-serif',
      fontSize: 12,
      fontWeight: 400,
    },
    line: {
      data: {
        shapeRendering: 'crisp',
        stroke: 'rgba(0, 133, 63, 0.87)',
        strokeWidth: 2,
      },
    },
    text: {
      fill: 'rgba(0, 0, 0, 0.54)',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: 14,
      fontWeight: 400,
    },
  },
  chart: {
    fontFamily: 'GoogleSans, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12,
    fontWeight: 400,
  },
  appBar: {
    height: 64,
    dividerColor: '#e0e0e0',
  },
  sidebar: {
    width: 353,
    color: '#000000',
    backgroundColor: '#ffffff',
  },
  sideSheet: {
    width: 353,
  },
  bottomAppBar: {
    height: 56,
    color: '#000000',
    backgroundColor: '#ffffff',
  },
  profile: {
    signOut: '#ff7100',
  },
  tab: {
    fontSize: 14,
    textTransform: 'none',
  },
  typography: {
    useNextVariants: true,
    h5: {
      fontFamily: 'GoogleSans, Roboto, Helvetica, Arial, sans-serif',
    },
    h6: {
      fontFamily: 'GoogleSans, Roboto, Helvetica, Arial, sans-serif',
    },
    button: {
      fontFamily: 'GoogleSans, Roboto, Helvetica, Arial, sans-serif',
    },
  },
  shadows,
  zIndex: {
    spinner: 1150,
  },
};

const theme = createMuiTheme(baseTheme);

export default theme;

export function mixinHeightWithToolbar(offset = 0) {
  return {
    height: `calc(100% - 56px - ${offset}px)`,
    '@media (min-width: 0px) and (orientation: landscape)': {
      height: `calc(100% - 48px - ${offset}px)`,
    },
    '@media (min-width: 600px)': {
      height: `calc(100% - 64px - ${offset}px)`,
    },
  };
}
