import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';
import { deviceInAlert } from '../utils/devices';
import formatValue from '../utils/formatValue';
import TinyBadge from './TinyBadge';

const styles = theme => ({
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
  primary: {
    color: theme.palette.text.secondary,
  },
});

class DevicesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      counter: 0,
    };

    this.renderAssignedDevice = this.renderAssignedDevice.bind(this);
    this.renderNearbyDevices = this.renderNearbyDevices.bind(this);
    this.renderUnassignedDevices = this.renderUnassignedDevices.bind(this);
    this.timer = undefined;
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      const { counter } = this.state;
      this.setState({ counter: counter + 1 });
    }, 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  renderAssignedDevice(device) {
    const { handleListItemClick } = this.props;

    const secondaryText = device.type === 'WSN' ? ` • ${formatValue(device.batteryVoltage, 'V', 1)}` : '';

    return (
      <ListItem key={device.barcode} button onClick={() => handleListItemClick(device)}>
        <ListItemText
          primary={<TinyBadge alert={deviceInAlert(device)}>{`${device.type} ${device.barcode}`}</TinyBadge>}
          secondary={`${distanceInWordsStrict(Date.now(), device.timestamp, { addSuffix: true })}${secondaryText}`}
        />
      </ListItem>
    );
  }

  renderNearbyDevices() {
    const { nearbyDevices, handleListItemClick } = this.props;

    return nearbyDevices.length !== 0 ? (
      <List>
        <ListSubheader disableSticky>Nearby sites</ListSubheader>
        {nearbyDevices.map(item => (
          <ListItem key={item.barcode} button onClick={() => handleListItemClick(item)}>
            <ListItemText primary={item.siteName} secondary={`${item.type} • ${item.barcode}`} />
          </ListItem>
        ))}
      </List>
    ) : null;
  }

  renderUnassignedDevices() {
    const { unassignedDevices, handleListItemClick } = this.props;
    return unassignedDevices.length !== 0 ? (
      <List>
        <ListSubheader disableSticky>Unassigned devices</ListSubheader>
        {unassignedDevices.map(item => (
          <ListItem key={item.barcode} button onClick={() => handleListItemClick(item)}>
            <ListItemText primary={`${item.type} ${item.barcode}`} />
          </ListItem>
        ))}
      </List>
    ) : null;
  }

  render() {
    const {
      classes, assignedDevices, nearbyDevices, unassignedDevices,
    } = this.props;

    return (
      <div className={classes.content}>
        <List>
          <ListSubheader disableSticky>Assigned devices</ListSubheader>
          {assignedDevices.length === 0 ? (
            <ListItem>
              <ListItemText classes={{ primary: classes.primary }} primary="No CAG assigned" />
            </ListItem>
          ) : (
            assignedDevices.map(item => (
              <React.Fragment key={item.barcode}>
                {item.devices.map(device => this.renderAssignedDevice(device))}
                {this.renderAssignedDevice(item)}
              </React.Fragment>
            ))
          )}
        </List>
        {assignedDevices.length === 0 ? (
          <>
            {unassignedDevices.length !== 0 && <Divider />}
            {this.renderUnassignedDevices()}
            {nearbyDevices.length !== 0 && <Divider />}
            {this.renderNearbyDevices()}
          </>
        ) : (
          <>
            {nearbyDevices.length !== 0 && <Divider />}
            {this.renderNearbyDevices()}
            {unassignedDevices.length !== 0 && <Divider />}
            {this.renderUnassignedDevices()}
          </>
        )}
      </div>
    );
  }
}

DevicesList.propTypes = {
  classes: PropTypes.object.isRequired,
  assignedDevices: PropTypes.arrayOf(PropTypes.object).isRequired,
  unassignedDevices: PropTypes.arrayOf(PropTypes.object).isRequired,
  nearbyDevices: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleListItemClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(DevicesList);
