import PropTypes from 'prop-types';
import React from 'react';
import DevicesComponent from '../components/Devices';
import {
  apiAssignDevice,
  apiFastFindDevice,
  apiGetDevices,
  apiGetSite,
  apiGetUserAlerts,
  apiUnassignDevice,
  apiUpdateDeviceNetwork,
} from '../utils/api';

function Devices(props) {
  const { siteId, handleBackClick } = props;

  return (
    <DevicesComponent
      site={apiGetSite(siteId)}
      devices={apiGetDevices()}
      alerts={apiGetUserAlerts()}
      handleAssignClick={apiAssignDevice}
      handleBackClick={handleBackClick}
      handleFastFindClick={apiFastFindDevice}
      handleUnassignClick={apiUnassignDevice}
      handleUpdateDeviceNetwork={apiUpdateDeviceNetwork}
    />
  );
}

Devices.propTypes = {
  siteId: PropTypes.string.isRequired,
  handleBackClick: PropTypes.func.isRequired,
};

export default Devices;
