import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';

const styles = {
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
};

function CompaniesDetails(props) {
  const { classes, company } = props;

  return (
    <div className={classes.content}>
      <List>
        <ListItem>
          <ListItemText primary="Company name" secondary={company.name} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListSubheader disableSticky>Sites</ListSubheader>
        {company.sites.length === 0 ? (
          <ListItem>
            <ListItemText secondary="No sites" />
          </ListItem>
        ) : (
          company.sites.map(item => (
            <ListItem key={item.id}>
              <ListItemText secondary={item.name} />
            </ListItem>
          ))
        )}
      </List>
    </div>
  );
}

CompaniesDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
};

export default withStyles(styles)(CompaniesDetails);
