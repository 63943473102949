import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import distanceInWords from 'date-fns/distance_in_words';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import { sensorTypeName } from '../constants/sensorTypes';
import { mixinHeightWithToolbar } from '../constants/theme';
import { AbilityContext, Auth } from '../utils/auth';
import { formatCellular, formatGps } from '../utils/devices';
import formatValue from '../utils/formatValue';
import getUnit from '../utils/getUnit';
import has from '../utils/has';
import { menuItemsNetworkId } from '../utils/menuItems';
import { sortByName } from '../utils/sort';
import DevicesSwitches from './DevicesSwitches';
import GoogleMap from './GoogleMap';
import Select from './Select';
import TinyBadge from './TinyBadge';
import withMobile from './withMobile';

const styles = theme => ({
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
  listItemMap: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 124,
    height: 124, // IE 11
  },
  dialogPaper: {
    minWidth: '75vw',
    maxWidth: '75vw',
    minHeight: '75vh',
    maxHeight: '75vh',
    width: '75vh', // IE 11
    height: '75vh', // IE 11
  },
  dialogMap: {
    display: 'flex',
    flexDirection: 'column',
  },
  justifyContent: {
    justifyContent: 'flex-end',
  },
  buttonFast: {
    margin: '4px 0 12px',
    textTransform: 'none',
  },
  buttonAssign: {
    margin: '12px 0',
    textTransform: 'none',
  },
  alert: {
    color: theme.palette.error.main,
  },
});

class DevicesDetails extends React.Component {
  constructor(props) {
    super(props);
    const { device } = props;

    this.state = {
      networkId: device.xbeeNetworkId || 16,
      waitFastFind: false,
      open: false,
    };

    this.handleAssignClick = this.handleAssignClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFastFindClick = this.handleFastFindClick.bind(this);
    this.handleUnassignClick = this.handleUnassignClick.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.device.fastFindEnabled && prevState.waitFastFind) {
      return { waitFastFind: false };
    }

    return null;
  }

  handleAssignClick() {
    const { device, handleAssignClick } = this.props;
    handleAssignClick(device.barcode, device.mainSiteId);
  }

  handleChange(event) {
    const { device, handleUpdateDeviceNetwork } = this.props;
    const networkId = event.target.value;
    this.setState({ networkId });
    handleUpdateDeviceNetwork(device.barcode, device.mainSiteId, networkId);
  }

  handleFastFindClick() {
    const { device, handleFastFindClick } = this.props;
    handleFastFindClick(device.barcode, device.mainSiteId)
      .then(() => {
        if (!device.fastFindEnabled) this.setState({ waitFastFind: true });
      })
      .catch(() => {});
  }

  handleUnassignClick() {
    const { device, handleUnassignClick } = this.props;
    handleUnassignClick(device.barcode, device.mainSiteId);
  }

  handleMapClick() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const { classes, widthMobile, device } = this.props;
    const {
      type, barcode, isMissing, isOffsite, isLowBattery, fastFindEnabled, isReassigned, siteName,
    } = device;
    const { networkId, waitFastFind, open } = this.state;

    const deviceSensors = has.call(device, 'sensors')
      ? sortByName(
        device.sensors.map(item => ({
          name: `${item.channel} - ${sensorTypeName(item.type)}`,
          type: item.type,
          channel: item.channel,
          value: item.value,
          inError: item.inError,
        })),
      )
      : null;
    const sensorInError = deviceSensors !== null && deviceSensors.some(item => item.inError);

    let alertText = null;
    let infoText = null;
    if (isMissing) {
      alertText = `${type} ${barcode} is missing`;
      if (isLowBattery) alertText = alertText.concat(' with battery alert');
    } else if (isReassigned) {
      alertText = `${type} ${barcode} is active on site ${siteName}`;
    } else if (isOffsite) {
      alertText = `${type} ${barcode} is active but offsite`;
    } else if (isLowBattery) {
      alertText = `${type} ${barcode} is in battery alert`;
    } else if (sensorInError) {
      alertText = `${type} ${barcode} has a failed sensor`;
    } else if (fastFindEnabled) {
      infoText = 'In fast find mode';
    } else if (waitFastFind) {
      infoText = 'Turning on fast find mode';
    }

    return (
      <>
        <div className={classes.content}>
          <List>
            {(alertText || infoText) && (
              <ListItem>
                <ListItemText
                  secondaryTypographyProps={alertText ? { className: classes.alert } : {}}
                  secondary={alertText || infoText}
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemText primary="Barcode" secondary={device.barcode} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Timestamp" secondary={format(device.timestamp, 'YYYY-MM-DD, h:mm A')} />
            </ListItem>
            {device.type === 'HMC' && (
              <ListItem>
                <ListItemText primary="AC power" secondary={has.call(device, 'powerFailure') ? 'No' : 'Yes'} />
              </ListItem>
            )}
            {['WSN', 'HMC'].includes(device.type) && (
              <ListItem>
                <ListItemText primary="Battery voltage" secondary={formatValue(device.batteryVoltage, 'V', 1)} />
              </ListItem>
            )}
            <ListItem>
              <ListItemText primary="Version" secondary={device.swVersion} />
            </ListItem>
            <ListItem>
              <ListItemText primary="XB radio version" secondary={device.xbeeRadioFw} />
            </ListItem>
            {device.type === 'CAG' && (
              <>
                <ListItem>
                  <ListItemText
                    primary="Uptime"
                    secondary={distanceInWords(device.uptime * 1000, 0, { includeSeconds: true })}
                  />
                </ListItem>
                {device.siteId !== null
                  && !fastFindEnabled
                  && !waitFastFind && (
                    <Auth do="modify" on="devices">
                      <ListItem className={classes.justifyContent}>
                        <Button
                          className={classes.buttonFast}
                          color="secondary"
                          variant="outlined"
                          onClick={this.handleFastFindClick}
                        >
                          Fast find
                        </Button>
                      </ListItem>
                    </Auth>
                )}
              </>
            )}
          </List>
          {deviceSensors !== null && (
            <>
              <Divider />
              <List>
                <ListSubheader disableSticky>Sensors</ListSubheader>
                {deviceSensors.length === 0 ? (
                  <ListItem>
                    <ListItemText secondary="No sensors" />
                  </ListItem>
                ) : (
                  deviceSensors.map(item => (
                    <ListItem key={item.name}>
                      <ListItemText
                        // prettier-ignore
                        primary={(
                          <TinyBadge alert={item.inError}>
                            {item.name}
                          </TinyBadge>
                        )}
                        secondary={formatValue(item.value, getUnit(item.type), 1)}
                      />
                    </ListItem>
                  ))
                )}
              </List>
            </>
          )}
          {device.type === 'CAG' && (
            <>
              {device.siteId === null ? (
                <ListItem>
                  <ListItemText primary="Local radio network ID" secondary={device.xbeeNetworkId || 'not set'} />
                </ListItem>
              ) : (
                <>
                  <Divider />
                  <List>
                    <ListSubheader disableSticky>Local radio network</ListSubheader>
                    <ListItem>
                      <AbilityContext.Consumer>
                        {ability => (
                          <Select
                            id="settings-network-id"
                            label="Network ID"
                            value={networkId}
                            fullWidth
                            readOnly={ability.cannot('modify', 'settings')}
                            onChange={this.handleChange}
                          >
                            {menuItemsNetworkId.map(item => (
                              <MenuItem key={item.value.toString()} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </AbilityContext.Consumer>
                    </ListItem>
                    <ListItem>
                      <ListItemText secondary="Set this DIP switch sequence inside the WSNs" />
                    </ListItem>
                    <ListItem>
                      <DevicesSwitches networkId={networkId} />
                    </ListItem>
                  </List>
                </>
              )}
              <Divider />
              <List>
                <ListSubheader disableSticky>Cellular</ListSubheader>
                <ListItem>
                  <ListItemText primary="Cellular signal" secondary={formatCellular(device.modem.csq)} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="IP address" secondary={device.ipAddress} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="rssi" secondary={device.modem.csq.rssi} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="ber" secondary={device.modem.csq.ber} />
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListSubheader disableSticky>GPS</ListSubheader>
                <ListItem>
                  <ListItemText
                    primary="Distance from site"
                    secondary={formatValue(device.distanceFromMainSite, 'm', 0)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="GPS signal" secondary={formatGps(device.modem.gps)} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="HDOP" secondary={device.modem.gps.hdop} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Satellite count" secondary={device.modem.gps.nsat} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Latitude" secondary={formatValue(device.modem.gps.lat, '°', 6)} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Longitude" secondary={formatValue(device.modem.gps.lng, '°', 6)} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Altitude" secondary={formatValue(device.modem.gps.alt, 'm', 0)} />
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListSubheader disableSticky>Geolocation</ListSubheader>
                {device.modem.gps && device.modem.gps.lat && device.modem.gps.lng ? (
                  <ListItem className={classes.listItemMap}>
                    <GoogleMap
                      center={{
                        lat: device.modem.gps.lat,
                        lng: device.modem.gps.lng,
                      }}
                      cags={[
                        {
                          lat: device.modem.gps.lat,
                          lng: device.modem.gps.lng,
                        },
                      ]}
                      isThumbnail
                      handleMapClick={this.handleMapClick}
                    />
                  </ListItem>
                ) : (
                  <ListItem>
                    <ListItemText secondary="No available geolocation" />
                  </ListItem>
                )}
              </List>
              <Auth do="modify" on="devices">
                <Divider />
                <List>
                  <ListItem className={classes.justifyContent}>
                    <Button
                      className={classes.buttonAssign}
                      color="secondary"
                      variant={device.siteId === null ? 'contained' : 'outlined'}
                      onClick={device.siteId === null ? this.handleAssignClick : this.handleUnassignClick}
                    >
                      {device.siteId === null ? 'Assign device' : 'Unassign device'}
                    </Button>
                  </ListItem>
                </List>
              </Auth>
            </>
          )}
        </div>
        {device.type === 'CAG' && (
          <Dialog
            classes={widthMobile ? undefined : { paper: classes.dialogPaper }}
            fullScreen={widthMobile}
            open={open}
            onClose={this.handleClose}
          >
            <DialogContent className={classes.dialogMap}>
              <GoogleMap
                center={{
                  lat: device.modem.gps.lat,
                  lng: device.modem.gps.lng,
                }}
                cags={[
                  {
                    lat: device.modem.gps.lat,
                    lng: device.modem.gps.lng,
                    title: device.type,
                  },
                ]}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}

DevicesDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  device: PropTypes.object.isRequired,
  handleAssignClick: PropTypes.func.isRequired,
  handleUnassignClick: PropTypes.func.isRequired,
  handleFastFindClick: PropTypes.func.isRequired,
  handleUpdateDeviceNetwork: PropTypes.func.isRequired,
};

export default compose(
  withMobile(),
  withStyles(styles),
)(DevicesDetails);
