import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import uuidv4 from 'uuid/v4';
import formatValue from '../utils/formatValue';
import getUnit from '../utils/getUnit';
import Charts from './Charts';
import Crossout from './Crossout';
import FloorplanValue from './FloorplanValue';
import TrendArrow from './TrendArrow';
import withMobile from './withMobile';

const minTableCellWidth = 118;
const tableTrendCellWidth = 48;

const styles = theme => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    margin: '0 auto',
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 2,
  },
  title: {
    padding: `${theme.spacing.unit * 4}px 0px ${theme.spacing.unit}px`,
  },
  card: {
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    boxShadow: '0 2px 2px -1px rgba(153, 153, 153, 0.3), 0 1px 5px -2px rgba(153, 153, 153, 0.3)',
    boxSizing: 'border-box',
    '&:hover': {
      borderColor: theme.palette.secondary.light,
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  divider: {
    margin: `${theme.spacing.unit * 1.5}px 0px`,
  },
  table: {
    tableLayout: 'fixed',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tableCellLocation: {
    width: minTableCellWidth,
  },
  tableCellValue: {
    paddingRight: 0,
    width: minTableCellWidth - tableTrendCellWidth,
  },
  tableCellTrend: {
    paddingLeft: 0,
    width: tableTrendCellWidth,
  },
  leftJustify: {
    display: 'flex',
  },
  rightJustify: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  alert: {
    color: theme.palette.error.main,
  },
  trendArrow: {
    marginLeft: 9,
    marginBottom: 3,
    height: 10,
  },
  heating: {
    color: theme.floorplan.heater.heating.color.primary,
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
});

function LocationCard(props) {
  const { classes, location, handleClick } = props;

  return (
    <Card className={classes.card}>
      <CardContent onClick={event => handleClick(event, location.id)}>
        <Typography variant="body2">{location.name}</Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={32}>
          {location.sensors.map(item => (
            <Grid item xs={6} sm={4} key={item.id}>
              <FloorplanValue
                sensor={{
                  value: formatValue(item.value.value, getUnit(item.type), 0),
                  label: item.sensorType,
                  missing: item.value.isMissing,
                  inAlert: item.value.thresholds.some(threshold => threshold.exceeded),
                  inError: item.value.inError,
                  trendData: item.value.thumbnail,
                }}
              />
            </Grid>
          ))}
          {location.heater !== null && (
            <Grid item xs={6} sm={4}>
              <FloorplanValue
                heater={{
                  value: formatValue(location.heater.heaterSetTo, getUnit('T'), 0),
                  missing: location.heater.isMissing,
                  enabled: location.heater.enabled,
                  heating: location.heater.isHeating,
                }}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

LocationCard.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
};

const LocationCardWrapped = withStyles(styles)(LocationCard);

function pivotTableColumns(floorplans, locations) {
  return floorplans
    .map(floorplan => ({
      names: floorplan.widgets
        // only gauges
        .filter(widget => widget.type === 'sensor')
        // get all sensor names
        .map(widget => locations.find(location => location.id === widget.locationConfigId).sensors.map(sensor => sensor.sensorType)) // eslint-disable-line max-len
        // flatten
        .reduce((flat, toFlatten) => flat.concat(toFlatten), [])
        // add heater
        .concat(
          floorplan.widgets.some(widget => locations.some(value => value.id === widget.locationConfigId && value.heater)) // eslint-disable-line max-len
            ? ['Heat set to']
            : [],
        )
        // get unique
        .filter((value, index, self) => self.indexOf(value) === index),
    }))
    .map(floorplan => ({
      names: [].concat(
        // temperature is first if it exists
        floorplan.names.includes('Temperature') ? ['Temperature'] : [],
        // humidity is second if it exists
        floorplan.names.includes('Humidity') ? ['Humidity'] : [],
        // and the rest
        floorplan.names.filter(name => name !== 'Temperature' && name !== 'Humidity'),
      ),
    }));
}

function maxColumns(columns) {
  return columns.map(item => item.names.length).reduce((a, b) => Math.max(a, b), 0);
}

function paddingTableCells(classes, number) {
  const padding = [];
  for (let i = 0; i < number; i += 1) {
    // eslint-disable-next-line max-len
    padding.push(<TableCell className={classNames(classes.tableCellValue, classes.ellipsis)} key={uuidv4()} />);
    padding.push(<TableCell className={classes.tableCellTrend} key={uuidv4()} />);
  }

  return padding;
}

function FloorplansListView(props) {
  const {
    classes,
    widthMobile,
    floorplans,
    locations,
    selectedLocationId,
    charts,
    handleLocationClick,
    handleChartCloseClick,
    handleChartBackClick,
    handleChartForwardClick,
    handleChartDataRequest,
    handleChartClick,
  } = props;

  const columns = pivotTableColumns(floorplans, locations);
  const numColumns = maxColumns(columns);

  return (
    <div className={classes.container}>
      {floorplans.length !== 0
        && widthMobile
        && floorplans.map(item => (
          <React.Fragment key={item.id}>
            <Typography className={classes.title} color="textSecondary" variant="body2" noWrap>
              {item.name}
            </Typography>
            <Grid container spacing={16}>
              {item.widgets.filter(widget => widget.type === 'sensor').map(widget => (
                <Grid item key={widget.locationConfigId} xs={12}>
                  <LocationCardWrapped
                    location={locations.find(value => value.id === widget.locationConfigId)}
                    handleClick={handleLocationClick}
                  />
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ))}
      {floorplans.length !== 0
        && !widthMobile
        && floorplans.map((floorplan, floorplanIndex) => (
          <React.Fragment key={floorplan.id}>
            <Typography className={classes.title} color="textSecondary" variant="body2" noWrap>
              {floorplan.name}
            </Typography>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classNames(classes.tableCellLocation, classes.ellipsis)}>Location</TableCell>
                  {columns[floorplanIndex].names.map((item, index) => (
                    <React.Fragment key={index.toString()}>
                      <TableCell className={classNames(classes.tableCellValue, classes.ellipsis)} numeric>
                        {item}
                      </TableCell>
                      <TableCell className={classes.tableCellTrend} />
                    </React.Fragment>
                  ))}
                  {paddingTableCells(classes, numColumns - columns[floorplanIndex].names.length)}
                </TableRow>
              </TableHead>
              <TableBody>
                {floorplan.widgets.filter(item => item.type === 'sensor').map((item) => {
                  const location = locations.find(config => config.id === item.locationConfigId);

                  return (
                    <TableRow
                      hover
                      key={item.locationConfigId}
                      onClick={event => handleLocationClick(event, item.locationConfigId)}
                      selected={selectedLocationId === item.locationConfigId}
                    >
                      <TableCell className={classNames(classes.tableCellLocation, classes.ellipsis)}>
                        {location.name}
                      </TableCell>
                      {columns[floorplanIndex].names.map((columnName, columnIndex) => {
                        const sensor = location.sensors.find(value => value.sensorType === columnName);
                        const alert = (sensor && sensor.value.thresholds.some(threshold => threshold.exceeded)) || false; // eslint-disable-line max-len
                        const isHeater = location.heater !== null && columnName === 'Heat set to';

                        function tableColumns(value, type, data, isMissing, inAlert, heating = false, enabled = true) {
                          return (
                            <React.Fragment key={columnIndex.toString()}>
                              <TableCell
                                className={classNames(classes.tableCellValue, classes.ellipsis, {
                                  [classes.heating]: heating,
                                  [classes.disabled]: !enabled,
                                  [classes.alert]: inAlert,
                                })}
                                numeric
                              >
                                {isMissing ? (
                                  <div className={classes.rightJustify}>
                                    <div className={classes.leftJustify}>
                                      <Crossout strokeWidth={4}>{formatValue(value, getUnit(type), 1)}</Crossout>
                                    </div>
                                  </div>
                                ) : (
                                  `${formatValue(value, getUnit(type), 1)}`
                                )}
                              </TableCell>
                              <TableCell className={classes.tableCellTrend}>
                                <TrendArrow className={classes.trendArrow} alert={inAlert} data={data} />
                              </TableCell>
                            </React.Fragment>
                          );
                        }

                        if (sensor) {
                          return tableColumns(
                            sensor.value.value,
                            sensor.type,
                            sensor.value.thumbnail,
                            sensor.value.isMissing,
                            alert,
                          );
                        }
                        if (isHeater) {
                          return tableColumns(
                            location.heater.heaterSetTo,
                            'T',
                            [],
                            location.heater.isMissing,
                            alert,
                            location.heater.isHeating,
                            location.heater.enabled,
                          );
                        }
                        return paddingTableCells(classes, 1);
                      })}
                      {paddingTableCells(classes, numColumns - columns[floorplanIndex].names.length)}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </React.Fragment>
        ))}
      <Charts
        charts={charts}
        handleChartCloseClick={handleChartCloseClick}
        handleChartBackClick={handleChartBackClick}
        handleChartForwardClick={handleChartForwardClick}
        handleChartDataRequest={handleChartDataRequest}
        handleChartClick={handleChartClick}
      />
    </div>
  );
}

FloorplansListView.propTypes = {
  classes: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  floorplans: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedLocationId: PropTypes.string,
  charts: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleLocationClick: PropTypes.func.isRequired,
  handleChartCloseClick: PropTypes.func.isRequired,
  handleChartBackClick: PropTypes.func.isRequired,
  handleChartForwardClick: PropTypes.func.isRequired,
  handleChartDataRequest: PropTypes.func.isRequired,
  handleChartClick: PropTypes.func.isRequired,
};

FloorplansListView.defaultProps = {
  selectedLocationId: undefined,
};

export default compose(
  withMobile(),
  withStyles(styles),
)(FloorplansListView);
