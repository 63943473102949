import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import wedgeLogo from '../images/wedge_logo.svg';

const styles = theme => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    height: 48,
    paddingLeft: 24,
    marginTop: 48,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 16,
    },
  },
  image: {
    width: 123.67,
    height: 28,
  },
});

function Logo(props) {
  const { classes } = props;

  return (
    <div>
      <ButtonBase component={Link} to="/" disableRipple>
        <img className={classes.image} src={wedgeLogo} alt="WEDGE logo" />
      </ButtonBase>
    </div>
  );
}

Logo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Logo);
