/* eslint-disable import/first */
import './shims'; // first import
import 'react-app-polyfill/ie11'; // second import (for IE 11 support)
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './containers/App';

if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root'),
);
