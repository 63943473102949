import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputIcon from '@material-ui/icons/Input';
import PeopleIcon from '@material-ui/icons/People';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import OfficeBuildingIcon from '../images/icons/OfficeBuilding';

const styles = theme => ({
  container: {
    margin: 0,
  },
  hide: {
    display: 'none',
  },
  count: {
    paddingLeft: 9,
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.secondary,
  },
  activeCount: {
    color: theme.palette.secondary.main,
    opacity: 0.7,
  },
  activeColor: {
    color: theme.palette.secondary.main,
  },
  activeItem: {
    textDecoration: 'none',
    backgroundColor: theme.palette.common.white,
  },
  listItem: {
    borderRightColor: theme.palette.background.default,
    borderRightStyle: 'solid',
    borderRightWidth: theme.spacing.unit,
    borderRadius: '24px 0px 0px 24px',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.common.white,
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
});

function AdminNav(props) {
  const {
    classes,
    active,
    companiesCount,
    usersCount,
    handleBackClick,
    handleCompaniesClick,
    handleUsersClick,
  } = props;

  return (
    <div className={classes.container}>
      <List component="nav">
        <ListItem
          className={classNames(classes.listItem, {
            [classes.activeItem]: active === 'companies',
          })}
          button
          onClick={handleCompaniesClick}
        >
          <ListItemIcon className={classNames({ [classes.activeColor]: active === 'companies' })}>
            <OfficeBuildingIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            // prettier-ignore
            primary={(
              <Typography
                className={classNames({ [classes.activeColor]: active === 'companies' })}
                variant="body2"
              >
                Companies
                <span
                  className={classNames(
                    classes.count,
                    {
                      [classes.hide]: companiesCount === 0,
                    },
                    { [classes.activeCount]: active === 'companies' },
                  )}
                >
                  {companiesCount}
                </span>
              </Typography>
            )}
          />
        </ListItem>
        <ListItem
          className={classNames(classes.listItem, { [classes.activeItem]: active === 'users' })}
          button
          onClick={handleUsersClick}
        >
          <ListItemIcon className={classNames({ [classes.activeColor]: active === 'users' })}>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={(
              <Typography className={classNames({ [classes.activeColor]: active === 'users' })} variant="body2">
                Users
                <span
                  className={classNames(
                    classes.count,
                    {
                      [classes.hide]: usersCount === 0,
                    },
                    { [classes.activeCount]: active === 'users' },
                  )}
                >
                  {usersCount}
                </span>
              </Typography>
)}
          />
        </ListItem>
      </List>
      <List component="nav">
        <ListItem className={classes.listItem} button onClick={handleBackClick}>
          <ListItemIcon>
            <InputIcon />
          </ListItemIcon>
          <ListItemText disableTypography primary={<Typography variant="body2">Return to site</Typography>} />
        </ListItem>
      </List>
    </div>
  );
}

AdminNav.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.oneOf(['companies', 'users']).isRequired,
  companiesCount: PropTypes.number.isRequired,
  usersCount: PropTypes.number.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleCompaniesClick: PropTypes.func.isRequired,
  handleUsersClick: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles),
)(AdminNav);
