import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import sensorTypes from '../constants/sensorTypes';
import { mixinHeightWithToolbar } from '../constants/theme';
import getTime from '../utils/getTime';
import getUnit from '../utils/getUnit';

const styles = theme => ({
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
  gutters: theme.mixins.gutters(),
  text: {
    paddingTop: 8,
  },
  spacing: {
    paddingBottom: 12,
  },
  label: {
    marginLeft: 24,
  },
  limit: {
    marginLeft: 12,
    color: theme.palette.text.secondary,
  },
});

function ZonesAlertDetails(props) {
  const {
    classes, zoneName, threshold, contacts,
  } = props;

  return (
    <div className={classes.content}>
      <List>
        <ListItem>
          <ListItemText primary="Zone name" secondary={zoneName} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Monitored condition" secondary={threshold.sensorType} />
        </ListItem>
      </List>
      <Divider />
      {['T', 'H'].includes(sensorTypes[threshold.sensorType]) && (
        <>
          <List>
            <ListSubheader disableSticky>Alert limits</ListSubheader>
            {threshold.low !== null || threshold.high !== null ? (
              <>
                <Typography
                  className={classNames(classes.text, classes.spacing, classes.gutters)}
                  variant="subtitle1"
                  color="textPrimary"
                >
                  {`Send alert when ${threshold.sensorType.toLowerCase()}`}
                </Typography>
                {threshold.low !== null && (
                  <Typography
                    className={classNames(classes.label, classes.spacing, classes.gutters)}
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    below
                    <span className={classes.limit}>
                      {`${threshold.low} ${getUnit(sensorTypes[threshold.sensorType])}`}
                    </span>
                  </Typography>
                )}
                {threshold.high !== null && (
                  <Typography
                    className={classNames(classes.label, classes.spacing, classes.gutters)}
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    above
                    <span className={classes.limit}>
                      {`${threshold.high} ${getUnit(sensorTypes[threshold.sensorType])}`}
                    </span>
                  </Typography>
                )}
              </>
            ) : (
              <ListItem>
                <ListItemText secondary="No limits" />
              </ListItem>
            )}
          </List>
          <Divider />
          <List>
            <ListItem>
              <ListItemText primary="Alert repeat" secondary={getTime(threshold.antispamInterval)} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListSubheader disableSticky>Team members to alert</ListSubheader>
            {threshold.contacts.map(item => (
              <ListItem key={item.id} dense>
                <ListItemText
                  // prettier-ignore
                  primary={(
                    <Typography variant="subtitle1" noWrap>
                      {contacts.find(contact => contact.userId === item.id).name}
                    </Typography>
                  )}
                  disableTypography
                />
              </ListItem>
            ))}
            {threshold.contacts.length === 0 && (
              <ListItem>
                <ListItemText secondary="No team members" />
              </ListItem>
            )}
          </List>
        </>
      )}
    </div>
  );
}

ZonesAlertDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  zoneName: PropTypes.string.isRequired,
  threshold: PropTypes.object.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(ZonesAlertDetails);
