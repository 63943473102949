import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DirectionsIcon from '@material-ui/icons/Directions';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  card: {
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    boxShadow: '0 2px 2px -1px rgba(153, 153, 153, 0.3), 0 1px 5px -2px rgba(153, 153, 153, 0.3)',
    boxSizing: 'border-box',
    '&:hover': {
      borderColor: theme.palette.secondary.light,
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  siteName: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  badge: {
    top: 5,
    right: 0,
    fontWeight: theme.typography.fontWeightMedium,
  },
  hide: {
    display: 'none',
  },
  margin: {
    marginBottom: 16,
  },
  lineHeight: {
    lineHeight: '28px',
  },
  caption: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.secondary,
  },
  icon: {
    marginRight: theme.spacing.unit,
  },
  button: {
    paddingRight: 0,
    paddingLeft: 0,
    textTransform: 'none',
  },
});

class SitesCard extends React.Component {
  constructor(props) {
    super(props);

    this.handleSiteAdminClick = this.handleSiteAdminClick.bind(this);
  }

  handleSiteAdminClick(event, item) {
    const { handleSiteAdminClick: handleSiteAdminClickProp } = this.props;

    event.stopPropagation();
    handleSiteAdminClickProp(item);
  }

  render() {
    const { classes, site, handleSiteClick } = this.props;
    const admins = site.contacts.filter(contact => contact.role === 'Site Admin');

    return (
      <Card className={classes.card}>
        <CardContent onClick={() => handleSiteClick(site.id)}>
          <div className={classes.siteName}>
            <Typography variant="h5">{site.name}</Typography>
            <Badge
              classes={{
                badge: classes.badge,
              }}
              className={classNames({
                [classes.hide]: site.summary.alerts.length === 0,
              })}
              badgeContent={site.summary.alerts.length}
              color="error"
            >
              <React.Fragment />
            </Badge>
          </div>
          <Typography className={classes.margin} variant="subtitle1" color="textSecondary">
            {site.company.name}
          </Typography>
          <>
            {admins.map(item => (
              <div key={item.userId}>
                <ButtonBase color="secondary" disableRipple onClick={event => this.handleSiteAdminClick(event, item)}>
                  <Typography className={classes.lineHeight} color="secondary">
                    {item.name}
                    <span className={classes.caption}>
                      {' • '}
                      {item.role}
                    </span>
                  </Typography>
                </ButtonBase>
              </div>
            ))}
          </>
        </CardContent>
        <Divider />
        <CardActions>
          <Button className={classes.button} color="secondary">
            <DirectionsIcon className={classes.icon} />
            Directions to site
          </Button>
        </CardActions>
      </Card>
    );
  }
}

SitesCard.propTypes = {
  classes: PropTypes.object.isRequired,
  site: PropTypes.object.isRequired,
  handleSiteClick: PropTypes.func.isRequired,
  handleSiteAdminClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(SitesCard);
