import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';
import { Auth } from '../utils/auth';
import Tooltip from './Tooltip';

const styles = theme => ({
  root: {
    position: 'relative',
    ...mixinHeightWithToolbar(48),
  },
  content: {
    height: '100%',
    overflowY: 'auto',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
});

function AdminList(props) {
  const {
    classes, items, variant, handleListItemClick, handleAddClick,
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <List>
          {items.length === 0 ? (
            <ListItem>
              <ListItemText secondary={`No ${variant}`} />
            </ListItem>
          ) : (
            items.map(item => (
              <ListItem key={item.id} onClick={event => handleListItemClick(event, item.id)} divider button>
                {variant === 'users' ? (
                  <ListItemText primary={item.name} secondary={item.company} />
                ) : (
                  <ListItemText primary={item.name} />
                )}
              </ListItem>
            ))
          )}
        </List>
        {variant === 'companies' && (
          <Auth do="modify" on={variant}>
            <Tooltip title="Add a company" placement="left">
              <Button className={classes.fab} color="secondary" variant="fab" mini onClick={handleAddClick}>
                <AddIcon />
              </Button>
            </Tooltip>
          </Auth>
        )}
      </div>
    </div>
  );
}

AdminList.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  variant: PropTypes.oneOf(['companies', 'users']).isRequired,
  handleListItemClick: PropTypes.func.isRequired,
  handleAddClick: PropTypes.func,
};

AdminList.defaultProps = {
  handleAddClick: undefined,
};

export default withStyles(styles)(AdminList);
