import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

class SubRoute extends React.Component {
  constructor(props) {
    super(props);

    this.handleBackClick = this.handleBackClick.bind(this);
  }

  handleBackClick() {
    const { history } = this.props;

    history.goBack();
  }

  render() {
    const {
      siteId, Component, backArrow, ...other
    } = this.props;

    return backArrow ? (
      <Component siteId={siteId} handleBackClick={this.handleBackClick} {...other} />
    ) : (
      <Component siteId={siteId} {...other} />
    );
  }
}

SubRoute.propTypes = {
  history: PropTypes.object.isRequired,
  siteId: PropTypes.string.isRequired,
  Component: PropTypes.func.isRequired,
  backArrow: PropTypes.bool.isRequired,
};

export default withRouter(SubRoute);
