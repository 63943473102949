import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';
import { Auth } from '../utils/auth';
import Tooltip from './Tooltip';

const styles = theme => ({
  root: {
    position: 'relative',
    ...mixinHeightWithToolbar(),
  },
  content: {
    height: '100%',
    overflowY: 'auto',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
});

function ZonesList(props) {
  const {
    classes, zones, sideSheet, handleListItemClick, handleAddClick,
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <List>
          {sideSheet && zones.length !== 0 && <Divider />}
          {zones.map(item => (
            <ListItem key={item.id} onClick={() => handleListItemClick(item.id)} divider button>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
        <Auth do="modify" on="zones">
          <Tooltip title="Create new zone" placement="left">
            <Button className={classes.fab} color="secondary" variant="fab" mini onClick={handleAddClick}>
              <AddIcon />
            </Button>
          </Tooltip>
        </Auth>
      </div>
    </div>
  );
}

ZonesList.propTypes = {
  classes: PropTypes.object.isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
  sideSheet: PropTypes.bool,
  handleListItemClick: PropTypes.func.isRequired,
  handleAddClick: PropTypes.func.isRequired,
};

ZonesList.defaultProps = { sideSheet: false };

export default withStyles(styles)(ZonesList);
