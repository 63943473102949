import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseButton from '@material-ui/icons/Close';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import subDays from 'date-fns/sub_days';
import subMonths from 'date-fns/sub_months';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import {
  VictoryAxis, VictoryChart, VictoryLine, VictoryScatter, VictoryVoronoiContainer,
} from 'victory-chart';
import { VictoryTooltip } from 'victory-core';
import getDate from '../utils/getDate';
import getUnit from '../utils/getUnit';
import ChartTooltip from './ChartTooltip';
import Spinner from './Spinner';
import TextField from './TextField';
import withMobile from './withMobile';

const styles = theme => ({
  paper: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    position: 'relative',
    zIndex: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  dragAnchor: {
    flex: 1,
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
  tab: {
    ...theme.tab,
    minWidth: '25%',
    maxWidth: '25%',
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexBasis: '100%',
  },
  dateChooser: {
    minWidth: 120,
  },
  chart: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholder: {
    width: '100%',
    height: 147,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      height: 180.55,
    },
  },
  spinner: {
    width: '100%',
    height: 163,
    [theme.breakpoints.up('md')]: {
      height: 196.55,
    },
  },
});

/**
 * Victory charts uses react-fast-compare to perform deep equals operations on its incoming props.
 * Inline created react components have a reference to their parent which creates a circular
 * dependency.  The react-fast-compare isEqual method will overflow when comparing these inline
 * react component properties.  React components created globally do not have a reference to any
 * parent and will not cause this problem.
 */
const flyoutComponent = <ChartTooltip />;
const labelComponent = <VictoryTooltip flyoutComponent={flyoutComponent} orientation="top" />;

function getRange(intervalType, customStartTime, customEndTime) {
  const now = new Date();
  let startTime;
  let endTime;
  let interval;

  switch (intervalType) {
    case 1:
      startTime = subDays(now, 7).toISOString();
      endTime = now.toISOString();
      interval = '3h';
      break;
    case 2:
      startTime = subMonths(now, 1).toISOString();
      endTime = now.toISOString();
      interval = '6h';
      break;
    case 3:
      startTime = customStartTime;
      endTime = customEndTime;
      interval = '3h';
      break;
    case 0:
    default:
      startTime = subDays(now, 1).toISOString();
      endTime = now.toISOString();
      interval = '30m';
  }

  return { startTime, endTime, interval };
}

function yAxisDomain(data) {
  let minValue = 0;
  let maxValue = 0;

  const values = data
    .filter(item => item.aggregate !== null && !Number.isNaN(Number(item.aggregate)))
    .map(item => item.aggregate);

  let interval = 1;
  if (values.length > 0) {
    minValue = Math.floor(Math.min(...values));
    maxValue = Math.ceil(Math.max(...values));
  }

  if ((maxValue - minValue) / interval > 8) {
    while ((maxValue - minValue) / interval > 8) {
      maxValue += 1;
      minValue -= 1;
      interval += 1;
    }
  } else if ((maxValue - minValue) / interval < 5) {
    while ((maxValue - minValue) / interval < 5) {
      maxValue += 1;
      minValue -= 1;
    }
  }

  return [minValue, maxValue];
}

class Chart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customEndTime: getDate(0),
      customStartTime: getDate(1),
      value: 0,
    };

    this.renderChart = this.renderChart.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCustomEnd = this.handleCustomEnd.bind(this);
    this.handleCustomStart = this.handleCustomStart.bind(this);
    this.handleForward = this.handleForward.bind(this);
  }

  componentDidMount() {
    const { handleDataRequest, location, sensor } = this.props;
    const { customEndTime, customStartTime, value } = this.state;
    const range = getRange(value, customStartTime, customEndTime);
    handleDataRequest(location, sensor, range.startTime, range.endTime, range.interval);
  }

  componentDidUpdate(prevProps, prevState) {
    const { handleDataRequest, location, sensor } = this.props;
    const { customEndTime, customStartTime, value } = this.state;

    const { sensor: prevSensor } = prevProps;
    const { customEndTime: prevCustomEndTime, customStartTime: prevCustomStartTime, value: prevValue } = prevState;

    if (
      sensor.id !== prevSensor.id
      || customEndTime !== prevCustomEndTime
      || customStartTime !== prevCustomStartTime
      || value !== prevValue
    ) {
      const range = getRange(value, customStartTime, customEndTime);
      handleDataRequest(location, sensor, range.startTime, range.endTime, range.interval);
    }
  }

  handleBack() {
    const { handleBack, location, sensor } = this.props;
    handleBack(location, sensor);
  }

  handleChange(_, value) {
    this.setState({ value });
  }

  handleCustomEnd(event) {
    this.setState({ customEndTime: event.target.value });
  }

  handleCustomStart(event) {
    this.setState({ customStartTime: event.target.value });
  }

  handleForward() {
    const { handleForward, location, sensor } = this.props;
    handleForward(location, sensor);
  }

  renderChart(data, sensor, value) {
    const { classes, widthMobile, theme } = this.props;

    const lineYDomain = yAxisDomain(data);
    const domainYPadding = (lineYDomain[1] - lineYDomain[0]) * 0.05;

    const lineData = data.map(item => ({
      value: item.aggregate,
      timestamp: parse(item.timestamp),
      unit: getUnit(sensor.type),
    }));
    const scatterData = lineData.filter(item => item.value !== null);

    return (
      <div className={classes.chart}>
        {data.length > 0 ? (
          <VictoryChart
            style={{ parent: { width: widthMobile ? 311 : 380, height: widthMobile ? 147 : 180.56 } }}
            containerComponent={<VictoryVoronoiContainer labels={() => ''} labelComponent={labelComponent} />}
            domain={{ y: lineYDomain }}
            domainPadding={{ y: [domainYPadding, domainYPadding] }}
            width={widthMobile ? 311 : 380}
            height={widthMobile ? 147 : 180.56}
            padding={{
              top: 10,
              right: 15,
              bottom: 30,
              left: 40,
            }}
            scale={{ x: 'time', y: 'linear' }}
          >
            <VictoryAxis
              offsetY={20}
              style={{
                axis: { stroke: 'transparent' },
                tickLabels: { ...theme.chart, fill: theme.palette.grey[500], padding: 5 },
              }}
              tickFormat={t => (value === 0 ? `${format(t, 'h A')}` : `${format(t, 'D')}`)}
              tickCount={8}
            />
            <VictoryAxis
              crossAxis={false}
              dependentAxis
              style={{
                axis: { stroke: 'transparent' },
                grid: { stroke: theme.palette.grey[300] },
                tickLabels: { ...theme.chart, fill: theme.palette.grey[500], padding: 5 },
              }}
              tickFormat={t => `${t.toFixed(0)}${getUnit(sensor.type)}`}
              tickCount={6}
            />
            <VictoryLine
              data={lineData}
              x="timestamp"
              y="value"
              style={{
                data: {
                  stroke: theme.palette.secondary.main,
                },
              }}
            />
            <VictoryScatter
              data={scatterData}
              x="timestamp"
              y="value"
              size={2.25}
              style={{
                data: {
                  fill: theme.palette.secondary.main,
                },
              }}
            />
          </VictoryChart>
        ) : (
          <div className={classes.placeholder}>
            <Typography variant="body2" color="textSecondary">
              No data in the selected time range
            </Typography>
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      classes, widthMobile, dragAnchorId, data, location, sensor, handleClose, handleClick,
    } = this.props;
    const { customEndTime, customStartTime, value } = this.state;

    return (
      <Paper className={classes.paper} elevation={24} onMouseDown={handleClick}>
        <div className={classes.header}>
          <Typography className={classes.dragAnchor} id={dragAnchorId} variant="h6" noWrap>
            {`${location.name}: ${sensor.sensorType}`}
          </Typography>
          <div>
            <IconButton onClick={this.handleBack}>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton onClick={this.handleForward}>
              <ChevronRightIcon />
            </IconButton>
            <IconButton onClick={handleClose}>
              <CloseButton />
            </IconButton>
          </div>
        </div>
        <Tabs value={value} onChange={this.handleChange} fullWidth>
          {['Day', 'Week', 'Month', 'Custom'].map(item => (
            <Tab className={classes.tab} label={item} key={item} disableRipple={!widthMobile} />
          ))}
        </Tabs>
        {value === 3 && (
          <div className={classes.dateContainer}>
            <TextField
              className={classes.dateChooser}
              id="chart-start"
              defaultValue={customStartTime}
              label="Start"
              onChange={this.handleCustomStart}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              className={classes.dateChooser}
              id="chart-end"
              defaultValue={customEndTime}
              label="End"
              onChange={this.handleCustomEnd}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}
        <Divider />
        {data ? this.renderChart(data, sensor, value) : <Spinner className={classes.spinner} show />}
      </Paper>
    );
  }
}

Chart.propTypes = {
  classes: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object.isRequired,
  sensor: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleForward: PropTypes.func.isRequired,
  handleDataRequest: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  dragAnchorId: PropTypes.string,
};

Chart.defaultProps = {
  data: undefined,
  dragAnchorId: '',
};

export default compose(
  withMobile(),
  withStyles(styles, { withTheme: true }),
)(Chart);
