import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import { apiGetUserPermissions } from '../utils/api';
import { getAbility } from '../utils/auth';
import getUnit from '../utils/getUnit';
import has from '../utils/has';
import HeaterWarning from './HeaterWarning';
import withMobile from './withMobile';

const styles = theme => ({
  center: {
    justifyContent: 'center',
  },
  table: {
    borderCollapse: 'collpase',
    borderSpacing: 0,
    paddingTop: theme.spacing.unit * 1.5,
    paddingBottom: theme.spacing.unit * 1.5,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
    },
  },
  tableCell: {
    padding: 0,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  value: {
    height: 64,
    lineHeight: 1.05,
    fontSize: 64,
    fontFamily: 'GoogleSans, Roboto, Helvetica, Arial, sans-serif',
    [theme.breakpoints.up('md')]: {
      height: 48,
      fontSize: 48,
    },
  },
  textDisabled: {
    color: theme.palette.text.disabled,
  },
  label: {
    textAlign: 'center',
  },
  buttonContainer: {
    paddingLeft: 34,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 25,
    },
  },
  button: {
    borderRadius: '50%',
    width: 32,
    height: 32,
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.main, theme.palette.action.hoverOpacity),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    [theme.breakpoints.up('md')]: {
      width: 24,
      height: 24,
    },
  },
  icon: {
    fontSize: 32,
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  spacing: {
    paddingLeft: theme.spacing.unit,
  },
});

class FloorplanHeater extends React.Component {
  constructor(props) {
    super(props);

    const { heater } = props.location;

    this.state = {
      enabled: heater.enabled,
      value: heater.heaterSetTo,
      open: false,
      acceptedState: undefined,
    };

    this.checkTermConditionsAccepted = this.checkTermConditionsAccepted.bind(this);
    this.modifyLocation = this.modifyLocation.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleUpClick = this.handleUpClick.bind(this);
    this.handleDownClick = this.handleDownClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAcceptClick = this.handleAcceptClick.bind(this);
  }

  modifyLocation(acceptedState) {
    const { siteId, location, handleModifyLocation } = this.props;
    const { enabled: enabledState, value: valueState } = this.state;

    const enabled = has.call(acceptedState, 'enabled') ? acceptedState.enabled : enabledState;
    const value = has.call(acceptedState, 'value') ? acceptedState.value : valueState;

    handleModifyLocation(
      {
        name: location.name,
        notes: location.notes,
        sensors: [],
        heater: {
          barcode: location.heater.barcode,
          heaterSetTo: value,
          enabled,
        },
      },
      siteId,
      location.id,
    )
      .then(() => {})
      .catch(() => {});
  }

  checkTermConditionsAccepted(acceptedState) {
    const { contacts, userId } = this.props;
    const contact = contacts.find(item => item.userId === userId);

    if (contact && contact.heaterWarningAccepted) {
      this.setState(acceptedState);
      this.modifyLocation(acceptedState);
    } else {
      this.setState({ open: true, acceptedState });
    }
  }

  handleSwitchChange(event) {
    this.checkTermConditionsAccepted({ enabled: event.target.checked });
  }

  handleUpClick() {
    const { value } = this.state;

    this.checkTermConditionsAccepted({ value: value + 1 });
  }

  handleDownClick() {
    const { value } = this.state;

    this.checkTermConditionsAccepted({ value: value - 1 });
  }

  handleClose() {
    this.setState({ open: false, acceptedState: undefined });
  }

  handleAcceptClick() {
    const { siteId, userId, handleAcceptHeaterWarning } = this.props;
    const { acceptedState } = this.state;

    handleAcceptHeaterWarning(siteId, userId).catch(() => {});
    this.setState({ open: false, ...acceptedState });
    this.modifyLocation(acceptedState);
  }

  render() {
    const {
      classes, widthMobile, zones, location, locations,
    } = this.props;
    const { enabled, value, open } = this.state;

    const { heater } = location;
    const zone = zones.find(item => item.environmentalControl === location.id);
    const zoneLocations = zone ? zone.locations.filter(item => item.id !== zone.environmentalControl) : [];

    let status = heater.isHeating ? 'Heating' : 'Idle';
    if (enabled === false) {
      status = 'Off';
    }
    if (heater.isMissing) {
      status = 'Missing';
    }

    const userCanModifyHeaters = getAbility(apiGetUserPermissions()).can('modify', 'heaters');

    return (
      <>
        <List>
          <ListItem>
            <ListItemText primary={status} />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem>
            <ListItemText primary="Enable heater" />
            <ListItemSecondaryAction>
              <Switch onChange={this.handleSwitchChange} checked={enabled} disabled={!userCanModifyHeaters} />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem className={classes.center}>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <td className={classes.tableCell}>
                    <Typography
                      className={classNames(classes.value, {
                        [classes.textDisabled]: !enabled,
                      })}
                      variant="subtitle1"
                    >
                      {value}
                      {getUnit('T')}
                    </Typography>
                  </td>
                  <td className={classes.tableCell}>
                    <div className={classNames(classes.column, classes.buttonContainer)}>
                      <ButtonBase
                        className={classes.button}
                        onClick={this.handleUpClick}
                        disabled={!enabled || !userCanModifyHeaters}
                        centerRipple
                      >
                        <KeyboardArrowUpIcon
                          className={classes.icon}
                          color={userCanModifyHeaters && enabled ? 'secondary' : 'disabled'}
                        />
                      </ButtonBase>
                      <ButtonBase
                        className={classes.button}
                        onClick={this.handleDownClick}
                        disabled={!enabled || !userCanModifyHeaters}
                        centerRipple
                      >
                        <KeyboardArrowDownIcon
                          className={classes.icon}
                          color={userCanModifyHeaters && enabled ? 'secondary' : 'disabled'}
                        />
                      </ButtonBase>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>
                    <Typography
                      className={classes.label}
                      variant={widthMobile ? 'subtitle1' : 'body2'}
                      color="textSecondary"
                    >
                      Heat set to
                    </Typography>
                  </td>
                  <td className={classes.tableCell} />
                </tr>
              </tbody>
            </table>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListSubheader disableSticky>Locations</ListSubheader>
          {zone !== undefined && zoneLocations.length > 0 ? (
            zoneLocations.map(item => (
              <ListItem key={item.id} dense>
                <ListItemText
                  // prettier-ignore
                  primary={(
                    <Typography variant="subtitle1" noWrap>
                      {locations.find(loc => loc.id === item.id).name}
                    </Typography>
                  )}
                  disableTypography
                />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText secondary="No locations" />
            </ListItem>
          )}
        </List>
        <HeaterWarning open={open} handleAcceptClick={this.handleAcceptClick} handleDeclineClick={this.handleClose} />
      </>
    );
  }
}

FloorplanHeater.propTypes = {
  classes: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  siteId: PropTypes.string.isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
  location: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  userId: PropTypes.string.isRequired,
  handleAcceptHeaterWarning: PropTypes.func.isRequired,
  handleModifyLocation: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  withMobile(),
)(FloorplanHeater);
