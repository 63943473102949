import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '../components/AppBar';
import NotificationsComponent from '../components/Notifications';
import { apiGetUserAlerts } from '../utils/api';

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.handleBackClick = this.handleBackClick.bind(this);
  }

  handleBackClick() {
    const { history } = this.props;

    history.goBack();
  }

  render() {
    const alerts = apiGetUserAlerts();

    return (
      <>
        <AppBar
          title="Notifications"
          search
          alerts={alerts}
          handleBackClick={this.handleBackClick}
        />
        <NotificationsComponent alerts={alerts} />
      </>
    );
  }
}

Notifications.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Notifications);
