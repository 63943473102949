import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import uuidv4 from 'uuid/v4';
import { mixinHeightWithToolbar } from '../constants/theme';
import { AbilityContext, Auth } from '../utils/auth';
import { menuItemsActivityTimeout, menuItemsAlertRepeat, menuReportingInterval } from '../utils/menuItems';
import ConfirmDialog from './ConfirmDialog';
import GoogleMap from './GoogleMap';
import Select from './Select';
import TextField from './TextField';
import Tooltip from './Tooltip';
import withMobile from './withMobile';

const styles = theme => ({
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(48),
  },
  listItemMap: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 124,
    height: 124, // IE 11
  },
  dialogPaper: {
    minWidth: '75vw',
    maxWidth: '75vw',
    minHeight: '75vh',
    maxHeight: '75vh',
    width: '75vh', // IE 11
    height: '75vh', // IE 11
  },
  dialogMap: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogButton: {
    marginLeft: theme.spacing.unit,
  },
  buttonLocation: {
    marginTop: 3,
    marginBottom: 20,
    textTransform: 'none',
  },
  buttonDelete: {
    margin: '12px 0',
    textTransform: 'none',
  },
});

class SettingsGeneral extends React.Component {
  constructor(props) {
    super(props);

    const { site } = props;

    this.state = {
      name: site.name,
      company: site.company.id,
      reportingInterval: site.reportingInterval,
      alertRepeat: site.alerts.antispamInterval,
      alertingDevices: site.alerts.alertingDevices,
      deleteOpen: false,
      dialogOpen: false,
      myLocation: undefined,
      centerMapToMyLocation: undefined,
      getCenter: undefined,
    };

    this.watchId = null;

    this.handleAlertsEnabledChange = this.handleAlertsEnabledChange.bind(this);
    this.handleAlertsRepeatChange = this.handleAlertsRepeatChange.bind(this);
    this.handleAlertsTimeoutChange = this.handleAlertsTimeoutChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSave = this.handleChangeSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCurrentLocation = this.handleCurrentLocation.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleDeleteCancelClick = this.handleDeleteCancelClick.bind(this);
    this.handleDeleteConfirmClick = this.handleDeleteConfirmClick.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleNearMeClick = this.handleNearMeClick.bind(this);
    this.handleSetLocationClick = this.handleSetLocationClick.bind(this);
    this.onMapReady = this.onMapReady.bind(this);
    this.updateAlerts = this.updateAlerts.bind(this);
    this.updateSite = this.updateSite.bind(this);
  }

  componentWillUnmount() {
    if ('geolocation' in navigator && this.watchId) {
      navigator.geolocation.clearWatch(this.watchId);
    }
  }

  onMapReady(getCenter) {
    this.setState({ getCenter });
  }

  updateAlerts(param, value) {
    const { site, handleSaveSiteAlerts } = this.props;

    const alerts = {
      alertingDevices: site.alerts.alertingDevices,
      antispamInterval: site.alerts.antispamInterval,
      id: site.alerts.id,
    };
    alerts[param] = value;

    handleSaveSiteAlerts(alerts, site.id)
      .then(() => {})
      .catch(() => {});
  }

  updateSite(param, value) {
    const { site: siteProp, handleSaveSite } = this.props;

    const site = {
      company: siteProp.company.id,
      geolocation: siteProp.geolocation,
      id: siteProp.id,
      name: siteProp.name,
      reportingInterval: siteProp.reportingInterval,
    };
    const apiParam = param === 'reportingInterval' ? 'reportingInterval' : param;
    site[apiParam] = value;

    handleSaveSite(site, site.id)
      .then(() => {})
      .catch(() => {});
  }

  handleAlertsEnabledChange(type) {
    return (event) => {
      const { alertingDevices } = this.state;
      const device = alertingDevices.find(item => item.type === type);
      device.enabled = event.target.checked;
      this.updateAlerts('alertingDevices', alertingDevices);
      this.setState({ alertingDevices });
    };
  }

  handleAlertsRepeatChange(event) {
    const { alertRepeat } = this.state;
    const { value } = event.target;

    if (value !== alertRepeat) {
      this.updateAlerts('antispamInterval', value);
      this.setState({ alertRepeat: value });
    }
  }

  handleAlertsTimeoutChange(type) {
    return (event) => {
      const { alertingDevices } = this.state;
      const { value } = event.target;
      const device = alertingDevices.find(item => item.type === type);

      if (value !== device.activityTimeout) {
        device.activityTimeout = value;
        this.updateAlerts('alertingDevices', alertingDevices);
        this.setState({ alertingDevices });
      }
    };
  }

  handleBlur(param) {
    const { site } = this.props;

    return (event) => {
      const oldValue = site[param];
      const { value } = event.target;

      if (value.length === 0) {
        this.setState({ [param]: oldValue });
      } else if (value !== oldValue) {
        this.updateSite(param, value);
      }
    };
  }

  handleChange(param) {
    return (event) => {
      this.setState({ [param]: event.target.value });
    };
  }

  handleChangeSave(param) {
    return (event) => {
      const oldValue = this.state[param]; // eslint-disable-line react/destructuring-assignment
      const { value } = event.target;

      if (value !== oldValue) {
        this.updateSite(param, value);
        this.setState({ [param]: value });
      }
    };
  }

  handleClose() {
    if ('geolocation' in navigator) {
      navigator.geolocation.clearWatch(this.watchId);
      this.watchId = null;
    }
    this.setState({ dialogOpen: false, centerMapToMyLocation: undefined });
  }

  handleCurrentLocation(position) {
    this.setState({
      myLocation: {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      },
    });
  }

  handleDeleteClick() {
    this.setState({ deleteOpen: true });
  }

  handleDeleteCancelClick() {
    this.setState({ deleteOpen: false });
  }

  handleDeleteConfirmClick() {
    const { site, handleDeleteClick } = this.props;

    handleDeleteClick(site)
      .then(() => this.setState({ deleteOpen: false }))
      .catch(() => this.setState({ deleteOpen: false }));
  }

  handleMapClick() {
    // eslint-disable-next-line no-unused-expressions
    if ('geolocation' in navigator) {
      this.watchId = navigator.geolocation.watchPosition(this.handleCurrentLocation, () => {}, {
        enableHighAccuracy: true,
      });
    }
    this.setState({ dialogOpen: true });
  }

  handleNearMeClick() {
    this.setState({ centerMapToMyLocation: uuidv4() });
  }

  handleSetLocationClick() {
    const { getCenter } = this.state;
    this.updateSite('geolocation', getCenter());
  }

  render() {
    const {
      classes, widthMobile, site, companies,
    } = this.props;
    const {
      name,
      company,
      reportingInterval,
      alertRepeat,
      alertingDevices,
      deleteOpen,
      dialogOpen,
      myLocation,
      centerMapToMyLocation,
    } = this.state;

    let center = { lat: 0, lng: 0 };
    if (site.geolocation) {
      center = {
        lat: site.geolocation.lat,
        lng: site.geolocation.lon,
      };
    } else if (myLocation) {
      center = myLocation;
    }

    return (
      <>
        <div className={classes.content}>
          <List>
            <ListItem>
              <AbilityContext.Consumer>
                {ability => (
                  <TextField
                    id="settings-site-name"
                    label="Site name"
                    value={name}
                    margin="normal"
                    fullWidth
                    readOnly={ability.cannot('modify', 'settings')}
                    onBlur={this.handleBlur('name')}
                    onChange={this.handleChange('name')}
                  />
                )}
              </AbilityContext.Consumer>
            </ListItem>
            <ListItem>
              <AbilityContext.Consumer>
                {ability => (
                  <Select
                    id="settings-company"
                    label="Company"
                    value={company}
                    fullWidth
                    readOnly={ability.cannot('modify', 'settings')}
                    onChange={this.handleChangeSave('company')}
                  >
                    {companies.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </AbilityContext.Consumer>
            </ListItem>
            <ListItem>
              <AbilityContext.Consumer>
                {ability => (
                  <Select
                    id="settings-reporting-interval"
                    label="Reporting interval"
                    value={reportingInterval}
                    fullWidth
                    readOnly={ability.cannot('modify', 'settings')}
                    onChange={this.handleChangeSave('reportingInterval')}
                  >
                    {menuReportingInterval.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </AbilityContext.Consumer>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListSubheader disableSticky>Missing devices alerts</ListSubheader>
            <ListItem>
              <AbilityContext.Consumer>
                {ability => (
                  <Select
                    id="settings-alert-repeat"
                    label="Alert repeat"
                    value={alertRepeat}
                    fullWidth
                    readOnly={ability.cannot('modify', 'settings')}
                    onChange={this.handleAlertsRepeatChange}
                  >
                    {menuItemsAlertRepeat.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </AbilityContext.Consumer>
            </ListItem>
          </List>
          <List>
            {alertingDevices.map(value => (
              <React.Fragment key={value.id}>
                <ListItem>
                  <ListItemText
                    disableTypography
                    // prettier-ignore
                    primary={(
                      <Typography variant="subtitle1" color="textPrimary">
                        {value.label.concat(' alert')}
                      </Typography>
                    )}
                  />
                  <ListItemSecondaryAction>
                    <AbilityContext.Consumer>
                      {ability => (
                        <Switch
                          checked={value.enabled}
                          disabled={ability.cannot('modify', 'settings')}
                          onChange={this.handleAlertsEnabledChange(value.type)}
                        />
                      )}
                    </AbilityContext.Consumer>
                  </ListItemSecondaryAction>
                </ListItem>
                {value.activityTimeout && (
                  <ListItem>
                    <AbilityContext.Consumer>
                      {ability => (
                        <Select
                          id="settings-activity-timeout"
                          label={value.label.concat(' activity timeout')}
                          value={value.activityTimeout}
                          fullWidth
                          readOnly={ability.cannot('modify', 'settings')}
                          onChange={this.handleAlertsTimeoutChange(value.type)}
                        >
                          {menuItemsActivityTimeout.map(item => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </AbilityContext.Consumer>
                  </ListItem>
                )}
              </React.Fragment>
            ))}
          </List>
          <Divider />
          <List>
            <ListSubheader disableSticky>Geolocation</ListSubheader>
            {site.geolocation ? (
              <ListItem className={classes.listItemMap}>
                <GoogleMap
                  center={{
                    lat: site.geolocation.lat,
                    lng: site.geolocation.lon,
                  }}
                  sites={[
                    {
                      lat: site.geolocation.lat,
                      lng: site.geolocation.lon,
                    },
                  ]}
                  isThumbnail
                  handleMapClick={this.handleMapClick}
                />
              </ListItem>
            ) : (
              <ListItem>
                <Button
                  className={classes.buttonLocation}
                  color="secondary"
                  variant="contained"
                  onClick={this.handleMapClick}
                >
                  Set site location
                </Button>
              </ListItem>
            )}
            <Auth do="modify" on="settings">
              <Divider />
              <List>
                <ListItem>
                  <Button className={classes.buttonDelete} variant="outlined" onClick={this.handleDeleteClick}>
                    Delete site
                  </Button>
                </ListItem>
              </List>
            </Auth>
          </List>
        </div>
        <ConfirmDialog
          open={deleteOpen}
          title="Delete site?"
          body={`This will permanently remove the ${name} site and all of its configuration.`}
          confirm="Delete"
          cancel="Cancel"
          handleCancelClick={this.handleDeleteCancelClick}
          handleConfirmClick={this.handleDeleteConfirmClick}
        />
        <Dialog
          classes={widthMobile ? undefined : { paper: classes.dialogPaper }}
          fullScreen={widthMobile}
          open={dialogOpen}
          onClose={this.handleClose}
        >
          <DialogContent className={classes.dialogMap}>
            <AbilityContext.Consumer>
              {ability => (
                <GoogleMap
                  center={center}
                  sites={
                    site.geolocation
                      ? [
                        {
                          lat: site.geolocation.lat,
                          lng: site.geolocation.lon,
                        },
                      ]
                      : undefined
                  }
                  myLocation={myLocation}
                  drawCrosshair={ability.can('modify', 'settings')}
                  centerMapToMyLocation={centerMapToMyLocation}
                  handleLocationChange={this.onMapReady}
                  onMapReady={this.onMapReady}
                />
              )}
            </AbilityContext.Consumer>
          </DialogContent>
          <DialogActions>
            <Tooltip title="Your location" placement="top">
              <IconButton onClick={this.handleNearMeClick}>
                <MyLocationIcon />
              </IconButton>
            </Tooltip>
            <Auth not do="modify" on="settings">
              <Button className={classes.dialogButton} onClick={this.handleClose}>
                Close
              </Button>
            </Auth>
            <Auth do="modify" on="settings">
              <>
                <Button className={classes.dialogButton} onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button
                  className={classes.dialogButton}
                  color="secondary"
                  variant="outlined"
                  onClick={this.handleSetLocationClick}
                >
                  Set site location
                </Button>
              </>
            </Auth>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

SettingsGeneral.propTypes = {
  classes: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  site: PropTypes.object.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleSaveSite: PropTypes.func.isRequired,
  handleSaveSiteAlerts: PropTypes.func.isRequired,
};

export default compose(
  withMobile(),
  withStyles(styles),
)(SettingsGeneral);
