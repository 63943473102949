import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import Select from './Select';

class ZonesAddLocation extends React.Component {
  constructor(props) {
    super(props);

    const { zones, zone, locations } = props;

    const zoneHasHeater = locations
      .filter(item => zone.locations.some(location => location.id === item.id))
      .some(item => item.heater !== null);
    const availableLocations = locations.filter(
      item => !zone.locations.some(location => location.id === item.id)
        && !(zoneHasHeater && item.heater !== null)
        && !zones.some(other => other.id !== zone.id && other.environmentalControl === item.id),
    );

    this.state = {
      id: availableLocations.length === 1 ? availableLocations[0].id : '',
    };

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  handleAddClick() {
    const { handleAddClick: handleAddClickProp } = this.props;
    const { id } = this.state;

    handleAddClickProp(id);
    this.handleClose();
  }

  handleClose() {
    const { handleClose: handleCloseProp } = this.props;

    handleCloseProp();
  }

  handleLocationChange(event) {
    this.setState({ id: event.target.value });
  }

  render() {
    const {
      zones, zone, locations, open,
    } = this.props;
    const { id } = this.state;

    const zoneHasHeater = locations.filter(item => zone.locations.includes(item.id)).some(item => item.heater !== null);

    return (
      <Dialog maxWidth="xs" fullWidth open={open} onClose={this.handleClose}>
        <>
          <DialogTitle>Add a location</DialogTitle>
        </>
        <DialogContent>
          <Select
            id="location-name"
            label="Name"
            value={id}
            margin="normal"
            fullWidth
            onChange={this.handleLocationChange}
          >
            {locations.filter(item => !zone.locations.some(location => location.id === item.id)).map((item) => {
              const locationIsZoneControl = zones.some(
                other => other.id !== zone.id && other.environmentalControl === item.id,
              );

              return (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  disabled={locationIsZoneControl || (zoneHasHeater && item.heater !== null)}
                >
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.handleClose}>
            Cancel
          </Button>
          <Button color="secondary" autoFocus onClick={this.handleAddClick} disabled={id === ''}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ZonesAddLocation.propTypes = {
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
  zone: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
  handleAddClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ZonesAddLocation;
