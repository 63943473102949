export default function formatValue(value, unit, precision) {
  if (value === null) {
    return value;
  }

  if (Number.isNaN(Number(value))) {
    return '';
  }

  const formatted = `${value.toFixed(precision)}`;
  return formatted === '-0' ? `0${unit}` : `${formatted}${unit}`;
}
