import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import SidebarNav from '../components/SidebarNav';
import { apiGetCurrentSite, apiGetDevices, apiGetInvites } from '../utils/api';

const styles = theme => ({
  sidebar: {
    width: theme.sidebar.width,
    height: '100%',
    paddingLeft: 1,
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sidebarClosed: {
    width: 0,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
});

function Sidebar(props) {
  const {
    classes,
    siteId,
    siteSummary,
    open,
    handleZonesClick,
    handleLocationsClick,
    handleDevicesClick,
    handleTeamClick,
    handleInvitationsClick,
    handleSettingsClick,
  } = props;

  return (
    <div className={classNames(classes.sidebar, { [classes.sidebarClosed]: !open })}>
      <SidebarNav
        siteId={siteId}
        site={apiGetCurrentSite()}
        siteSummary={siteSummary}
        devices={apiGetDevices()}
        invites={apiGetInvites()}
        handleZonesClick={handleZonesClick}
        handleLocationsClick={handleLocationsClick}
        handleDevicesClick={handleDevicesClick}
        handleTeamClick={handleTeamClick}
        handleInvitationsClick={handleInvitationsClick}
        handleSettingsClick={handleSettingsClick}
      />
    </div>
  );
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  siteId: PropTypes.string.isRequired,
  siteSummary: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleZonesClick: PropTypes.func.isRequired,
  handleLocationsClick: PropTypes.func.isRequired,
  handleDevicesClick: PropTypes.func.isRequired,
  handleTeamClick: PropTypes.func.isRequired,
  handleInvitationsClick: PropTypes.func.isRequired,
  handleSettingsClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(Sidebar);
