import PropTypes from 'prop-types';
import React from 'react';
import InvitationsComponent from '../components/Invitations';
import {
  apiDeleteInvite,
  apiGetInvites,
  apiGetNameFromEmail,
  apiGetUserAlerts,
  apiSendInvite,
  apiGetCurrentSite,
} from '../utils/api';

function Invitations(props) {
  const { handleBackClick } = props;
  const site = apiGetCurrentSite();

  return (
    <InvitationsComponent
      siteId={site.id}
      invites={apiGetInvites()}
      contacts={site.contacts}
      alerts={apiGetUserAlerts()}
      getNameFromEmail={apiGetNameFromEmail}
      handleBackClick={handleBackClick}
      handleDeleteInvite={apiDeleteInvite}
      handleSendInvite={apiSendInvite}
    />
  );
}

Invitations.propTypes = {
  handleBackClick: PropTypes.func.isRequired,
};

export default Invitations;
