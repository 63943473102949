import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const CrosshairIcon = props => (
  <SvgIcon viewBox="0 0 150 150" {...props}>
    <path
      fill="none"
      stroke="#000"
      strokeWidth="3"
      strokeMiterlimit="10"
      d="M100 75h50M75 50V0 M0 75h50M75 150v-50M74.5 74.5h1v1h-1z M 75 75 m -50, 0 a 50,50 0 1,0 100,0 a 50,50 0 1,0 -100,0"
    />
  </SvgIcon>
);

export default CrosshairIcon;
