import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const WarningOutlineIcon = props => (
  <SvgIcon {...props}>
    <path d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16" />
  </SvgIcon>
);

export default WarningOutlineIcon;
