const sensorTypes = {
  'Ambient temperature': 'T',
  'Ambient humidity': 'H',
  'Core temperature': 'T',
  'Surface temperature': 'T',
};

export default sensorTypes;

export function sensorTypeName(type) {
  if (type === 't') {
    return 'Temperature - thermocouple';
  }
  if (type === 'T') {
    return 'Temperature';
  }
  if (type === 'H') {
    return 'Humidity';
  }
  return '';
}

function orderRank(sensor) {
  if (sensor.type === 'H') {
    return 4;
  }
  switch (sensor.sensorType) {
    case 'Ambient temperature':
      return 1;
    case 'Core temperature':
      return 2;
    case 'Surface temperature':
      return 3;
    default:
      break;
  }
  return 5;
}

export function compareSensors(a, b) {
  return orderRank(a) - orderRank(b);
}
