import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import EditorDragLayer from './EditorDragLayer';
import FloorplanViewport from './FloorplanViewport';

const styles = theme => ({
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
  },
  navButton: {
    width: 64,
    height: '100%',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover svg': {
      fill: theme.palette.secondary.light,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hidden: {
    display: 'none',
  },
  icon: {
    fontSize: 36,
    transition: 'unset',
  },
});

function FloorplanEditor(props) {
  const {
    classes,
    floorplan,
    floorplans,
    image,
    widgets,
    locations,
    scale,
    onViewportScaleChange,
    handleBackClick,
    handleForwardClick,
    handleImageDrop,
    handleLocationDrop,
    handleAnnotationDrop,
    handleWidgetMove,
    handleCalloutMove,
    handleRemoveClick,
    handleTextChange,
    handleWidgetClick,
    handleFileDialogOpen,
  } = props;

  return (
    <div className={classes.container}>
      <IconButton
        className={classNames(classes.navButton, {
          [classes.hidden]: floorplan ? floorplans.length < 2 : floorplans.length < 1,
        })}
        onClick={handleBackClick}
        disableRipple
      >
        <ChevronLeftIcon className={classes.icon} />
      </IconButton>
      {floorplan ? (
        <FloorplanViewport
          image={image}
          floorplan={floorplan}
          widgets={widgets}
          locations={locations}
          scale={scale}
          onScaleChange={onViewportScaleChange}
          handleImageDrop={handleImageDrop}
          handleLocationDrop={handleLocationDrop}
          handleAnnotationDrop={handleAnnotationDrop}
          handleWidgetMove={handleWidgetMove}
          handleCalloutMove={handleCalloutMove}
          handleRemoveClick={handleRemoveClick}
          handleTextChange={handleTextChange}
          handleWidgetClick={handleWidgetClick}
          handleFileDialogOpen={handleFileDialogOpen}
        />
      ) : (
        <div className={classes.container} />
      )}

      <EditorDragLayer widgets={widgets} />
      <IconButton
        className={classNames(classes.navButton, {
          [classes.hidden]: floorplan ? floorplans.length < 2 : floorplans.length < 1,
        })}
        onClick={handleForwardClick}
        disableRipple
      >
        <ChevronRightIcon className={classes.icon} />
      </IconButton>
    </div>
  );
}

FloorplanEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  floorplans: PropTypes.arrayOf(PropTypes.object).isRequired,
  floorplan: PropTypes.object,
  image: PropTypes.object,
  widgets: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  scale: PropTypes.number.isRequired,
  onViewportScaleChange: PropTypes.func.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleForwardClick: PropTypes.func.isRequired,
  handleImageDrop: PropTypes.func.isRequired,
  handleLocationDrop: PropTypes.func.isRequired,
  handleAnnotationDrop: PropTypes.func.isRequired,
  handleWidgetMove: PropTypes.func.isRequired,
  handleCalloutMove: PropTypes.func.isRequired,
  handleRemoveClick: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleWidgetClick: PropTypes.func.isRequired,
  handleFileDialogOpen: PropTypes.func.isRequired,
};

FloorplanEditor.defaultProps = {
  floorplan: undefined,
  image: undefined,
};

export default withStyles(styles)(FloorplanEditor);
