import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import EventListener from 'react-event-listener';
import compose from 'recompose/compose';
import AppBarLogo from '../components/AppBarLogo';
import BottomAppBar from '../components/BottomAppBar';
import Devices from '../components/Devices';
import Invitations from '../components/Invitations';
import Locations from '../components/Locations';
import ReportsComponent from '../components/Reports';
import Team from '../components/Team';
import withMobile from '../components/withMobile';
import Zones from '../components/Zones';
import { mixinHeightWithToolbar } from '../constants/theme';
import {
  apiAssignDevice,
  apiDeleteContact,
  apiDeleteInvite,
  apiDeleteLocation,
  apiDeleteThreshold,
  apiDeleteZone,
  apiFastFindDevice,
  apiGetCurrentSite,
  apiGetDevices,
  apiGetInvites,
  apiGetNameFromEmail,
  apiGetReportSensors,
  apiGetUserAlerts,
  apiModifyContact,
  apiModifyLocation,
  apiModifyThreshold,
  apiModifyZone,
  apiRequestReport,
  apiSendInvite,
  apiUnassignDevice,
  apiUpdateDeviceNetwork,
} from '../utils/api';
import Settings from './Settings';
import Sidebar from './Sidebar';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: theme.sideSheet.width + theme.floorplan.width,
    margin: `${theme.spacing.unit * 3}px 0px`,
    ...mixinHeightWithToolbar(theme.spacing.unit * 6),
  },
  contentContainer: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    boxShadow: '0 2px 2px -1px rgba(153, 153, 153, 0.3), 0 1px 5px -2px rgba(153, 153, 153, 0.3)',
    boxSizing: 'border-box',
    padding: 1,
  },
  componentAppBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: theme.appBar.height,
    ...theme.mixins.gutters(),
  },
  componentContainer: {
    flexGrow: 1,
    height: '100%',
  },
  component: {
    width: '100%',
    height: `calc(100% - ${theme.bottomAppBar.height}px - 56px)`,
    marginTop: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100% - ${theme.bottomAppBar.height}px - 48px)`,
      marginTop: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${theme.bottomAppBar.height}px - 64px)`,
      marginTop: 64,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      ...mixinHeightWithToolbar(),
    },
  },
  sideSheet: {
    width: theme.sideSheet.width,
    height: '100%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sideSheetClosed: {
    width: 0,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  divider: {
    marginRight: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 3,
  },
});

class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: true,
      index: 0,
    };

    this.handleSideSheetClose = this.handleSideSheetClose.bind(this);
    this.handleZonesClick = this.handleZonesClick.bind(this);
    this.handleLocationsClick = this.handleLocationsClick.bind(this);
    this.handleDevicesClick = this.handleDevicesClick.bind(this);
    this.handleTeamClick = this.handleTeamClick.bind(this);
    this.handleTeamAddClick = this.handleTeamAddClick.bind(this);
    this.handleInvitationsClick = this.handleInvitationsClick.bind(this);
    this.handleSettingsClick = this.handleSettingsClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleSideSheetClose() {
    this.setState({ sidebarOpen: true, index: 0 });
  }

  handleZonesClick() {
    this.setState({ sidebarOpen: false, index: 1 });
  }

  handleLocationsClick() {
    this.setState({ sidebarOpen: false, index: 2 });
  }

  handleDevicesClick() {
    this.setState({ sidebarOpen: false, index: 3 });
  }

  handleTeamClick() {
    this.setState({ sidebarOpen: false, index: 4 });
  }

  handleTeamAddClick() {
    this.setState({ sidebarOpen: false, index: 5 });
  }

  handleInvitationsClick() {
    this.setState({ sidebarOpen: false, index: 5 });
  }

  handleSettingsClick() {
    this.setState({ sidebarOpen: false, index: 6 });
  }

  handleKeyDown(event) {
    const { sidebarOpen } = this.state;

    if (event.key === 'Escape' && sidebarOpen === false) {
      event.preventDefault();
      this.handleSideSheetClose();
    }
  }

  render() {
    const { classes, widthMobile, siteId } = this.props;
    const { sidebarOpen, index } = this.state;

    const site = apiGetCurrentSite();
    const alerts = apiGetUserAlerts();
    const devices = apiGetDevices();
    const invites = apiGetInvites();

    return (
      <>
        {!widthMobile ? (
          <>
            <EventListener target="window" onKeyDown={this.handleKeyDown} />
            <AppBarLogo title={site.name} alerts={alerts} />
            <div className={classes.root}>
              <div className={classes.container}>
                <Sidebar
                  siteId={siteId}
                  siteSummary={site.summary}
                  open={sidebarOpen}
                  handleZonesClick={this.handleZonesClick}
                  handleLocationsClick={this.handleLocationsClick}
                  handleDevicesClick={this.handleDevicesClick}
                  handleTeamClick={this.handleTeamClick}
                  handleInvitationsClick={this.handleInvitationsClick}
                  handleSettingsClick={this.handleSettingsClick}
                />
                <div className={classes.contentContainer}>
                  <div className={classes.componentContainer}>
                    <div className={classes.componentAppBar}>
                      <Typography variant="h6">Report</Typography>
                    </div>
                    <Divider className={classes.divider} />
                    <div className={classes.component}>
                      <ReportsComponent
                        siteId={siteId}
                        siteName={site.name}
                        getSensors={apiGetReportSensors}
                        requestReport={apiRequestReport}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames(classes.sideSheet, {
                      [classes.sideSheetClosed]: sidebarOpen,
                    })}
                  >
                    {!sidebarOpen && (
                      <>
                        {index === 1 && (
                          <Zones
                            site={site}
                            sideSheet
                            handleClose={this.handleSideSheetClose}
                            handleDeleteThresholdClick={apiDeleteThreshold}
                            handleSaveThresholdClick={apiModifyThreshold}
                            handleDeleteZoneClick={apiDeleteZone}
                            handleSaveZoneClick={apiModifyZone}
                          />
                        )}
                        {index === 2 && (
                          <Locations
                            site={site}
                            devices={devices}
                            sideSheet
                            handleDeleteClick={apiDeleteLocation}
                            handleSaveClick={apiModifyLocation}
                            handleClose={this.handleSideSheetClose}
                          />
                        )}

                        {index === 3 && (
                          <Devices
                            site={site}
                            devices={devices}
                            alerts={apiGetUserAlerts()}
                            sideSheet
                            handleAssignClick={apiAssignDevice}
                            handleFastFindClick={apiFastFindDevice}
                            handleUnassignClick={apiUnassignDevice}
                            handleUpdateDeviceNetwork={apiUpdateDeviceNetwork}
                            handleClose={this.handleSideSheetClose}
                          />
                        )}
                        {index === 4 && (
                          <Team
                            site={site}
                            sideSheet
                            handleTeamDelete={apiDeleteContact}
                            handleTeamUpdate={apiModifyContact}
                            handleTeamAddClick={this.handleTeamAddClick}
                            handleClose={this.handleSideSheetClose}
                          />
                        )}
                        {index === 5 && (
                          <Invitations
                            siteId={site.id}
                            invites={invites}
                            contacts={site.contacts}
                            sideSheet
                            getNameFromEmail={apiGetNameFromEmail}
                            handleClose={this.handleSideSheetClose}
                            handleDeleteInvite={apiDeleteInvite}
                            handleSendInvite={apiSendInvite}
                          />
                        )}
                        {index === 6 && <Settings siteId={siteId} sideSheet handleClose={this.handleSideSheetClose} />}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <AppBar>
              <Toolbar>
                <Typography variant="h6">Report</Typography>
              </Toolbar>
            </AppBar>
            <div className={classes.component}>
              <ReportsComponent
                siteId={siteId}
                siteName={site.name}
                getSensors={apiGetReportSensors}
                requestReport={apiRequestReport}
              />
            </div>
            <BottomAppBar siteId={siteId} site={site} devices={devices} invites={invites} alerts={alerts} />
          </>
        )}
      </>
    );
  }
}

Reports.propTypes = {
  classes: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  siteId: PropTypes.string.isRequired,
};

export default compose(
  withMobile(),
  withStyles(styles),
)(Reports);
