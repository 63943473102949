import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import compose from 'recompose/compose';
import dragAndDrop from '../constants/dragAndDrop';
import FloorplanGaugeBody from './FloorplanGaugeBody';

const styles = {
  root: {
    cursor: 'move',
    transformOrigin: '0px 0px',
  },
  dragging: {
    opacity: 0.54,
  },
};

class EditorPaletteGauge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bodyWidth: 0,
      bodyHeight: 0,
    };

    this.bodyCoordinatesRef = null;

    this.bodyProperties = this.bodyProperties.bind(this);
    this.handleBodyResize = this.handleBodyResize.bind(this);
    this.handleBodyCoordinatesRef = this.handleBodyCoordinatesRef.bind(this);
    this.renderLocation = this.renderLocation.bind(this);
  }

  bodyProperties() {
    const { bodyWidth, bodyHeight } = this.state;

    let bodyCoordinates = { x: 0, y: 0 };

    if (this.bodyCoordinatesRef) {
      bodyCoordinates = this.bodyCoordinatesRef();
    }

    return {
      bodyWidth,
      bodyHeight,
      bodyCoordinates,
    };
  }

  handleBodyResize(bodyWidth, bodyHeight) {
    this.setState((prevState) => {
      if (prevState.bodyWidth !== bodyWidth) {
        return { bodyWidth };
      }

      return null;
    });

    this.setState((prevState) => {
      if (prevState.bodyHeight !== bodyHeight) {
        return { bodyHeight };
      }

      return null;
    });
  }

  handleBodyCoordinatesRef(ref) {
    this.bodyCoordinatesRef = ref;
  }

  renderLocation() {
    const {
      classes, isDragging, location, scale,
    } = this.props;
    const { bodyWidth, bodyHeight } = this.state;

    return (
      <div
        className={classNames(classes.root, { [classes.dragging]: isDragging })}
        style={{
          transform: `scale(${scale})`,
          width: bodyWidth * scale,
          height: bodyHeight * scale,
        }}
      >
        <FloorplanGaugeBody
          location={location}
          onResize={this.handleBodyResize}
          coordinatesRef={this.handleBodyCoordinatesRef}
        />
      </div>
    );
  }

  render() {
    const { connectDragSource, connectDragPreview } = this.props;

    connectDragPreview(getEmptyImage());
    return connectDragSource(this.renderLocation());
  }
}

EditorPaletteGauge.propTypes = {
  classes: PropTypes.object.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  scale: PropTypes.number.isRequired,
};

export default compose(
  withStyles(styles),
  DragSource(
    dragAndDrop.paletteGauge,
    {
      beginDrag(props, _, component) {
        const { location, scale } = props;

        return { ...component.bodyProperties(), location, scale };
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    }),
  ),
)(EditorPaletteGauge);
