import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';
import HumidityIcon from '../images/icons/Humidity';
import TemperatureIcon from '../images/icons/Temperature';

const styles = {
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
};

function LocationsDetails(props) {
  const { classes, location } = props;

  const hasHeater = location.heater !== null;

  return (
    <div className={classes.content}>
      <List>
        <ListItem>
          <ListItemText primary="Location name" secondary={location.name} />
        </ListItem>
        {location.notes && (
          <ListItem>
            <ListItemText primary="Note" secondary={location.notes} />
          </ListItem>
        )}
      </List>
      <Divider />
      {!hasHeater && (
        <List>
          <ListSubheader disableSticky>Sensors</ListSubheader>
          {location.sensors.map(item => (
            <ListItem key={item.id} dense>
              <ListItemIcon>{item.type === 'H' ? <HumidityIcon /> : <TemperatureIcon />}</ListItemIcon>
              <ListItemText primary={item.sensorType} secondary={`WSN ${item.barcode} • ${item.channel}`} />
            </ListItem>
          ))}
          {location.sensors.length === 0 && (
            <ListItem>
              <ListItemText secondary="No sensors" />
            </ListItem>
          )}
        </List>
      )}
      {!hasHeater && location.sensors.length === 0 && <Divider />}
      {(hasHeater || location.sensors.length === 0) && (
        <List>
          <ListSubheader disableSticky>Smart heater</ListSubheader>
          {hasHeater && (
            <ListItem dense>
              <ListItemIcon>
                <WhatshotIcon />
              </ListItemIcon>
              <ListItemText secondary={`HMC ${location.heater.barcode}`} />
            </ListItem>
          )}
          {!hasHeater && (
            <ListItem>
              <ListItemText secondary="No smart heater" />
            </ListItem>
          )}
        </List>
      )}
    </div>
  );
}

LocationsDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withStyles(styles)(LocationsDetails);
