const awsExports = {
  Analytics: {
    appId: '95bb26c37ba649ae8004836c1a21b707',
    disabled: true,
    region: 'us-east-1',
  },
  API: {
    endpoints: [
      {
        endpoint: 'https://dev.wedgeapi.com',
        name: 'APIGatewayAPI',
        region: 'us-west-2',
      },
    ],
  },
  Auth: {
    identityPoolId: 'us-west-2:8241be44-993a-403b-b2e5-a175ea071c90',
    mandatorySignIn: true,
    region: 'us-west-2',
    userPoolId: 'us-west-2_kmsIPLct5',
    userPoolWebClientId: '4qnfjee1epmdr1oirbdjh46a3k',
  },
  PubSub: {
    endpoint: 'a1uukk7lei9dd1-ats.iot.us-west-2.amazonaws.com',
    region: 'us-west-2',
    stage: 'dev',
  },
  DGLuxLegacy: {
    endpoint: 'https://dev2017.wedgesystems.com',
  },
};

export default awsExports;
