import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  connector: {
    position: 'absolute',
    overflow: 'visible !important',
  },
};

function Callout(props) {
  const {
    classes, className, from, to, scale, fill, stroke,
  } = props;

  const height = Math.abs(from.y - to.y);
  const width = Math.abs(from.x - to.x);
  const hypotenuse = Math.sqrt(height * height + width * width);
  let angle = (Math.acos(width / hypotenuse) / Math.PI) * 180;

  if (from.x > to.x) {
    angle = 180 - angle;
  }
  if (from.y > to.y) {
    angle *= -1;
  }

  return (
    <svg
      className={classNames(classes.connector, className)}
      style={{
        width: hypotenuse,
        height: 20 * scale,
        transform: `translate(${from.x}px, ${from.y - 10 * scale}px) rotate(${angle}deg)`,
        transformOrigin: `0px ${10 * scale}px`,
      }}
    >
      <polygon
        points={`0,0 ${hypotenuse},${10 * scale} 0,${20 * scale}`}
        style={{
          fill,
          stroke,
        }}
      />
    </svg>
  );
}

Callout.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  from: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  to: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  scale: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
};

Callout.defaultProps = {
  className: '',
};

export default withStyles(styles)(Callout);
