export function deviceInAlert(device) {
  if (device.type === 'WSN') {
    return device.isMissing || device.isLowBattery || device.sensors.some(item => item.inError);
  }
  if (device.type === 'CAG') {
    return device.isMissing || device.isOffsite || device.isReassigned;
  }
  if (device.type === 'HMC') {
    return device.isMissing || device.sensors.some(item => item.inError);
  }
  return false;
}

export function formatCellular(csq) {
  if (csq && csq.rssi) {
    const rssi = Number(
      csq.rssi
        .replace(' dBm or less', '')
        .replace(' dBm or greater', '')
        .replace(' dBm', ''),
    );
    // eslint-disable-next-line no-restricted-globals
    if (isFinite(rssi)) {
      if (rssi >= -69) return 'Great';
      if (rssi >= -89) return 'Good';
      if (rssi >= -99) return 'Poor';
      return 'Unusable';
    }
  }
  return 'Unknown';
}

export function formatGps(gps) {
  if (gps && gps.lat != null && !Number.isNaN(gps.lat)) {
    return 'Locked';
  }
  return 'Acquiring';
}
