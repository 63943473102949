import React from 'react';

const NONE = 'NONE';
const STARTED = 'STARTED';
const LOADED = 'LOADED';

export function withScript(scriptUrl) {
  return function ScriptContainerWrapped(Component) {
    const factory = React.createFactory(Component);

    class ScriptContainer extends React.PureComponent {
      constructor(props) {
        super(props);

        this.state = { loading: NONE };

        this.factory = factory;
        this.isUnmounted = undefined;

        this.handleLoaded = this.handleLoaded.bind(this);
      }

      componentDidMount() {
        const { loading } = this.state;

        this.isUnmounted = false;

        if (loading !== NONE) {
          return;
        }

        this.setState({ loading: STARTED }); // eslint-disable-line react/no-did-mount-set-state

        const scriptjs = require('scriptjs'); // eslint-disable-line global-require
        scriptjs(scriptUrl, this.handleLoaded);
      }

      componentWillUnmount() {
        this.isUnmounted = true;
      }

      handleLoaded() {
        if (this.isUnmounted) {
          return;
        }

        this.setState({ loading: LOADED });
      }

      render() {
        const { loading } = this.state;

        if (loading === LOADED) {
          return factory(this.props);
        }
        return <div />;
      }
    }

    return ScriptContainer;
  };
}

export default withScript;
