import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BarChartIcon from '@material-ui/icons/BarChart';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import LayersIcon from '@material-ui/icons/Layers';
import PeopleIcon from '@material-ui/icons/People';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PlaceIcon from '@material-ui/icons/Place';
import SettingsIcon from '@material-ui/icons/Settings';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import ZoneIcon from '../images/icons/Zone';
import { apiGetDefaultFloorplanId } from '../utils/api';
import { AbilityContext, Auth } from '../utils/auth';
import { deviceInAlert } from '../utils/devices';
import ListItemLink from './ListItemLink';
import ListItemLinkIcon from './ListItemLinkIcon';

const styles = theme => ({
  container: {
    margin: 0,
  },
  hide: {
    display: 'none',
  },
  count: {
    paddingLeft: 9,
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.secondary,
  },
  alert: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  activeRoute: {
    color: theme.palette.secondary.main,
  },
  activeItem: {
    textDecoration: 'none',
    backgroundColor: theme.palette.common.white,
  },
  activeCount: {
    color: theme.palette.secondary.main,
    opacity: 0.7,
  },
  listItem: {
    borderRightColor: theme.palette.background.default,
    borderRightStyle: 'solid',
    borderRightWidth: theme.spacing.unit,
    borderRadius: '24px 0px 0px 24px',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.common.white,
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
});

function SidebarNav(props) {
  const {
    classes,
    location,
    siteId,
    site,
    devices,
    invites,
    handleZonesClick,
    handleLocationsClick,
    handleDevicesClick,
    handleTeamClick,
    handleInvitationsClick,
    handleSettingsClick,
  } = props;

  const floorplansCount = site.floorplans.filter(item => item.active).length;
  const zonesCount = site.zones.length;
  const locationsCount = site.locations.length;
  const devicesCount = devices.assignedDevices.reduce((accumulator, item) => accumulator + 1 + item.devices.length, 0);
  const teamCount = site.contacts.length;
  const invitationsCount = invites.length;

  const zonesAlert = site.locations.some(item => item.sensors.some(sensor => sensor.value.thresholds.some(threshold => threshold.exceeded))); // eslint-disable-line max-len
  const devicesAlert = devices.assignedDevices.some(
    cag => deviceInAlert(cag) || cag.devices.some(device => deviceInAlert(device)),
  );

  return (
    <div className={classes.container}>
      <List component="nav">
        <Auth do="access" on="floorplans">
          <ListItemLinkIcon
            className={classNames({
              [classes.activeRoute]: location.pathname.indexOf('floorplans') > -1,
            })}
            classNameItem={classNames(classes.listItem, {
              [classes.activeItem]: location.pathname.indexOf('floorplans') > -1,
            })}
            classNameCount={classNames(
              classes.count,
              {
                [classes.activeCount]: location.pathname.indexOf('floorplans') > -1,
              },
              { [classes.hide]: floorplansCount === 0 },
            )}
            to={`/site/${siteId}/floorplans/${apiGetDefaultFloorplanId(siteId)}`}
            primary="Floorplans"
            icon={<LayersIcon />}
            count={floorplansCount}
          />
        </Auth>
        <Auth do="access" on="reports">
          <ListItemLinkIcon
            className={classNames({
              [classes.activeRoute]: location.pathname.indexOf('reports') > -1,
            })}
            classNameItem={classNames(classes.listItem, {
              [classes.activeItem]: location.pathname.indexOf('reports') > -1,
            })}
            to={`/site/${siteId}/reports`}
            primary="Reports"
            icon={<BarChartIcon />}
          />
        </Auth>
      </List>
      <List component="nav">
        <Auth do="access" on="zones">
          <ListItem className={classes.listItem} button onClick={handleZonesClick}>
            <ListItemIcon>
              <ZoneIcon />
            </ListItemIcon>
            <ListItemText
              disableTypography
              // prettier-ignore
              primary={(
                <Typography>
                  Zones
                  <span
                    className={classNames(classes.count, {
                      [classes.alert]: zonesAlert,
                      [classes.hide]: zonesCount === 0,
                    })}
                  >
                    {zonesCount}
                  </span>
                </Typography>
              )}
            />
          </ListItem>
        </Auth>
        <Auth do="access" on="locations">
          <ListItem className={classes.listItem} button onClick={handleLocationsClick}>
            <ListItemIcon>
              <PlaceIcon />
            </ListItemIcon>
            <ListItemText
              disableTypography
              // prettier-ignore
              primary={(
                <Typography>
                  Locations
                  <span
                    className={classNames(classes.count, {
                      [classes.hide]: locationsCount === 0,
                    })}
                  >
                    {locationsCount}
                  </span>
                </Typography>
              )}
            />
          </ListItem>
        </Auth>
        <Auth do="access" on="devices">
          <ListItem className={classes.listItem} button onClick={handleDevicesClick}>
            <ListItemIcon>
              <DeviceHubIcon />
            </ListItemIcon>
            <ListItemText
              disableTypography
              // prettier-ignore
              primary={(
                <Typography>
                  Devices
                  <span
                    className={classNames(classes.count, {
                      [classes.alert]: devicesAlert,
                      [classes.hide]: devicesCount === 0,
                    })}
                  >
                    {devicesCount}
                  </span>
                </Typography>
              )}
            />
          </ListItem>
        </Auth>
        <Auth do="access" on="team">
          <ListItem className={classes.listItem} button onClick={handleTeamClick}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText
              disableTypography
              // prettier-ignore
              primary={(
                <Typography>
                  Team
                  <span
                    className={classNames(classes.count, {
                      [classes.hide]: teamCount === 0,
                    })}
                  >
                    {teamCount}
                  </span>
                </Typography>
              )}
            />
          </ListItem>
        </Auth>
        <Auth do="access" on="invitations">
          <ListItem className={classes.listItem} button onClick={handleInvitationsClick}>
            <ListItemIcon>
              <PersonAddIcon />
            </ListItemIcon>
            <ListItemText
              disableTypography
              // prettier-ignore
              primary={(
                <Typography>
                  Invitations
                  <span
                    className={classNames(classes.count, {
                      [classes.hide]: invitationsCount === 0,
                    })}
                  >
                    {invitationsCount}
                  </span>
                </Typography>
              )}
            />
          </ListItem>
        </Auth>
        <Auth do="access" on="settings">
          <ListItem className={classes.listItem} button onClick={handleSettingsClick}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText disableTypography primary={<Typography variant="body2">Settings</Typography>} />
          </ListItem>
        </Auth>
      </List>
      <List component="nav">
        <ListItemLink className={classes.listItem} to="/profile" primary="View profile" />
        <AbilityContext.Consumer>
          {ability => (ability.can('access', 'companies') && ability.can('access', 'users') ? (
            <ListItemLink className={classes.listItem} to="/admin" primary="Admin tools" />
          ) : null)
          }
        </AbilityContext.Consumer>
      </List>
    </div>
  );
}

SidebarNav.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  siteId: PropTypes.string.isRequired,
  site: PropTypes.object.isRequired,
  devices: PropTypes.object.isRequired,
  invites: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleZonesClick: PropTypes.func.isRequired,
  handleLocationsClick: PropTypes.func.isRequired,
  handleDevicesClick: PropTypes.func.isRequired,
  handleTeamClick: PropTypes.func.isRequired,
  handleInvitationsClick: PropTypes.func.isRequired,
  handleSettingsClick: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles),
)(SidebarNav);
