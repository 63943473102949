import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';
import { AbilityContext } from '../utils/auth';

const styles = {
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
};

class ZonesDetails extends React.Component {
  constructor(props) {
    super(props);

    const { zone } = props;
    const switches = zone.thresholds.map(item => ({
      id: item.id,
      sensorType: item.sensorType,
      enabled: item.enabled,
    }));

    this.state = {
      switches,
    };

    this.handleSwitchClick = this.handleSwitchClick.bind(this);
  }

  static getDerivedStateFromProps(props) {
    const { zone } = props;
    const switches = zone.thresholds.map(item => ({
      id: item.id,
      sensorType: item.sensorType,
      enabled: item.enabled,
    }));

    return { switches };
  }

  handleSwitchClick(event, id) {
    const { zone, handleToggleAlert } = this.props;
    const { switches } = this.state;

    const threshold = zone.thresholds.find(item => item.id === id);
    threshold.enabled = event.target.checked;
    handleToggleAlert(threshold);

    switches.find(item => item.id === id).enabled = event.target.checked;
    this.setState({ switches });
  }

  render() {
    const { classes, zone, handleMonitoredItemClick } = this.props;
    const { switches } = this.state;

    return (
      <div className={classes.content}>
        <List>
          <ListItem>
            <ListItemText primary="Zone name" secondary={zone.name} />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListSubheader disableSticky>Locations</ListSubheader>
          {zone.locations.map(item => (
            <ListItem key={item.id} dense>
              <ListItemText
                // prettier-ignore
                primary={(
                  <Typography variant="subtitle1" noWrap>
                    {item.name}
                  </Typography>
                )}
                disableTypography
              />
            </ListItem>
          ))}
          {zone.locations.length === 0 && (
            <ListItem>
              <ListItemText secondary="No locations" />
            </ListItem>
          )}
        </List>
        <Divider />
        <List>
          <ListSubheader disableSticky>Automated monitoring</ListSubheader>
          {switches.map(item => (
            <ListItem key={item.id} onClick={() => handleMonitoredItemClick(item.id)} button>
              <ListItemText primary={item.sensorType} />
              <ListItemSecondaryAction>
                <AbilityContext.Consumer>
                  {ability => (
                    <Switch
                      checked={item.enabled}
                      disabled={ability.cannot('modify', 'zones')}
                      onChange={event => this.handleSwitchClick(event, item.id)}
                    />
                  )}
                </AbilityContext.Consumer>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          {zone.thresholds.length === 0 && (
            <ListItem>
              <ListItemText secondary="No monitored conditions" />
            </ListItem>
          )}
        </List>
        <Divider />
        <List>
          <ListSubheader disableSticky>Environmental control</ListSubheader>
          <ListItem>
            {zone.environmentalControl !== null ? (
              <ListItemText
                primary="Smart heater"
                secondary={zone.locations.find(item => item.id === zone.environmentalControl).name}
              />
            ) : (
              <ListItemText secondary="No smart heater assigned" />
            )}
          </ListItem>
        </List>
      </div>
    );
  }
}

ZonesDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  zone: PropTypes.object.isRequired,
  handleMonitoredItemClick: PropTypes.func.isRequired,
  handleToggleAlert: PropTypes.func.isRequired,
};

export default withStyles(styles)(ZonesDetails);
