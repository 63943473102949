import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import compose from 'recompose/compose';
import AppBar from '../components/AppBar';
import AppBarLogo from '../components/AppBarLogo';
import InviteComponent from '../components/Invite';
import InviteList from '../components/InviteList';
import InviteNotFound from '../components/InviteNotFound';
import Spinner from '../components/Spinner';
import withMobile from '../components/withMobile';
import { mixinHeightWithToolbar } from '../constants/theme';
import { apiGetInvite, apiModifyUser, apiSignIn } from '../utils/api';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: theme.floorplan.width,
    margin: `${theme.spacing.unit * 3}px 0px`,
    ...mixinHeightWithToolbar(theme.spacing.unit * 6),
  },
  contentContainer: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    boxShadow: '0 2px 2px -1px rgba(153, 153, 153, 0.3), 0 1px 5px -2px rgba(153, 153, 153, 0.3)',
    boxSizing: 'border-box',
    padding: 1,
  },
  componentContainer: {
    flexGrow: 1,
    height: '100%',
  },
  componentAppBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: theme.appBar.height,
    ...theme.mixins.gutters(),
  },
  component: {
    width: '100%',
    ...mixinHeightWithToolbar(),
  },
  divider: {
    marginRight: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 3,
  },
});

class Invite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invite: undefined,
      redirect: false,
      handling: false,
    };

    this.handleSignUpClick = this.handleSignUpClick.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const { inviteId } = match.params;

    this.setState({ handling: true });

    apiGetInvite(inviteId)
      .then(invite => this.setState({ invite, handling: false }))
      .catch(() => this.setState({ invite: null, handling: false }));
  }

  handleSignUpClick(name, company, mobile, password) {
    const { invite, handling } = this.state;

    if (handling) return;

    this.setState({ handling: true });

    const user = {
      inviteId: invite.id,
      name,
      company,
      mobile,
      password,
    };

    apiModifyUser(user)
      .then(() => apiSignIn(invite.email, password)
        .then(() => this.setState({ redirect: true, handling: false }))
        .catch(() => this.setState({ handling: false })))
      .catch(() => this.setState({ handling: false }));
  }

  render() {
    const { classes, widthMobile } = this.props;
    const { invite, redirect, handling } = this.state;

    if (redirect) {
      return <Redirect to="/" />;
    }

    return (
      <>
        {!widthMobile ? (
          <>
            <AppBarLogo />
            <div className={classes.root}>
              <div className={classes.container}>
                <div className={classes.contentContainer}>
                  <Spinner className={classes.componentContainer} show={handling}>
                    <div className={classes.componentAppBar}>
                      <Typography variant="h6">Sign up</Typography>
                    </div>
                    <Divider className={classes.divider} />
                    <div className={classes.component}>
                      {invite && <InviteComponent email={invite.email} handleSignUpClick={this.handleSignUpClick} />}
                      {invite === null && <InviteNotFound />}
                    </div>
                  </Spinner>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Spinner show={handling}>
            <AppBar title="Sign up" />
            <div className={classes.component}>
              {invite && <InviteList email={invite.email} handleSignUpClick={this.handleSignUpClick} />}
              {invite === null && <InviteNotFound />}
            </div>
          </Spinner>
        )}
      </>
    );
  }
}

Invite.propTypes = {
  classes: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withMobile(),
)(Invite);
