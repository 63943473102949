import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import AppBar from './AppBar';
import ConfirmDialog from './ConfirmDialog';
import SideSheet from './SideSheet';
import Spinner from './Spinner';
import TeamDetails from './TeamDetails';
import TeamEdit from './TeamEdit';
import TeamList from './TeamList';
import withMobile from './withMobile';

class Team extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      userId: undefined,
      user: undefined,
      handling: false,
      open: false,
    };

    this.handleListItemClick = this.handleListItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleDeleteCancelClick = this.handleDeleteCancelClick.bind(this);
    this.handleDeleteConfirmClick = this.handleDeleteConfirmClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleTeamAddClick = this.handleTeamAddClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { site } = nextProps;
    const { step, userId } = prevState;

    if ([1, 2].includes(step) && !site.contacts.some(item => item.userId === userId)) {
      // Go to list
      return { step: 0 };
    }

    return null;
  }

  handleListItemClick(userId) {
    // Go to details
    this.setState({ step: 1, userId });
  }

  handleBackClick() {
    // Go to list
    this.setState({ step: 0 });
  }

  handleChange(user) {
    this.setState({ user });
  }

  handleDeleteClick() {
    const { handling } = this.state;

    if (!handling) this.setState({ open: true });
  }

  handleDeleteCancelClick() {
    this.setState({ open: false });
  }

  handleDeleteConfirmClick() {
    const { site, handleTeamDelete } = this.props;
    const { userId } = this.state;

    this.setState({ open: false });
    if (userId) {
      this.setState({ handling: true });
      handleTeamDelete(site.id, userId)
        .then(() => {
          this.setState({ handling: false });
          this.handleBackClick();
        })
        .catch(() => this.setState({ handling: false }));
    }
  }

  handleEditClick() {
    const { site } = this.props;
    const { userId } = this.state;

    // Go to edit
    this.setState({ step: 2, user: site.contacts.find(item => item.userId === userId) });
  }

  handleSaveClick() {
    const { site, handleTeamUpdate } = this.props;
    const { user, handling } = this.state;

    if (!handling) {
      const updatedUser = {
        wedgeUser: user.userId,
        role: user.role,
        notifications: {
          smsEnabled: user.notifications.smsEnabled,
          alertingDeviceSubscriptions: user.notifications.alertingDeviceSubscriptions,
        },
      };

      this.setState({ handling: true });
      handleTeamUpdate(updatedUser, site.id, user.userId)
        .then(() => {
          // Go to list
          this.setState({ step: 0, handling: false });
        })
        .catch(() => this.setState({ handling: false }));
    }
  }

  handleTeamAddClick() {
    const { history, site } = this.props;

    history.push(`/site/${site.id}/invitations`);
  }

  render() {
    const {
      widthMobile, site, alerts, sideSheet, handleBackClick, handleTeamAddClick, handleClose,
    } = this.props;
    const {
      handling, open, step, userId, user,
    } = this.state;

    return (
      <>
        {sideSheet ? (
          <>
            {step === 0 && (
              <SideSheet title="Team" authSubject="team" handleClose={handleClose}>
                <TeamList
                  site={site}
                  handleListItemClick={this.handleListItemClick}
                  handleTeamAddClick={handleTeamAddClick}
                />
              </SideSheet>
            )}
            {step === 1 && (
              <SideSheet
                title="Contact details"
                authSubject="team"
                handleBackClick={this.handleBackClick}
                handleEditClick={this.handleEditClick}
                handleClose={handleClose}
              >
                <TeamDetails user={site.contacts.find(item => item.userId === userId)} />
              </SideSheet>
            )}
            {step === 2 && (
              <SideSheet
                title="Edit contact"
                authSubject="team"
                showSpinner={handling}
                handleBackClick={this.handleBackClick}
                handleSaveClick={this.handleSaveClick}
              >
                <TeamEdit user={user} handleDeleteClick={this.handleDeleteClick} handleChange={this.handleChange} />
              </SideSheet>
            )}
          </>
        ) : (
          <Spinner show={handling}>
            {step === 0 && (
              <>
                <AppBar title="Team" search alerts={alerts} handleBackClick={handleBackClick} />
                <TeamList
                  site={site}
                  handleListItemClick={this.handleListItemClick}
                  handleTeamAddClick={widthMobile ? this.handleTeamAddClick : handleTeamAddClick}
                />
              </>
            )}
            {step === 1 && (
              <>
                <AppBar
                  title="Contact details"
                  handleBackClick={this.handleBackClick}
                  handleEditClick={this.handleEditClick}
                />
                <TeamDetails user={site.contacts.find(item => item.userId === userId)} />
              </>
            )}
            {step === 2 && (
              <>
                <AppBar
                  title="Edit contact"
                  handleBackClick={this.handleBackClick}
                  handleSaveClick={this.handleSaveClick}
                />
                <TeamEdit user={user} handleDeleteClick={this.handleDeleteClick} handleChange={this.handleChange} />
              </>
            )}
          </Spinner>
        )}
        <ConfirmDialog
          open={open}
          title="Delete team member?"
          body="This action cannot be undone."
          confirm="Delete"
          cancel="Cancel"
          handleConfirmClick={this.handleDeleteConfirmClick}
          handleCancelClick={this.handleDeleteCancelClick}
        />
      </>
    );
  }
}

Team.propTypes = {
  history: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  site: PropTypes.object.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.object),
  sideSheet: PropTypes.bool,
  handleBackClick: PropTypes.func,
  handleTeamDelete: PropTypes.func.isRequired,
  handleTeamUpdate: PropTypes.func.isRequired,
  handleTeamAddClick: PropTypes.func,
  handleClose: PropTypes.func,
};

Team.defaultProps = {
  alerts: undefined,
  sideSheet: false,
  handleBackClick: undefined,
  handleTeamAddClick: undefined,
  handleClose: undefined,
};

export default compose(
  withRouter,
  withMobile(),
)(Team);
