import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import HumidityIcon from '../images/icons/Humidity';
import TemperatureIcon from '../images/icons/Temperature';
import getDate from '../utils/getDate';
import getUnit from '../utils/getUnit';
import Select from './Select';
import TextField from './TextField';

const styles = theme => ({
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    margin: theme.spacing.unit * 2,
  },
  caption: {
    lineHeight: '20px',
    letterSpacing: 0,
  },
  empty: {
    paddingTop: 53,
  },
  emptyLocations: {
    paddingTop: 13,
  },
  start: {
    flex: 1,
    paddingRight: theme.spacing.unit,
  },
  end: {
    flex: 1,
    paddingLeft: theme.spacing.unit,
  },
  gutters: theme.mixins.gutters(),
  checkboxIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkbox: {
    marginRight: theme.spacing.unit,
  },
  paddingRight: {
    paddingRight: theme.spacing.unit,
  },
  button: {
    marginTop: 20,
    marginBottom: 4,
    [theme.breakpoints.up('md')]: {
      marginBottom: 12,
    },
  },
  radio: {
    paddingRight: theme.spacing.unit * 2,
  },
  expanded: {
    '&:before': {
      opacity: 1,
    },
  },
  header: {
    boxSizing: 'border-box',
    lineHeight: '48px',
    listStyle: 'none',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
  },
  label: {
    '&$focused': {
      color: theme.palette.secondary.dark,
    },
  },
  focused: {},
  underline: {
    '&:after': {
      borderBottomColor: theme.palette.secondary.dark,
    },
  },
  yAxisTemperature: {
    marginTop: 12,
  },
  yAxisHumidity: {
    marginTop: theme.spacing.unit * 3,
  },
  yAxisTextField: {
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.down('sm')]: {
      width: 128,
      marginRight: theme.spacing.unit * 2,
    },
  },
});

class Reports extends React.Component {
  static types = [
    {
      value: 'summary',
      label: 'Summary report',
    },
    {
      value: 'full',
      label: 'Full report',
    },
  ];

  static format = [
    {
      value: 'excel',
      label: 'Excel',
    },
    {
      value: 'pdf',
      label: 'PDF',
    },
  ];

  static layout = [
    {
      value: 'letter',
      label: 'Letter',
    },
    {
      value: 'ledger',
      label: 'Ledger',
    },
  ];

  constructor(props) {
    super(props);
    const start = getDate(1);
    const end = getDate(0);

    this.state = {
      name: '',
      reportType: Reports.types[0].value,
      locations: [],
      sensorTypes: [],
      start,
      startError: false,
      end,
      endError: false,
      filterTemperature: true,
      filterHumidity: true,
      unchecked: new Set(),
      lineCharts: true,
      boxPlotCharts: true,
      tabularData: true,
      format: Reports.format[0].value,
      layout: Reports.layout[0].value,
      imperialUnits: false,
      yAxisTemperatureStart: '',
      yAxisTemperatureStartError: false,
      yAxisTemperatureEnd: '',
      yAxisTemperatureEndError: false,
      yAxisHumidityStart: '',
      yAxisHumidityStartError: false,
      yAxisHumidityEnd: '',
      yAxisHumidityEndError: false,
      open: false,
    };

    this.getSensors = this.getSensors.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleReportType = this.handleReportType.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
    this.handleBlurStart = this.handleBlurStart.bind(this);
    this.handleBlurEnd = this.handleBlurEnd.bind(this);
    this.handleFilterTemperature = this.handleFilterTemperature.bind(this);
    this.handleFilterHumidity = this.handleFilterHumidity.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleLineCharts = this.handleLineCharts.bind(this);
    this.handleBoxPlotCharts = this.handleBoxPlotCharts.bind(this);
    this.handleTabularData = this.handleTabularData.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleImperialUnits = this.handleImperialUnits.bind(this);
    this.handleYAxisTemperatureStart = this.handleYAxisTemperatureStart.bind(this);
    this.handleYAxisTemperatureEnd = this.handleYAxisTemperatureEnd.bind(this);
    this.handleBlurTemperatureStart = this.handleBlurTemperatureStart.bind(this);
    this.handleBlurTemperatureEnd = this.handleBlurTemperatureEnd.bind(this);
    this.handleYAxisHumidityStart = this.handleYAxisHumidityStart.bind(this);
    this.handleYAxisHumidityEnd = this.handleYAxisHumidityEnd.bind(this);
    this.handleBlurHumidityStart = this.handleBlurHumidityStart.bind(this);
    this.handleBlurHumidityEnd = this.handleBlurHumidityEnd.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.getSensors(start, end);
  }

  getSensors(start, end) {
    const { getSensors, siteId } = this.props;

    getSensors(siteId, start, end)
      .then((results) => {
        this.setState({
          locations: results.locations,
          sensorTypes: results.sensorTypes,
        });
      })
      .catch(() => {});
  }

  handleOnKeyPressTemperature = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode < 48 || keyCode > 57) {
      if (keyCode !== 45) {
        event.preventDefault();
      }
    }
  };

  handleOnKeyPressHumidity = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode < 48 || keyCode > 57) {
      event.preventDefault();
    }
  };

  handleOnKeyPressName = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 34 || keyCode === 47 || keyCode === 92) {
      event.preventDefault();
    }
  };

  handleName(event) {
    this.setState({ name: event.target.value });
  }

  handleReportType(event) {
    this.setState({ reportType: event.target.value });
  }

  handleStart(event) {
    const { end } = this.state;
    this.setState({ start: event.target.value });
    this.getSensors(event.target.value, end);
  }

  handleEnd(event) {
    const { start } = this.state;
    this.setState({ end: event.target.value });
    this.getSensors(start, event.target.value);
  }

  handleBlurStart() {
    const { start, end } = this.state;

    this.setState({ startError: start > end, endError: false });
  }

  handleBlurEnd() {
    const { start, end } = this.state;

    this.setState({ endError: start > end, startError: false });
  }

  handleFilterTemperature(event) {
    this.setState({ filterTemperature: event.target.checked });
  }

  handleFilterHumidity(event) {
    this.setState({ filterHumidity: event.target.checked });
  }

  handleChecked(item) {
    const { unchecked } = this.state;

    if (unchecked.has(item.name)) {
      unchecked.delete(item.name);
    } else {
      unchecked.add(item.name);
    }

    this.setState({ unchecked });
  }

  handleLineCharts() {
    const { lineCharts } = this.state;

    this.setState({ lineCharts: !lineCharts });
  }

  handleBoxPlotCharts() {
    const { boxPlotCharts } = this.state;

    this.setState({ boxPlotCharts: !boxPlotCharts });
  }

  handleTabularData() {
    const { tabularData } = this.state;

    this.setState({ tabularData: !tabularData });
  }

  handleRadioChange(event, param) {
    this.setState({ [param]: event.target.value });
  }

  handleImperialUnits() {
    const { imperialUnits } = this.state;

    this.setState({ imperialUnits: !imperialUnits });
  }

  handleYAxisTemperatureStart(event) {
    this.setState({ yAxisTemperatureStart: event.target.value });
  }

  handleYAxisTemperatureEnd(event) {
    this.setState({ yAxisTemperatureEnd: event.target.value });
  }

  handleBlurTemperatureStart() {
    const { yAxisTemperatureStart, yAxisTemperatureEnd } = this.state;

    const yAxisTemperatureStartError = parseInt(yAxisTemperatureStart, 10) > parseInt(yAxisTemperatureEnd, 10);
    this.setState({ yAxisTemperatureStartError, yAxisTemperatureEndError: false });
  }

  handleBlurTemperatureEnd() {
    const { yAxisTemperatureStart, yAxisTemperatureEnd } = this.state;

    const yAxisTemperatureEndError = parseInt(yAxisTemperatureStart, 10) > parseInt(yAxisTemperatureEnd, 10);
    this.setState({ yAxisTemperatureStartError: false, yAxisTemperatureEndError });
  }

  handleYAxisHumidityStart(event) {
    this.setState({ yAxisHumidityStart: event.target.value });
  }

  handleYAxisHumidityEnd(event) {
    this.setState({ yAxisHumidityEnd: event.target.value });
  }

  handleBlurHumidityStart() {
    // eslint-disable-next-line react/destructuring-assignment
    const yAxisHumidityStart = parseInt(this.state.yAxisHumidityStart, 10);

    if (Number.isNaN(yAxisHumidityStart)) {
      this.setState({ yAxisHumidityStart: '', yAxisHumidityStartError: false, yAxisHumidityEndError: false });
    } else if (yAxisHumidityStart > 100) {
      this.setState({
        yAxisHumidityStart: '100',
        yAxisHumidityStartError: false,
        yAxisHumidityEndError: false,
      });
    } else {
      const { yAxisHumidityEnd } = this.state;

      const yAxisHumidityStartError = yAxisHumidityStart > parseInt(yAxisHumidityEnd, 10);
      this.setState({ yAxisHumidityStartError, yAxisHumidityEndError: false });
    }
  }

  handleBlurHumidityEnd() {
    // eslint-disable-next-line react/destructuring-assignment
    const yAxisHumidityEnd = parseInt(this.state.yAxisHumidityEnd, 10);

    if (Number.isNaN(yAxisHumidityEnd)) {
      this.setState({ yAxisHumidityEnd: '', yAxisHumidityStartError: false, yAxisHumidityEndError: false });
    } else if (yAxisHumidityEnd > 100) {
      this.setState({
        yAxisHumidityEnd: '100',
        yAxisHumidityEndError: false,
        yAxisHumidityStartError: false,
      });
    } else {
      const { yAxisHumidityStart } = this.state;

      const yAxisHumidityEndError = parseInt(yAxisHumidityStart, 10) > yAxisHumidityEnd;
      this.setState({ yAxisHumidityStartError: false, yAxisHumidityEndError });
    }
  }

  handleButtonClick() {
    const { requestReport, siteId } = this.props;
    const {
      boxPlotCharts,
      unchecked,
      end,
      filterHumidity,
      filterTemperature,
      format,
      imperialUnits,
      layout,
      lineCharts,
      locations,
      name,
      reportType,
      start,
      tabularData,
      yAxisTemperatureStart,
      yAxisTemperatureEnd,
      yAxisHumidityStart,
      yAxisHumidityEnd,
    } = this.state;

    const request = {
      endDate: end,
      filename: name,
      fileFormat: format,
      locations: locations.filter(item => !unchecked.has(item.id)).reduce(
        (acc, value) => acc.concat({
          configId: value.id,
          sensors: value.sensors
            .filter(sensor => (sensor.type === 'H' && filterHumidity) || (sensor.type === 'T' && filterTemperature))
            .reduce((acc2, sensor) => acc2.concat(sensor.id), []),
        }),
        [],
      ),
      pageSize: layout,
      reportType,
      showBoxplot: boxPlotCharts,
      showLineGraph: lineCharts,
      showTables: tabularData,
      siteId,
      startDate: start,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      useFahrenheit: imperialUnits,
    };

    if (
      yAxisTemperatureStart !== ''
      || yAxisTemperatureEnd !== ''
      || yAxisHumidityStart !== ''
      || yAxisHumidityEnd !== ''
    ) {
      request.yAxis = {};
      if (yAxisTemperatureStart !== '' || yAxisTemperatureEnd !== '') {
        request.yAxis.T = {};
        if (yAxisTemperatureStart !== '') request.yAxis.T.low = Number(yAxisTemperatureStart);
        if (yAxisTemperatureEnd !== '') request.yAxis.T.high = Number(yAxisTemperatureEnd);
      }
      if (yAxisHumidityStart !== '' || yAxisHumidityEnd !== '') {
        request.yAxis.H = {};
        if (yAxisHumidityStart !== '') request.yAxis.H.low = Number(yAxisHumidityStart);
        if (yAxisHumidityEnd !== '') request.yAxis.H.high = Number(yAxisHumidityEnd);
      }
    }

    requestReport(request).catch(() => {});

    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const { classes, siteName } = this.props;
    const {
      name,
      reportType,
      locations,
      sensorTypes,
      start,
      startError,
      end,
      endError,
      filterTemperature,
      filterHumidity,
      unchecked,
      lineCharts,
      boxPlotCharts,
      tabularData,
      format,
      layout,
      imperialUnits,
      yAxisTemperatureStart,
      yAxisTemperatureStartError,
      yAxisTemperatureEnd,
      yAxisTemperatureEndError,
      yAxisHumidityStart,
      yAxisHumidityStartError,
      yAxisHumidityEnd,
      yAxisHumidityEndError,
      open,
    } = this.state;

    const hasTemperature = sensorTypes.some(item => item.id === 'T');
    const hasHumidity = sensorTypes.some(item => item.id === 'H');
    const filteredLocations = locations
      .reduce(
        (acc, item) => acc.concat({
          id: item.id,
          name: item.name,
          hasTemperature: item.sensors.some(sensor => sensor.type === 'T'),
          hasHumidity: item.sensors.some(sensor => sensor.type === 'H'),
        }),
        [],
      )
      .filter(item => (filterTemperature && item.hasTemperature) || (filterHumidity && item.hasHumidity));
    const checkedCount = filteredLocations.reduce((acc, item) => (unchecked.has(item.name) ? acc : acc + 1), 0);

    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <List>
            <ListItem>
              <ListItemText
                disableTypography
                // prettier-ignore
                primary={(
                  <>
                    <Typography className={classes.caption} variant="caption">
                      Site name
                    </Typography>
                    <Typography variant="subtitle1">{siteName}</Typography>
                  </>
                )}
              />
            </ListItem>
            <ListItem>
              <Select
                id="report-type"
                label="Report type"
                value={reportType}
                fullWidth
                onChange={this.handleReportType}
              >
                {Reports.types.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </ListItem>
            <ListItem>
              <div className={classes.start}>
                <FormControl fullWidth>
                  <InputLabel
                    FormLabelClasses={{
                      root: classes.label,
                      focused: classes.focused,
                    }}
                    htmlFor="report-start"
                    shrink
                  >
                    Start
                  </InputLabel>
                  <Input
                    classes={{
                      underline: classes.underline,
                    }}
                    id="report-start"
                    placeholder="off"
                    type="date"
                    value={start}
                    onChange={this.handleStart}
                    onBlur={this.handleBlurStart}
                  />
                  {startError && <FormHelperText error>Start date after end date</FormHelperText>}
                </FormControl>
              </div>
              <div className={classes.end}>
                <FormControl fullWidth>
                  <InputLabel
                    FormLabelClasses={{
                      root: classes.label,
                      focused: classes.focused,
                    }}
                    htmlFor="end"
                    shrink
                  >
                    End
                  </InputLabel>
                  <Input
                    classes={{
                      underline: classes.underline,
                    }}
                    id="report-end"
                    placeholder="off"
                    type="date"
                    value={end}
                    onChange={this.handleEnd}
                    onBlur={this.handleBlurEnd}
                  />
                  {endError && <FormHelperText error>End date before start date</FormHelperText>}
                </FormControl>
              </div>
            </ListItem>
          </List>
          {hasTemperature || hasHumidity ? (
            <>
              <Divider />
              <List dense>
                <ListSubheader disableSticky>Filters</ListSubheader>
                {hasTemperature && (
                  <ListItem>
                    <ListItemIcon>
                      <TemperatureIcon color="action" />
                    </ListItemIcon>
                    <ListItemText disableTypography primary={<Typography>Temperature</Typography>} />
                    <ListItemSecondaryAction>
                      <Switch checked={filterTemperature} onChange={this.handleFilterTemperature} />
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
                {hasHumidity && (
                  <ListItem>
                    <ListItemIcon>
                      <HumidityIcon color="action" />
                    </ListItemIcon>
                    <ListItemText disableTypography primary={<Typography>Humidity</Typography>} />
                    <ListItemSecondaryAction>
                      <Switch checked={filterHumidity} onChange={this.handleFilterHumidity} />
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
              </List>
              <Divider />
              <List>
                <ListSubheader disableSticky>Locations</ListSubheader>
                {filteredLocations.length === 0 ? (
                  <ListItem className={classes.emptyLocations}>
                    <Typography variant="subtitle1" color="textSecondary">
                      Adjust your filter settings to select locations to report.
                    </Typography>
                  </ListItem>
                ) : (
                  <FormGroup className={classes.gutters}>
                    {filteredLocations.map(item => (
                      <div className={classes.checkboxIcon} key={item.id}>
                        <FormControlLabel
                          // prettier-ignore
                          control={(
                            <Checkbox
                              className={classes.checkbox}
                              checked={!unchecked.has(item.name)}
                              onClick={() => this.handleChecked(item)}
                            />
                          )}
                          label={item.name}
                        />
                        <div>
                          {filterTemperature && item.hasTemperature && <TemperatureIcon color="action" />}
                          {filterHumidity && item.hasHumidity && <HumidityIcon color="action" />}
                        </div>
                      </div>
                    ))}
                  </FormGroup>
                )}
              </List>
              <Divider />
              <List>
                <ListSubheader disableSticky>Charts and data</ListSubheader>
                <FormGroup className={classes.gutters} row>
                  <FormControlLabel
                    className={classes.paddingRight}
                    control={<Checkbox checked={lineCharts} onClick={this.handleLineCharts} />}
                    label="Line charts"
                  />
                  <FormControlLabel
                    className={classes.paddingRight}
                    // prettier-ignore
                    control={(
                      <Checkbox
                        checked={reportType !== 'full' && boxPlotCharts}
                        disabled={reportType === 'full'}
                        onClick={this.handleBoxPlotCharts}
                      />
                    )}
                    label="Box plot charts"
                  />
                  <FormControlLabel
                    className={classes.paddingRight}
                    control={<Checkbox checked={tabularData} onClick={this.handleTabularData} />}
                    label="Tabular data"
                  />
                </FormGroup>
              </List>
              <Divider />
              <List>
                <ListSubheader disableSticky>Format</ListSubheader>
                <FormControl>
                  <RadioGroup
                    className={classes.gutters}
                    value={format}
                    row
                    onChange={event => this.handleRadioChange(event, 'format')}
                  >
                    {Reports.format.map(item => (
                      <FormControlLabel
                        className={classes.radio}
                        key={item.value}
                        value={item.value}
                        label={item.label}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </List>
              <Divider />
              <List>
                <ListSubheader disableSticky>Layout</ListSubheader>
                <FormControl>
                  <RadioGroup
                    className={classes.gutters}
                    value={layout}
                    row
                    onChange={event => this.handleRadioChange(event, 'layout')}
                  >
                    {Reports.layout.map(item => (
                      <FormControlLabel
                        className={classes.radio}
                        key={item.value}
                        value={item.value}
                        label={item.label}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </List>
              <ExpansionPanel classes={{ expanded: classes.expanded }} elevation={0}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.header}>Advanced settings</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        // prettier-ignore
                        control={(
                          <Checkbox
                            className={classes.checkbox}
                            checked={imperialUnits}
                            onClick={this.handleImperialUnits}
                          />
                        )}
                        label="Fahrenheit temperatures"
                      />
                    </FormGroup>
                    <Typography className={classNames(classes.header, classes.yAxisTemperature)}>
                      Y-axis start/end values for temperature charts
                    </Typography>
                    <FormGroup row>
                      <FormControl className={classes.yAxisTextField}>
                        <InputLabel
                          FormLabelClasses={{
                            root: classes.label,
                            focused: classes.focused,
                          }}
                          htmlFor="y-axis-temperature-start"
                        >
                          Start
                        </InputLabel>
                        <Input
                          classes={{
                            underline: classes.underline,
                          }}
                          id="y-axis-temperature-start"
                          placeholder="off"
                          type="number"
                          value={yAxisTemperatureStart}
                          onKeyPress={this.handleOnKeyPressTemperature}
                          onChange={this.handleYAxisTemperatureStart}
                          onBlur={this.handleBlurTemperatureStart}
                          // prettier-ignore
                          endAdornment={(
                            <InputAdornment position="end">
                              {getUnit('T', imperialUnits === false)}
                            </InputAdornment>
                          )}
                        />
                        {yAxisTemperatureStartError && <FormHelperText error>Low exceeds high</FormHelperText>}
                      </FormControl>
                      <FormControl className={classes.yAxisTextField}>
                        <InputLabel
                          FormLabelClasses={{
                            root: classes.label,
                            focused: classes.focused,
                          }}
                          htmlFor="y-axis-temperature-end"
                        >
                          End
                        </InputLabel>
                        <Input
                          classes={{
                            underline: classes.underline,
                          }}
                          id="y-axis-temperature-end"
                          placeholder="off"
                          type="number"
                          value={yAxisTemperatureEnd}
                          onKeyPress={this.handleOnKeyPressTemperature}
                          onChange={this.handleYAxisTemperatureEnd}
                          onBlur={this.handleBlurTemperatureEnd}
                          // prettier-ignore
                          endAdornment={(
                            <InputAdornment position="end">
                              {getUnit('T', imperialUnits === false)}
                            </InputAdornment>
                          )}
                        />
                        {yAxisTemperatureEndError && <FormHelperText error>High less than low</FormHelperText>}
                      </FormControl>
                    </FormGroup>
                    <Typography className={classNames(classes.header, classes.yAxisHumidity)}>
                      Y-axis start/end values for humidity charts
                    </Typography>
                    <FormGroup row>
                      <FormControl className={classes.yAxisTextField}>
                        <InputLabel
                          FormLabelClasses={{
                            root: classes.label,
                            focused: classes.focused,
                          }}
                          htmlFor="y-axis-humidity-start"
                        >
                          Start
                        </InputLabel>
                        <Input
                          classes={{
                            underline: classes.underline,
                          }}
                          id="y-axis-humidity-start"
                          placeholder="off"
                          type="number"
                          value={yAxisHumidityStart}
                          onKeyPress={this.handleOnKeyPressHumidity}
                          onChange={this.handleYAxisHumidityStart}
                          onBlur={this.handleBlurHumidityStart}
                          endAdornment={<InputAdornment position="end">{getUnit('H')}</InputAdornment>}
                        />
                        {yAxisHumidityStartError && <FormHelperText error>Low exceeds high</FormHelperText>}
                      </FormControl>
                      <FormControl className={classes.yAxisTextField}>
                        <InputLabel
                          FormLabelClasses={{
                            root: classes.label,
                            focused: classes.focused,
                          }}
                          htmlFor="y-axis-humidity-end"
                        >
                          End
                        </InputLabel>
                        <Input
                          classes={{
                            underline: classes.underline,
                          }}
                          id="y-axis-humidity-end"
                          placeholder="off"
                          type="number"
                          value={yAxisHumidityEnd}
                          onKeyPress={this.handleOnKeyPressHumidity}
                          onChange={this.handleYAxisHumidityEnd}
                          onBlur={this.handleBlurHumidityEnd}
                          endAdornment={<InputAdornment position="end">{getUnit('H')}</InputAdornment>}
                        />
                        {yAxisHumidityEndError && <FormHelperText error>High less than low</FormHelperText>}
                      </FormControl>
                    </FormGroup>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <Divider />
              <List>
                <ListItem>
                  <TextField
                    id="report-name"
                    label="Report name"
                    placeholder={`e.g. ${siteName} ${getDate(1)} to ${getDate(0)}`}
                    value={name}
                    fullWidth
                    onKeyPress={this.handleOnKeyPressName}
                    onChange={this.handleName}
                  />
                </ListItem>
                <ListItem className={classes.button}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={this.handleButtonClick}
                    disabled={
                      name.length === 0
                      || checkedCount === 0
                      || startError
                      || endError
                      || yAxisTemperatureStartError
                      || yAxisTemperatureEndError
                      || yAxisHumidityStartError
                      || yAxisHumidityEndError
                    }
                  >
                    Generate Report
                  </Button>
                  <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={open}
                    onClose={this.handleClose}
                    // prettier-ignore
                    message={(
                      <span id="message-id">
                        Your report will be emailed to you. It normally arrives within minutes, but
                        may take up to 20 minutes.
                      </span>
                    )}
                  />
                </ListItem>
              </List>
            </>
          ) : (
            <ListItem className={classes.empty}>
              <Typography variant="subtitle1" color="textSecondary">
                There were no temperature or humidity sensors installed during that time.
              </Typography>
            </ListItem>
          )}
        </div>
      </div>
    );
  }
}

Reports.propTypes = {
  classes: PropTypes.object.isRequired,
  siteId: PropTypes.string.isRequired,
  siteName: PropTypes.string.isRequired,
  getSensors: PropTypes.func.isRequired,
  requestReport: PropTypes.func.isRequired,
};

export default withStyles(styles)(Reports);
