import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  root: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.tertiary.light,
    border: 'solid',
    borderColor: theme.palette.grey[600],
    borderWidth: 1,
    borderRadius: '50%',
  },
});

function CalloutDot(props) {
  const { classes, className } = props;

  return <div className={classNames(classes.root, className)} />;
}

CalloutDot.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

CalloutDot.defaultProps = {
  className: '',
};

export default withStyles(styles)(CalloutDot);
