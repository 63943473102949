import MuiCircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  circularProgress: {
    animation: 'mui-progress-circular-rotate 2.0s linear infinite',
  },
};

function CircularProgress(props) {
  const {
    classes, theme, className: classNameProp, color, size, thickness,
  } = props;

  return (
    <Fade
      in
      style={{
        transitionDelay: `${1000 - theme.transitions.duration.enteringScreen}ms`,
      }}
      unmountOnExit
    >
      <MuiCircularProgress
        className={classNames(classes.cirularProgress, classNameProp)}
        color={color}
        size={size}
        thickness={thickness}
      />
    </Fade>
  );
}

CircularProgress.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  size: PropTypes.number,
  thickness: PropTypes.number,
};

CircularProgress.defaultProps = {
  className: '',
  color: 'secondary',
  size: 40,
  thickness: 3.6,
};

export default withStyles(styles, { withTheme: true })(CircularProgress);
