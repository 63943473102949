import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';
import { mixinHeightWithToolbar } from '../constants/theme';
import {
  emptyMobile, formatMobile, mobileMask, removeMobileFormat, validMobile,
} from '../utils/formatMobile';
import { passwordHelper, validPassword } from '../utils/passwords';
import TextField from './TextField';

const styles = theme => ({
  content: {
    width: '100%',
    overflowY: 'auto',
    ...mixinHeightWithToolbar(48),
  },
  caption: {
    lineHeight: '20px',
    letterSpacing: 0,
  },
  underline: {
    '&:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  list: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  button: {
    textTransform: 'none',
  },
  buttonText: {
    minWidth: 0,
    padding: 0,
    fontSize: 12,
    letterSpacing: 0.4,
    textTransform: 'none',
    color: theme.palette.secondary.main,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
  },
  buttonEdit: {
    marginLeft: 12,
  },
  buttonSave: {
    marginLeft: 20,
  },
  buttonCancel: {
    marginLeft: 16,
  },
  buttonPassword: {
    marginTop: 21,
  },
});

function TextMask(props) {
  const { inputRef, ...other } = props;

  return <MaskedInput {...other} ref={inputRef} mask={mobileMask} placeholderChar={'\u2000'} showMask />;
}

TextMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class ProfileList extends React.Component {
  constructor(props) {
    super(props);

    const { user } = props;

    this.state = {
      name: user ? user.name : '',
      nameEdit: false,
      nameError: null,
      company: user && user.company ? user.company : '',
      companyEdit: false,
      mobile: user && emptyMobile(user.mobile) === false ? user.mobile : '',
      mobileEdit: false,
      mobileError: null,
      password: '',
      passwordError: null,
      passwordShow: false,
      passwordAgain: '',
      passwordAgainError: null,
      passwordAgainShow: false,
      passwordCurrent: '',
      passwordCurrentError: null,
      passwordCurrentShow: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleMobileChange = this.handleMobileChange.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handlePasswordSaveClick = this.handlePasswordSaveClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handlePasswordShowClick = this.handlePasswordShowClick.bind(this);
    this.handlePasswordAgainShowClick = this.handlePasswordAgainShowClick.bind(this);
    this.handlePasswordCurrentShowClick = this.handlePasswordCurrentShowClick.bind(this);
  }

  handleChange(event, param) {
    this.setState({ [param]: event.target.value ? event.target.value : '', [`${param}Error`]: null });
  }

  handleMobileChange(event) {
    if (emptyMobile(event.target.value)) {
      this.setState({ mobile: '', mobileError: null });
    } else {
      this.setState({ mobile: removeMobileFormat(event.target.value), mobileError: null });
    }
  }

  handleEditClick(param) {
    return () => {
      this.setState({ [`${param}Edit`]: true, [`${param}Error`]: null });
    };
  }

  handleSaveClick(param) {
    return () => {
      const { user, handleSaveClick: handleSaveClickProp } = this.props;
      const { name, company, mobile } = this.state;

      if (param === 'name' && !name) {
        this.setState({ nameError: 'Enter full name' });
        return;
      }

      if (param === 'mobile' && emptyMobile(mobile) === false && validMobile(mobile) === false) {
        this.setState({ mobileError: 'Invalid number' });
        return;
      }

      handleSaveClickProp(
        {
          id: user.id,
          email: user.email,
          role: user.role,
          name: param === 'name' ? name : user.name,
          company: param === 'company' ? company : user.company,
          mobile: param === 'mobile' ? mobile : user.mobile,
        },
        user.id,
      )
        .then(() => {})
        .catch(() => {});

      this.setState({ [`${param}Edit`]: false, [`${param}Error`]: null });
    };
  }

  handlePasswordSaveClick() {
    const { handleSavePassword: handleSavePasswordProp, handleClose } = this.props;
    const { passwordCurrent, password, passwordAgain } = this.state;

    if (!passwordCurrent) {
      this.setState({ passwordCurrentError: 'Enter current password' });
    }

    if (!password) {
      this.setState({ passwordError: 'Enter a password' });
      return;
    }

    if (!passwordCurrent) {
      return;
    }

    // Check new password is valid
    const passwordError = validPassword(password);
    if (passwordError !== null) {
      this.setState({ passwordError });
      return;
    }

    // Check new password different than current
    if (password === passwordCurrent) {
      this.setState({
        passwordError: 'New password must be different than current password',
      });
      return;
    }

    // Check new passwords match
    if (password !== passwordAgain) {
      this.setState({ passwordAgainError: 'Passwords do not match' });
      return;
    }

    handleSavePasswordProp(password, passwordCurrent)
      .then(() => {
        this.setState({
          password: '',
          passwordError: null,
          passwordShow: false,
          passwordAgain: '',
          passwordAgainError: null,
          passwordAgainShow: false,
          passwordCurrent: '',
          passwordCurrentError: null,
          passwordCurrentShow: false,
        });
        handleClose();
      })
      .catch(() => {
        this.setState({
          passwordAgainError: 'Unable to change password, check current password is correct',
        });
      });
  }

  handleCancelClick(param) {
    const { user } = this.props;

    return () => {
      this.setState({ [param]: user[param], [`${param}Edit`]: false, [`${param}Error`]: null });
    };
  }

  handlePasswordShowClick() {
    const { passwordShow } = this.state;

    this.setState({ passwordShow: !passwordShow });
  }

  handlePasswordAgainShowClick() {
    const { passwordAgainShow } = this.state;

    this.setState({ passwordAgainShow: !passwordAgainShow });
  }

  handlePasswordCurrentShowClick() {
    const { passwordCurrentShow } = this.state;

    this.setState({ passwordCurrentShow: !passwordCurrentShow });
  }

  render() {
    const { classes, user, step } = this.props;
    const {
      name,
      nameEdit,
      nameError,
      company,
      companyEdit,
      mobile,
      mobileEdit,
      mobileError,
      password,
      passwordError,
      passwordShow,
      passwordAgain,
      passwordAgainError,
      passwordAgainShow,
      passwordCurrent,
      passwordCurrentError,
      passwordCurrentShow,
    } = this.state;

    return (
      <div className={classes.content}>
        {step === 1 && (
          <List className={classes.list}>
            <ListItem>
              <ListItemText
                disableTypography
                primary={
                  <>
                    <Typography className={classes.caption} variant="caption" color="textSecondary">
                      Email
                    </Typography>
                    <Typography variant="subtitle1">{user.email}</Typography>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                disableTypography
                primary={
                  <>
                    <Typography className={classes.caption} variant="caption" color="textSecondary">
                      Full name
                    </Typography>
                    {nameEdit === false ? (
                      <Typography variant="subtitle1">
                        {name}
                        <span>
                          <Button
                            className={classNames(classes.buttonText, { [classes.buttonEdit]: name })}
                            onClick={this.handleEditClick('name')}
                          >
                            Edit
                          </Button>
                        </span>
                      </Typography>
                    ) : (
                      <>
                        <Input
                          classes={{
                            underline: classes.underline,
                          }}
                          id="profile-name"
                          value={name}
                          autoFocus
                          onChange={event => this.handleChange(event, 'name')}
                        />
                        <span>
                          <Button
                            className={classNames(classes.buttonText, classes.buttonSave)}
                            onClick={this.handleSaveClick('name')}
                          >
                            Save
                          </Button>
                          <Button
                            className={classNames(classes.buttonText, classes.buttonCancel)}
                            onClick={this.handleCancelClick('name')}
                          >
                            Cancel
                          </Button>
                        </span>
                        {nameError && <FormHelperText error>{nameError}</FormHelperText>}
                      </>
                    )}
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                disableTypography
                primary={
                  <>
                    <Typography className={classes.caption} variant="caption" color="textSecondary">
                      Company (optional)
                    </Typography>
                    {companyEdit === false ? (
                      <Typography variant="subtitle1">
                        {company}
                        <span>
                          <Button
                            className={classNames(classes.buttonText, { [classes.buttonEdit]: company })}
                            onClick={this.handleEditClick('company')}
                          >
                            Edit
                          </Button>
                        </span>
                      </Typography>
                    ) : (
                      <>
                        <Input
                          classes={{
                            underline: classes.underline,
                          }}
                          id="profile-company"
                          value={company}
                          autoFocus
                          onChange={event => this.handleChange(event, 'company')}
                        />
                        <span>
                          <Button
                            className={classNames(classes.buttonText, classes.buttonSave)}
                            onClick={this.handleSaveClick('company')}
                          >
                            Save
                          </Button>
                          <Button
                            className={classNames(classes.buttonText, classes.buttonCancel)}
                            onClick={this.handleCancelClick('company')}
                          >
                            Cancel
                          </Button>
                        </span>
                      </>
                    )}
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                disableTypography
                primary={
                  <>
                    <Typography className={classes.caption} variant="caption" color="textSecondary">
                      Mobile (optional)
                    </Typography>
                    {mobileEdit === false ? (
                      <Typography variant="subtitle1">
                        {formatMobile(mobile)}
                        <span>
                          <Button
                            className={classNames(classes.buttonText, { [classes.buttonEdit]: mobile })}
                            onClick={this.handleEditClick('mobile')}
                          >
                            Edit
                          </Button>
                        </span>
                      </Typography>
                    ) : (
                      <>
                        <Input
                          classes={{
                            underline: classes.underline,
                          }}
                          id="profile-mobile"
                          type="tel"
                          value={formatMobile(mobile)}
                          autoFocus
                          inputComponent={TextMask}
                          onChange={event => this.handleMobileChange(event)}
                        />
                        <span>
                          <Button
                            className={classNames(classes.buttonText, classes.buttonSave)}
                            onClick={this.handleSaveClick('mobile')}
                          >
                            Save
                          </Button>
                          <Button
                            className={classNames(classes.buttonText, classes.buttonCancel)}
                            onClick={this.handleCancelClick('mobile')}
                          >
                            Cancel
                          </Button>
                        </span>
                        {mobileError && <FormHelperText error>{mobileError}</FormHelperText>}
                      </>
                    )}
                  </>
                }
              />
            </ListItem>
          </List>
        )}
        {step === 2 && (
          <List>
            <ListItem>
              <TextField
                id="profile-password-current"
                type={passwordCurrentShow ? 'text' : 'password'}
                label="Current password"
                value={passwordCurrent}
                margin="normal"
                fullWidth
                autoFocus
                spellCheck={false}
                error={passwordCurrentError !== null}
                helperText={passwordCurrentError}
                onChange={event => this.handleChange(event, 'passwordCurrent')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment variant="filled" position="end">
                      <IconButton onClick={this.handlePasswordCurrentShowClick}>
                        {passwordCurrentShow ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </ListItem>
            <ListItem>
              <TextField
                id="profile-password"
                type={passwordShow ? 'text' : 'password'}
                label="Password"
                value={password}
                margin="normal"
                fullWidth
                autoFocus
                spellCheck={false}
                error={passwordError !== null}
                helperText={passwordError || passwordHelper}
                onChange={event => this.handleChange(event, 'password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment variant="filled" position="end">
                      <IconButton onClick={this.handlePasswordShowClick}>
                        {passwordShow ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </ListItem>
            <ListItem>
              <TextField
                id="profile-password-again"
                type={passwordAgainShow ? 'text' : 'password'}
                label="Confirm password"
                value={passwordAgain}
                margin="normal"
                fullWidth
                autoFocus
                spellCheck={false}
                error={passwordAgainError !== null}
                helperText={passwordAgainError}
                onChange={event => this.handleChange(event, 'passwordAgain')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment variant="filled" position="end">
                      <IconButton onClick={this.handlePasswordAgainShowClick}>
                        {passwordAgainShow ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </ListItem>
            <ListItem>
              <Button
                className={classNames(classes.button, classes.buttonPassword)}
                variant="contained"
                color="secondary"
                onClick={this.handlePasswordSaveClick}
              >
                Change password
              </Button>
            </ListItem>
          </List>
        )}
      </div>
    );
  }
}

ProfileList.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  step: PropTypes.number.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  handleSavePassword: PropTypes.func,
  handleClose: PropTypes.func,
};

ProfileList.defaultProps = {
  user: undefined,
  handleSavePassword: undefined,
  handleClose: undefined,
};

export default withStyles(styles)(ProfileList);
