import PropTypes from 'prop-types';
import React from 'react';
import Error from '../components/Error';
import FloorplansContainer from '../containers/Floorplans';
import FloorplansEditor from '../containers/FloorplansEditor';
import { apiGetFloorplan, apiUpdateRecentSite } from '../utils/api';
import { AbilityContext } from '../utils/auth';
import has from '../utils/has';

const queryString = require('query-string');

const Floorplans = (props) => {
  const { match, location, siteId } = props;
  const values = queryString.parse(location.search);
  const floorplanId = match.params.id;

  apiUpdateRecentSite(siteId, floorplanId);

  if (has.call(values, 'edit') && values.edit === 'true') {
    const floorplan = apiGetFloorplan(floorplanId, siteId);
    const key = `${floorplanId}${floorplan && floorplan.id ? 'defined' : 'undefined'}`;
    return (
      <AbilityContext.Consumer>
        {ability => (ability.can('modify', 'floorplans') ? (
          <FloorplansEditor siteId={siteId} floorplanId={floorplanId} key={key} />
        ) : (
          <Error variant="forbidden" />
        ))
        }
      </AbilityContext.Consumer>
    );
  }

  return <FloorplansContainer siteId={siteId} floorplanId={floorplanId} />;
};

Floorplans.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  siteId: PropTypes.string.isRequired,
};

export default Floorplans;
