import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

class ListItemLinkIcon extends React.Component {
  renderLink = (itemProps) => {
    const { to } = this.props;

    return <Link to={to} {...itemProps} />;
  };

  render() {
    const {
      icon, count, primary, disableTouchRipple, classNameItem, classNameCount, ...other
    } = this.props;

    return (
      <ListItem
        className={classNames({ [classNameItem]: classNameItem !== undefined })}
        button
        component={this.renderLink}
        disableTouchRipple={disableTouchRipple}
      >
        <ListItemIcon {...other}>{icon}</ListItemIcon>
        <ListItemText
          disableTypography
          // prettier-ignore
          primary={(
            <Typography component="span" variant="body2" {...other}>
              {primary}
              {count !== undefined && (
              <span className={classNames({ [classNameCount]: classNameCount !== undefined })}>
                {count}
              </span>
              )}
            </Typography>
          )}
        />
      </ListItem>
    );
  }
}

ListItemLinkIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  count: PropTypes.number,
  primary: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  disableTouchRipple: PropTypes.bool,
  classNameItem: PropTypes.string,
  classNameCount: PropTypes.string,
};

ListItemLinkIcon.defaultProps = {
  count: undefined,
  disableTouchRipple: false,
  classNameItem: undefined,
  classNameCount: undefined,
};

export default ListItemLinkIcon;
