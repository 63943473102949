import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';
import {
  emptyMobile, formatMobile, mobileMask, removeMobileFormat, validMobile,
} from '../utils/formatMobile';
import { passwordHelper, validPassword } from '../utils/passwords';
import TextField from './TextField';

const styles = theme => ({
  content: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  list: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  caption: {
    lineHeight: '20px',
    letterSpacing: 0,
  },
  buttonSignUp: {
    textTransform: 'none',
    marginTop: 21,
  },
});

function TextMask(props) {
  const { inputRef, ...other } = props;

  return <MaskedInput {...other} ref={inputRef} mask={mobileMask} placeholderChar={'\u2000'} showMask />;
}

TextMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class InviteList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      nameError: null,
      company: '',
      mobile: '',
      mobileFocus: false,
      mobileError: null,
      password: '',
      passwordError: null,
      passwordShow: false,
      passwordAgain: '',
      passwordAgainShow: false,
      passwordGeneralError: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleMobileBlur = this.handleMobileBlur.bind(this);
    this.handleMobileChange = this.handleMobileChange.bind(this);
    this.handleMobileFocus = this.handleMobileFocus.bind(this);
    this.handlePasswordAgainChange = this.handlePasswordAgainChange.bind(this);
    this.handlePasswordAgainShowClick = this.handlePasswordAgainShowClick.bind(this);
    this.handlePasswordShowClick = this.handlePasswordShowClick.bind(this);
    this.handleSignUpClick = this.handleSignUpClick.bind(this);
  }

  handleChange(event, param) {
    this.setState({ [param]: event.target.value, [`${param}Error`]: null });
  }

  handleCompanyChange(event) {
    this.setState({ company: event.target.value });
  }

  handleMobileBlur() {
    this.setState({ mobileFocus: false });
  }

  handleMobileChange(event) {
    if (emptyMobile(event.target.value)) {
      this.setState({ mobile: '', mobileError: null });
    } else {
      this.setState({ mobile: removeMobileFormat(event.target.value), mobileError: null });
    }
  }

  handleMobileFocus() {
    this.setState({ mobileFocus: true });
  }

  handlePasswordAgainChange(event) {
    this.setState({ passwordAgain: event.target.value });
  }

  handlePasswordAgainShowClick() {
    const { passwordAgainShow } = this.state;

    this.setState({ passwordAgainShow: !passwordAgainShow });
  }

  handlePasswordShowClick() {
    const { passwordShow } = this.state;

    this.setState({ passwordShow: !passwordShow });
  }

  handleSignUpClick() {
    const { handleSignUpClick: handleSignUpClickProp } = this.props;
    const {
      name, company, mobile, password, passwordAgain,
    } = this.state;

    let nameError = null;
    let mobileError = null;
    let passwordError = null;
    let passwordGeneralError = null;

    if (!name) {
      nameError = 'Enter full name';
    }

    if (!emptyMobile(mobile) && !validMobile(mobile)) {
      mobileError = 'Invalid number';
    }

    if (!password) {
      passwordError = 'Enter a password';
    }

    // Check password is valid
    if (!passwordError) {
      passwordError = validPassword(password);
    }

    // Check passwords match
    if (!passwordError && password !== passwordAgain) {
      passwordGeneralError = 'Passwords do not match';
    }

    this.setState({
      nameError,
      mobileError,
      passwordError,
      passwordGeneralError,
    });

    if (nameError || mobileError || passwordError || passwordGeneralError) {
      return;
    }

    handleSignUpClickProp(name, company, mobile, password);
  }

  render() {
    const { classes, email } = this.props;
    const {
      name,
      nameError,
      company,
      mobile,
      mobileFocus,
      mobileError,
      password,
      passwordError,
      passwordShow,
      passwordAgain,
      passwordAgainShow,
      passwordGeneralError,
    } = this.state;

    const mobileInputProps = mobileFocus || !emptyMobile(mobile) ? { inputComponent: TextMask, autoFocus: true } : {};

    return (
      <div className={classes.content}>
        <List className={classes.list}>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <>
                  <Typography className={classes.caption} variant="caption" color="textSecondary">
                    Email
                  </Typography>
                  <Typography variant="subtitle1" noWrap>
                    {email}
                  </Typography>
                </>
              }
            />
          </ListItem>
          <ListItem>
            <TextField
              id="invite-name"
              label="Full name"
              value={name}
              margin="normal"
              fullWidth
              autoFocus
              error={nameError !== null}
              helperText={nameError}
              onChange={event => this.handleChange(event, 'name')}
            />
          </ListItem>
          <ListItem>
            <TextField
              id="invite-company"
              label="Company (optional)"
              value={company}
              margin="normal"
              fullWidth
              autoFocus
              onChange={event => this.handleCompanyChange(event)}
            />
          </ListItem>
          <ListItem>
            <TextField
              id="invite-mobile"
              type="tel"
              label="Mobile (optional)"
              value={mobileFocus || !emptyMobile(mobile) ? formatMobile(mobile) : ''}
              margin="normal"
              fullWidth
              autoFocus
              InputProps={mobileInputProps}
              error={mobileError !== null}
              helperText={mobileError}
              onBlur={this.handleMobileBlur}
              onChange={event => this.handleMobileChange(event)}
              onFocus={this.handleMobileFocus}
            />
          </ListItem>
          <ListItem>
            <TextField
              id="invite-password"
              type={passwordShow ? 'text' : 'password'}
              label="Password"
              value={password}
              margin="normal"
              fullWidth
              autoFocus
              spellCheck={false}
              error={passwordError !== null}
              helperText={passwordError || passwordHelper}
              onChange={event => this.handleChange(event, 'password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment variant="filled" position="end">
                    <IconButton onClick={this.handlePasswordShowClick}>
                      {passwordShow ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>
          <ListItem>
            <TextField
              id="invite-password-again"
              type={passwordAgainShow ? 'text' : 'password'}
              label="Confirm password"
              value={passwordAgain}
              margin="normal"
              fullWidth
              autoFocus
              spellCheck={false}
              error={passwordGeneralError !== null}
              helperText={passwordGeneralError}
              onChange={event => this.handlePasswordAgainChange(event)}
              InputProps={{
                endAdornment: (
                  <InputAdornment variant="filled" position="end">
                    <IconButton onClick={this.handlePasswordAgainShowClick}>
                      {passwordAgainShow ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>
          <ListItem>
            <Button
              className={classes.buttonSignUp}
              variant="contained"
              color="secondary"
              onClick={this.handleSignUpClick}
            >
              Sign up
            </Button>
          </ListItem>
        </List>
      </div>
    );
  }
}

InviteList.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  handleSignUpClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(InviteList);
