import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import AppBar from '../components/AppBar';
import AppBarLogo from '../components/AppBarLogo';
import ForgotComponent from '../components/Forgot';
import Spinner from '../components/Spinner';
import withMobile from '../components/withMobile';
import { mixinHeightWithToolbar } from '../constants/theme';
import { apiForgotPassword } from '../utils/api';
import has from '../utils/has';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: theme.floorplan.width,
    margin: `${theme.spacing.unit * 3}px 0px`,
    ...mixinHeightWithToolbar(theme.spacing.unit * 6),
  },
  contentContainer: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    boxShadow: '0 2px 2px -1px rgba(153, 153, 153, 0.3), 0 1px 5px -2px rgba(153, 153, 153, 0.3)',
    boxSizing: 'border-box',
    padding: 1,
  },
  componentContainer: {
    flexGrow: 1,
    height: '100%',
  },
  componentAppBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: theme.appBar.height,
    ...theme.mixins.gutters(),
  },
  divider: {
    marginRight: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 3,
  },
  component: {
    width: '100%',
    ...mixinHeightWithToolbar(),
  },
});

class Forgot extends React.Component {
  constructor(props) {
    super(props);

    this.state = { handling: false };

    this.handleForgotPassword = this.handleForgotPassword.bind(this);
  }

  handleForgotPassword(email) {
    const { handling } = this.state;

    if (handling) return Promise.reject();

    this.setState({ handling: true });

    return apiForgotPassword(email)
      .then(() => {
        this.setState({ handling: false });
        return Promise.resolve();
      })
      .catch(() => {
        this.setState({ handling: false });
        return Promise.reject();
      });
  }

  render() {
    const { classes, widthMobile, location } = this.props;
    const { handling } = this.state;

    const { state } = location;
    const email = state && has.call(state, 'email') ? state.email : '';

    return (
      <>
        {!widthMobile ? (
          <>
            <AppBarLogo />
            <div className={classes.root}>
              <div className={classes.container}>
                <div className={classes.contentContainer}>
                  <Spinner className={classes.componentContainer} show={handling}>
                    <div className={classes.componentAppBar}>
                      <Typography variant="h6">Forgot password</Typography>
                    </div>
                    <Divider className={classes.divider} />
                    <div className={classes.component}>
                      <ForgotComponent email={email} handleForgotPassword={this.handleForgotPassword} />
                    </div>
                  </Spinner>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Spinner show={handling}>
            <AppBar title="Forgot password" />
            <div className={classes.component}>
              <ForgotComponent email={email} handleForgotPassword={this.handleForgotPassword} />
            </div>
          </Spinner>
        )}
      </>
    );
  }
}

Forgot.propTypes = {
  classes: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withMobile(),
)(Forgot);
