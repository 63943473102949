import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  container: {
    position: 'relative',
    minWidth: 15,
  },
  crossout: {
    position: 'absolute',
    top: theme.spacing.unit * -1,
    left: theme.spacing.unit * -1,
    margin: theme.spacing.unit,
    width: '100%',
    height: '100%',
  },
});

function Crossout(props) {
  const {
    classes, theme, strokeWidth, children,
  } = props;

  return (
    <div className={classes.container}>
      <svg className={classes.crossout} width="75" height="25" viewBox="0 0 75 25">
        <line x1="0" y1="25" x2="75" y2="0" strokeWidth={strokeWidth} stroke={theme.palette.error.main} />
      </svg>
      {children}
    </div>
  );
}

Crossout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles, { withTheme: true })(Crossout);
