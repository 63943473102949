import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  badge: {
    top: 2,
    right: -14,
    width: 10,
    height: 10,
  },
};

function TinyBadge(props) {
  const { classes, children, alert } = props;

  return alert ? (
    <>
      <Badge classes={{ badge: classes.badge }} badgeContent="" color="error">
        {children}
      </Badge>
    </>
  ) : (
    children
  );
}

TinyBadge.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  alert: PropTypes.bool,
};

TinyBadge.defaultProps = {
  alert: true,
};

export default withStyles(styles)(TinyBadge);
