import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import calcMenuWidth from '../utils/calcMenuWidth';
import TinyBadge from './TinyBadge';

const styles = theme => ({
  selected: {
    color: theme.palette.secondary.main,
  },
  popper: {
    zIndex: theme.zIndex.appBar + 1,
  },
  paper: {
    width: '100%',
    border: '1px solid #d9d9d9',
    borderBottomColor: 'rgba(153, 153, 153, 0.6)',
    boxShadow:
      '0 6px 12px -1px rgba(153, 153, 153, 0.2), 0 4px 4px -1px rgba(153, 153, 153, 0.2), 0 2px 2px 0 rgba(153, 153, 153, 0.2)',
  },
  label: {
    maxWidth: 200,
  },
  labelMargin: {
    marginTop: 4,
  },
  button: {
    textTransform: 'none',
    marginLeft: -16,
    marginTop: 4,
  },
  arrowIcon: {
    marginLeft: 4,
    transition: 'all 150ms ease-in',
  },
  arrowIconActive: {
    transform: 'rotate(180deg)',
  },
});

class FloorplanMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      anchorEl: null,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleFloorplanMenuClick = this.handleFloorplanMenuClick.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleClick(event) {
    const { open } = this.state;

    const { currentTarget } = event;

    if (!open) {
      this.setState({ open: true, anchorEl: currentTarget });
    } else {
      this.setState({ open: false, anchorEl: null });
    }
  }

  handleClose() {
    const { open } = this.state;

    if (!open) {
      return;
    }

    // setTimeout to ensure a close event comes after a target click event
    this.timeout = setTimeout(() => {
      this.setState({ open: false, anchorEl: null });
    });
  }

  handleFloorplanMenuClick(item) {
    const { handleFloorplanMenuClick: handleFloorplanMenuClickProp } = this.props;

    handleFloorplanMenuClickProp(item);
    this.handleClose();
  }

  render() {
    const {
      classes, width, siteSummary, floorplans, floorplan,
    } = this.props;
    const { open, anchorEl } = this.state;

    return (floorplans.length === 1 && floorplan && floorplan.active) || floorplans.length === 0 ? (
      <Typography className={classNames(classes.label, classes.labelMargin)} variant="subtitle1" noWrap>
        {floorplan && floorplan.name}
      </Typography>
    ) : (
      <>
        <Button className={classes.button} onClick={this.handleClick}>
          <Typography className={classes.label} variant="subtitle1" noWrap>
            {floorplan && floorplan.name}
          </Typography>
          <ArrowDropDownIcon className={classNames(classes.arrowIcon, { [classes.arrowIconActive]: open })} />
        </Button>
        <Popper className={classes.popper} open={open} anchorEl={anchorEl} placement="bottom-start">
          <ClickAwayListener onClickAway={this.handleClose}>
            <Grow in={open} id="menu-list" style={{ transformOrigin: '0 0 0' }}>
              <Paper className={classes.paper} style={calcMenuWidth(width)}>
                <MenuList role="menu">
                  {floorplans.map((item) => {
                    const viewStatus = siteSummary.views.find(element => element.viewId === item.id);
                    return (
                      <MenuItem
                        key={item.id}
                        selected={floorplan && item.id === floorplan.id}
                        onClick={() => this.handleFloorplanMenuClick(item)}
                      >
                        <TinyBadge alert={viewStatus ? viewStatus.inAlert : false}>
                          <Typography
                            className={classNames({
                              [classes.selected]: floorplan && item.id === floorplan.id,
                            })}
                            variant="subtitle1"
                            noWrap
                          >
                            {item.name}
                          </Typography>
                        </TinyBadge>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Paper>
            </Grow>
          </ClickAwayListener>
        </Popper>
      </>
    );
  }
}

FloorplanMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  siteSummary: PropTypes.object.isRequired,
  floorplans: PropTypes.arrayOf(PropTypes.object).isRequired,
  floorplan: PropTypes.object,
  handleFloorplanMenuClick: PropTypes.func.isRequired,
};

FloorplanMenu.defaultProps = {
  floorplan: undefined,
};

export default compose(
  withStyles(styles),
  withWidth(),
)(FloorplanMenu);
