import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const RemoveIcon = props => (
  <SvgIcon {...props} viewBox="-1987 1989 24 24">
    <circle fill="#757575" cx="-1975" cy="2001.2" r="8.3" />
    <path
      fill="#FFF"
      d="M-1975 1991c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm5 13.6l-1.4 1.4-3.6-3.6-3.6 3.6-1.4-1.4 3.6-3.6-3.6-3.6 1.4-1.4 3.6 3.6 3.6-3.6 1.4 1.4-3.6 3.6 3.6 3.6z"
    />
  </SvgIcon>
);

export default RemoveIcon;
