import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';

const styles = {
  switchWidth: {
    width: 272,
  },
  switchContainer: {
    display: 'flex',
    flexBasis: '100%',
  },
  flexItem: {
    flex: 1,
    flexBasis: 68,
  },
  rotateOn: {
    flex: 1,
    flexBasis: 68,
    display: 'inline-flex',
    transform: 'rotate(90deg)',
  },
  rotateOff: {
    flex: 1,
    flexBasis: 68,
    display: 'inline-flex',
    transform: 'rotate(-90deg)',
  },
};

const switches = {
  1: [false, false, false, false],
  2: [false, false, false, true],
  3: [false, false, true, false],
  4: [false, false, true, true],
  5: [false, true, false, false],
  6: [false, true, false, true],
  7: [false, true, true, false],
  8: [false, true, true, true],
  9: [true, false, false, false],
  10: [true, false, false, true],
  11: [true, false, true, false],
  12: [true, false, true, true],
  13: [true, true, false, false],
  14: [true, true, false, true],
  15: [true, true, true, false],
  16: [true, true, true, true],
};

function DevicesSwitches(props) {
  const { classes, networkId } = props;

  return (
    <div className={classes.switchWidth}>
      <div className={classes.switchContainer}>
        <Typography className={classes.flexItem} variant="body2" align="center">
          1
        </Typography>
        <Typography className={classes.flexItem} variant="body2" align="center">
          2
        </Typography>
        <Typography className={classes.flexItem} variant="body2" align="center">
          3
        </Typography>
        <Typography className={classes.flexItem} variant="body2" align="center">
          4
        </Typography>
      </div>
      <div className={classes.switchContainer}>
        {networkId && switches[networkId][0] ? (
          <div className={classes.rotateOn}>
            <Switch className={classes.flexItem} disabled checked={false} />
          </div>
        ) : (
          <div className={classes.rotateOff}>
            <Switch className={classes.flexItem} disabled checked={false} />
          </div>
        )}
        {networkId && switches[networkId][1] ? (
          <div className={classes.rotateOn}>
            <Switch className={classes.flexItem} disabled checked={false} />
          </div>
        ) : (
          <div className={classes.rotateOff}>
            <Switch className={classes.flexItem} disabled checked={false} />
          </div>
        )}
        {networkId && switches[networkId][2] ? (
          <div className={classes.rotateOn}>
            <Switch className={classes.flexItem} disabled checked={false} />
          </div>
        ) : (
          <div className={classes.rotateOff}>
            <Switch className={classes.flexItem} disabled checked={false} />
          </div>
        )}
        {networkId && switches[networkId][3] ? (
          <div className={classes.rotateOn}>
            <Switch className={classes.flexItem} disabled checked={false} />
          </div>
        ) : (
          <div className={classes.rotateOff}>
            <Switch className={classes.flexItem} disabled checked={false} />
          </div>
        )}
      </div>
    </div>
  );
}

DevicesSwitches.propTypes = {
  classes: PropTypes.object.isRequired,
  networkId: PropTypes.number.isRequired,
};

export default compose(withStyles(styles))(DevicesSwitches);
