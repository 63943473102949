import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    margin: '0 auto',
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 2,
  },
  table: {
    tableLayout: 'fixed',
  },
  nowrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  secondary: {
    color: theme.palette.text.secondary,
  },
});

function AdminTable(props) {
  const {
    classes, rows, variant, selectedRowId, handleRowClick,
  } = props;

  return (
    <div className={classes.container}>
      <Table className={classes.table}>
        <TableBody>
          {rows.map(item => (
            <TableRow
              hover
              key={item.id}
              selected={selectedRowId === item.id}
              onClick={event => handleRowClick(event, item.id)}
            >
              <TableCell className={classes.nowrap}>{item.name}</TableCell>
              {variant === 'users' && (
                <>
                  <TableCell className={classNames(classes.nowrap, classes.secondary)}>{item.company}</TableCell>
                  <TableCell className={classNames(classes.nowrap, classes.secondary)}>{item.role}</TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

AdminTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  variant: PropTypes.oneOf(['companies', 'users']).isRequired,
  selectedRowId: PropTypes.string,
  handleRowClick: PropTypes.func.isRequired,
};

AdminTable.defaultProps = {
  selectedRowId: null,
};

export default withStyles(styles)(AdminTable);
