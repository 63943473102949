import PropTypes from 'prop-types';
import React from 'react';
import Floorplan from './Floorplan';
import FloorplansListView from './FloorplansListView';

function Floorplans(props) {
  const {
    floorplans,
    floorplan,
    locations,
    selectedLocationId,
    listView,
    charts,
    handleBackClick,
    handleForwardClick,
    handleLocationClick,
    handleChartCloseClick,
    handleChartBackClick,
    handleChartForwardClick,
    handleChartDataRequest,
    handleChartClick,
  } = props;

  return (
    <>
      {!listView ? (
        <Floorplan
          floorplan={floorplan}
          floorplans={floorplans}
          locations={locations}
          charts={charts}
          handleBackClick={handleBackClick}
          handleForwardClick={handleForwardClick}
          handleLocationClick={handleLocationClick}
          handleChartCloseClick={handleChartCloseClick}
          handleChartBackClick={handleChartBackClick}
          handleChartForwardClick={handleChartForwardClick}
          handleChartDataRequest={handleChartDataRequest}
          handleChartClick={handleChartClick}
        />
      ) : (
        <FloorplansListView
          floorplans={floorplans}
          locations={locations}
          selectedLocationId={selectedLocationId}
          charts={charts}
          handleLocationClick={handleLocationClick}
          handleChartCloseClick={handleChartCloseClick}
          handleChartBackClick={handleChartBackClick}
          handleChartForwardClick={handleChartForwardClick}
          handleChartDataRequest={handleChartDataRequest}
          handleChartClick={handleChartClick}
        />
      )}
    </>
  );
}

Floorplans.propTypes = {
  floorplans: PropTypes.arrayOf(PropTypes.object).isRequired,
  floorplan: PropTypes.object,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedLocationId: PropTypes.string,
  listView: PropTypes.bool.isRequired,
  charts: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleForwardClick: PropTypes.func.isRequired,
  handleLocationClick: PropTypes.func.isRequired,
  handleChartCloseClick: PropTypes.func.isRequired,
  handleChartBackClick: PropTypes.func.isRequired,
  handleChartForwardClick: PropTypes.func.isRequired,
  handleChartDataRequest: PropTypes.func.isRequired,
  handleChartClick: PropTypes.func.isRequired,
};

Floorplans.defaultProps = {
  floorplan: undefined,
  selectedLocationId: undefined,
};

export default Floorplans;
