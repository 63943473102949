import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = theme => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing.unit}px auto`,
    },
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 2,
  },
  list: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  paragraph: {
    paddingBottom: theme.spacing.unit,
  },
  buttonSignIn: {
    minHeight: 21,
    padding: 0,
    verticalAlign: 'baseline',
    textTransform: 'none',
    letterSpacing: 0.4,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

function InviteNotFound(props) {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <List className={classes.list}>
        <ListItem>
          <ListItemText
            disableTypography
            primary={<Typography variant="h6">Your invitation cannot be found.</Typography>}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <>
                <Typography className={classes.paragraph} variant="body2">
                  If you need an account, please contact WesternOne today for further information.
                </Typography>
                <Typography variant="body2">
                  {'If you already have an account, you can '}
                  <Button
                    className={classes.buttonSignIn}
                    component={Link}
                    to="/signin"
                    color="secondary"
                    disableRipple
                  >
                    sign in here.
                  </Button>
                </Typography>
              </>
            }
          />
        </ListItem>
      </List>
    </div>
  );
}

InviteNotFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InviteNotFound);
