import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';
import {
  emptyMobile, formatMobile, mobileMask, removeMobileFormat, validMobile,
} from '../utils/formatMobile';
import { passwordHelper, validPassword } from '../utils/passwords';

const styles = theme => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    margin: '0 auto',
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 2,
  },
  list: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  caption: {
    lineHeight: '20px',
    letterSpacing: 0,
  },
  spacingEmail: {
    marginTop: 8,
  },
  underline: {
    '&:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  listItemPassword: {
    alignItems: 'flex-end',
    paddingBottom: 5,
  },
  spacing: {
    marginLeft: 20,
  },
  helperTextPassword: {
    paddingBottom: 11,
    ...theme.mixins.gutters(),
  },
  button: {
    textTransform: 'none',
    margin: '12px 0',
  },
});

function TextMask(props) {
  const { inputRef, ...other } = props;

  return <MaskedInput {...other} ref={inputRef} mask={mobileMask} placeholderChar={'\u2000'} showMask />;
}

TextMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Invite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      nameError: null,
      company: '',
      mobile: '',
      mobileFocus: false,
      mobileError: false,
      password: '',
      passwordError: null,
      passwordShow: false,
      passwordAgain: '',
      passwordGeneralError: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleMobileBlur = this.handleMobileBlur.bind(this);
    this.handleMobileChange = this.handleMobileChange.bind(this);
    this.handleMobileFocus = this.handleMobileFocus.bind(this);
    this.handlePasswordAgainChange = this.handlePasswordAgainChange.bind(this);
    this.handlePasswordShowClick = this.handlePasswordShowClick.bind(this);
    this.handleSignUpClick = this.handleSignUpClick.bind(this);
  }

  handleChange(event, param) {
    this.setState({ [param]: event.target.value, [`${param}Error`]: null });
  }

  handleCompanyChange(event) {
    this.setState({ company: event.target.value });
  }

  handleMobileBlur() {
    this.setState({ mobileFocus: false });
  }

  handleMobileChange(event) {
    if (emptyMobile(event.target.value)) {
      this.setState({ mobile: '', mobileError: null });
    } else {
      this.setState({ mobile: removeMobileFormat(event.target.value), mobileError: null });
    }
  }

  handleMobileFocus() {
    this.setState({ mobileFocus: true });
  }

  handlePasswordAgainChange(event) {
    this.setState({ passwordAgain: event.target.value });
  }

  handlePasswordShowClick() {
    const { passwordShow } = this.state;

    this.setState({ passwordShow: !passwordShow });
  }

  handleSignUpClick() {
    const { handleSignUpClick: handleSignUpClickProp } = this.props;
    const {
      name, company, mobile, password, passwordAgain,
    } = this.state;

    let nameError = null;
    let mobileError = null;
    let passwordError = null;
    let passwordGeneralError = null;

    if (!name) {
      nameError = 'Enter full name';
    }

    if (!emptyMobile(mobile) && !validMobile(mobile)) {
      mobileError = 'Invalid number';
    }

    if (!password) {
      passwordError = 'Enter a password';
    }

    // Check password is valid
    if (!passwordError) {
      passwordError = validPassword(password);
    }

    // Check passwords match
    if (!passwordError && password !== passwordAgain) {
      passwordGeneralError = 'Passwords do not match';
    }

    this.setState({
      nameError,
      mobileError,
      passwordError,
      passwordGeneralError,
    });

    if (nameError || mobileError || passwordError || passwordGeneralError) {
      return;
    }

    handleSignUpClickProp(name, company, mobile, password);
  }

  render() {
    const { classes, email } = this.props;
    const {
      name,
      nameError,
      company,
      mobile,
      mobileFocus,
      mobileError,
      password,
      passwordError,
      passwordAgain,
      passwordShow,
      passwordGeneralError,
    } = this.state;

    const mobileInputProps = mobileFocus || !emptyMobile(mobile)
      ? { value: formatMobile(mobile), inputComponent: TextMask, autoFocus: true }
      : { value: '' };

    return (
      <div className={classes.container}>
        <List className={classes.list}>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <>
                  <Typography className={classes.caption} variant="caption" color="textSecondary">
                    Email
                  </Typography>
                  <Typography className={classes.spacingEmail} variant="subtitle1">
                    {email}
                  </Typography>
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <>
                  <Typography className={classes.caption} variant="caption" color="textSecondary">
                    Full name
                  </Typography>
                  <Input
                    classes={{ underline: classes.underline }}
                    id="invite-name"
                    value={name}
                    autoFocus
                    onChange={event => this.handleChange(event, 'name')}
                  />
                  {nameError && <FormHelperText error>{nameError}</FormHelperText>}
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <>
                  <Typography className={classes.caption} variant="caption" color="textSecondary">
                    Company (optional)
                  </Typography>
                  <Input
                    classes={{ underline: classes.underline }}
                    id="invite-company"
                    value={company}
                    onChange={event => this.handleCompanyChange(event)}
                  />
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <>
                  <Typography className={classes.caption} variant="caption" color="textSecondary">
                    Mobile (optional)
                  </Typography>
                  <Input
                    classes={{ underline: classes.underline }}
                    id="invite-mobile"
                    type="tel"
                    {...mobileInputProps}
                    onBlur={this.handleMobileBlur}
                    onChange={event => this.handleMobileChange(event)}
                    onFocus={this.handleMobileFocus}
                  />
                  {mobileError && <FormHelperText error>{mobileError}</FormHelperText>}
                </>
              }
            />
          </ListItem>
          <ListItem className={classes.listItemPassword}>
            <div>
              <Typography className={classes.caption} variant="caption" color="textSecondary">
                Password
              </Typography>
              <Input
                classes={{ underline: classes.underline }}
                id="invite-password"
                type={passwordShow ? 'text' : 'password'}
                value={password}
                spellCheck="false"
                onChange={event => this.handleChange(event, 'password')}
              />
            </div>
            <div className={classes.spacing}>
              <Typography className={classes.caption} variant="caption" color="textSecondary">
                Confirm password
              </Typography>
              <Input
                classes={{ underline: classes.underline }}
                id="invite-password-again"
                type={passwordShow ? 'text' : 'password'}
                value={passwordAgain}
                spellCheck="false"
                onChange={event => this.handlePasswordAgainChange(event)}
              />
            </div>
            <span>
              <IconButton onClick={this.handlePasswordShowClick}>
                {passwordShow ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </span>
          </ListItem>
          {passwordError || passwordGeneralError ? (
            <FormHelperText className={classes.helperTextPassword} error>
              {passwordError || passwordGeneralError}
            </FormHelperText>
          ) : (
            <FormHelperText className={classes.helperTextPassword}>{passwordHelper}</FormHelperText>
          )}
          <ListItem>
            <Button className={classes.button} variant="contained" color="secondary" onClick={this.handleSignUpClick}>
              Sign up
            </Button>
          </ListItem>
        </List>
      </div>
    );
  }
}

Invite.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  handleSignUpClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(Invite);
