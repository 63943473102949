import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Hammer from 'react-hammerjs';

const styles = {
  scroll: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  content: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
};

function absolutePosition(element) {
  let x = 0;
  let y = 0;

  let tempElement = element;

  while (tempElement !== null) {
    x += tempElement.offsetLeft;
    y += tempElement.offsetTop;
    tempElement = tempElement.offsetParent;
  }

  return { x, y };
}

function restrictScale(scale) {
  let restrictedScale = scale;

  if (scale < 1) {
    restrictedScale = 1;
  } else if (scale > 7) {
    restrictedScale = 7;
  }
  return restrictedScale;
}

class PanZoomSwipe extends React.Component {
  constructor(props) {
    super(props);

    this.contentRef = null;
    this.parentRef = null;
    this.parentAbsolutePosition = null;
    this.viewportWidth = 0;
    this.viewportHeight = 0;
    this.scale = 0;
    this.lastScale = 0;
    this.x = 0;
    this.lastX = 0;
    this.y = 0;
    this.lastY = 0;
    this.pinchCenter = null;
    this.pinchCenterOffset = null;
    this.isPanning = false;

    this.handleContentRef = this.handleContentRef.bind(this);
    this.handlePan = this.handlePan.bind(this);
    this.handlePanStart = this.handlePanStart.bind(this);
    this.handlePinch = this.handlePinch.bind(this);
    this.handlePinchEnd = this.handlePinchEnd.bind(this);
    this.handleSwipe = this.handleSwipe.bind(this);
    this.absoluteCenter = this.absoluteCenter.bind(this);
    this.restrictPosition = this.restrictPosition.bind(this);
    this.translate = this.translate.bind(this);
    this.updateLastPosition = this.updateLastPosition.bind(this);
    this.updateLastScale = this.updateLastScale.bind(this);
    this.zoom = this.zoom.bind(this);
    this.zoomAround = this.zoomAround.bind(this);
  }

  handleContentRef(ref) {
    if (ref && this.contentRef !== ref) {
      this.contentRef = ref;
      this.parentRef = ref.parentElement;
      this.parentAbsolutePosition = absolutePosition(ref.parentElement);
      this.viewportWidth = ref.parentElement.clientWidth;
      this.viewportHeight = ref.parentElement.clientHeight;
      this.scale = ref.parentElement.clientWidth / ref.clientWidth;
      this.lastScale = this.scale;
    } else if (
      ref
      && this.contentRef === ref
      && this.viewportWidth !== ref.parentElement.clientWidth
      && this.viewportHeight !== ref.parentElement.clientHeight
    ) {
      // orientation change
      const centerX = this.x - this.viewportWidth / this.scale / 2;
      const centerY = this.y - this.viewportHeight / this.scale / 2;
      const percentageX = centerX / this.viewportWidth;
      const percentageY = centerY / this.viewportHeight;
      const newCenterX = percentageX * ref.parentElement.clientWidth;
      const newCenterY = percentageY * ref.parentElement.clientHeight;
      const newX = newCenterX + ref.parentElement.clientWidth / this.scale / 2;
      const newY = newCenterY + ref.parentElement.clientHeight / this.scale / 2;

      this.x = newX;
      this.y = newY;
      this.lastX = newX;
      this.lastY = newY;

      this.parentAbsolutePosition = absolutePosition(ref.parentElement);
      this.viewportWidth = ref.parentElement.clientWidth;
      this.viewportHeight = ref.parentElement.clientHeight;

      this.translate(0, 0);
    }
  }

  handlePan(event) {
    if (this.isPanning) {
      this.translate(event.deltaX, event.deltaY);

      if (event.isFinal) {
        this.isPanning = false;
        this.updateLastPosition();
      }
    }
  }

  handlePanStart() {
    this.isPanning = true;
  }

  handlePinch(event) {
    if (this.pinchCenter === null) {
      this.pinchCenter = this.absoluteCenter(event);
      const offsetX = this.pinchCenter.x * this.scale - (-this.x * this.scale + this.viewportWidth / 2);
      const offsetY = this.pinchCenter.y * this.scale - (-this.y * this.scale + this.viewportHeight / 2);
      this.pinchCenterOffset = { x: offsetX, y: offsetY };
    }

    const newScale = restrictScale(this.scale * event.scale);
    const zoomX = this.pinchCenter.x * newScale - this.pinchCenterOffset.x - event.deltaX * this.scale;
    const zoomY = this.pinchCenter.y * newScale - this.pinchCenterOffset.y - event.deltaY * this.scale;
    const zoomCenter = { x: zoomX / newScale, y: zoomY / newScale };

    this.contentRef.style.transformOrigin = `${this.pinchCenter.x}px ${this.pinchCenter.y}px`;

    this.zoomAround(event.scale, zoomCenter.x, zoomCenter.y);
  }

  handlePinchEnd() {
    this.updateLastScale();
    this.updateLastPosition();
    this.pinchCenter = null;
  }

  handleSwipe(event) {
    const { handleSwipeBack, handleSwipeForward } = this.props;

    if (this.scale.toFixed(8) === (1).toFixed(8)) {
      if (event.direction === 2) {
        handleSwipeForward();
      } else if (event.direction === 4) {
        handleSwipeBack();
      }
    }
  }

  absoluteCenter(event) {
    const scrollLeft = window.pageXOffset ? window.pageXOffset : document.body.scrollLeft;
    const scrollTop = window.pageYOffset ? window.pageYOffset : document.body.scrollTop;
    const zoomX = -this.x + (event.center.x - this.parentAbsolutePosition.x + scrollLeft) / this.scale;
    const zoomY = -this.y + (event.center.y - this.parentAbsolutePosition.y + scrollTop) / this.scale;
    return { x: zoomX, y: zoomY };
  }

  restrictPosition(position, viewPortDimension) {
    let restrictedPosition = position;

    if (position < viewPortDimension / this.scale - viewPortDimension) {
      restrictedPosition = viewPortDimension / this.scale - viewPortDimension;
    } else if (position > 0) {
      restrictedPosition = 0;
    }

    return restrictedPosition;
  }

  translate(deltaX, deltaY) {
    const newX = this.restrictPosition(this.lastX + deltaX / this.scale, this.viewportWidth);
    this.x = newX;

    const newY = this.restrictPosition(this.lastY + deltaY / this.scale, this.viewportHeight);
    this.y = newY;

    const transform = `translate(${newX * this.scale}px, ${newY * this.scale}px) scale(${this.scale})`;
    this.contentRef.style.transformOrigin = '0px 0px';
    this.contentRef.style.transform = transform;
  }

  updateLastPosition() {
    this.lastX = this.x;
    this.lastY = this.y;
  }

  updateLastScale() {
    this.lastScale = this.scale;
  }

  zoom(scaleBy) {
    this.scale = restrictScale(this.lastScale * scaleBy);

    const transform = `translate(${this.x * this.scale}px, ${this.content * this.scale}px) scale(${this.scale})`;
    this.contentRef.style.transform = transform;

    this.translate(0, 0);
  }

  zoomAround(scaleBy, zoomX, zoomY) {
    // Zoom
    this.zoom(scaleBy);
    // New absolute center of viewport
    const absoluteCenterX = -this.x + this.viewportWidth / 2 / this.scale;
    const absoluteCenterY = -this.y + this.viewportHeight / 2 / this.scale;
    // Delta
    const deltaX = (absoluteCenterX - zoomX) * this.scale;
    const deltaY = (absoluteCenterY - zoomY) * this.scale;
    // Translate back to zoom center
    this.translate(deltaX, deltaY);
  }

  render() {
    const { classes, children } = this.props;

    return (
      <Hammer
        onPan={this.handlePan}
        onPanStart={this.handlePanStart}
        onPinch={this.handlePinch}
        onPinchCancel={this.handlePinchEnd}
        onPinchEnd={this.handlePinchEnd}
        onSwipe={this.handleSwipe}
        options={{
          recognizers: { pinch: { enable: true } },
        }}
      >
        <div className={classes.scroll}>
          <div className={classes.content} ref={ref => this.handleContentRef(ref)}>
            {children}
          </div>
        </div>
      </Hammer>
    );
  }
}

PanZoomSwipe.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  handleSwipeBack: PropTypes.func.isRequired,
  handleSwipeForward: PropTypes.func.isRequired,
};

PanZoomSwipe.defaultProps = {
  children: null,
};

export default withStyles(styles)(PanZoomSwipe);
