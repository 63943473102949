import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import SettingsComponent from '../components/Settings';
import {
  apiDeleteFloorplan,
  apiDeleteSite,
  apiGetCompanies,
  apiGetCurrentSite,
  apiGetDefaultFloorplanId,
  apiGetDefaultSiteId,
  apiGetFloorplans,
  apiGetUserAlerts,
  apiModifyFloorplan,
  apiModifySite,
  apiModifySiteAlerts,
} from '../utils/api';

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.handleDeleteSiteClick = this.handleDeleteSiteClick.bind(this);
  }

  handleDeleteSiteClick(site) {
    return apiDeleteSite(site)
      .then(() => {
        const { history, handleClose } = this.props;
        handleClose();
        const id = apiGetDefaultSiteId();
        history.push(`/site/${id}/floorplans/${apiGetDefaultFloorplanId(id)}`);
        return Promise.resolve();
      });
  }

  render() {
    const {
      siteId, sideSheet, handleBackClick, handleClose,
    } = this.props;

    return (
      <SettingsComponent
        siteId={siteId}
        site={apiGetCurrentSite()}
        sideSheet={sideSheet}
        floorplans={apiGetFloorplans()}
        companies={apiGetCompanies()}
        alerts={apiGetUserAlerts()}
        handleSwitchClick={apiModifyFloorplan}
        handleBackClick={handleBackClick}
        handleClose={handleClose}
        handleDeleteFloorplanClick={apiDeleteFloorplan}
        handleDeleteSiteClick={this.handleDeleteSiteClick}
        handleSaveFloorplanClick={apiModifyFloorplan}
        handleSaveSite={apiModifySite}
        handleSaveSiteAlerts={apiModifySiteAlerts}
      />
    );
  }
}

Settings.propTypes = {
  history: PropTypes.object.isRequired,
  siteId: PropTypes.string.isRequired,
  sideSheet: PropTypes.bool,
  handleBackClick: PropTypes.func,
  handleClose: PropTypes.func,
};

Settings.defaultProps = {
  sideSheet: false,
  handleBackClick: undefined,
  handleClose: undefined,
};

export default withRouter(Settings);
