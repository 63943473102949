import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import wedgeByWesternOneLogo from '../images/wedge_by_westernone_logo.svg';

const styles = theme => ({
  container: {
    margin: '7% auto 0',
    maxWidth: 360,
    minHeight: 180,
    padding: '30px 0 15px',
  },
  logo: {
    width: 154.34,
    height: 54,
    display: 'inline-block',
    marginBottom: 47,
  },
  message: {
    marginBottom: 25,
  },
  button: {
    marginLeft: -2 * theme.spacing.unit,
  },
});

function Error(props) {
  const { classes, variant } = props;

  const message = variant === 'forbidden' ? 'Sorry, you do not have permission.' : "Sorry, that's an error.";

  return (
    <div className={classes.container}>
      <img className={classes.logo} src={wedgeByWesternOneLogo} alt="WEDGE logo" />
      <Typography className={classes.message} variant="h6" color="textSecondary">
        {message}
      </Typography>
      <Button className={classes.button} color="secondary" component={Link} to="/">
        Go To Wedge
      </Button>
    </div>
  );
}

Error.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['general', 'forbidden']),
};

Error.defaultProps = {
  variant: 'forbidden',
};

export default withStyles(styles)(Error);
