import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';

function FileError(props) {
  const { open, filename, handleClose } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Unsupported file type</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`${
            filename ? filename.split('.').pop() : ''
          } is an unsupported file type. Please select a png, jpeg, gif, or bmp.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FileError.propTypes = {
  filename: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FileError;
