import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const styles = {
  root: {
    width: 12,
    height: 12,
  },
};

const TrendArrow = props => (
  <SvgIcon {...props} viewBox="0 0 7 9">
    <svg
      id="metric-table-decrease-delta-arrow_cache26"
      viewBox="0 0 7 9"
      xmlns="http://www.w3.org/2000/svg"
      fit=""
      height="100%"
      width="100%"
      preserveAspectRatio="xMidYMid meet"
      focusable="false"
    >
      <g transform="translate(3, 4.5)">
        <polygon points="0 -4.5 -3.15 -1.2354545 -0.7875 -1.2354545 -0.7875 4.5 0.7875 4.5 0.7875 -1.2354545 3.15 -1.2354545" />
      </g>
    </svg>
  </SvgIcon>
);

export default withStyles(styles)(TrendArrow);
