import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';
import { Auth } from '../utils/auth';
import Tooltip from './Tooltip';

const styles = theme => ({
  root: {
    position: 'relative',
    ...mixinHeightWithToolbar(),
  },
  content: {
    height: '100%',
    overflowY: 'auto',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
});

function TeamList(props) {
  const {
    classes, site, handleListItemClick, handleTeamAddClick,
  } = props;

  const admins = site.contacts.filter(contact => contact.role === 'Site Admin');
  const team = site.contacts.filter(contact => contact.role === 'Team Member');
  const support = site.contacts.filter(contact => contact.role === 'Support');

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <List>
          <ListSubheader disableSticky>Site admins</ListSubheader>
          {admins.map(item => (
            <ListItem onClick={() => handleListItemClick(item.userId)} key={item.userId} button>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
          {admins.length === 0 && (
            <ListItem>
              <ListItemText secondary="No site admins" />
            </ListItem>
          )}
        </List>
        <Divider />
        <List>
          <ListSubheader disableSticky>Team members</ListSubheader>
          {team.map(item => (
            <ListItem onClick={() => handleListItemClick(item.userId)} key={item.userId} button>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
          {team.length === 0 && (
            <ListItem>
              <ListItemText secondary="No team members" />
            </ListItem>
          )}
        </List>
        <Divider />
        <List>
          <ListSubheader disableSticky>Support</ListSubheader>
          {support.map(item => (
            <ListItem onClick={() => handleListItemClick(item.userId)} key={item.userId} button>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
          {support.length === 0 && (
            <ListItem>
              <ListItemText secondary="No support users" />
            </ListItem>
          )}
        </List>
        <Auth do="modify" on="invitations">
          <Tooltip title="Invite a new team member" placement="left">
            <Button className={classes.fab} color="secondary" variant="fab" mini onClick={handleTeamAddClick}>
              <AddIcon />
            </Button>
          </Tooltip>
        </Auth>
      </div>
    </div>
  );
}

TeamList.propTypes = {
  classes: PropTypes.object.isRequired,
  site: PropTypes.object.isRequired,
  handleListItemClick: PropTypes.func.isRequired,
  handleTeamAddClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(TeamList);
