import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import compose from 'recompose/compose';
import dragAndDrop from '../constants/dragAndDrop';
import RemoveIcon from '../images/icons/Remove';
import formatValue from '../utils/formatValue';
import getUnit from '../utils/getUnit';
import FloorplanValue from './FloorplanValue';
import Tooltip from './Tooltip';

const styles = theme => ({
  root: {
    position: 'absolute',
    transformOrigin: '0px 0px',
  },
  draggable: {
    cursor: 'move',
  },
  hoverRemoveButton: {
    '&:hover $iconButton': {
      opacity: 1,
    },
  },
  iconButton: {
    position: 'absolute',
    top: -24,
    left: -24,
    zIndex: 1,
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 1,
    },
  },
  circle: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50%',
    boxShadow: theme.shadows[2],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    top: 0,
    left: 0,
    display: 'inline-block',
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
    '&:active': {
      boxShadow: theme.shadows[8],
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      '@media (hover: none)': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    width: 109,
    [theme.breakpoints.up('md')]: {
      width: 129,
    },
  },
  buttonGauge: {
    borderColor: theme.floorplan.gauge.border,
    background: theme.floorplan.gauge.background,
  },
  buttonHeater: {
    borderColor: theme.floorplan.heater.border,
    background: theme.floorplan.heater.background,
  },
  buttonHeating: {
    borderColor: theme.floorplan.heater.heating.border,
    background: theme.floorplan.heater.heating.background,
  },
  header: {
    textAlign: 'left',
    padding: 8,
  },
  headerGauge: {
    color: theme.floorplan.gauge.color.secondary,
  },
  headerHeater: {
    color: theme.floorplan.heater.color.secondary,
  },
  values: {
    display: 'block',
    textAlign: 'left',
  },
  value: {
    padding: '0px 8px 13px',
    [theme.breakpoints.up('md')]: {
      padding: '0px 16px 13px',
    },
  },
  alert: {
    borderColor: theme.palette.error.main,
  },
});

class FloorplanGaugeBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
    };

    this.rootRef = null;
    this.rootCoorinatesRef = null;

    this.dragProperties = this.dragProperties.bind(this);
    this.handleRootRef = this.handleRootRef.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.renderRoot = this.renderRoot.bind(this);
  }

  componentWillUnmount() {
    const { handleClick, draggable } = this.props;

    if (this.rootRef && draggable && handleClick) {
      this.rootRef.removeEventListener('click', this.handleClick, false);
    }
  }

  dragProperties() {
    const { width, height } = this.state;

    let bodyCoordinates = { x: 0, y: 0 };

    if (this.rootCoorinatesRef) {
      bodyCoordinates = this.rootCoorinatesRef();
    }

    return { bodyWidth: width, bodyHeight: height, bodyCoordinates };
  }

  handleRootRef(ref) {
    const {
      onResize, coordinatesRef, handleClick, draggable,
    } = this.props;

    if (ref) {
      this.rootRef = ref;

      this.setState((prevState) => {
        if (prevState.width !== ref.offsetWidth) {
          return { width: ref.offsetWidth };
        }

        return null;
      });

      this.setState((prevState) => {
        if (prevState.height !== ref.offsetHeight) {
          return { height: ref.offsetHeight };
        }

        return null;
      });

      if (onResize) {
        onResize(ref.offsetWidth, ref.offsetHeight);
      }

      this.rootCoorinatesRef = function coordinates() {
        const domRect = ref.getBoundingClientRect();

        return { x: domRect.left, y: domRect.top };
      };

      if (coordinatesRef) {
        coordinatesRef(this.rootCoorinatesRef);
      }

      if (handleClick && draggable) {
        this.rootRef.addEventListener('click', this.handleClick, false);
      }
    }
  }

  handleClick() {
    const { handleClick } = this.props;

    handleClick();
  }

  renderRoot() {
    const {
      classes,
      className,
      x,
      y,
      originX,
      originY,
      location,
      scale: scaleProp,
      draggable,
      handleRemoveClick,
      handleClick,
    } = this.props;
    const { width, height } = this.state;

    const scale = scaleProp > 1 ? 1 : scaleProp;

    const originBehindBody = originX >= x && originX <= x + width && originY >= y && originY <= y + height;
    const originBehindButton = originX >= x - 24 * scale && originX <= x + 24 * scale && originY >= y - 24 * scale && originY <= y + 24 * scale; // eslint-disable-line max-len
    const buttonStyle = originBehindButton && !originBehindBody ? { left: width - 24 } : {};

    const isHeater = location.heater !== null;

    const { sensors } = location;
    const alert = location.sensors.some(
      sensor => sensor.value.isMissing
          || sensor.value.inError
          || sensor.value.thresholds.some(threshold => threshold.exceeded),
    )
      || (isHeater && location.heater.isMissing);

    return (
      <div
        className={classNames(
          classes.root,
          {
            [classes.draggable]: draggable,
            [classes.hoverRemoveButton]: handleRemoveClick,
          },
          className,
        )}
        ref={ref => this.handleRootRef(ref)}
        style={{
          transform: `translate(${x}px, ${y}px) scale(${scale})`,
        }}
      >
        {handleRemoveClick && (
          <Tooltip title="Remove">
            <IconButton
              className={classes.iconButton}
              onClick={() => handleRemoveClick()}
              disableRipple
              style={buttonStyle}
            >
              <div className={classes.circle}>
                <RemoveIcon />
              </div>
            </IconButton>
          </Tooltip>
        )}
        <ButtonBase
          className={classNames(
            classes.button,
            {
              [classes.buttonGauge]: !isHeater,
              [classes.buttonHeater]: isHeater && !location.heater.isHeating,
              [classes.buttonHeating]: isHeater && location.heater.isHeating,
              [classes.alert]: alert,
            },
            className,
          )}
          disableRipple
          disabled={draggable || handleClick === undefined}
          onClick={event => handleClick(event, location.id)}
        >
          <Typography
            className={classNames(classes.header, {
              [classes.headerGauge]: !isHeater,
              [classes.headerHeater]: isHeater,
            })}
            variant="caption"
            noWrap
          >
            {location.name}
          </Typography>
          <div className={classes.values}>
            {sensors.map(item => (
              <div className={classes.value} key={item.id}>
                <FloorplanValue
                  sensor={{
                    value: formatValue(item.value.value, getUnit(item.type), 0),
                    label: item.sensorType,
                    missing: item.value.isMissing,
                    inAlert: item.value.thresholds.some(threshold => threshold.exceeded),
                    inError: item.value.inError,
                    trendData: item.value.thumbnail,
                  }}
                />
              </div>
            ))}
            {isHeater && (
              <div className={classes.value}>
                <FloorplanValue
                  heater={{
                    value: formatValue(location.heater.heaterSetTo, getUnit('T'), 0),
                    missing: location.heater.isMissing,
                    enabled: location.heater.enabled,
                    heating: location.heater.isHeating,
                  }}
                />
              </div>
            )}
          </div>
        </ButtonBase>
      </div>
    );
  }

  render() {
    const { connectDragSource, connectDragPreview } = this.props;
    connectDragPreview(getEmptyImage());
    return connectDragSource(this.renderRoot());
  }
}

FloorplanGaugeBody.propTypes = {
  classes: PropTypes.object.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  className: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  originX: PropTypes.number,
  originY: PropTypes.number,
  location: PropTypes.object.isRequired,
  scale: PropTypes.number,
  draggable: PropTypes.bool,
  onResize: PropTypes.func,
  coordinatesRef: PropTypes.func,
  handleRemoveClick: PropTypes.func,
  handleClick: PropTypes.func,
};

FloorplanGaugeBody.defaultProps = {
  className: '',
  x: 0,
  y: 0,
  originX: 0,
  originY: 0,
  scale: 1,
  draggable: false,
  onResize: undefined,
  coordinatesRef: undefined,
  handleRemoveClick: undefined,
  handleClick: undefined,
};

export default compose(
  withStyles(styles),
  DragSource(
    dragAndDrop.widgetBody,
    {
      beginDrag(props, _, component) {
        const { handleHide } = props;

        if (handleHide) {
          handleHide();
        }

        return { ...component.dragProperties(), ...props };
      },
      endDrag(props) {
        const { handleShow } = props;

        if (handleShow) {
          handleShow();
        }
      },
      canDrag(props) {
        return props.draggable;
      },
    },
    connect => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
    }),
  ),
)(FloorplanGaugeBody);
