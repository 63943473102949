import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import React from 'react';
import uuidv4 from 'uuid/v4';
import { mixinHeightWithToolbar } from '../constants/theme';
import deepCopy from '../utils/deepCopy';
import ConfirmDialog from './ConfirmDialog';
import TextField from './TextField';
import Tooltip from './Tooltip';
import ZonesAddLocation from './ZonesAddLocation';
import ZonesAddMonitoring from './ZonesAddMonitoring';

const styles = theme => ({
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
  addButton: {
    marginLeft: -5,
    marginTop: theme.spacing.unit,
    color: theme.palette.secondary.main,
  },
  addLabel: {
    marginLeft: 10,
  },
});

class ZonesEdit extends React.Component {
  constructor(props) {
    super(props);

    const { zone } = props;

    this.state = {
      openAddLocation: false,
      openAddMonitoring: false,
      openConfirm: false,
      id: undefined,
      name: zone.name,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAddLocationClick = this.handleAddLocationClick.bind(this);
    this.handleAddMonitoringClick = this.handleAddMonitoringClick.bind(this);
    this.handleNewLocation = this.handleNewLocation.bind(this);
    this.handleNewMonitoring = this.handleNewMonitoring.bind(this);
    this.handleRemoveLocationClick = this.handleRemoveLocationClick.bind(this);
    this.handleRemoveMonitoringClick = this.handleRemoveMonitoringClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
  }

  handleNameChange(event) {
    const { zone: zoneProp, onChange } = this.props;

    const zone = deepCopy(zoneProp);
    this.setState({ name: event.target.value });
    zone.name = event.target.value;
    onChange(zone);
  }

  handleClose() {
    this.setState({
      openAddLocation: false,
      openAddMonitoring: false,
      openConfirm: false,
    });
  }

  handleAddLocationClick() {
    this.setState({ openAddLocation: true });
  }

  handleAddMonitoringClick() {
    this.setState({ openAddMonitoring: true });
  }

  handleNewLocation(id) {
    const { zone: zoneProp, locations, onChange } = this.props;

    const zone = deepCopy(zoneProp);
    const location = locations.find(item => item.id === id);
    zone.locations.push({ id, name: location.name });
    if (location.heater !== null) {
      zone.environmentalControl = id;
    }
    onChange(zone);
  }

  handleNewMonitoring(item) {
    const { zone: zoneProp, onChange } = this.props;

    const zone = deepCopy(zoneProp);
    zone.newThresholds.push({ id: uuidv4(), ...item });
    onChange(zone);
  }

  handleRemoveLocationClick(id) {
    const { zone: zoneProp, locations, onChange } = this.props;

    if (locations.find(item => item.id === id).heater !== null) {
      this.setState({ openConfirm: true, id });
    } else {
      const zone = deepCopy(zoneProp);
      zone.locations = zone.locations.filter(item => item.id !== id);
      onChange(zone);
    }
  }

  handleRemoveMonitoringClick(id) {
    const { zone: zoneProp, onChange } = this.props;

    const zone = deepCopy(zoneProp);
    zone.thresholds = zone.thresholds.filter(item => item.id !== id);
    zone.newThresholds = zone.newThresholds.filter(item => item.id !== id);
    onChange(zone);
  }

  handleRemoveClick() {
    const { zone: zoneProp, onChange } = this.props;
    const { id } = this.state;

    const zone = deepCopy(zoneProp);
    zone.environmentalControl = null;
    zone.locations = zone.locations.filter(item => item.id !== id);
    onChange(zone);
    this.handleClose();
  }

  render() {
    const {
      classes, zones, zone, locations,
    } = this.props;
    const {
      openAddLocation, openAddMonitoring, openConfirm, name,
    } = this.state;

    return (
      <>
        <div className={classes.content}>
          <List>
            <ListItem>
              <TextField
                id="zone-name"
                label="Zone name"
                value={name}
                margin="normal"
                fullWidth
                onChange={this.handleNameChange}
              />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListSubheader disableSticky>Locations</ListSubheader>
            {zone.locations.map(item => (
              <ListItem key={item.id}>
                <ListItemText primary={item.name} />
                <ListItemSecondaryAction>
                  <Tooltip title="Remove">
                    <IconButton onClick={() => this.handleRemoveLocationClick(item.id)}>
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <ListItem className={classes.addButton} button onClick={this.handleAddLocationClick}>
              <AddIcon />
              <Typography className={classes.addLabel} variant="subtitle1" color="textSecondary">
                Add a location
              </Typography>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListSubheader disableSticky>Automated monitoring</ListSubheader>
            {zone.thresholds.concat(zone.newThresholds).map(item => (
              <ListItem key={item.id}>
                <ListItemText primary={item.sensorType} />
                <ListItemSecondaryAction>
                  <Tooltip title="Remove">
                    <IconButton onClick={() => this.handleRemoveMonitoringClick(item.id)}>
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <ListItem
              className={classes.addButton}
              button
              onClick={this.handleAddMonitoringClick}
              disabled={zone.locations.length === 0}
            >
              <AddIcon />
              <Typography className={classes.addLabel} variant="subtitle1" color="textSecondary">
                Add a condition to monitor
              </Typography>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListSubheader disableSticky>Environmental control</ListSubheader>
            <ListItem>
              {zone.environmentalControl !== null ? (
                <ListItemText
                  primary="Smart heater"
                  secondary={zone.locations.find(item => item.id === zone.environmentalControl).name}
                />
              ) : (
                <ListItemText secondary="No smart heater assigned" />
              )}
            </ListItem>
          </List>
        </div>
        <ZonesAddLocation
          zones={zones}
          zone={zone}
          locations={locations}
          open={openAddLocation}
          handleAddClick={this.handleNewLocation}
          handleClose={this.handleClose}
          key={`zonesAddLocation-${openAddLocation.toString()}`}
        />
        <ZonesAddMonitoring
          open={openAddMonitoring}
          handleAddClick={this.handleNewMonitoring}
          handleClose={this.handleClose}
        />
        <ConfirmDialog
          title="Remove smart heater?"
          body="This zone will lose heating environmental control."
          confirm="Remove"
          cancel="Cancel"
          open={openConfirm}
          handleConfirmClick={this.handleRemoveClick}
          handleCancelClick={this.handleClose}
        />
      </>
    );
  }
}

ZonesEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
  zone: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ZonesEdit);
