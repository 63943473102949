import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import AutosizeInput from 'react-input-autosize';
import compose from 'recompose/compose';
import dragAndDrop from '../constants/dragAndDrop';
import RemoveIcon from '../images/icons/Remove';
import Tooltip from './Tooltip';

const styles = theme => ({
  root: {
    position: 'absolute',
    transformOrigin: '0px 0px',
  },
  draggable: {
    cursor: 'move',
  },
  hoverRemoveButton: {
    '&:hover $button': {
      opacity: 1,
    },
  },
  button: {
    position: 'absolute',
    top: -24,
    left: -24,
    zIndex: 1,
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 1,
    },
  },
  circle: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50%',
    boxShadow: theme.shadows[2],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  body: {
    display: 'inline-block',
  },
  input: {
    color: theme.floorplan.annotation.color,
    background: theme.floorplan.annotation.background,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.floorplan.annotation.border,
    borderRadius: theme.shape.borderRadius,
    height: 54,
    fontSize: 21,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: 'GoogleSans, Roboto, Helvetica, Arial, sans-serif',
    letterSpacing: 0.5,
    padding: `0px ${theme.spacing.unit * 2}px`,
  },
  disabled: {
    cursor: 'unset',
    opacity: 1,
    pointerEvents: 'none',
    '-webkit-text-fill-color': theme.floorplan.annotation.color,
    '&::selection': {
      background: 'transparent',
    },
  },
});

class AnnotationBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
    };

    this.rootCoordinatesRef = null;
    this.bodyRef = null;
    this.inputRef = null;

    this.dragProperties = this.dragProperties.bind(this);
    this.handleRootRef = this.handleRootRef.bind(this);
    this.handleBodyRef = this.handleBodyRef.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleInputRef = this.handleInputRef.bind(this);
    this.renderRoot = this.renderRoot.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { edit } = this.props;

    if (prevProps.edit !== edit && edit && this.inputRef) {
      this.inputRef.focus();
    }
  }

  componentWillUnmount() {
    const { handleClick, handleDoubleClick } = this.props;

    if (this.bodyRef) {
      if (handleClick) {
        this.bodyRef.removeEventListener('click', this.handleClick, false);
      }

      if (handleDoubleClick) {
        this.bodyRef.removeEventListener('dblclick', this.handleDoubleClick, false);
      }
    }
  }

  dragProperties() {
    const { width, height } = this.state;

    let bodyCoordinates = { x: 0, y: 0 };

    if (this.rootCoordinatesRef) {
      bodyCoordinates = this.rootCoordinatesRef();
    }

    return { bodyWidth: width, bodyHeight: height, bodyCoordinates };
  }

  handleRootRef(ref) {
    const { onResize, coordinatesRef } = this.props;

    if (ref) {
      this.setState((prevState) => {
        if (prevState.width !== ref.offsetWidth) {
          return { width: ref.offsetWidth };
        }

        return null;
      });

      this.setState((prevState) => {
        if (prevState.height !== ref.offsetHeight) {
          return { height: ref.offsetHeight };
        }

        return null;
      });

      if (onResize) {
        onResize(ref.offsetWidth, ref.offsetHeight);
      }

      this.rootCoordinatesRef = function coordinates() {
        const domRect = ref.getBoundingClientRect();

        return { x: domRect.left, y: domRect.top };
      };

      if (coordinatesRef) {
        coordinatesRef(this.rootCoordinatesRef);
      }
    }
  }

  handleBodyRef(ref) {
    const { handleClick, handleDoubleClick } = this.props;

    if (ref) {
      this.bodyRef = ref;

      if (handleClick) {
        this.bodyRef.addEventListener('click', this.handleClick, false);
      }

      if (handleDoubleClick) {
        this.bodyRef.addEventListener('dblclick', this.handleDoubleClick, false);
      }
    }
  }

  handleClick() {
    const { handleClick } = this.props;

    handleClick();
  }

  handleDoubleClick() {
    const { handleDoubleClick } = this.props;

    handleDoubleClick();
  }

  handleKeyDown(event) {
    if (event.key === 'Enter' && this.inputRef) {
      this.inputRef.blur();
    }
  }

  handleInputRef(ref) {
    if (ref) {
      this.inputRef = ref;
    }
  }

  renderRoot() {
    const {
      classes,
      className,
      x,
      y,
      originX,
      originY,
      text,
      scale: scaleProp,
      draggable,
      edit,
      handleRemoveClick,
      handleChange,
      handleBlur,
    } = this.props;
    const { width, height } = this.state;

    const scale = scaleProp > 1 ? 1 : scaleProp;

    const originBehindBody = originX >= x && originX <= x + width && originY >= y && originY <= y + height;
    const originBehindButton = originX >= x - 24 * scale && originX <= x + 24 * scale && originY >= y - 24 * scale && originY <= y + 24 * scale; // eslint-disable-line max-len
    const buttonStyle = originBehindButton && !originBehindBody ? { left: width - 24 } : {};

    return (
      <div
        className={classNames(
          classes.root,
          {
            [classes.draggable]: draggable,
            [classes.hoverRemoveButton]: handleRemoveClick && !edit,
          },
          className,
        )}
        ref={ref => this.handleRootRef(ref)}
        style={{
          transform: `translate(${x}px, ${y}px) scale(${scale})`,
        }}
      >
        {handleRemoveClick && (
          <Tooltip title="Remove">
            <IconButton
              className={classes.button}
              onClick={() => handleRemoveClick()}
              disableRipple
              style={buttonStyle}
            >
              <div className={classes.circle}>
                <RemoveIcon />
              </div>
            </IconButton>
          </Tooltip>
        )}
        <div className={classes.body} ref={ref => this.handleBodyRef(ref)}>
          <AutosizeInput
            inputClassName={classNames(classes.input, { [classes.disabled]: !edit })}
            disabled={!edit}
            value={text}
            onChange={event => handleChange(event)}
            onBlur={() => handleBlur()}
            onKeyDown={event => this.handleKeyDown(event)}
            inputRef={this.handleInputRef}
          />
        </div>
      </div>
    );
  }

  render() {
    const { connectDragSource, connectDragPreview } = this.props;

    connectDragPreview(getEmptyImage());
    return connectDragSource(this.renderRoot());
  }
}

AnnotationBody.propTypes = {
  classes: PropTypes.object.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  className: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  originX: PropTypes.number,
  originY: PropTypes.number,
  text: PropTypes.string.isRequired,
  scale: PropTypes.number,
  draggable: PropTypes.bool,
  edit: PropTypes.bool,
  onResize: PropTypes.func,
  coordinatesRef: PropTypes.func,
  handleRemoveClick: PropTypes.func,
  handleClick: PropTypes.func,
  handleDoubleClick: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
};

AnnotationBody.defaultProps = {
  className: '',
  x: 0,
  y: 0,
  originX: 0,
  originY: 0,
  scale: 1,
  draggable: false,
  edit: false,
  onResize: undefined,
  coordinatesRef: undefined,
  handleRemoveClick: undefined,
  handleClick: undefined,
  handleDoubleClick: undefined,
  handleChange: undefined,
  handleBlur: undefined,
};

export default compose(
  withStyles(styles),
  DragSource(
    dragAndDrop.widgetBody,
    {
      beginDrag(props, _, component) {
        const { handleHide } = props;

        if (handleHide) {
          handleHide();
        }

        return { ...component.dragProperties(), ...props };
      },
      endDrag(props) {
        const { handleShow } = props;

        if (handleShow) {
          handleShow();
        }
      },
      canDrag(props) {
        return props.draggable && !props.edit;
      },
    },
    connect => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
    }),
  ),
)(AnnotationBody);
