import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  label: {
    '&$focused': {
      color: theme.palette.secondary.dark,
    },
  },
  focused: {},
  underline: {
    '&:after': {
      borderBottomColor: theme.palette.secondary.dark,
    },
  },
});

function TextField(props) {
  const {
    classes,
    className,
    id,
    label,
    type,
    value,
    defaultValue,
    placeholder,
    margin,
    fullWidth,
    disabled,
    readOnly,
    spellCheck,
    error,
    helperText,
    InputLabelProps,
    InputProps,
    onKeyPress,
    onBlur,
    onFocus,
    onChange,
  } = props;

  return (
    <FormControl className={className} disabled={disabled} fullWidth={fullWidth} margin={margin} autoComplete="off">
      {label !== undefined ? (
        <InputLabel
          FormLabelClasses={{
            root: classes.label,
            focused: classes.focused,
          }}
          htmlFor={id}
          {...InputLabelProps}
        >
          {label}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          underline: classes.underline,
        }}
        id={id}
        type={type}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        fullWidth={fullWidth}
        readOnly={readOnly}
        spellCheck={spellCheck}
        autoComplete="off"
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        {...InputProps}
      />
      {helperText && (
        <FormHelperText id={`${id}-text`} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

TextField.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  spellCheck: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
};

TextField.defaultProps = {
  className: undefined,
  label: undefined,
  type: undefined,
  value: undefined,
  defaultValue: undefined,
  placeholder: undefined,
  margin: 'none',
  fullWidth: false,
  disabled: false,
  readOnly: false,
  spellCheck: true,
  error: false,
  helperText: undefined,
  InputLabelProps: undefined,
  InputProps: undefined,
  onKeyPress: undefined,
  onBlur: undefined,
  onFocus: undefined,
  onChange: undefined,
};

export default withStyles(styles)(TextField);
