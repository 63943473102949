import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import EventListener from 'react-event-listener';
import compose from 'recompose/compose';
import AdminList from '../components/AdminList';
import AdminNav from '../components/AdminNav';
import AdminTable from '../components/AdminTable';
import AppBar from '../components/AppBar';
import AppBarLogo from '../components/AppBarLogo';
import Companies from '../components/Companies';
import CreateEditDialog from '../components/CreateEditDialog';
import Spinner from '../components/Spinner';
import Users from '../components/Users';
import withMobile from '../components/withMobile';
import { mixinHeightWithToolbar } from '../constants/theme';
import {
  apiDeleteCompany,
  apiDeleteUser,
  apiGetCompanies,
  apiGetCompany,
  apiGetUser,
  apiGetUserAlerts,
  apiGetUsers,
  apiModifyCompany,
  apiModifyUserRole,
} from '../utils/api';
import { Auth } from '../utils/auth';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: theme.sideSheet.width + theme.floorplan.width,
    margin: `${theme.spacing.unit * 3}px 0px`,
    ...mixinHeightWithToolbar(theme.spacing.unit * 6),
  },
  contentContainer: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    boxShadow: '0 2px 2px -1px rgba(153, 153, 153, 0.3), 0 1px 5px -2px rgba(153, 153, 153, 0.3)',
    boxSizing: 'border-box',
    padding: 1,
  },
  componentAppBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: theme.appBar.height,
    ...theme.mixins.gutters(),
  },
  componentContainer: {
    flexGrow: 1,
    height: '100%',
  },
  component: {
    width: '100%',
    height: `calc(100% - ${theme.bottomAppBar.height}px - 56px)`,
    marginTop: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100% - ${theme.bottomAppBar.height}px - 48px)`,
      marginTop: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${theme.bottomAppBar.height}px - 64px)`,
      marginTop: 64,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      ...mixinHeightWithToolbar(),
    },
  },
  sidebar: {
    width: theme.sidebar.width,
    height: '100%',
    paddingLeft: 1,
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sidebarClosed: {
    width: 0,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  sideSheet: {
    width: theme.sideSheet.width,
    height: '100%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sideSheetClosed: {
    width: 0,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  divider: {
    marginRight: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 3,
  },
  tabs: {
    ...theme.mixins.gutters(),
  },
  tab: theme.tab,
  button: {
    textTransform: 'none',
  },
});

class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: true,
      active: 'companies',
      index: 0,
      step: 0,
      open: false,
      companyId: undefined,
      userId: undefined,
      handling: false,
    };

    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleCompaniesClick = this.handleCompaniesClick.bind(this);
    this.handleCompanyClick = this.handleCompanyClick.bind(this);
    this.handleUsersClick = this.handleUsersClick.bind(this);
    this.handleUserClick = this.handleUserClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleAddCompanyClick = this.handleAddCompanyClick.bind(this);
    this.handleDeleteCompanyClick = this.handleDeleteCompanyClick.bind(this);
    this.handleDeleteUserClick = this.handleDeleteUserClick.bind(this);
    this.handleSaveCompanyClick = this.handleSaveCompanyClick.bind(this);
    this.handleSaveUserClick = this.handleSaveUserClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { widthMobile } = nextProps;
    const {
      sidebarOpen, active, index, step, companyId, userId,
    } = prevState;

    if (
      ((!widthMobile && !sidebarOpen && active === 'companies') || (widthMobile && step === 1 && index === 0))
      && !apiGetCompanies().some(item => item.id === companyId)
    ) {
      return {
        sidebarOpen: true,
        step: 0,
        open: false,
        companyId: undefined,
        userId: undefined,
      };
    }
    if (
      ((!widthMobile && !sidebarOpen && active === 'users') || (widthMobile && step === 1 && index === 1))
      && !apiGetUsers().some(item => item.id === userId)
    ) {
      return {
        sidebarOpen: true,
        step: 0,
        open: false,
        companyId: undefined,
        userId: undefined,
      };
    }

    return null;
  }

  handleTabClick(_, index) {
    this.setState({ index });
  }

  handleCompaniesClick() {
    this.setState({ active: 'companies' });
  }

  handleCompanyClick(event, companyId) {
    event.stopPropagation();
    this.setState({ sidebarOpen: false, step: 1, companyId });
  }

  handleUsersClick() {
    this.setState({ active: 'users' });
  }

  handleUserClick(event, userId) {
    event.stopPropagation();
    this.setState({ sidebarOpen: false, step: 1, userId });
  }

  handleBackClick() {
    const { history } = this.props;

    history.goBack();
  }

  handleAddClick() {
    this.setState({ open: true });
  }

  handleAddCompanyClick(name) {
    const { handling } = this.state;

    if (handling) return;

    if (name.length !== 0) {
      this.setState({ handling: true });

      apiModifyCompany({ name, description: name })
        .then(() => {
          this.setState({ handling: false });
        })
        .catch(() => {
          this.setState({ handling: false });
        });

      this.setState({ open: false });
    }
  }

  handleDeleteCompanyClick() {
    const { companyId, handling } = this.state;

    if (handling) return;

    this.setState({ handling: true });
    this.handleClose();

    apiDeleteCompany(companyId)
      .then(() => {
        this.setState({ handling: false });
      })
      .catch(() => {
        this.setState({ handling: false });
      });
  }

  handleDeleteUserClick() {
    const { userId, handling } = this.state;

    if (handling) return;

    this.setState({ handling: true });
    this.handleClose();

    apiDeleteUser(userId)
      .then(() => {
        this.setState({ handling: false });
      })
      .catch(() => {
        this.setState({ handling: false });
      });
  }

  handleSaveCompanyClick(name) {
    const { companyId, handling } = this.state;

    if (handling) return;

    if (name.length !== 0) {
      this.setState({ handling: true });

      apiModifyCompany({ name, description: name }, companyId)
        .then(() => {
          this.setState({ handling: false });
        })
        .catch(() => {
          this.setState({ handling: false });
        });

      this.handleClose();
    }
  }

  handleSaveUserClick(role) {
    const { userId, handling } = this.state;

    if (handling) return;

    this.setState({ handling: true });

    apiModifyUserRole(userId, role)
      .then(() => {
        this.setState({ handling: false });
      })
      .catch(() => {
        this.setState({ handling: false });
      });

    this.handleClose();
  }

  handleCancelClick() {
    this.setState({ open: false });
  }

  handleClose() {
    this.setState({
      sidebarOpen: true,
      step: 0,
      open: false,
      companyId: undefined,
      userId: undefined,
    });
  }

  handleKeyDown(event) {
    const { sidebarOpen } = this.state;

    if (event.key === 'Escape' && sidebarOpen === false) {
      event.preventDefault();
      this.handleClose();
    }
  }

  render() {
    const { classes, widthMobile } = this.props;
    const {
      sidebarOpen, active, index, step, companyId, userId, open, handling,
    } = this.state;

    const companies = apiGetCompanies();
    const users = apiGetUsers();

    return (
      <>
        {!widthMobile ? (
          <>
            <EventListener target="window" onKeyDown={this.handleKeyDown} />
            <AppBarLogo alerts={apiGetUserAlerts()} />
            <div className={classes.root}>
              <div className={classes.container}>
                <div className={classNames(classes.sidebar, { [classes.sidebarClosed]: !sidebarOpen })}>
                  <AdminNav
                    active={active}
                    companiesCount={companies.length}
                    usersCount={users.length}
                    handleBackClick={this.handleBackClick}
                    handleCompaniesClick={this.handleCompaniesClick}
                    handleUsersClick={this.handleUsersClick}
                  />
                </div>
                <div className={classes.contentContainer}>
                  <div className={classes.componentContainer}>
                    <Spinner show={handling}>
                      <div className={classes.componentAppBar}>
                        <Typography variant="h6">{active === 'companies' ? 'Companies' : 'Users'}</Typography>
                        {active === 'companies' && (
                          <Auth do="modify" on={active}>
                            <Button
                              className={classes.button}
                              variant="outlined"
                              color="secondary"
                              onClick={this.handleAddClick}
                            >
                              Add a company
                            </Button>
                          </Auth>
                        )}
                      </div>
                      <Divider className={classes.divider} />
                      <div className={classes.component}>
                        {active === 'companies' ? (
                          <AdminTable
                            rows={companies}
                            variant={active}
                            selectedRowId={companyId !== undefined ? companyId : undefined}
                            handleRowClick={this.handleCompanyClick}
                          />
                        ) : (
                          <AdminTable
                            rows={users}
                            variant={active}
                            selectedRowId={userId !== undefined ? userId : undefined}
                            handleRowClick={this.handleUserClick}
                          />
                        )}
                      </div>
                    </Spinner>
                  </div>
                  <div
                    className={classNames(classes.sideSheet, {
                      [classes.sideSheetClosed]: sidebarOpen,
                    })}
                  >
                    {!sidebarOpen && (
                      <>
                        {active === 'companies' ? (
                          <Companies
                            company={apiGetCompany(companyId)}
                            sideSheet
                            handleDeleteClick={this.handleDeleteCompanyClick}
                            handleSaveClick={this.handleSaveCompanyClick}
                            handleClose={this.handleClose}
                          />
                        ) : (
                          <Users
                            user={apiGetUser(userId)}
                            sideSheet
                            handleDeleteClick={this.handleDeleteUserClick}
                            handleSaveClick={this.handleSaveUserClick}
                            handleClose={this.handleClose}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Spinner show={handling}>
            {step === 0 && (
              <>
                <AppBar title="Admin tools" search alerts={apiGetUserAlerts()} handleBackClick={this.handleBackClick}>
                  <Tabs className={classes.tabs} value={index} fullWidth onChange={this.handleTabClick}>
                    {['Companies', 'Users'].map(item => (
                      <Tab className={classes.tab} label={item} key={item} />
                    ))}
                  </Tabs>
                </AppBar>
                <Divider />
                {index === 0 && (
                  <AdminList
                    items={companies}
                    variant="companies"
                    handleListItemClick={this.handleCompanyClick}
                    handleAddClick={this.handleAddClick}
                  />
                )}
                {index === 1 && <AdminList items={users} variant="users" handleListItemClick={this.handleUserClick} />}
              </>
            )}
            {step === 1 && (
              <>
                {index === 0 ? (
                  <Companies
                    company={apiGetCompany(companyId)}
                    handleBackClick={this.handleClose}
                    handleDeleteClick={this.handleDeleteCompanyClick}
                    handleSaveClick={this.handleSaveCompanyClick}
                  />
                ) : (
                  <Users
                    user={apiGetUser(userId)}
                    handleBackClick={this.handleClose}
                    handleDeleteClick={this.handleDeleteUserClick}
                    handleSaveClick={this.handleSaveUserClick}
                  />
                )}
              </>
            )}
          </Spinner>
        )}
        <CreateEditDialog
          title="Add a company"
          save="Add"
          cancel="Cancel"
          open={open}
          handleSaveClick={this.handleAddCompanyClick}
          handleCancelClick={this.handleCancelClick}
        />
      </>
    );
  }
}

Admin.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
};

export default compose(
  withMobile(),
  withStyles(styles),
)(Admin);
