import { withStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfHour from 'date-fns/start_of_hour';
import subHours from 'date-fns/sub_hours';
import PropTypes from 'prop-types';
import React from 'react';
import { VictoryAxis, VictoryChart, VictoryLine } from 'victory-chart';
import { VictoryLabel } from 'victory-core';

const styles = theme => ({
  text: theme.thumbnail.text,
});

function Thumbnail(props) {
  const { classes, theme, data } = props;

  const dataValues = data
    .filter(item => item.aggregate !== null && !Number.isNaN(Number(item.aggregate)))
    .map(item => item.aggregate);
  const dataMinVal = Math.min(...dataValues);
  const dataMaxVal = Math.max(...dataValues);
  const dataMidVal = (dataMaxVal + dataMinVal) / 2;
  const lineYDomain = dataMaxVal - dataMinVal > 2 ? [dataMinVal, dataMaxVal] : [dataMidVal - 1, dataMidVal + 1];
  const dataTimes = data.map(item => parse(item.timestamp));
  const dataMinTime = Math.min(...dataTimes);
  const dataMaxTime = Math.max(...dataTimes);

  let tempTime = dataMaxTime;

  const timeTicks = [];
  while (tempTime > dataMinTime) {
    timeTicks.unshift(startOfHour(tempTime));
    tempTime = subHours(tempTime, 1);
  }

  const lineXDomain = [dataMinTime, dataMaxTime];

  function CustomYAxisLabel(myProps) {
    const { text, x, y } = myProps;

    if (text === lineYDomain[0].toFixed(1)) {
      const updatedProps = Object.assign({}, myProps, {
        verticalAnchor: 'end',
        x: x + 2,
        y: y - 2,
      });
      return <VictoryLabel {...updatedProps} />;
    }
    const updatedProps = Object.assign({}, myProps, {
      verticalAnchor: 'start',
      x: x + 2,
      y: y + 2,
    });
    return <VictoryLabel {...updatedProps} />;
  }

  function CustomXAxisLabel(myProps) {
    const { x, y } = myProps;
    const updatedProps = Object.assign({}, myProps, {
      textAnchor: 'start',
      x: x + 2,
      y: y - 2,
    });
    return <VictoryLabel {...updatedProps} />;
  }

  return (
    <svg viewBox="0 0 304 136">
      {data.length > 0 ? (
        <VictoryChart
          domain={{ x: lineXDomain, y: lineYDomain }}
          domainPadding={{ y: 1 }}
          height={136}
          padding={{
            top: 1,
            bottom: 20,
            left: 1,
            right: 35,
          }}
          standalone={false}
          width={304}
        >
          <VictoryAxis
            offsetY={21}
            orientation="bottom"
            scale="time"
            style={{
              axis: { ...theme.thumbnail.axis, strokeWidth: 0 },
              grid: theme.thumbnail.axis,
              ticks: { ...theme.thumbnail.axis, size: 5 },
              tickLabels: { ...theme.thumbnail.labels, padding: 0 },
            }}
            tickFormat={timestamp => format(timestamp, 'h A')}
            tickLabelComponent={<CustomXAxisLabel />}
            tickValues={timeTicks}
          />
          <VictoryAxis
            dependentAxis
            offsetX={35}
            orientation="right"
            style={{
              axis: { ...theme.thumbnail.axis, strokeWidth: 0 },
              grid: { ...theme.thumbnail.axis, strokeWidth: 0 },
              ticks: { ...theme.thumbnail.axis, size: 35 },
              tickLabels: { ...theme.thumbnail.labels, padding: 0 },
            }}
            tickFormat={() => ''}
            tickValues={lineYDomain}
          />
          <VictoryAxis
            dependentAxis
            offsetX={35}
            orientation="right"
            style={{
              axis: { ...theme.thumbnail.axis, strokeWidth: 0 },
              grid: theme.thumbnail.axis,
              ticks: theme.thumbnail.axis,
              tickLabels: { ...theme.thumbnail.labels, padding: 0 },
            }}
            tickFormat={y => y.toFixed(1)}
            tickLabelComponent={<CustomYAxisLabel />}
            tickValues={lineYDomain}
          />
          <VictoryLine
            data={data.map(item => Object.assign(
              {},
              {
                value: item.aggregate,
                timestamp: parse(item.timestamp),
              },
            ))}
            scale={{ x: 'time', y: 'linear' }}
            style={theme.thumbnail.line}
            x="timestamp"
            y="value"
          />
        </VictoryChart>
      ) : (
        <text className={classes.text} x="152" y="68" textAnchor="middle" alignmentBaseline="middle">
          No data in the past 4 hours
        </text>
      )}
    </svg>
  );
}

Thumbnail.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles, { withTheme: true })(Thumbnail);
