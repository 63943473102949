import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';
import { formatMobile } from '../utils/formatMobile';

const styles = theme => ({
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
  name: {
    ...theme.mixins.gutters(),
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.up('md')]: {
      marginBottom: 8,
    },
  },
});

function UsersDetails(props) {
  const { classes, user } = props;

  return (
    <div className={classes.content}>
      <Typography className={classes.name} variant="h5">
        {user.name}
      </Typography>
      <Divider />
      <List>
        <ListItem>
          <ListItemText primary="Company" secondary={user.company} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Email" secondary={user.email} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Mobile" secondary={formatMobile(user.mobile)} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemText primary="Role" secondary={user.role} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListSubheader disableSticky>Sites</ListSubheader>
        {user.sites.some(item => item.isMember) === false ? (
          <ListItem>
            <ListItemText secondary="Team member of no sites" />
          </ListItem>
        ) : (
          user.sites.map(
            item => (item.isMember ? (
              <ListItem key={item.site.id}>
                <ListItemText secondary={item.site.name} />
              </ListItem>
            ) : null),
          )
        )}
      </List>
    </div>
  );
}

UsersDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles)(UsersDetails);
