import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  root: {
    height: '100%',
    position: 'relative',
    zIndex: 0,
  },
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.87)',
    borderRadius: 'inherit',
    zIndex: theme.zIndex.spinner,
  },
  spinner: {
    display: 'inline-block',
    position: 'relative',
  },
  bar: {
    animation: 'floating-bars 2.0s infinite linear',
    '&::after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      left: '0.4583333333em',
      top: '0.00833333333em',
      width: '0.08333333333em',
      height: '0.3064166667em',
      borderRadius: '0.04625em',
      background: theme.palette.secondary.light,
    },
  },
  '@keyframes floating-bars': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
});

function Spinner(props) {
  const {
    classes, theme, className, children, show, size,
  } = props;

  const blades = 12;
  const seconds = 2;

  return (
    <div className={classNames(classes.root, className)}>
      <Fade
        in={show}
        style={{
          transitionDelay: `${1000 - theme.transitions.duration.enteringScreen}ms`,
        }}
        unmountOnExit
      >
        <div className={classes.container}>
          <div className={classes.spinner} style={{ fontSize: size, width: size, height: size }}>
            {[...Array(blades)].map((_, index) => (
              <div
                key={index.toString()}
                className={classes.bar}
                style={{
                  transformOrigin: `${size / 2}px ${size / 2}px`,
                  transform: `rotate(${(index * 360) / blades}deg)`,
                  animationDelay: `${-seconds + ((index + 1) / blades) * seconds}s`,
                }}
              />
            ))}
          </div>
        </div>
      </Fade>
      {children}
    </div>
  );
}

Spinner.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  show: PropTypes.bool.isRequired,
  size: PropTypes.number,
};

Spinner.defaultProps = {
  className: '',
  children: null,
  size: 48,
};

export default withStyles(styles, { withTheme: true })(Spinner);
