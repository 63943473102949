import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import validEmail from '../utils/validEmail';
import TextField from './TextField';

const styles = theme => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    margin: '0 auto',
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 2,
  },
  list: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  caption: {
    lineHeight: '20px',
    letterSpacing: 0,
  },
  underline: {
    '&:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  button: {
    textTransform: 'none',
    marginTop: 21,
    [theme.breakpoints.up('md')]: {
      margin: '12px 0',
    },
  },
  inline: {
    display: 'inline',
  },
});

class Forgot extends React.Component {
  constructor(props) {
    super(props);

    const { email } = props;

    this.state = {
      step: 0,
      email,
      disabled: !validEmail(email),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
  }

  handleChange(event) {
    const value = event.target.value.toLowerCase();
    this.setState({ email: value, disabled: !validEmail(value) });
  }

  handleNextClick() {
    const { handleForgotPassword } = this.props;
    const { email } = this.state;

    handleForgotPassword(email)
      .then(() => this.setState({ step: 1 }))
      .catch(() => {});
  }

  render() {
    const { classes } = this.props;
    const { step, email, disabled } = this.state;

    return (
      <div className={classes.container}>
        <List className={classes.list}>
          {step === 0 && (
            <>
              <ListItem>
                <ListItemText
                  disableTypography
                  // prettier-ignore
                  primary={(
                    <Typography variant="subtitle1">
                      {"Enter your email and we'll send you a link to reset your password."}
                    </Typography>
                  )}
                />
              </ListItem>
              <ListItem>
                <TextField
                  id="forgot-email"
                  type="email"
                  label="Email"
                  value={email}
                  fullWidth
                  InputProps={{ autoFocus: true, spellCheck: false }}
                  onChange={event => this.handleChange(event)}
                />
              </ListItem>
              <ListItem>
                <Button
                  className={classes.button}
                  color="secondary"
                  variant="contained"
                  disabled={disabled}
                  onClick={this.handleNextClick}
                >
                  Next
                </Button>
              </ListItem>
            </>
          )}
          {step === 1 && (
            <ListItem>
              <ListItemText
                disableTypography
                // prettier-ignore
                primary={(
                  <p className={classes.inline}>
                    <Typography className={classes.inline} variant="subtitle1" component="span">
                      {'We have sent a link to reset your password to '}
                    </Typography>
                    <Typography className={classes.inline} variant="subtitle1" color="secondary" component="span" noWrap>
                      {email}
                    </Typography>
                    <span>.</span>
                  </p>
                )}
              />
            </ListItem>
          )}
        </List>
      </div>
    );
  }
}

Forgot.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  handleForgotPassword: PropTypes.func.isRequired,
};

export default withStyles(styles)(Forgot);
