import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import sensorTypes from '../constants/sensorTypes';
import { mixinHeightWithToolbar } from '../constants/theme';
import deepCopy from '../utils/deepCopy';
import getUnit from '../utils/getUnit';
import { menuItemsAlertRepeat } from '../utils/menuItems';
import Select from './Select';
import Tooltip from './Tooltip';
import ZonesAddTeam from './ZonesAddTeam';

const styles = theme => ({
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
  gutters: theme.mixins.gutters(),
  text: {
    paddingTop: 8,
  },
  spacing: {
    paddingBottom: 4,
  },
  label: {
    '&$focused': {
      color: theme.palette.secondary.dark,
    },
  },
  focused: {},
  underline: {
    '&:after': {
      borderBottomColor: theme.palette.secondary.dark,
    },
  },
  addButton: {
    marginLeft: -5,
    marginTop: theme.spacing.unit,
    color: theme.palette.secondary.main,
  },
  addLabel: {
    marginLeft: 10,
  },
});

class ZonesAlertEdit extends React.Component {
  constructor(props) {
    super(props);

    const { threshold } = props;

    this.state = {
      open: false,
      alertRepeat: threshold.antispamInterval,
      low: threshold.low,
      lowError: false,
      lowAdornment: threshold.low !== null,
      high: threshold.high,
      highError: false,
      highAdornment: threshold.high !== null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeAdornment = this.handleChangeAdornment.bind(this);
    this.handleBlurLow = this.handleBlurLow.bind(this);
    this.handleBlurHigh = this.handleBlurHigh.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAddTeamMemberClick = this.handleAddTeamMemberClick.bind(this);
    this.handleNewTeamMember = this.handleNewTeamMember.bind(this);
    this.handleRemoveTeamMember = this.handleRemoveTeamMember.bind(this);
  }

  handleChange = param => (event) => {
    const { threshold: thresholdProp, onChange } = this.props;

    const threshold = deepCopy(thresholdProp);
    this.setState({ [param]: event.target.value });
    threshold[param] = event.target.value;
    onChange(threshold);
  };

  handleChangeAdornment = param => (event) => {
    const { threshold: thresholdProp, onChange } = this.props;

    const threshold = deepCopy(thresholdProp);
    this.setState({
      [param]: event.target.value,
      [`${param}Adornment`]: event.target.value.length !== 0,
    });
    threshold[param] = event.target.value;
    onChange(threshold);
  };

  handleOnKeyPressTemperature = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode < 48 || keyCode > 57) {
      if (keyCode !== 45) {
        event.preventDefault();
      }
    }
  };

  handleOnKeyPressHumidity = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode < 48 || keyCode > 57) {
      event.preventDefault();
    }
  };

  handleClose() {
    this.setState({ open: false });
  }

  handleBlurLow() {
    const { threshold: thresholdProp, onChange } = this.props;
    const { low, high } = this.state;

    this.setState({ highError: false });
    if (thresholdProp.type === 'H') {
      let humidityLow = parseInt(low, 10);
      if (Number.isNaN(humidityLow)) {
        const threshold = deepCopy(thresholdProp);

        this.setState({ low: '' });
        this.setState({ lowError: false });

        threshold.low = '';
        onChange(threshold);
      } else {
        if (humidityLow > 100) {
          const threshold = deepCopy(thresholdProp);

          humidityLow = '100';
          this.setState({ low: humidityLow });

          threshold.low = humidityLow;
          onChange(threshold);
        }
        this.setState({
          lowError: parseInt(humidityLow, 10) > parseInt(high, 10),
        });
      }
    } else {
      this.setState({ lowError: parseInt(low, 10) > parseInt(high, 10) });
    }
  }

  handleBlurHigh() {
    const { threshold: thresholdProp, onChange } = this.props;
    const { low, high } = this.state;

    this.setState({ lowError: false });
    if (thresholdProp.type === 'H') {
      let humidityHigh = parseInt(high, 10);
      if (Number.isNaN(humidityHigh)) {
        const threshold = deepCopy(thresholdProp);

        this.setState({ high: '' });
        this.setState({ highError: false });

        threshold.high = '';
        onChange(threshold);
      } else {
        if (humidityHigh > 100) {
          const threshold = deepCopy(thresholdProp);

          humidityHigh = '100';
          this.setState({ high: humidityHigh });

          threshold.high = humidityHigh;
          onChange(threshold);
        }
        this.setState({
          highError: parseInt(low, 10) > parseInt(humidityHigh, 10),
        });
      }
    } else {
      this.setState({ highError: parseInt(low, 10) > parseInt(high, 10) });
    }
  }

  handleAddTeamMemberClick() {
    this.setState({ open: true });
  }

  handleNewTeamMember(id) {
    const { threshold: thresholdProp, onChange } = this.props;

    const threshold = deepCopy(thresholdProp);
    threshold.contacts.push({ id });
    onChange(threshold);
  }

  handleRemoveTeamMember(id) {
    const { threshold: thresholdProp, onChange } = this.props;

    const threshold = deepCopy(thresholdProp);
    threshold.contacts = threshold.contacts.filter(item => item.id !== id);
    onChange(threshold);
  }

  render() {
    const {
      classes, zoneName, threshold, contacts,
    } = this.props;
    const {
      open, alertRepeat, low, lowError, lowAdornment, high, highError, highAdornment,
    } = this.state;

    const contactsAvailableForAdd = contacts.filter(
      item => !threshold.contacts.some(contact => contact.id === item.userId),
    );

    return (
      <>
        <div className={classes.content}>
          <List>
            <ListItem>
              <ListItemText primary="Zone name" secondary={zoneName} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Monitored condition" secondary={threshold.sensorType} />
            </ListItem>
          </List>
          <Divider />
          {['T', 'H'].includes(sensorTypes[threshold.sensorType]) && (
            <>
              <List>
                <ListSubheader disableSticky>Alert limits</ListSubheader>
                <Typography
                  className={classNames(classes.text, classes.spacing, classes.gutters)}
                  variant="subtitle1"
                  color="textPrimary"
                >
                  {`Send alert when ${threshold.sensorType.toLowerCase()}`}
                </Typography>
                <ListItem>
                  <FormControl fullWidth>
                    <InputLabel
                      FormLabelClasses={{
                        root: classes.label,
                        focused: classes.focused,
                      }}
                      htmlFor="zones-alert-low"
                    >
                      Below
                    </InputLabel>
                    <Input
                      classes={{
                        underline: classes.underline,
                      }}
                      id="zones-alert-low"
                      placeholder="off"
                      type="number"
                      value={low || ''}
                      inputProps={sensorTypes[threshold.sensorType] === 'H' ? { min: '0', max: '100' } : {}}
                      onKeyPress={
                        sensorTypes[threshold.sensorType] === 'T'
                          ? this.handleOnKeyPressTemperature
                          : this.handleOnKeyPressHumidity
                      }
                      onChange={this.handleChangeAdornment('low')}
                      onBlur={this.handleBlurLow}
                      endAdornment={
                        lowAdornment && (
                          <InputAdornment position="end">{getUnit(sensorTypes[threshold.sensorType])}</InputAdornment>
                        )
                      }
                    />
                    {lowError && <FormHelperText error>Below limit exceeds above limit</FormHelperText>}
                  </FormControl>
                </ListItem>
                <ListItem>
                  <FormControl fullWidth>
                    <InputLabel
                      FormLabelClasses={{
                        root: classes.label,
                        focused: classes.focused,
                      }}
                      htmlFor="zones-alert-high"
                    >
                      Above
                    </InputLabel>
                    <Input
                      classes={{
                        underline: classes.underline,
                      }}
                      id="zones-alert-high"
                      placeholder="off"
                      type="number"
                      value={high || ''}
                      inputProps={sensorTypes[threshold.sensorType] === 'H' ? { min: '0', max: '100' } : {}}
                      onKeyPress={
                        sensorTypes[threshold.sensorType] === 'T'
                          ? this.handleOnKeyPressTemperature
                          : this.handleOnKeyPressHumidity
                      }
                      onChange={this.handleChangeAdornment('high')}
                      onBlur={this.handleBlurHigh}
                      endAdornment={
                        highAdornment && (
                          <InputAdornment position="end">{getUnit(sensorTypes[threshold.sensorType])}</InputAdornment>
                        )
                      }
                    />
                    {highError && <FormHelperText error>Above limit exceeds below limit</FormHelperText>}
                  </FormControl>
                </ListItem>
              </List>
              <Divider />
            </>
          )}
          <List>
            <ListItem>
              <Select
                id="zones-alert-repeat"
                label="Alert repeat"
                value={alertRepeat}
                fullWidth
                onChange={this.handleChange('alertRepeat')}
              >
                {menuItemsAlertRepeat.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListSubheader disableSticky>Team members to alert</ListSubheader>
            {threshold.contacts.map(item => (
              <ListItem key={item.id}>
                <ListItemText primary={contacts.find(contact => contact.userId === item.id).name} />
                <ListItemSecondaryAction>
                  <Tooltip title="Remove">
                    <IconButton onClick={() => this.handleRemoveTeamMember(item.id)}>
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <ListItem className={classes.addButton} button onClick={this.handleAddTeamMemberClick}>
              <AddIcon />
              <Typography className={classes.addLabel} variant="subtitle1" color="textSecondary">
                Add a team member
              </Typography>
            </ListItem>
          </List>
        </div>
        <ZonesAddTeam
          contacts={contactsAvailableForAdd}
          open={open}
          handleAddClick={this.handleNewTeamMember}
          handleClose={this.handleClose}
          key={open.toString()}
        />
      </>
    );
  }
}

ZonesAlertEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  zoneName: PropTypes.string.isRequired,
  threshold: PropTypes.object.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ZonesAlertEdit);
