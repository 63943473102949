import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { DragLayer } from 'react-dnd';
import compose from 'recompose/compose';
import dragAndDrop from '../constants/dragAndDrop';
import getOS from '../utils/getOS';
import Annotation from './Annotation';
import AnnotationBody from './AnnotationBody';
import CalloutDot from './CalloutDot';
import FloorplanGauge from './FloorplanGauge';
import FloorplanGaugeBody from './FloorplanGaugeBody';

const styles = {
  root: {
    position: 'absolute',
    pointerEvents: 'none',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  dot: {
    position: 'absolute',
    top: -10,
    left: -10,
    opacity: 0.54,
  },
  item: {
    position: 'absolute',
    transformOrigin: '0px 0px',
  },
  container: {
    '-webkit-filter': 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3))',
    filter:
      'drop-shadow(0px 1px 5px 0px rgba(0, 0, 0, 0.2)) drop-shadow(0px 2px 2px 0px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 1px -2px rgba(0, 0, 0, 0.12))',
  },
  containerIOS: {
    '-webkit-filter': 'unset',
  },
};

class EditorDragLayer extends React.Component {
  constructor(props) {
    super(props);

    this.getItemStyles = this.getItemStyles.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  getItemStyles() {
    const { initialOffset, currentOffset } = this.props;

    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      };
    }

    return null;
  }

  renderItem() {
    const {
      classes, widgets, item, itemType, initialOffset, currentOffset,
    } = this.props;

    if (!initialOffset || !currentOffset) {
      return null;
    }

    switch (itemType) {
      case dragAndDrop.widgetBody: {
        const {
          bodyCoordinates, index, x, y, originX, originY, scale,
        } = item;

        const widget = widgets[index];

        if (widget.type === 'sensor') {
          const { location } = item;

          if (!location) {
            return null;
          }

          return (
            <FloorplanGauge
              x={bodyCoordinates.x + (currentOffset.x - initialOffset.x)}
              y={bodyCoordinates.y + (currentOffset.y - initialOffset.y)}
              originX={bodyCoordinates.x + originX - x}
              originY={bodyCoordinates.y + originY - y}
              location={location}
              scale={scale}
            />
          );
        }

        if (widget.type === 'textbox') {
          const { text } = item;

          if (!text) {
            return null;
          }

          return (
            <Annotation
              x={bodyCoordinates.x + (currentOffset.x - initialOffset.x)}
              y={bodyCoordinates.y + (currentOffset.y - initialOffset.y)}
              originX={bodyCoordinates.x + originX - x}
              originY={bodyCoordinates.y + originY - y}
              text={text}
              scale={scale}
            />
          );
        }
        break;
      }
      case dragAndDrop.widgetCallout: {
        const {
          bodyCoordinates, index, x, y, originX, originY, scale,
        } = item;

        const widget = widgets[index];

        const calcOriginX = bodyCoordinates.x + originX - x + currentOffset.x - initialOffset.x;
        const calcOriginY = bodyCoordinates.y + originY - y + currentOffset.y - initialOffset.y;

        if (widget.type === 'sensor') {
          const { location } = item;

          if (!location) {
            return null;
          }

          return (
            <>
              <FloorplanGauge
                x={bodyCoordinates.x}
                y={bodyCoordinates.y}
                originX={calcOriginX}
                originY={calcOriginY}
                location={location}
                scale={scale}
              />
              <div
                className={classes.dot}
                style={{
                  transform: `translate(${calcOriginX}px, ${calcOriginY}px) scale(${scale})`,
                }}
              >
                <CalloutDot />
              </div>
            </>
          );
        }
        if (widget.type === 'textbox') {
          const { text } = item;

          if (!text) {
            return null;
          }

          return (
            <>
              <Annotation
                x={bodyCoordinates.x}
                y={bodyCoordinates.y}
                originX={calcOriginX}
                originY={calcOriginY}
                text={text}
                scale={scale}
              />
              <div
                className={classes.dot}
                style={{
                  transform: `translate(${calcOriginX}px, ${calcOriginY}px) scale(${scale})`,
                }}
              >
                <CalloutDot />
              </div>
            </>
          );
        }
        break;
      }
      case dragAndDrop.paletteAnnotation: {
        const { bodyCoordinates, scale } = item;

        const x = bodyCoordinates.x + currentOffset.x - initialOffset.x;
        const y = bodyCoordinates.y + currentOffset.y - initialOffset.y;

        return (
          <div className={classes.item} style={{ transform: `translate(${x}px, ${y}px) scale(${scale})` }}>
            <AnnotationBody text="Label" />
          </div>
        );
      }
      case dragAndDrop.paletteGauge: {
        const { bodyCoordinates, location, scale } = item;

        const x = bodyCoordinates.x + currentOffset.x - initialOffset.x;
        const y = bodyCoordinates.y + currentOffset.y - initialOffset.y;

        return (
          <div className={classes.item} style={{ transform: `translate(${x}px, ${y}px) scale(${scale})` }}>
            <FloorplanGaugeBody location={location} />
          </div>
        );
      }
      default:
        return null;
    }

    return null;
  }

  render() {
    const { classes, isDragging } = this.props;

    if (!isDragging) {
      return null;
    }

    return (
      <div className={classes.root}>
        <div
          className={classNames(classes.container, { [classes.containerIOS]: getOS() === 'iOS' })}
          style={this.getItemStyles(this.props)}
        >
          {this.renderItem()}
        </div>
      </div>
    );
  }
}

EditorDragLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  widgets: PropTypes.arrayOf(PropTypes.object).isRequired,
  item: PropTypes.object,
  itemType: PropTypes.string,
  initialOffset: PropTypes.object,
  currentOffset: PropTypes.object,
  isDragging: PropTypes.bool.isRequired,
};

EditorDragLayer.defaultProps = {
  item: undefined,
  itemType: undefined,
  initialOffset: undefined,
  currentOffset: undefined,
};

export default compose(
  withStyles(styles),
  DragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  })),
)(EditorDragLayer);
