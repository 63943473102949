import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';
import {
  emptyMobile, formatMobile, mobileMask, removeMobileFormat, validMobile,
} from '../utils/formatMobile';
import { passwordHelper, validPassword } from '../utils/passwords';

const styles = theme => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    margin: '0 auto',
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 2,
  },
  spacing: {
    marginLeft: 20,
  },
  spacingEmail: {
    marginTop: 8,
  },
  alignItems: {
    alignItems: 'flex-end',
  },
  caption: {
    lineHeight: '20px',
    letterSpacing: 0,
  },
  underline: {
    '&:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  list: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  button: {
    textTransform: 'none',
  },
  buttonText: {
    minWidth: 0,
    padding: 0,
    fontSize: 12,
    letterSpacing: 0.4,
    textTransform: 'none',
    color: theme.palette.secondary.main,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
  },
  buttonEdit: {
    marginLeft: 12,
  },
  buttonSave: {
    marginLeft: 20,
  },
  buttonCancel: {
    marginLeft: 16,
  },
  buttonDelete: {
    margin: '13px 0',
  },
  listItemPassword: {
    paddingBottom: 5,
  },
  helperTextCurrent: {
    marginTop: 4,
  },
  helperTextPassword: {
    paddingBottom: 11,
    ...theme.mixins.gutters(),
  },
});

function TextMask(props) {
  const { inputRef, ...other } = props;

  return <MaskedInput {...other} ref={inputRef} mask={mobileMask} placeholderChar={'\u2000'} showMask />;
}

TextMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Profile extends React.Component {
  constructor(props) {
    super(props);

    const { user } = props;

    this.state = {
      name: user ? user.name : '',
      nameEdit: false,
      nameError: null,
      company: user && user.company ? user.company : '',
      companyEdit: false,
      mobile: user && emptyMobile(user.mobile) === false ? user.mobile : '',
      mobileEdit: false,
      mobileError: null,
      password: '',
      passwordEdit: false,
      passwordError: null,
      passwordShow: false,
      passwordAgain: '',
      passwordCurrent: '',
      passwordCurrentError: null,
      passwordCurrentShow: false,
      passwordGeneralError: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleMobileChange = this.handleMobileChange.bind(this);
    this.handlePasswordAgainChange = this.handlePasswordAgainChange.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handlePasswordSaveClick = this.handlePasswordSaveClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handlePasswordCancelClick = this.handlePasswordCancelClick.bind(this);
    this.handlePasswordShowClick = this.handlePasswordShowClick.bind(this);
    this.handlePasswordCurrentShowClick = this.handlePasswordCurrentShowClick.bind(this);
  }

  handleChange(event, param) {
    this.setState({ [param]: event.target.value ? event.target.value : '', [`${param}Error`]: null });
  }

  handleMobileChange(event) {
    if (emptyMobile(event.target.value)) {
      this.setState({ mobile: '', mobileError: null });
    } else {
      this.setState({ mobile: removeMobileFormat(event.target.value), mobileError: null });
    }
  }

  handlePasswordAgainChange(event) {
    this.setState({ passwordAgain: event.target.value });
  }

  handleEditClick(param) {
    return () => {
      this.setState({ [`${param}Edit`]: true, [`${param}Error`]: null });
    };
  }

  handleSaveClick(param) {
    return () => {
      const { user, handleSaveClick: handleSaveClickProp } = this.props;
      const { name, company, mobile } = this.state;

      if (param === 'name' && !name) {
        this.setState({ nameError: 'Enter full name' });
        return;
      }

      if (param === 'mobile' && emptyMobile(mobile) === false && validMobile(mobile) === false) {
        this.setState({ mobileError: 'Invalid number' });
        return;
      }

      handleSaveClickProp(
        {
          id: user.id,
          email: user.email,
          name: param === 'name' ? name : user.name,
          company: param === 'company' ? company : user.company,
          mobile: param === 'mobile' ? mobile : user.mobile,
        },
        user.id,
      )
        .then(() => {})
        .catch(() => {});

      this.setState({ [`${param}Edit`]: false, [`${param}Error`]: null });
    };
  }

  handlePasswordSaveClick() {
    const { handleSavePassword: handleSavePasswordProp } = this.props;
    const { passwordCurrent, password, passwordAgain } = this.state;

    if (!passwordCurrent) {
      this.setState({ passwordCurrentError: 'Enter current password' });
    }

    if (!password) {
      this.setState({ passwordError: 'Enter a password', passwordGeneralError: null });
      return;
    }

    if (!passwordCurrent) {
      return;
    }

    // Check new password is valid
    const passwordError = validPassword(password);
    if (passwordError !== null) {
      this.setState({ passwordError, passwordGeneralError: null });
      return;
    }

    // Check new password different than current
    if (password === passwordCurrent) {
      this.setState({
        passwordError: null,
        passwordGeneralError: 'New password must be different than current password',
      });
      return;
    }

    // Check new passwords match
    if (password !== passwordAgain) {
      this.setState({ passwordError: null, passwordGeneralError: 'Passwords do not match' });
      return;
    }

    handleSavePasswordProp(password, passwordCurrent)
      .then(() => {
        this.setState({
          password: '',
          passwordEdit: false,
          passwordError: null,
          passwordShow: false,
          passwordAgain: '',
          passwordCurrent: '',
          passwordCurrentError: null,
          passwordCurrentShow: false,
          passwordGeneralError: null,
        });
      })
      .catch(() => {
        this.setState({
          passwordError: 'Unable to change password, check current password is correct',
        });
      });
  }

  handleCancelClick(param) {
    const { user } = this.props;

    return () => {
      this.setState({ [param]: user[param], [`${param}Edit`]: false, [`${param}Error`]: null });
    };
  }

  handlePasswordCancelClick() {
    this.setState({
      password: '',
      passwordEdit: false,
      passwordError: null,
      passwordShow: false,
      passwordAgain: '',
      passwordCurrent: '',
      passwordCurrentError: null,
      passwordCurrentShow: false,
      passwordGeneralError: null,
    });
  }

  handlePasswordShowClick() {
    const { passwordShow } = this.state;

    this.setState({ passwordShow: !passwordShow });
  }

  handlePasswordCurrentShowClick() {
    const { passwordCurrentShow } = this.state;

    this.setState({ passwordCurrentShow: !passwordCurrentShow });
  }

  render() {
    const { classes, user, handleDeleteClick } = this.props;
    const {
      name,
      nameEdit,
      nameError,
      company,
      companyEdit,
      mobile,
      mobileEdit,
      mobileError,
      password,
      passwordEdit,
      passwordError,
      passwordShow,
      passwordAgain,
      passwordCurrent,
      passwordCurrentError,
      passwordCurrentShow,
      passwordGeneralError,
    } = this.state;

    return (
      <div className={classes.container}>
        <List className={classes.list}>
          <ListSubheader disableSticky>Personal information</ListSubheader>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <>
                  <Typography className={classes.caption} variant="caption" color="textSecondary">
                    Email
                  </Typography>
                  <Typography className={classes.spacingEmail} variant="subtitle1">
                    {user.email}
                  </Typography>
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <>
                  <Typography className={classes.caption} variant="caption" color="textSecondary">
                    Full name
                  </Typography>
                  {nameEdit === false ? (
                    <Typography variant="subtitle1">
                      {name}
                      <span>
                        <Button
                          className={classNames(classes.buttonText, { [classes.buttonEdit]: name })}
                          onClick={this.handleEditClick('name')}
                        >
                          Edit
                        </Button>
                      </span>
                    </Typography>
                  ) : (
                    <>
                      <Input
                        classes={{
                          underline: classes.underline,
                        }}
                        id="profile-name"
                        value={name}
                        autoFocus
                        onChange={event => this.handleChange(event, 'name')}
                      />
                      <span>
                        <Button
                          className={classNames(classes.buttonText, classes.buttonSave)}
                          onClick={this.handleSaveClick('name')}
                        >
                          Save
                        </Button>
                        <Button
                          className={classNames(classes.buttonText, classes.buttonCancel)}
                          onClick={this.handleCancelClick('name')}
                        >
                          Cancel
                        </Button>
                      </span>
                      {nameError && <FormHelperText error>{nameError}</FormHelperText>}
                    </>
                  )}
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <>
                  <Typography className={classes.caption} variant="caption" color="textSecondary">
                    Company (optional)
                  </Typography>
                  {companyEdit === false ? (
                    <Typography variant="subtitle1">
                      {company}
                      <span>
                        <Button
                          className={classNames(classes.buttonText, { [classes.buttonEdit]: company })}
                          onClick={this.handleEditClick('company')}
                        >
                          Edit
                        </Button>
                      </span>
                    </Typography>
                  ) : (
                    <>
                      <Input
                        classes={{
                          underline: classes.underline,
                        }}
                        id="profile-company"
                        value={company}
                        autoFocus
                        onChange={event => this.handleChange(event, 'company')}
                      />
                      <span>
                        <Button
                          className={classNames(classes.buttonText, classes.buttonSave)}
                          onClick={this.handleSaveClick('company')}
                        >
                          Save
                        </Button>
                        <Button
                          className={classNames(classes.buttonText, classes.buttonCancel)}
                          onClick={this.handleCancelClick('company')}
                        >
                          Cancel
                        </Button>
                      </span>
                    </>
                  )}
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <>
                  <Typography className={classes.caption} variant="caption" color="textSecondary">
                    Mobile (optional)
                  </Typography>
                  {mobileEdit === false ? (
                    <Typography variant="subtitle1">
                      {formatMobile(mobile)}
                      <span>
                        <Button
                          className={classNames(classes.buttonText, { [classes.buttonEdit]: mobile })}
                          onClick={this.handleEditClick('mobile')}
                        >
                          Edit
                        </Button>
                      </span>
                    </Typography>
                  ) : (
                    <>
                      <Input
                        classes={{
                          underline: classes.underline,
                        }}
                        id="profile-mobile"
                        type="tel"
                        value={formatMobile(mobile)}
                        autoFocus
                        inputComponent={TextMask}
                        onChange={event => this.handleMobileChange(event)}
                      />
                      <span>
                        <Button
                          className={classNames(classes.buttonText, classes.buttonSave)}
                          onClick={this.handleSaveClick('mobile')}
                        >
                          Save
                        </Button>
                        <Button
                          className={classNames(classes.buttonText, classes.buttonCancel)}
                          onClick={this.handleCancelClick('mobile')}
                        >
                          Cancel
                        </Button>
                      </span>
                      {mobileError && <FormHelperText error>{mobileError}</FormHelperText>}
                    </>
                  )}
                </>
              }
            />
          </ListItem>
        </List>
        <Divider />
        <List className={classes.list}>
          <ListSubheader disableSticky>Change password</ListSubheader>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <>
                  {passwordEdit === false ? (
                    <>
                      <Typography className={classes.caption} variant="caption" color="textSecondary">
                        Password
                      </Typography>
                      <Typography variant="subtitle1">
                        ••••••••
                        <span>
                          <Button
                            className={classNames(classes.buttonText, classes.buttonEdit)}
                            onClick={this.handleEditClick('password')}
                          >
                            Edit
                          </Button>
                        </span>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography className={classes.caption} variant="caption" color="textSecondary">
                        Current password
                      </Typography>
                      <Input
                        classes={{
                          underline: classes.underline,
                        }}
                        id="profile-password-current"
                        type={passwordCurrentShow ? 'text' : 'password'}
                        value={passwordCurrent}
                        autoFocus
                        spellCheck="false"
                        onChange={event => this.handleChange(event, 'passwordCurrent')}
                      />
                      <span>
                        <IconButton onClick={this.handlePasswordCurrentShowClick}>
                          {passwordCurrentShow ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </span>
                      {passwordCurrentError && (
                        <FormHelperText className={classes.helperTextCurrent} error>
                          {passwordCurrentError}
                        </FormHelperText>
                      )}
                    </>
                  )}
                </>
              }
            />
          </ListItem>
          {passwordEdit && (
            <>
              <ListItem className={classNames(classes.alignItems, classes.listItemPassword)}>
                <div>
                  <Typography className={classes.caption} variant="caption" color="textSecondary">
                    Password
                  </Typography>
                  <Input
                    classes={{
                      underline: classes.underline,
                    }}
                    id="profile-password"
                    type={passwordShow ? 'text' : 'password'}
                    value={password}
                    spellCheck="false"
                    onChange={event => this.handleChange(event, 'password')}
                  />
                </div>
                <div className={classes.spacing}>
                  <Typography className={classes.caption} variant="caption" color="textSecondary">
                    Confirm password
                  </Typography>
                  <Input
                    classes={{
                      underline: classes.underline,
                    }}
                    id="profile-password-again"
                    type={passwordShow ? 'text' : 'password'}
                    value={passwordAgain}
                    spellCheck="false"
                    onChange={event => this.handlePasswordAgainChange(event)}
                  />
                </div>
                <span>
                  <IconButton onClick={this.handlePasswordShowClick}>
                    {passwordShow ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </span>
              </ListItem>
              {passwordError || passwordGeneralError ? (
                <FormHelperText className={classes.helperTextPassword} error>
                  {passwordError || passwordGeneralError}
                </FormHelperText>
              ) : (
                <FormHelperText className={classes.helperTextPassword}>{passwordHelper}</FormHelperText>
              )}
              <ListItem>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={this.handlePasswordSaveClick}
                >
                  Change password
                </Button>
                <Button
                  className={classNames(classes.button, classes.buttonCancel)}
                  color="secondary"
                  size="small"
                  onClick={this.handlePasswordCancelClick}
                >
                  Cancel
                </Button>
              </ListItem>
            </>
          )}
        </List>
        <Divider />
        <List>
          <ListItem>
            <Button
              className={classNames(classes.button, classes.buttonDelete)}
              variant="outlined"
              tabIndex={-1}
              onClick={handleDeleteClick}
            >
              Delete my account
            </Button>
          </ListItem>
        </List>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  handleSavePassword: PropTypes.func.isRequired,
};

export default withStyles(styles)(Profile);
