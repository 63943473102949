import { isWidthUp } from '@material-ui/core/withWidth';

function calcMenuWidth(width) {
  // Material UI menu width varies depending on the string length and the responsive UI breakpoint

  // mobile and desktop minimum blocks is 2
  // overridden to 3
  const minBlocks = 3;
  // mobile maximum blocks is 5
  let maxBlocks = 5;
  // mobile block width is 56dp
  let blockWidth = 56;

  // desktop responsive UI desktop breakpoint is 'md' or larger
  if (isWidthUp('md', width)) {
    // desktop block width is 64dp
    blockWidth = 64;
    // desktop maximum blocks is undefined
    maxBlocks = undefined;
  }

  const style = {
    minWidth: minBlocks * blockWidth,
  };

  if (maxBlocks) {
    return Object.assign(style, {
      maxWidth: maxBlocks * blockWidth,
    });
  }

  return style;
}

export default calcMenuWidth;
