import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';
import { AbilityContext, Auth } from '../utils/auth';
import deepCopy from '../utils/deepCopy';
import Select from './Select';


const styles = theme => ({
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
  name: {
    ...theme.mixins.gutters(),
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 8,
  },
  buttonMargin: {
    margin: '8px 0px',
  },
  buttonRemove: {
    margin: '12px 0',
    textTransform: 'none',
  },
});

class TeamEdit extends React.Component {
  constructor(props) {
    super(props);

    const { user } = props;

    this.state = {
      role: user.role,
      smsEnabled: user.notifications.smsEnabled,
      alertingDeviceSubscriptions: deepCopy(user.notifications.alertingDeviceSubscriptions),
    };

    this.handleDeviceChange = this.handleDeviceChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleSmsChange = this.handleSmsChange.bind(this);
  }

  handleDeviceChange(id, enabled) {
    const { user, handleChange } = this.props;
    const { alertingDeviceSubscriptions } = this.state;
    const deviceCopy = deepCopy(alertingDeviceSubscriptions);

    const device = deviceCopy.find(item => item.id === id);
    if (device) {
      device.enabled = enabled;
      this.setState({ alertingDeviceSubscriptions: deviceCopy });
      const updatedUser = deepCopy(user);
      updatedUser.notifications.alertingDeviceSubscriptions = deviceCopy;
      handleChange(updatedUser);
    }
  }

  handleRoleChange(role) {
    const { user, handleChange } = this.props;

    this.setState({ role });
    const updatedUser = deepCopy(user);
    updatedUser.role = role;
    handleChange(updatedUser);
  }

  handleSmsChange(smsEnabled) {
    const { user, handleChange } = this.props;

    this.setState({ smsEnabled });
    const updatedUser = deepCopy(user);
    updatedUser.notifications.smsEnabled = smsEnabled;
    handleChange(updatedUser);
  }

  render() {
    const {
      classes, user, handleDeleteClick,
    } = this.props;
    const { role, smsEnabled, alertingDeviceSubscriptions } = this.state;

    return (
      <div className={classes.content}>
        <Typography className={classes.name} variant="h5">
          {user.name}
        </Typography>
        <Divider />
        <List>
          <AbilityContext.Consumer>
            {myAbility => (myAbility.can('modify', 'settings') ? (
              <ListItem>
                <Select
                  id="role"
                  label="Site role"
                  value={role}
                  fullWidth
                  onChange={event => this.handleRoleChange(event.target.value)}
                >
                  {['Team Member', 'Site Admin', 'Support'].map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </ListItem>
            ) : (
              <ListItem>
                <ListItemText primary="Site Admin" />
                <ListItemSecondaryAction>
                  <Switch
                    checked={role === 'Site Admin'}
                    onChange={event => this.handleRoleChange(event.target.checked ? 'Site Admin' : 'Team Member')}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </AbilityContext.Consumer>
        </List>
        <Divider />
        <List>
          <ListItem>
            <ListItemText primary="Receive SMS alerts" />
            <ListItemSecondaryAction>
              <Switch checked={smsEnabled} onChange={event => this.handleSmsChange(event.target.checked)} />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Auth do="modify" on="settings">
          <Divider />
          <List>
            <ListSubheader disableSticky>Devices alerts</ListSubheader>
            {alertingDeviceSubscriptions.map(item => (
              <ListItem key={item.id}>
                <ListItemText primary={item.label} />
                <ListItemSecondaryAction>
                  <Switch
                    checked={item.enabled}
                    onChange={event => this.handleDeviceChange(item.id, event.target.checked)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Auth>
        <Divider />
        <List>
          <ListItem>
            <Button className={classes.buttonRemove} variant="outlined" onClick={handleDeleteClick}>
              Remove from team
            </Button>
          </ListItem>
        </List>
      </div>
    );
  }
}

TeamEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(TeamEdit);
