import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import MessageIcon from '@material-ui/icons/Message';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';

const styles = theme => ({
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
  name: {
    ...theme.mixins.gutters(),
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 8,
  },
  icons: {
    justifyContent: 'space-around',
  },
  label: {
    marginTop: -4,
  },
});

function TeamDetails(props) {
  const { classes, user } = props;

  return (
    <div className={classes.content}>
      <Typography className={classes.name} variant="h5">
        {user.name}
      </Typography>
      <Divider />
      <List>
        <ListItem className={classes.icons}>
          <IconButton color="secondary" href={`tel:${user.mobile}`}>
            <div>
              <CallIcon />
              <Typography className={classes.label} variant="caption" color="secondary">
                Call
              </Typography>
            </div>
          </IconButton>
          <IconButton
            color="secondary"
            href={`${window.navigator.platform === 'MacIntel' ? 'imessage' : 'sms'}:${user.mobile}`}
          >
            <div>
              <MessageIcon />
              <Typography className={classes.label} variant="caption" color="secondary">
                Text
              </Typography>
            </div>
          </IconButton>
          <IconButton color="secondary" href={`mailto:${user.email}`}>
            <div>
              <EmailIcon />
              <Typography className={classes.label} variant="caption" color="secondary">
                Email
              </Typography>
            </div>
          </IconButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemText primary="Company" secondary={user.company} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Role" secondary={user.role} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Receive SMS alerts" secondary={user.notifications.smsEnabled ? 'On' : 'Off'} />
        </ListItem>
      </List>
    </div>
  );
}

TeamDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles)(TeamDetails);
