import * as arrayIncludes from 'array-includes';
import * as arrayFrom from 'array.from';
import * as arrayPrototypeFind from 'array.prototype.find';
import * as arrayPrototypeFindindex from 'array.prototype.findindex';
import * as functionPrototypeName from 'function.prototype.name';
import * as isNan from 'is-nan';
import * as objectValues from 'object.values';

arrayIncludes.shim();
arrayPrototypeFind.shim();
arrayPrototypeFindindex.shim();
arrayFrom.shim();
functionPrototypeName.shim();
isNan.shim();
objectValues.shim();
