import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

function ListItemLink(props) {
  const {
    className: classNameProp, primary, to, disableTouchRipple,
  } = props;
  return (
    <ListItem
      className={classNames({ [classNameProp]: classNameProp !== undefined })}
      button
      component={Link}
      to={to}
      disableTouchRipple={disableTouchRipple}
    >
      <ListItemText
        disableTypography
        // prettier-ignore
        primary={(
          <Typography component="span" variant="body2">
            {primary}
          </Typography>
        )}
      />
    </ListItem>
  );
}

ListItemLink.propTypes = {
  className: PropTypes.string,
  primary: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  disableTouchRipple: PropTypes.bool,
};

ListItemLink.defaultProps = {
  className: undefined,
  disableTouchRipple: false,
};

export default ListItemLink;
