import Admin from '../containers/Admin';
import Devices from '../containers/Devices';
import Invitations from '../containers/Invitations';
import Locations from '../containers/Locations';
import Notifications from '../containers/Notifications';
import Profile from '../containers/Profile';
import Reports from '../containers/Reports';
import Search from '../containers/Search';
import Settings from '../containers/Settings';
import Sites from '../containers/Sites';
import Team from '../containers/Team';
import Zones from '../containers/Zones';
import Floorplans from './Floorplans';
import Site from './Site';

export const routes = [
  {
    path: '/site/:siteId',
    component: Site,
    authAccess: ability => ability.can('access', 'sites'),
  },
  {
    path: '/notifications',
    component: Notifications,
    authAccess: () => true,
  },
  {
    path: '/search',
    component: Search,
    authAccess: () => true,
  },
  {
    path: '/profile',
    component: Profile,
    authAccess: () => true,
  },
  {
    path: '/admin',
    component: Admin,
    authAccess: ability => ability.can('access', 'companies') && ability.can('access', 'users'),
  },
  {
    path: '/sites',
    component: Sites,
    authAccess: ability => ability.can('access', 'sites'),
  },
];

export const subRoutes = [
  {
    path: '/floorplans/:id',
    component: Floorplans,
    authAccess: ability => ability.can('access', 'floorplans'),
    backArrow: false,
  },
  {
    path: '/zones',
    component: Zones,
    authAccess: ability => ability.can('access', 'zones'),
    backArrow: true,
  },
  {
    path: '/devices',
    component: Devices,
    authAccess: ability => ability.can('access', 'devices'),
    backArrow: true,
  },
  {
    path: '/locations',
    component: Locations,
    authAccess: ability => ability.can('access', 'locations'),
    backArrow: true,
  },
  {
    path: '/team',
    component: Team,
    authAccess: ability => ability.can('access', 'team'),
    backArrow: true,
  },
  {
    path: '/settings',
    component: Settings,
    authAccess: ability => ability.can('access', 'settings'),
    backArrow: true,
  },
  {
    path: '/invitations',
    component: Invitations,
    authAccess: ability => ability.can('access', 'invitations'),
    backArrow: true,
  },
  {
    path: '/reports',
    component: Reports,
    authAccess: ability => ability.can('access', 'reports'),
    backArrow: false,
  },
];
