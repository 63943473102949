import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import TrendArrowIcon from '../images/icons/TrendArrow';

const styles = theme => ({
  trendArrow: {
    display: 'inline-block',
    'vertical-align': 'middle',
    fill: theme.floorplan.gauge.color.secondary,
  },
  alert: {
    fill: theme.palette.error.main,
  },
  hidden: {
    visibility: 'hidden',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
});

function TrendArrow(props) {
  const {
    classes, className: classNameProp, alert, data,
  } = props;

  const trendArrowHidden = data.length < 2 || Math.abs(data[0].aggregate - data[data.length - 1].aggregate) < 1;
  const trendArrowDown = data.length >= 2 && data[0].aggregate > data[data.length - 1].aggregate;

  return (
    <TrendArrowIcon
      className={classNames(
        classes.trendArrow,
        {
          [classes.alert]: alert,
          [classes.hidden]: trendArrowHidden,
          [classes.rotate]: trendArrowDown,
        },
        classNameProp,
      )}
    />
  );
}

TrendArrow.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  alert: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

TrendArrow.defaultProps = {
  className: undefined,
};

export default withStyles(styles)(TrendArrow);
