import MuiAppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import React from 'react';
import SearchComponent from '../components/Search';

const styles = theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
  },
  padding: {
    paddingLeft: theme.spacing.unit,
  },
  backIcon: {
    marginRight: 16,
    marginLeft: -16,
  },
});

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = { query: '' };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleListItemClick = this.handleListItemClick.bind(this);
  }

  handleBackClick() {
    const { history } = this.props;

    history.goBack();
  }

  handleChange(event, param) {
    this.setState({ [param]: event.target.value });
  }

  handleListItemClick(item) {
    const { history } = this.props;

    if (item.type === 'Site') {
      history.push(`/site/${item.id}/floorplans/${item.defaultFloorplanId}`);
    } else {
      history.push(`/site/${item.siteId}/floorplans/${item.id}`);
    }
  }

  render() {
    const { classes } = this.props;
    const { query } = this.state;

    return (
      <>
        <MuiAppBar className={classes.appBar} color="default">
          <Toolbar>
            <IconButton className={classes.backIcon} color="inherit" onClick={this.handleBackClick}>
              <ArrowBackIcon />
            </IconButton>
            <TextField
              className={classes.padding}
              id="search-query"
              fullWidth
              InputProps={{
                disableUnderline: true,
              }}
              inputRef={(node) => {
                if (node) {
                  node.focus();
                }
              }}
              onChange={event => this.handleChange(event, 'query')}
              placeholder="Search WEDGE"
              value={query}
            />
          </Toolbar>
        </MuiAppBar>
        <SearchComponent query={query} handleListItemClick={this.handleListItemClick} />
      </>
    );
  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles)(Search);
