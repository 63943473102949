import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';
import TextField from './TextField';

const styles = {
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
};

function CompaniesEdit(props) {
  const {
    classes, company, name, handleNameChange,
  } = props;

  return (
    <div className={classes.content}>
      <List>
        <ListItem>
          <TextField
            id="company-name"
            label="Company name"
            value={name}
            margin="normal"
            fullWidth
            onChange={handleNameChange}
          />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListSubheader disableSticky>Sites</ListSubheader>
        {company.sites.length === 0 ? (
          <ListItem>
            <ListItemText secondary="No sites" />
          </ListItem>
        ) : (
          company.sites.map(item => (
            <ListItem key={item.id}>
              <ListItemText secondary={item.name} />
            </ListItem>
          ))
        )}
      </List>
    </div>
  );
}

CompaniesEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  handleNameChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(CompaniesEdit);
