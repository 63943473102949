import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React from 'react';
import { Portal } from 'react-portal';
import formatValue from '../utils/formatValue';

const styles = theme => ({
  circle: {
    fill: 'none',
    stroke: theme.palette.secondary.main,
    strokeWidth: 1.5,
  },
  paper: {
    position: 'absolute',
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    zIndex: theme.zIndex.tooltip,
  },
});

class ChartTooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = { left: 0, top: 0 };

    this.handlePosition = this.handlePosition.bind(this);
  }

  handlePosition(node) {
    const { left, top } = this.state;

    if (node) {
      const rect = node.getBoundingClientRect();

      if (rect.left !== left || rect.top !== top) {
        this.setState({ left: rect.left, top: rect.top });
      }
    }
  }

  render() {
    const {
      classes, x, y, datum,
    } = this.props;
    const { left, top } = this.state;

    if (datum.value !== null) {
      return (
        <g>
          <circle className={classes.circle} cx={x} cy={y} r={4} ref={node => this.handlePosition(node)} />
          <Portal>
            <Paper
              className={classes.paper}
              style={{
                right: window.innerWidth - left + 10,
                bottom: window.innerHeight - top + 10,
              }}
              elevation={10}
            >
              <Typography variant="subtitle1">{formatValue(datum.value, datum.unit, 1)}</Typography>
              <Typography variant="caption">{format(datum.timestamp, 'MMM D h:mm A')}</Typography>
            </Paper>
          </Portal>
        </g>
      );
    }

    return null;
  }
}

ChartTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  datum: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChartTooltip);
