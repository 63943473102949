import IconButton from '@material-ui/core/IconButton';
import BusinessIcon from '@material-ui/icons/Business';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Tooltip from './Tooltip';

class SitesIcon extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { history } = this.props;

    history.push('/sites');
  }

  render() {
    const { color } = this.props;

    return (
      <Tooltip title="Go to sites directory" variant="large">
        <IconButton onClick={this.handleClick} color={color}>
          <BusinessIcon />
        </IconButton>
      </Tooltip>
    );
  }
}

SitesIcon.propTypes = {
  history: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
};

SitesIcon.defaultProps = {
  color: 'default',
};

export default withRouter(SitesIcon);
