import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';

function ConfirmDialog(props) {
  const {
    title, body, confirm, cancel, open, handleConfirmClick, handleCancelClick,
  } = props;

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={handleCancelClick}>
      <>
        <DialogTitle>{title}</DialogTitle>
      </>
      <DialogContent>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancel && (
          <Button autoFocus onClick={handleCancelClick}>
            {cancel}
          </Button>
        )}
        <Button color="secondary" autoFocus onClick={handleConfirmClick}>
          {confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  confirm: PropTypes.string.isRequired,
  cancel: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleConfirmClick: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func,
};

ConfirmDialog.defaultProps = {
  cancel: undefined,
  handleCancelClick: undefined,
};

export default ConfirmDialog;
