import Badge from '@material-ui/core/Badge';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MuiNotificationsIcon from '@material-ui/icons/Notifications';
import classNames from 'classnames';
import distanceInWords from 'date-fns/distance_in_words';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import Tooltip from './Tooltip';

const styles = theme => ({
  popper: {
    zIndex: theme.zIndex.appBar + 1,
    marginRight: theme.spacing.unit,
  },
  paper: {
    width: 520,
    maxHeight: 448,
    overflowY: 'auto',
    border: '1px solid #d9d9d9',
    borderBottomColor: 'rgba(153, 153, 153, 0.6)',
    boxShadow:
      '0 6px 12px -1px rgba(153, 153, 153, 0.2), 0 4px 4px -1px rgba(153, 153, 153, 0.2), 0 2px 2px 0 rgba(153, 153, 153, 0.2)',
  },
  spacing: {
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing.unit,
    },
  },
  badge: {
    top: -5,
    right: -5,
    width: 18,
    height: 18,
    fontSize: 11,
    fontWeight: theme.typography.fontWeightMedium,
  },
  itemText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textSecondary: {
    ...theme.typography.body2Next,
    color: theme.palette.text.secondary,
  },
  pressed: {
    backgroundColor: theme.palette.action.selected,
  },
});

class NotificationsIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      anchorEl: null,
      tooltipOpen: false,
      readOnly: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleTooltipOpen = this.handleTooltipOpen.bind(this);
    this.handleTooltipClose = this.handleTooltipClose.bind(this);
    this.handleListItemClick = this.handleListItemClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { location } = nextProps;
    const { readOnly } = prevState;

    const nextReadOnly = location.pathname.indexOf('notifications') !== -1;

    if (nextReadOnly !== readOnly) {
      return {
        readOnly: nextReadOnly,
      };
    }

    return null;
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleClose() {
    const { open } = this.state;

    if (!open) {
      return;
    }

    // setTimeout to ensure a close event comes after a target click event
    this.timeout = setTimeout(() => {
      this.setState({ open: false, anchorEl: null, tooltipOpen: false });
    });
  }

  handleTooltipOpen() {
    const { open } = this.state;

    this.setState({ tooltipOpen: !open });
  }

  handleTooltipClose() {
    this.setState({ tooltipOpen: false });
  }

  handleListItemClick(item) {
    const { history } = this.props;

    if (item.floorplanId) {
      history.push(`/site/${item.siteId}/floorplans/${item.floorplanId}`);
    } else {
      history.push(`/site/${item.siteId}`);
    }
    this.handleClose();
  }

  render() {
    const { classes, alerts, color } = this.props;
    const { open, anchorEl, tooltipOpen } = this.state;

    return (
      <>
        <Tooltip
          title="Coming soon..."
          variant="large"
          open={tooltipOpen}
          onOpen={this.handleTooltipOpen}
          onClose={this.handleTooltipClose}
        >
          <IconButton
            className={classNames(classes.spacing, { [classes.pressed]: open })}
            disableRipple
            onClick={() => {}} // Coming soon...
            color={color}
          >
            {alerts.length !== 0 ? (
              <Badge
                classes={{
                  badge: classes.badge,
                }}
                badgeContent={alerts.length}
                color="error"
              >
                <MuiNotificationsIcon />
              </Badge>
            ) : (
              <MuiNotificationsIcon />
            )}
          </IconButton>
        </Tooltip>
        <Popper className={classes.popper} open={open} anchorEl={anchorEl}>
          <ClickAwayListener onClickAway={this.handleClose}>
            <Grow in={open} id="list" style={{ transformOrigin: '0 0 0' }}>
              <Paper className={classes.paper}>
                {alerts.length !== 0 && (
                  <List disablePadding>
                    {alerts.map(item => (
                      <ListItem key={item.id} onClick={() => this.handleListItemClick(item)} divider button>
                        <ListItemIcon>
                          <ErrorOutlineIcon />
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          // prettier-ignore
                          primary={(
                            <Typography className={classes.itemText} variant="subtitle1">
                              {item.alertText}
                              <span className={classes.textSecondary}>
                                {distanceInWords(item.timestamp, new Date())}
                              </span>
                            </Typography>
                          )}
                          // prettier-ignore
                          secondary={(
                            <Typography variant="body2" color="textSecondary">
                              {item.siteName}
                              {item.locationName ? ` • Location ${item.locationName}` : ''}
                            </Typography>
                          )}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Paper>
            </Grow>
          </ClickAwayListener>
        </Popper>
      </>
    );
  }
}

NotificationsIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
};

NotificationsIcon.defaultProps = {
  color: 'default',
};

export default compose(
  withRouter,
  withStyles(styles),
)(NotificationsIcon);
