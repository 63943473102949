import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import AnnotationBody from './AnnotationBody';
import Callout from './Callout';
import EditorDot from './EditorDot';

const styles = theme => ({
  hide: {
    opacity: 0,
  },
  callout: {
    zIndex: -1,
  },
  hoverCalloutDot: {
    '&:hover ~ $dot': {
      opacity: 1,
    },
  },
  dot: {
    opacity: 0,
    transition: theme.transitions.create('opacity'),
  },
  hoverDot: {
    '&:hover': {
      opacity: 1,
    },
  },
});

class Annotation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bodyWidth: 0,
      bodyHeight: 0,
      hidden: false,
      edit: false,
      textState: '',
    };

    this.bodyCoordinatesRef = null;

    this.handleBodyResize = this.handleBodyResize.bind(this);
    this.handleBodyCoordinatesRef = this.handleBodyCoordinatesRef.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleBodyResize(bodyWidth, bodyHeight) {
    this.setState((prevState) => {
      if (prevState.bodyWidth !== bodyWidth) {
        return { bodyWidth };
      }

      return null;
    });

    this.setState((prevState) => {
      if (prevState.bodyHeight !== bodyHeight) {
        return { bodyHeight };
      }

      return null;
    });
  }

  handleBodyCoordinatesRef(ref) {
    this.bodyCoordinatesRef = ref;
  }

  handleHide() {
    this.setState({ hidden: true });
  }

  handleShow() {
    this.setState({ hidden: false });
  }

  handleDoubleClick() {
    const { text } = this.props;

    this.setState({ edit: true, textState: text });
  }

  handleBlur() {
    const { index, handleChange } = this.props;
    const { textState } = this.state;

    this.setState({ edit: false });

    if (textState !== '') {
      handleChange(index, textState);
    }
  }

  handleChange(event) {
    this.setState({ textState: event.target.value });
  }

  render() {
    const {
      classes,
      theme,
      index,
      x,
      y,
      originX,
      originY,
      text,
      scale: scaleProp,
      draggable,
      handleRemoveClick,
      handleClick,
    } = this.props;
    const {
      bodyWidth, bodyHeight, hidden, edit, textState,
    } = this.state;

    const scale = scaleProp > 1 ? 1 : scaleProp;

    const calloutBehindBody = originX >= x && originX <= x + bodyWidth * scale && originY >= y && originY <= y + bodyHeight * scale; // eslint-disable-line max-len
    return (
      <div className={classNames({ [classes.hide]: hidden })}>
        {!calloutBehindBody && (
          <Callout
            className={classNames(classes.callout, { [classes.hoverCalloutDot]: !edit })}
            from={{
              x: x + (bodyWidth * scale) / 2,
              y: y + (bodyHeight * scale) / 2,
            }}
            to={{ x: originX, y: originY }}
            scale={scale}
            fill={theme.floorplan.annotation.border}
            stroke={theme.floorplan.annotation.border}
          />
        )}
        <AnnotationBody
          className={classNames({ [classes.hoverCalloutDot]: !edit })}
          index={index}
          x={x}
          y={y}
          originX={originX}
          originY={originY}
          text={edit ? textState : text}
          scale={scale}
          draggable={draggable}
          edit={edit}
          onResize={this.handleBodyResize}
          coordinatesRef={this.handleBodyCoordinatesRef}
          handleRemoveClick={
            !edit && handleRemoveClick && index !== -1
              ? () => {
                handleRemoveClick(index);
              }
              : undefined
          }
          handleClick={
            draggable && handleClick && index !== -1
              ? () => {
                handleClick(index);
              }
              : undefined
          }
          handleDoubleClick={draggable ? this.handleDoubleClick : undefined}
          handleChange={this.handleChange}
          handleBlur={this.handleBlur}
          handleHide={this.handleHide}
          handleShow={this.handleShow}
        />
        {draggable
          && !edit && (
            <EditorDot
              className={classNames(classes.dot, { [classes.hoverDot]: !edit })}
              index={index}
              x={x}
              y={y}
              originX={calloutBehindBody ? x + (bodyWidth * scale) / 2 : originX}
              originY={calloutBehindBody ? y + (bodyHeight * scale) / 2 : originY}
              text={text}
              scale={scale}
              bodyCoordinates={this.bodyCoordinatesRef ? this.bodyCoordinatesRef() : { x: 0, y: 0 }}
              handleHide={this.handleHide}
              handleShow={this.handleShow}
            />
        )}
      </div>
    );
  }
}

Annotation.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  index: PropTypes.number,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  originX: PropTypes.number.isRequired,
  originY: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  scale: PropTypes.number.isRequired,
  draggable: PropTypes.bool,
  handleRemoveClick: PropTypes.func,
  handleClick: PropTypes.func,
  handleChange: PropTypes.func,
};

Annotation.defaultProps = {
  index: -1,
  draggable: false,
  handleRemoveClick: undefined,
  handleClick: undefined,
  handleChange: undefined,
};

export default withStyles(styles, { withTheme: true })(Annotation);
