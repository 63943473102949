import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Redirect, Route, Switch, withRouter,
} from 'react-router-dom';
import compose from 'recompose/compose';
import Error from '../components/Error';
import wedgeLogo from '../images/wedge_logo.svg';
import { routes } from '../routes/routes';
import {
  apiGetDefaultFloorplanId,
  apiGetDefaultSiteId,
  apiGetUserPermissions,
  apiRemoveAppHandlers,
  apiSetAppHandlers,
} from '../utils/api';
import { AbilityContext, getAbility } from '../utils/auth';
import has from '../utils/has';
import Forgot from './Forgot';
import Invite from './Invite';
import Reset from './Reset';
import SignIn from './SignIn';
import withRoot from './withRoot';

const styles = theme => ({
  container: {
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    '-webkitOverflowScrolling': 'touch',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
  progress: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  image: {
    width: 123.67,
    height: 28,
    marginBottom: 16,
  },
  linearProgress: {
    width: 64,
    height: 2,
  },
  progressBar: {
    backgroundColor: theme.palette.grey[300],
  },
  indeterminateBar: {
    backgroundColor: theme.palette.secondary.light,
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      ability: getAbility(),
    };

    this.handleApiNotify = this.handleApiNotify.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.renderSignIn = this.renderSignIn.bind(this);
    this.renderPrivateRoute = this.renderPrivateRoute.bind(this);
  }

  componentDidMount() {
    apiSetAppHandlers(this.handleAuthentication, this.handleApiNotify);
  }

  componentWillUnmount() {
    apiRemoveAppHandlers();
  }

  handleAuthentication = (isAuthenticated) => {
    this.setState({
      isAuthenticated,
      isAuthenticating: false,
      ability: getAbility(isAuthenticated ? apiGetUserPermissions() : null),
    });
  };

  renderInvite = props => <Invite {...props} />;

  renderSignIn = props => <SignIn {...props} />;

  renderForgot = (props) => {
    const { state } = props.location;
    return <Forgot {...props} key={state && has.call(state, 'email') ? state.email : undefined} />;
  };

  renderReset = props => <Reset {...props} />;

  handleApiNotify() {
    this.forceUpdate();
  }

  renderPrivateRoute({ path, component: Component, authAccess }) {
    const { isAuthenticated } = this.state;

    return (
      <Route
        path={path}
        key={path}
        render={props => (isAuthenticated === true ? (
          <AbilityContext.Consumer>
            {ability => (authAccess(ability) ? <Component {...props} /> : <Error variant="forbidden" />)}
          </AbilityContext.Consumer>
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        ))
        }
      />
    );
  }

  render() {
    const { classes } = this.props;
    const { isAuthenticating, isAuthenticated, ability } = this.state;
    const defaultSiteId = apiGetDefaultSiteId();

    let defaultPath = defaultSiteId ? `site/${defaultSiteId}` : undefined;
    if (defaultPath) {
      defaultPath = defaultPath.concat(`/floorplans/${apiGetDefaultFloorplanId(defaultSiteId)}`);
    } else if (!isAuthenticated) {
      defaultPath = '/signin';
    }

    const progressWithLogo = () => (
      <div className={classes.progress}>
        <img className={classes.image} src={wedgeLogo} alt="WEDGE logo" />
        <LinearProgress
          className={classes.linearProgress}
          classes={{ colorPrimary: classes.progressBar, barColorPrimary: classes.indeterminateBar }}
        />
      </div>
    );

    return (
      <AbilityContext.Provider value={ability}>
        <div className={classes.container}>
          {!isAuthenticating ? (
            <Switch>
              {routes.map(item => this.renderPrivateRoute(item))}
              <Route path="/invite/:inviteId" render={this.renderInvite} />
              <Route path="/signin/forgot" render={this.renderForgot} />
              <Route path="/signin/reset" render={this.renderReset} />
              <Route path="/signin" render={this.renderSignIn} />
              {defaultPath && <Redirect exact from="/" to={defaultPath} />}
              <Route component={progressWithLogo} />
            </Switch>
          ) : (
            progressWithLogo()
          )}
        </div>
      </AbilityContext.Provider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withRoot,
  withStyles(styles),
)(App);
