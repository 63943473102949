import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import compose from 'recompose/compose';
import uuidv4 from 'uuid/v4';
import WarningIcon from '../images/icons/WarningOutline';
import Tooltip from './Tooltip';
import withMobile from './withMobile';

const styles = theme => ({
  paper: {
    maxWidth: 464,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: -16,
    marginRight: -20,
    marginBottom: -8,
  },
  subtitle: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 18,
    marginBottom: -2,
    paddingBottom: 12,
    paddingTop: 12,
    ...theme.mixins.gutters(),
    borderRadius: 4,
    backgroundColor: 'rgba(176, 0, 32, 0.08)',

  },
  subtitleIcon: {
    display: 'inline-block',
    verticalAlign: 'top',
    fill: theme.palette.error.main,
    marginRight: 14,
  },
  subtitleText: {
    display: 'inline-block',
    verticalAlign: 'top',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 14,
    letterSpacing: 0.1,
    color: theme.palette.error.main,
    whiteSpace: 'normal',
  },
});

class HeaterWarning extends React.Component {
  constructor(props) {
    super(props);

    this.state = { scroll: 'paper' };

    this.modalId = `modal-${uuidv4()}`;
    this.paperId = `paper-${uuidv4()}`;
    this.modalHeight = 0;
    this.paperHeight = 0;

    this.handleModalResize = this.handleModalResize.bind(this);
    this.handlePaperResize = this.handlePaperResize.bind(this);
    this.handleDialogScroll = this.handleDialogScroll.bind(this);
  }

  handleModalResize(height) {
    this.modalHeight = height;
    this.handleDialogScroll(height, this.paperHeight);
  }

  handlePaperResize(height) {
    this.paperHeight = height;
    this.handleDialogScroll(this.modalHeight, height);
  }

  handleDialogScroll(modalHeight, paperHeight) {
    const { scroll } = this.state;

    if (paperHeight + 96 >= this.modalHeight && scroll === 'paper') {
      this.setState({ scroll: 'body' });
    } else if (paperHeight + 96 < modalHeight && scroll === 'body') {
      this.setState({ scroll: 'paper' });
    }
  }

  render() {
    const {
      classes, widthMobile, open, handleAcceptClick, handleDeclineClick,
    } = this.props;
    const { scroll } = this.state;

    return (
      // prettier-ignore
      <Dialog
        classes={widthMobile ? undefined : { paper: classes.paper }}
        fullScreen={widthMobile}
        open={open}
        onClose={handleDeclineClick}
        id={this.modalId}
        PaperProps={{ id: this.paperId }}
        scroll={scroll}
      >
        <DialogTitle disableTypography>
          <div className={widthMobile ? classes.title : undefined}>
            <Typography variant="h6">
              Warning
            </Typography>
            {widthMobile && (
              <Tooltip title="Disagree and close" placement="bottom-end">
                <IconButton visible="false" onClick={handleDeclineClick}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <div className={classes.subtitle}>
            <WarningIcon className={classes.subtitleIcon} />
            <Typography className={classes.subtitleText} variant="subtitle1">
              Death, Personal Injury, and Property Damage Hazard
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            I acknowledge that I am an authorized user of the smart heater, that I have read the
            instructions for safe operation of the smart heater, that I have satisfied myself that
            remote operation of the smart heater will not endanger any personnel or property, and
            that I assume responsibility for the risks of improper or unsafe use.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeclineClick}>
            Disagree
          </Button>
          <Button autoFocus onClick={handleAcceptClick}>
            Agree
          </Button>
        </DialogActions>
        <ReactResizeDetector
          handleHeight
          resizableElementId={this.modalId}
          onResize={(_, height) => this.handleModalResize(height)}
        />
        <ReactResizeDetector
          handleHeight
          resizableElementId={this.paperId}
          onResize={(_, height) => this.handlePaperResize(height)}
        />
      </Dialog>
    );
  }
}

HeaterWarning.propTypes = {
  classes: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  handleAcceptClick: PropTypes.func.isRequired,
  handleDeclineClick: PropTypes.func.isRequired,
};

export default compose(
  withMobile(),
  withStyles(styles),
)(HeaterWarning);
