import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import CircularProgress from '../components/CircularProgress';
import icAccess from '../images/icons/ic_access.svg';
import icAlert from '../images/icons/ic_alert.svg';
import icControl from '../images/icons/ic_control.svg';
import icLock from '../images/icons/ic_lock.svg';
import icPerson from '../images/icons/ic_person.svg';
import icYoutubePlay from '../images/icons/ic_youtube_play.svg';
import signinBg from '../images/signin-bg.jpg';
import wedgeByWesternOneLogo from '../images/wedge_by_westernone_logo.svg';
import { apiSignIn } from '../utils/api';

const styles = theme => ({
  body: {
    padding: 0,
    margin: 0,
    height: '100%',
    width: '100%',
    'font-family': 'Roboto, tahoma, serif',
    'font-display': 'optional',
    color: 'white',
    'background-color': 'white',
    'background-position': 'center',
    'background-repeat': 'no-repeat',
    'background-size': 'cover',
    overflow: 'hidden',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  },
  img: {
    'vertical-align': 'middle',
  },
  input: {
    '-ms-touch-action': 'manipulation',
    'touch-action': 'manipulation',
    margin: 0,
    'font-family': 'inherit',
    'font-size': 'inherit',
    'line-height': 'inherit',
    overflow: 'visible',
    '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
      '-webkit-box-shadow': '0 0 0px 1000px white inset !important',
    },
  },
  'container-fluid': {
    width: '100%',
    'margin-right': 'auto',
    'margin-left': 'auto',
    height: '100%',
    padding: 0,
    '@media (min-width: 992px)': {
      'background-image': `url(${signinBg})`,
    },
  },
  'no-gutters': {
    'margin-right': 0,
    'margin-left': 0,
  },
  'd-none': {
    display: 'none !important',
    '@media (min-width: 992px)': {
      display: 'block !important',
    },
  },
  col: {
    position: 'relative',
    width: '100%',
    'min-height': '1px',
    '-ms-flex-preferred-size': 0,
    'flex-basis': 0,
    '-ms-flex-positive': 1,
    'flex-grow': 1,
    'max-width': '100%',
  },
  'col-12': {
    position: 'relative',
    width: '100%',
    'min-height': '1px',
    '-ms-flex': '0 0 100%',
    flex: '0 0 100%',
    'max-width': '100%',
  },
  'col-lg': {
    position: 'relative',
    width: '100%',
    'min-height': '1px',
    '@media (min-width: 992px)': {
      '-ms-flex-preferred-size': 0,
      'flex-basis': 0,
      '-ms-flex-positive': 1,
      'flex-grow': 1,
      'max-width': '100%',
    },
  },
  'col-lg-auto': {
    position: 'relative',
    width: '100%',
    'min-height': '1px',
    '@media (min-width: 992px)': {
      '-ms-flex': '0 0 auto',
      flex: '0 0 auto',
      width: 'auto',
      'max-width': 'none',
    },
  },
  row: {
    display: 'flex',
    '-ms-flex-wrap': 'wrap',
    'flex-wrap': 'wrap',
  },
  'img-fluid': {
    'max-width': '100%',
    height: '100%',
  },
  'row-main': {
    height: '100%',
    '-ms-flex-pack': 'center !important',
    'justify-content': 'center !important',
    '@media (min-width: 992px)': {
      '-ms-flex-pack': 'end !important',
      'justify-content': 'flex-end !important',
    },
  },
  'logo-container': {
    width: '100%',
  },
  logo: {
    margin: '0 auto',
    width: '194.36px',
    height: '68px',
    '@media (min-width: 992px)': {
      width: '205.79px',
      height: '72px',
    },
    '@media (min-width: 1280px)': {
      width: '222.95px',
      height: '78px',
    },
    '@media (min-width: 1920px)': {
      width: '240.08px',
      height: '84px',
    },
  },
  'signin-column': {
    width: '100%',
    height: '100%',
    'max-width': '450px',
    'background-color': 'white',
  },
  'signin-container': {
    position: 'absolute',
    top: '4%',
    bottom: 0,
    '@media (min-width: 320px)': {
      left: '19px',
      right: '19px',
    },
    '@media (min-width: 360px)': {
      left: '37px',
      right: '37px',
    },
    '@media (min-width: 450px)': {
      left: '50px',
      right: '50px',
    },
    '@media (min-width: 992px)': {
      top: '20%',
    },
  },
  'form-signin': {
    'padding-top': '15px',
    width: '100%',
    '& $input[type="checkbox"]': {
      width: '13px',
      height: '13px',
    },
    '@media (min-width: 992px)': {
      'padding-top': '70px',
    },
  },
  'form-error': {
    'padding-bottom': '8px',
    visibility: 'hidden',
    'font-size': '14px',
    'font-weight': 'normal',
    'line-height': '22px',
    color: 'rgba(217, 17, 3, 1.0)',
  },
  'form-control': {
    display: 'block',
    width: '100%',
    padding: '0.375rem 0.75rem',
    'font-size': '1rem',
    'background-color': '#fff',
    'background-image': 'none',
    'background-clip': 'padding-box',
    border: '1px solid #ced4da',
    transition: 'border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s',
    'padding-left': 0,
    'font-weight': 'normal',
    'letter-spacing': '0.3px',
    'line-height': '32px',
    color: 'solid rgba(0, 0, 0, 0.54)',
    'border-radius': 0,
    'border-top': 'none',
    'border-right': 'none',
    'border-bottom': '1px solid rgba(0, 0, 0, 0.42)',
    'border-left': 'none',
    '&:hover': {
      'border-color': 'rgba(0, 0, 0, 0.87)',
      'box-shadow': 'none',
    },
    '&:focus': {
      'background-color': '#fff',
      outline: 0,
      color: 'solid rgba(0, 0, 0, 0.87)',
      'border-color': 'rgba(0, 0, 0, 1.0)',
      'box-shadow': 'none',
    },
    '&::-ms-expand': {
      'background-color': 'transparent',
      border: 0,
    },
    '&::-webkit-input-placeholder': {
      color: '#868e96',
      opacity: 1,
    },
    '&:-ms-input-placeholder': {
      color: '#868e96',
      opacity: 1,
    },
    '&::-ms-input-placeholder': {
      color: '#868e96',
      opacity: 1,
    },
    '&::placeholder': {
      color: '#868e96',
      opacity: 1,
    },
    '&:disabled, &[readonly]': {
      'background-color': '#e9ecef',
      opacity: 1,
    },
  },
  'input-group': {
    position: 'relative',
    display: 'flex',
    '-ms-flex-align': 'stretch',
    'align-items': 'stretch',
    width: '100%',
    '& $form-control': {
      position: 'relative',
      'z-index': 2,
      '-ms-flex': '1 1 auto',
      flex: '1 1 auto',
      width: '1%',
      'margin-bottom': 0,
      display: 'flex',
      '-ms-flex-align': 'center',
      'align-items': 'center',
      '&:active, &:focus, &:hover': {
        'z-index': 3,
      },
      '&:not(:first-child):not(:last-child)': {
        'border-radius': 0,
      },
      '&:not(:first-child)': {
        'border-top-left-radius': 0,
        'border-bottom-left-radius': 0,
      },
      '&:not(:last-child)': {
        'border-top-right-radius': 0,
        'border-bottom-right-radius': 0,
      },
    },
  },
  'input-group-addon': {
    'white-space': 'nowrap',
    display: 'flex',
    '-ms-flex-align': 'center',
    'align-items': 'center',
    padding: '0.375rem 0.75rem',
    'margin-bottom': 0,
    'font-size': '1rem',
    'font-weight': 400,
    'line-height': 1.5,
    color: '#495057',
    'text-align': 'center',
    'border-radius': '0.25rem',
    'padding-right': '16px',
    'padding-left': 0,
    'background-color': 'white',
    border: 'none',
    '& img': {
      opacity: 0.54,
    },
    '&:not(:first-child):not(:last-child)': {
      'border-radius': 0,
    },
    '&:not(:first-child)': {
      'border-top-left-radius': 0,
      'border-bottom-left-radius': 0,
    },
    '&:not(:last-child)': {
      'border-right': 0,
      'border-top-right-radius': 0,
      'border-bottom-right-radius': 0,
    },
  },
  'input-group-password': {
    'padding-top': '21px',
  },
  checkbox: {
    'padding-top': '20px',
    'padding-bottom': '8px',
    'font-size': '14px',
    'font-weight': 'normal',
    '& label': {
      color: 'rgba(0, 0, 0, 0.54)',
      'margin-left': '4px',
    },
    '& a': {
      'text-decoration': 'none',
      color: 'rgba(68, 138, 255, 0.87)',
    },
    '& a:hover': {
      'text-decoration': 'none',
      transition: 'color 0.2s ease-out',
      color: 'rgba(41, 98, 255, 1.0)',
    },
  },
  'promo-column': {
    height: '100%',
  },
  'promo-container': {
    'max-width': '850px',
    position: 'absolute',
    top: '13%',
    bottom: 0,
    right: 0,
    'padding-right': '50px',
    'padding-left': '50px',
    '@media (min-height: 800px)': {
      top: '15%',
    },
    '@media (min-height: 1050px)': {
      top: '17%',
    },
    '@media (min-height: 1200px)': {
      top: '19%',
    },
    '@media (min-height: 1440px)': {
      top: '21%',
    },
  },
  'promo-title': {
    'font-size': '72px',
    'font-weight': 500,
    'line-height': '80px',
  },
  'promo-subtitle': {
    'padding-top': '36px',
    'font-size': '22px',
    'font-weight': 'normal',
    'line-height': '30px',
    '& a': {
      'text-decoration': 'none',
      color: 'rgba(68, 138, 255, 1.0)',
      'padding-left': '8px',
    },
    '& a:hover': {
      'text-decoration': 'none',
      transition: 'color 0.2s ease-out',
      color: 'rgba(41, 98, 225, 1.0)',
    },
    '@media (min-height: 1050px)': {
      'padding-top': '41px',
    },
    '@media (min-height: 1200px)': {
      'padding-top': '44px',
    },
    '@media (min-height: 1440px)': {
      'padding-top': '52px',
    },
  },
  'promo-video': {
    'padding-top': '36px',
    'line-height': '24px',
    opacity: 1.0,
    display: 'inline-block',
    '&:hover': {
      'text-decoration': 'none',
      transition: 'opacity 0.2s ease-out',
      opacity: 0.7,
    },
    '& a': {
      'text-decoration': 'none',
      color: 'inherit',
      opacity: 'inherit',
    },
    '& img': {
      width: '24px',
      height: '24px',
      'margin-right': '12px',
    },
    '& span': {
      'font-weight': 500,
      'letter-spacing': '0.3px',
      'vertical-align': 'middle',
    },
    '@media (min-height: 1050px)': {
      'padding-top': '41px',
    },
    '@media (min-height: 1200px)': {
      'padding-top': '44px',
    },
    '@media (min-height: 1440px)': {
      'padding-top': '52px',
    },
  },
  'icon-container': {
    'padding-top': '65px',
    width: '100%',
  },
  icon: {
    width: '48px',
    height: '48px',
    margin: '0 auto',
    'background-size': 'contain',
    'background-repeat': 'no-repeat',
  },
  'icon-alert': {
    'background-image': `url(${icAlert})`,
  },
  'icon-control': {
    'background-image': `url(${icControl})`,
  },
  'icon-access': {
    'background-image': `url(${icAccess})`,
  },
  'icon-primary-text': {
    'padding-top': '25px',
    'font-size': '22px',
    'font-weight': 500,
    'line-height': '30px',
    'letter-spacing': '0.3px',
    'text-align': 'center',
  },
  'icon-secondary-text': {
    'padding-top': '8px',
    'font-size': '16px',
    'font-weight': 'normal',
    'line-height': '24px',
    'text-align': 'center',
  },
  signIn: {
    display: 'block',
    marginTop: 15,
    width: '100%',
    padding: '12px 0',
    borderRadius: 2,
    fontSize: 16,
    maxHeight: 48,
    letterSpacing: 0.5,
    textTransform: 'none',
    '@media (min-width: 992px)': {
      maxWidth: 234,
      margin: '19px auto 0 auto',
    },
  },
  signingIn: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&:active': {
      boxShadow: theme.shadows[2],
    },
  },
  flexContainer: {
    display: 'flex',
  },
  flexCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    display: 'block',
  },
  text: {
    display: 'inline',
    verticalAlign: 'middle',
  },
  flex: {
    flex: 1,
  },
  circularProgress: {
    marginLeft: 12,
  },
});

const defaultRedirect = { from: { pathname: '/' } };

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      redirectToReferrer: false,
      invalidCredentials: false,
      signingIn: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value.toLowerCase() });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleSignIn() {
    const { email, password, signingIn } = this.state;

    if (email.length === 0 || password.length === 0 || signingIn) return;

    this.setState({ invalidCredentials: false, signingIn: true });

    apiSignIn(email, password)
      .then(() => {
        this.setState(() => ({
          redirectToReferrer: true,
          signingIn: false,
        }));
      })
      .catch(() => {
        this.setState(() => ({
          redirectToReferrer: false,
          invalidCredentials: true,
          signingIn: false,
        }));
      });
  }

  render() {
    const { classes, location } = this.props;

    const { from } = location.state || defaultRedirect;
    const {
      email, password, redirectToReferrer, invalidCredentials, signingIn,
    } = this.state;
    const visibility = invalidCredentials ? 'visible' : 'hidden';

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <div className={`${classes['container-fluid']} ${classes.body}`}>
        <div className={`${classes.row} ${classes['no-gutters']} ${classes['row-main']}`}>
          <div
            className={`${classes['d-none']} ${classes['d-lg-block']} ${classes['col-lg']} ${classes['promo-column']}`}
          >
            <div className={classes['promo-container']}>
              <div className={classes['promo-title']}>
                SMART
                <br />
                &amp; TOUGH
              </div>
              <div className={classes['promo-subtitle']}>
                WEDGE is the smart system you need for complete project control, an innovative system for remotely
                monitoring worksites.
                <a href="http://www.westernone.ca/wedge">Learn more about WEDGE.</a>
              </div>
              <div className={classes['promo-video']}>
                <a
                  title="See Work Smarter with WEDGE"
                  href="https://youtu.be/PYoGh-Onwp4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icYoutubePlay} className={classes.img} alt="" />
                  <span>WATCH VIDEO</span>
                </a>
              </div>
              <div className={classes['icon-container']}>
                <div className={`${classes.row} ${classes['no-gutters']}`}>
                  <div className={classes.col}>
                    <div className={`${classes.icon} ${classes['icon-alert']}`} />
                    <div className={classes['icon-primary-text']}>Alerting</div>
                    <div className={classes['icon-secondary-text']}>Receive instant notifications</div>
                  </div>
                  <div className={classes.col}>
                    <div className={`${classes.icon} ${classes['icon-control']}`} />
                    <div className={classes['icon-primary-text']}>Control</div>
                    <div className={classes['icon-secondary-text']}>Take control of your environment</div>
                  </div>
                  <div className={classes.col}>
                    <div className={`${classes.icon} ${classes['icon-access']}`} />
                    <div className={classes['icon-primary-text']}>24/7 Access</div>
                    <div className={classes['icon-secondary-text']}>Access your data remotely</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${classes['col-12']} ${classes['col-lg-auto']} ${classes['signin-column']}`}>
            <div className={classes['signin-container']}>
              <div className={classes['logo-container']}>
                <div className={classes.logo}>
                  <img src={wedgeByWesternOneLogo} className={classes['img-fluid']} alt="WEDGE Sign In" />
                </div>
              </div>
              <form id="form" className={classes['form-signin']} onSubmit={event => event.preventDefault()}>
                <div id="invalid" className={classes['form-error']} style={{ visibility }}>
                  You entered an incorrect email or password
                </div>
                <div className={classes['input-group']}>
                  <span className={classes['input-group-addon']}>
                    <img src={icPerson} alt="" />
                  </span>
                  <input
                    id="username"
                    className={`${classes['form-control']} ${classes.input}`}
                    name="username"
                    type="email"
                    placeholder="Email"
                    required
                    autoCorrect="off"
                    spellCheck="false"
                    autoCapitalize="off"
                    value={email}
                    onChange={this.handleEmailChange}
                  />
                </div>
                <div className={`${classes['input-group']} ${classes['input-group-password']}`}>
                  <span className={classes['input-group-addon']}>
                    <img src={icLock} alt="" />
                  </span>
                  <input
                    id="password"
                    className={`${classes['form-control']} ${classes.input}`}
                    name="password"
                    type="password"
                    placeholder="Password"
                    required
                    value={password}
                    onChange={this.handlePasswordChange}
                  />
                </div>
                <div className={classes.checkbox}>
                  <Link to={{ pathname: '/signin/forgot', state: { email } }}>Forgot password?</Link>
                </div>
                <Button
                  className={classNames(classes.signIn, { [classes.signingIn]: signingIn })}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  onClick={() => this.handleSignIn()}
                >
                  <div className={classNames(classes.flexContainer, classes.flexCenter)}>
                    <div className={classes.flex} />
                    <div className={classes.textContainer}>
                      <span className={classes.text}>Sign in</span>
                    </div>
                    {signingIn ? (
                      <div className={classNames(classes.flex, classes.flexContainer)}>
                        <CircularProgress
                          className={classes.circularProgress}
                          color="primary"
                          size={13}
                          thickness={5}
                        />
                      </div>
                    ) : (
                      <div className={classes.flex} />
                    )}
                  </div>
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
};

SignIn.defaultProps = {
  location: { state: defaultRedirect },
};

export default withStyles(styles)(SignIn);
