import PropTypes from 'prop-types';
import React from 'react';
import AppBar from './AppBar';
import InvitationsDetails from './InvitationsDetails';
import InvitationsList from './InvitationsList';
import SideSheet from './SideSheet';
import Spinner from './Spinner';

class Invitations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      inviteId: undefined,
      handling: false,
    };

    this.getNameFromEmail = this.getNameFromEmail.bind(this);
    this.handleListItemClick = this.handleListItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleSendInvite = this.handleSendInvite.bind(this);
    this.handleDeleteInvite = this.handleDeleteInvite.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { invites } = nextProps;
    const { step, inviteId } = prevState;

    if (step === 1 && !invites.some(item => item.id === inviteId)) {
      // Go to list
      return { step: 0, inviteId: undefined };
    }

    return null;
  }

  getNameFromEmail(email) {
    const { getNameFromEmail } = this.props;
    const { handling } = this.state;

    if (handling) return Promise.reject();

    this.setState({ handling: true });

    return getNameFromEmail(email)
      .then((reply) => {
        this.setState({ handling: false });
        return Promise.resolve(reply);
      })
      .catch(() => this.setState({ handling: false }));
  }

  handleListItemClick(inviteId) {
    this.setState({ step: 1, inviteId });
  }

  handleBackClick() {
    // Go to list
    this.setState({ step: 0 });
  }

  handleSendInvite(siteId, email) {
    const { handleSendInvite } = this.props;
    const { handling } = this.state;

    if (handling) return Promise.reject();

    this.setState({ handling: true });

    return handleSendInvite(siteId, email)
      .then(() => {
        this.setState({ handling: false });
        return Promise.resolve();
      })
      .catch(() => {
        this.setState({ handling: false });
        return Promise.reject();
      });
  }

  handleDeleteInvite(siteId, inviteId) {
    const { handleDeleteInvite } = this.props;
    const { handling } = this.state;

    if (handling) return;

    this.setState({ handling: true });

    handleDeleteInvite(siteId, inviteId)
      .then(() => {
        // Go to list
        this.setState({ step: 0, handling: false });
      })
      .catch(() => this.setState({ handling: false }));
  }

  render() {
    const {
      siteId, invites, contacts, alerts, sideSheet, handleBackClick, handleClose,
    } = this.props;
    const { step, inviteId, handling } = this.state;
    const invite = invites.find(item => item.id === inviteId) || {};

    return (
      <>
        {sideSheet ? (
          <>
            {step === 0 && (
              <SideSheet title="Invitations" authSubject="invitations" showSpinner={handling} handleClose={handleClose}>
                <InvitationsList
                  siteId={siteId}
                  invites={invites}
                  contacts={contacts}
                  getNameFromEmail={this.getNameFromEmail}
                  handleListItemClick={this.handleListItemClick}
                  handleSendInvite={this.handleSendInvite}
                />
              </SideSheet>
            )}
            {step === 1 && (
              <SideSheet
                title="Pending invitation"
                authSubject="invitations"
                showSpinner={handling}
                handleBackClick={this.handleBackClick}
                handleClose={handleClose}
              >
                <InvitationsDetails
                  siteId={siteId}
                  invite={invite}
                  handleResendClick={this.handleSendInvite}
                  handleRevokeClick={this.handleDeleteInvite}
                />
              </SideSheet>
            )}
          </>
        ) : (
          <Spinner show={handling}>
            {step === 0 && (
              <>
                <AppBar title="Invitations" search alerts={alerts} handleBackClick={handleBackClick} />
                <InvitationsList
                  siteId={siteId}
                  invites={invites}
                  contacts={contacts}
                  getNameFromEmail={this.getNameFromEmail}
                  handleListItemClick={this.handleListItemClick}
                  handleSendInvite={this.handleSendInvite}
                />
              </>
            )}
            {step === 1 && (
              <>
                <AppBar title="Pending invitation" handleBackClick={handleBackClick} />
                <InvitationsDetails
                  siteId={siteId}
                  invite={invite}
                  handleResendClick={this.handleSendInvite}
                  handleRevokeClick={this.handleDeleteInvite}
                />
              </>
            )}
          </Spinner>
        )}
      </>
    );
  }
}

Invitations.propTypes = {
  siteId: PropTypes.string.isRequired,
  invites: PropTypes.arrayOf(PropTypes.object).isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  alerts: PropTypes.arrayOf(PropTypes.object),
  sideSheet: PropTypes.bool,
  getNameFromEmail: PropTypes.func.isRequired,
  handleBackClick: PropTypes.func,
  handleClose: PropTypes.func,
  handleDeleteInvite: PropTypes.func.isRequired,
  handleSendInvite: PropTypes.func.isRequired,
};

Invitations.defaultProps = {
  alerts: undefined,
  sideSheet: false,
  handleBackClick: undefined,
  handleClose: undefined,
};

export default Invitations;
