import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputIcon from '@material-ui/icons/Input';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

const styles = theme => ({
  container: {
    margin: 0,
  },
  listItem: {
    borderRightColor: theme.palette.background.default,
    borderRightStyle: 'solid',
    borderRightWidth: theme.spacing.unit,
    borderRadius: '24px 0px 0px 24px',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.common.white,
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
});

function ProfileNav(props) {
  const { classes, handleBackClick } = props;

  return (
    <div className={classes.container}>
      <List component="nav">
        <ListItem className={classes.listItem} button onClick={handleBackClick}>
          <ListItemIcon>
            <InputIcon />
          </ListItemIcon>
          <ListItemText disableTypography primary={<Typography variant="body2">Return to site</Typography>} />
        </ListItem>
      </List>
    </div>
  );
}

ProfileNav.propTypes = {
  classes: PropTypes.object.isRequired,
  handleBackClick: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles),
)(ProfileNav);
