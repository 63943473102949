import EscapeStringRegexp from 'escape-string-regexp';
import { apiGetSearch } from './api';

export default function filterSearch(query) {
  if (query === '') {
    return [];
  }

  const regexp = new RegExp(EscapeStringRegexp(query), 'i');

  const search = apiGetSearch();

  const addSite = search.length > 1;

  const sites = search.filter(item => regexp.test(item.name)).map(item => ({
    name: item.name,
    type: 'Site',
    id: item.id,
    defaultFloorplanId: item.defaultFloorplanId,
  }));

  const floorplans = search
    .map(site => site.floorplans.filter(item => regexp.test(item.name)).map(item => ({
      name: item.name,
      type: 'Floorplan',
      id: item.id,
      site: addSite ? site.name : undefined,
      siteId: site.id,
    })))
    .reduce((previous, current) => previous.concat(current), []);

  return sites.concat(floorplans);
}
