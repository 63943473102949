import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React from 'react';
import formatValue from '../utils/formatValue';
import getUnit from '../utils/getUnit';
import Crossout from './Crossout';
import Thumbnail from './Thumbnail';

const styles = theme => ({
  sensorContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  chartHeaderContainer: {
    width: '100%',
    display: 'flex',
    paddingBottom: theme.spacing.unit * 2,
  },
  flex: {
    flex: 1,
  },
  thumbnail: {
    width: '100%',
  },
});

function FloorplanThumbnails(props) {
  const { classes, location, handleClick } = props;

  return (
    <>
      <List>
        <ListItem>
          <ListItemText primary="Timestamp" secondary={format(location.timestamp, 'YYYY-MM-DD, h:mm A')} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListSubheader disableSticky>Sensors</ListSubheader>
        {location.sensors.length === 0 ? (
          <ListItem>
            <Typography variant="body2">No sensors</Typography>
          </ListItem>
        ) : (
          location.sensors.map((item) => {
            const sensorAlert = item.value.thresholds.some(value => value.exceeded);
            return (
              <ListItem
                className={classes.sensorContainer}
                key={item.id}
                onClick={() => handleClick(location, item)}
                button
              >
                <div className={classes.chartHeaderContainer}>
                  <Typography className={classes.flex} noWrap variant="subtitle1">
                    {item.sensorType}
                  </Typography>
                  {item.value.isMissing ? (
                    <Crossout strokeWidth={4}>
                      <Typography color={item.alert ? 'error' : 'default'} variant="subtitle1">
                        {formatValue(item.value.value, getUnit(item.type), 0)}
                      </Typography>
                    </Crossout>
                  ) : (
                    <Typography color={sensorAlert ? 'error' : 'default'} variant="subtitle1">
                      {formatValue(item.value.value, getUnit(item.type), 0)}
                    </Typography>
                  )}
                </div>
                <div className={classes.thumbnail}>
                  <Thumbnail data={item.value.thumbnail} />
                </div>
              </ListItem>
            );
          })
        )}
      </List>
    </>
  );
}

FloorplanThumbnails.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(FloorplanThumbnails);
