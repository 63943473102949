import { Ability } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { createContext } from 'react';

export const AbilityContext = createContext();
export const Auth = createContextualCan(AbilityContext.Consumer);

export function getAbility(permissions = null) {
  const ability = [];
  if (permissions !== undefined && permissions !== null) {
    permissions.forEach((item) => {
      const permission = item
        .replace(/([A-Z])/g, '-$1')
        .toLowerCase()
        .split('-');
      if (permission.length > 2) {
        ability.push([permission[1], permission.slice(2).join('-')]);
      }
    });
  }
  return new Ability(ability.map(item => ({ actions: item[0], subject: item[1] })));
}
