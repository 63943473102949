import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';
import ConfirmDialog from './ConfirmDialog';

const styles = theme => ({
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
  buttons: {
    justifyContent: 'flex-end',
    margin: `${theme.spacing.unit}px 0`,
  },
});

class InvitationsDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogTitle: '',
      dialogText: '',
      dialogCancel: '',
      dialogConfirm: '',
      dialogHandleClick: undefined,
      open: false,
    };

    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
    this.handleResendClick = this.handleResendClick.bind(this);
    this.handleRevokeClick = this.handleRevokeClick.bind(this);
  }

  handleCancelClick() {
    this.setState({ open: false });
  }

  handleConfirmClick() {
    const { dialogHandleClick } = this.state;
    dialogHandleClick();
    this.setState({ open: false });
  }

  handleResendClick() {
    const { siteId, invite, handleResendClick } = this.props;
    this.setState({
      dialogTitle: 'Send invite again',
      dialogText: 'The email invitation will be sent again',
      dialogCancel: 'Cancel',
      dialogConfirm: 'Send',
      dialogHandleClick: () => handleResendClick(siteId, invite.email),
      open: true,
    });
  }

  handleRevokeClick() {
    const { siteId, invite, handleRevokeClick } = this.props;
    this.setState({
      dialogTitle: 'Revoke this invite',
      dialogText: 'This invite will no longer work',
      dialogCancel: 'Keep',
      dialogConfirm: 'Revoke',
      dialogHandleClick: () => handleRevokeClick(siteId, invite.id),
      open: true,
    });
  }

  render() {
    const { classes, invite } = this.props;
    const {
      dialogTitle, dialogText, dialogCancel, dialogConfirm, open,
    } = this.state;

    return (
      <div className={classes.content}>
        <List>
          <ListItem>
            <ListItemText primary="Sent to" secondary={invite.email} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Invited by" secondary={invite.invitedBy} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Sent on" secondary={format(invite.timestamp, 'YYYY-MM-DD, h:mm A')} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Invite count" secondary={invite.sendCount} />
          </ListItem>
          <ListItem className={classes.buttons}>
            <Button onClick={this.handleRevokeClick}>Revoke invite</Button>
            <Button color="secondary" variant="outlined" onClick={this.handleResendClick}>
              Invite again
            </Button>
          </ListItem>
        </List>
        <ConfirmDialog
          open={open}
          title={dialogTitle}
          body={dialogText}
          cancel={dialogCancel}
          confirm={dialogConfirm}
          handleCancelClick={this.handleCancelClick}
          handleConfirmClick={this.handleConfirmClick}
        />
      </div>
    );
  }
}

InvitationsDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  siteId: PropTypes.string.isRequired,
  invite: PropTypes.object.isRequired,
  handleResendClick: PropTypes.func.isRequired,
  handleRevokeClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(InvitationsDetails);
