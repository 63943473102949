import PropTypes from 'prop-types';
import React from 'react';
import FloorplanEditor from './FloorplanEditor';

function FloorplansEditor(props) {
  const {
    floorplans,
    floorplan,
    image,
    widgets,
    locations,
    scale,
    onViewportScaleChange,
    handleBackClick,
    handleForwardClick,
    handleImageDrop,
    handleLocationDrop,
    handleAnnotationDrop,
    handleWidgetMove,
    handleCalloutMove,
    handleRemoveClick,
    handleTextChange,
    handleWidgetClick,
    handleFileDialogOpen,
  } = props;

  return (
    <FloorplanEditor
      floorplans={floorplans}
      floorplan={floorplan}
      image={image}
      widgets={widgets}
      locations={locations}
      scale={scale}
      onViewportScaleChange={onViewportScaleChange}
      handleBackClick={handleBackClick}
      handleForwardClick={handleForwardClick}
      handleImageDrop={handleImageDrop}
      handleLocationDrop={handleLocationDrop}
      handleAnnotationDrop={handleAnnotationDrop}
      handleWidgetMove={handleWidgetMove}
      handleCalloutMove={handleCalloutMove}
      handleRemoveClick={handleRemoveClick}
      handleTextChange={handleTextChange}
      handleWidgetClick={handleWidgetClick}
      handleFileDialogOpen={handleFileDialogOpen}
    />
  );
}

FloorplansEditor.propTypes = {
  floorplans: PropTypes.arrayOf(PropTypes.object).isRequired,
  floorplan: PropTypes.object,
  image: PropTypes.object,
  widgets: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  scale: PropTypes.number.isRequired,
  onViewportScaleChange: PropTypes.func.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleForwardClick: PropTypes.func.isRequired,
  handleImageDrop: PropTypes.func.isRequired,
  handleLocationDrop: PropTypes.func.isRequired,
  handleAnnotationDrop: PropTypes.func.isRequired,
  handleWidgetMove: PropTypes.func.isRequired,
  handleCalloutMove: PropTypes.func.isRequired,
  handleRemoveClick: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleWidgetClick: PropTypes.func.isRequired,
  handleFileDialogOpen: PropTypes.func.isRequired,
};

FloorplansEditor.defaultProps = {
  floorplan: undefined,
  image: undefined,
};

export default FloorplansEditor;
