import PropTypes from 'prop-types';
import React from 'react';
import AppBar from './AppBar';
import SideSheet from './SideSheet';
import Spinner from './Spinner';
import ZonesAlertDetails from './ZonesAlertDetails';
import ZonesAlertEdit from './ZonesAlertEdit';
import ZonesDetails from './ZonesDetails';
import ZonesEdit from './ZonesEdit';
import ZonesList from './ZonesList';

class Zones extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      zoneId: undefined,
      thresholdId: undefined,
      zone: undefined,
      threshold: undefined,
      handling: false,
    };

    this.handleListItemClick = this.handleListItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleMonitoredItemClick = this.handleMonitoredItemClick.bind(this);
    this.handleZoneChange = this.handleZoneChange.bind(this);
    this.handleAlertChange = this.handleAlertChange.bind(this);
    this.handleToggleAlert = this.handleToggleAlert.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { site } = nextProps;
    const { step, zoneId, thresholdId } = prevState;

    if ([1, 3, 4, 5].includes(step) && !site.zones.some(item => item.id === zoneId)) {
      // Go to list
      return { step: 0 };
    }
    if (
      [4, 5].includes(step)
      && !site.zones.find(item => item.id === zoneId).thresholds.some(item => item.id === thresholdId)
    ) {
      // Go to details
      return { step: 1 };
    }

    return null;
  }

  handleListItemClick(zoneId) {
    // Go to details
    this.setState({ step: 1, zoneId });
  }

  handleBackClick() {
    const { step } = this.state;

    if ([3, 4, 5].includes(step)) {
      // Go to zone details from zone edit, alert details or alert edit
      this.setState({ step: 1 });
    } else {
      // Go to list
      this.setState({ step: 0 });
    }
  }

  handleAddClick() {
    const zone = {
      name: '',
      locations: [],
      thresholds: [],
      newThresholds: [],
      environmentalControl: null,
    };
    // Go to add
    this.setState({ step: 2, zoneId: undefined, zone });
  }

  handleEditClick() {
    const { site } = this.props;
    const { step, zoneId, thresholdId } = this.state;

    if (step === 4) {
      // Go to alert edit from alert details
      this.setState({
        step: 5,
        threshold: site.zones.find(item => item.id === zoneId).thresholds.find(item => item.id === thresholdId),
      });
    } else {
      // Go to edit
      this.setState({ step: 3, zone: { ...site.zones.find(item => item.id === zoneId), newThresholds: [] } });
    }
  }

  handleDeleteClick() {
    const { site, handleDeleteThresholdClick, handleDeleteZoneClick } = this.props;
    const {
      step, zoneId, thresholdId, handling,
    } = this.state;

    if (handling) return;

    this.setState({ handling: true });

    if (step === 4) {
      handleDeleteThresholdClick(site.id, zoneId, thresholdId)
        .then(() => {
          // Go to details from alert details
          this.setState({ step: 1, handling: false });
        })
        .catch(() => {
          this.setState({ handling: false });
        });
    } else {
      handleDeleteZoneClick(site.id, zoneId)
        .then(() => {
          // Go to list
          this.setState({ step: 1, handling: false });
        })
        .catch(() => {
          this.setState({ handling: false });
        });
    }
  }

  handleSaveClick() {
    const {
      site, handleDeleteThresholdClick, handleSaveThresholdClick, handleSaveZoneClick,
    } = this.props;
    const {
      step, zoneId, zone, threshold, handling,
    } = this.state;

    if (handling) return;

    this.setState({ handling: true });

    if (step === 5) {
      handleSaveThresholdClick(threshold, site.id, zoneId, threshold.id)
        .then(() => {
          // Go to zone details from alert edit
          this.setState({ step: 1, handling: false });
        })
        .catch(() => {
          this.setState({ handling: false });
        });
    } else {
      handleSaveZoneClick(
        {
          name: zone.name,
          locations: zone.locations.filter(item => site.locations.some(value => value.id === item.id)),
          environmentalControl: zone.environmentalControl,
        },
        site.id,
        zoneId,
      )
        .then((newZone) => {
          const newZoneId = newZone.id;
          const origZone = site.zones.find(item => item.id === zoneId);

          const promises = [];

          if (origZone) {
            origZone.thresholds.filter(item => !zone.thresholds.some(value => value.id === item.id)).forEach((item) => {
              promises.push(handleDeleteThresholdClick(site.id, zoneId, item.id).catch(() => {}));
            });
          }
          zone.thresholds.forEach((item) => {
            promises.push(handleSaveThresholdClick(item, site.id, newZoneId, item.id).catch(() => {}));
          });
          zone.newThresholds.forEach((item) => {
            promises.push(handleSaveThresholdClick(item, site.id, newZoneId).catch(() => {}));
          });

          Promise.all(promises)
            .then(() => {
              // Go to zone details from edit
              this.setState({ step: 1, zoneId: newZoneId, handling: false });
            })
            .catch(() => this.setState({ handling: false }));
        })
        .catch(() => {
          this.setState({ handling: false });
        });
    }
  }

  handleMonitoredItemClick(thresholdId) {
    // Go to alert details from details
    this.setState({ step: 4, thresholdId });
  }

  handleZoneChange(zone) {
    this.setState({ zone });
  }

  handleAlertChange(threshold) {
    this.setState({ threshold });
  }

  handleToggleAlert(threshold) {
    const { site, handleSaveThresholdClick } = this.props;
    const { step, zoneId } = this.state;

    if (step === 1) {
      handleSaveThresholdClick(threshold, site.id, zoneId, threshold.id).catch(() => {});
    }
  }

  render() {
    const {
      site, alerts, sideSheet, handleBackClick, handleClose,
    } = this.props;
    const {
      step, zoneId, thresholdId, zone, threshold, handling,
    } = this.state;

    return (
      <>
        {sideSheet ? (
          <>
            {step === 0 && (
              <SideSheet title="Zones" authSubject="zones" showSpinner={handling} handleClose={handleClose}>
                <ZonesList
                  zones={site.zones}
                  sideSheet
                  handleListItemClick={this.handleListItemClick}
                  handleAddClick={this.handleAddClick}
                />
              </SideSheet>
            )}
            {step === 1 && (
              <SideSheet
                title="Zone details"
                authSubject="zones"
                showSpinner={handling}
                handleBackClick={this.handleBackClick}
                handleEditClick={this.handleEditClick}
                handleDeleteClick={this.handleDeleteClick}
                handleClose={handleClose}
              >
                <ZonesDetails
                  zone={site.zones.find(item => item.id === zoneId)}
                  handleMonitoredItemClick={this.handleMonitoredItemClick}
                  handleToggleAlert={this.handleToggleAlert}
                />
              </SideSheet>
            )}
            {step === 2 && (
              <SideSheet
                title="Add zone"
                authSubject="zones"
                showSpinner={handling}
                handleBackClick={this.handleBackClick}
                handleSaveClick={this.handleSaveClick}
              >
                <ZonesEdit zones={site.zones} zone={zone} locations={site.locations} onChange={this.handleZoneChange} />
              </SideSheet>
            )}
            {step === 3 && (
              <SideSheet
                title="Edit zone"
                authSubject="zones"
                showSpinner={handling}
                handleBackClick={this.handleBackClick}
                handleSaveClick={this.handleSaveClick}
              >
                <ZonesEdit zones={site.zones} zone={zone} locations={site.locations} onChange={this.handleZoneChange} />
              </SideSheet>
            )}
            {step === 4 && (
              <SideSheet
                title="Zone alert details"
                authSubject="zones"
                showSpinner={handling}
                handleBackClick={this.handleBackClick}
                handleEditClick={this.handleEditClick}
                handleDeleteClick={this.handleDeleteClick}
                handleClose={handleClose}
              >
                <ZonesAlertDetails
                  zoneName={site.zones.find(item => item.id === zoneId).name}
                  threshold={site.zones
                    .find(item => item.id === zoneId)
                    .thresholds.find(item => item.id === thresholdId)}
                  contacts={site.contacts}
                />
              </SideSheet>
            )}
            {step === 5 && (
              <SideSheet
                title="Edit zone alert"
                authSubject="zones"
                showSpinner={handling}
                handleBackClick={this.handleBackClick}
                handleSaveClick={this.handleSaveClick}
              >
                <ZonesAlertEdit
                  zoneName={site.zones.find(item => item.id === zoneId).name}
                  threshold={threshold}
                  contacts={site.contacts}
                  onChange={this.handleAlertChange}
                />
              </SideSheet>
            )}
          </>
        ) : (
          <Spinner show={handling}>
            {step === 0 && (
              <>
                <AppBar title="Zones" search alerts={alerts} handleBackClick={handleBackClick} />
                <ZonesList
                  zones={site.zones}
                  handleListItemClick={this.handleListItemClick}
                  handleAddClick={this.handleAddClick}
                />
              </>
            )}
            {step === 1 && (
              <>
                <AppBar
                  title="Zone details"
                  handleBackClick={this.handleBackClick}
                  handleEditClick={this.handleEditClick}
                  handleDeleteClick={this.handleDeleteClick}
                />
                <ZonesDetails
                  zone={site.zones.find(item => item.id === zoneId)}
                  handleMonitoredItemClick={this.handleMonitoredItemClick}
                  handleToggleAlert={this.handleToggleAlert}
                />
              </>
            )}
            {step === 2 && (
              <>
                <AppBar
                  title="Add zone"
                  handleBackClick={this.handleBackClick}
                  handleSaveClick={this.handleSaveClick}
                />
                <ZonesEdit zones={site.zones} zone={zone} locations={site.locations} onChange={this.handleZoneChange} />
              </>
            )}
            {step === 3 && (
              <>
                <AppBar
                  title="Edit zone"
                  handleBackClick={this.handleBackClick}
                  handleSaveClick={this.handleSaveClick}
                />
                <ZonesEdit zones={site.zones} zone={zone} locations={site.locations} onChange={this.handleZoneChange} />
              </>
            )}
            {step === 4 && (
              <>
                <AppBar
                  title="Zone alert details"
                  handleBackClick={this.handleBackClick}
                  handleEditClick={this.handleEditClick}
                  handleDeleteClick={this.handleDeleteClick}
                />
                <ZonesAlertDetails
                  zoneName={site.zones.find(item => item.id === zoneId).name}
                  threshold={site.zones
                    .find(item => item.id === zoneId)
                    .thresholds.find(item => item.id === thresholdId)}
                  contacts={site.contacts}
                />
              </>
            )}
            {step === 5 && (
              <>
                <AppBar
                  title="Edit zone alert"
                  handleBackClick={this.handleBackClick}
                  handleSaveClick={this.handleSaveClick}
                />
                <ZonesAlertEdit
                  zoneName={site.zones.find(item => item.id === zoneId).name}
                  threshold={threshold}
                  contacts={site.contacts}
                  onChange={this.handleAlertChange}
                />
              </>
            )}
          </Spinner>
        )}
      </>
    );
  }
}

Zones.propTypes = {
  site: PropTypes.object.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.object),
  sideSheet: PropTypes.bool,
  handleBackClick: PropTypes.func,
  handleClose: PropTypes.func,
  handleDeleteThresholdClick: PropTypes.func.isRequired,
  handleSaveThresholdClick: PropTypes.func.isRequired,
  handleDeleteZoneClick: PropTypes.func.isRequired,
  handleSaveZoneClick: PropTypes.func.isRequired,
};

Zones.defaultProps = {
  alerts: undefined,
  sideSheet: false,
  handleBackClick: undefined,
  handleClose: undefined,
};

export default Zones;
