import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/lab/Slider';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import getOS from '../utils/getOS';
import EditorPaletteAnnotation from './EditorPaletteAnnotation';
import EditorPaletteGauge from './EditorPaletteGauge';
import SideSheet from './SideSheet';

const styles = theme => ({
  scroll: {
    height: 'calc(100% - 64px)',
    overflowY: 'auto',
  },
  sliderContainer: {
    padding: `${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 3}px`,
  },
  track: {
    backgroundColor: theme.palette.secondary.main,
  },
  thumb: {
    backgroundColor: theme.palette.secondary.main,
  },
  widgetContainer: {
    width: '100%',
    paddingTop: theme.spacing.unit * 5,
    '-webkit-filter': 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3))',
    filter:
      'drop-shadow(0px 1px 5px 0px rgba(0, 0, 0, 0.2)) drop-shadow(0px 2px 2px 0px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 1px -2px rgba(0, 0, 0, 0.12))',
  },
  widgetContainerIOS: {
    '-webkit-filter': 'unset',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing.unit * 5,
  },
  padding: {
    flex: 1,
  },
  annotation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function chunkArray(array, size) {
  const results = [];

  while (array.length) {
    results.push(array.splice(0, size));
  }

  return results;
}

function EditorPalette(props) {
  const {
    classes, locations, scale, scaleFactor, handleScaleFactorChange,
  } = props;

  const locationPairs = chunkArray(locations, 2);

  return (
    <SideSheet title="Drag and drop palette">
      <Divider />
      <div className={classes.scroll}>
        <List>
          <ListSubheader disableSticky>Size</ListSubheader>
          <ListItem>
            <Slider
              classes={{ track: classes.track, thumb: classes.thumb }}
              min={0.75}
              max={1}
              value={scaleFactor}
              onChange={handleScaleFactorChange}
            />
          </ListItem>
        </List>
        <div
          className={classNames(classes.widgetContainer, {
            [classes.widgetContainerIOS]: getOS() === 'iOS',
          })}
        >
          {locationPairs.map(item => (
            <div className={classes.row} key={item.length > 1 ? `${item[0].id}-${item[1].id}` : `${item[0].id}`}>
              <div className={classes.padding} />
              <EditorPaletteGauge key={item[0].id} location={item[0]} scale={scale} />
              <div className={classes.padding} />
              {item.length > 1 ? (
                <EditorPaletteGauge key={item[1].id} location={item[1]} scale={scale} />
              ) : (
                <div className={classes.annotation} style={{ flexBasis: 129 * scale }}>
                  <EditorPaletteAnnotation scale={scale} />
                </div>
              )}
              <div className={classes.padding} />
            </div>
          ))}
          {(locationPairs.length === 0 || locationPairs[locationPairs.length - 1].length === 2) && (
            <div className={classes.row}>
              <div className={classes.padding} />
              <EditorPaletteAnnotation scale={scale} />
              <div className={classes.padding} />
            </div>
          )}
        </div>
      </div>
    </SideSheet>
  );
}

EditorPalette.propTypes = {
  classes: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  scale: PropTypes.number.isRequired,
  scaleFactor: PropTypes.number.isRequired,
  handleScaleFactorChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(EditorPalette);
