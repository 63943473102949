import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControl from '@material-ui/core/FormControl';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import MuiSearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import filterSearch from '../utils/filterSearch';
import searchGetIcon from '../utils/search';
import Tooltip from './Tooltip';
import withMobile from './withMobile';

const styles = theme => ({
  textFieldContainer: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
  },
  textFieldNoWidth: {
    '& input': {
      transition: theme.transitions.create('width'),
      width: 0,
    },
  },
  textFieldFullWidth: {
    '& input': {
      transition: theme.transitions.create('width'),
      width: 200,
    },
  },
  inputUnderline: {
    '&:after': {
      borderBottomColor: 'rgba(255, 255, 255, 0.7)',
    },
  },
  inputRoot: {
    color: theme.palette.common.black,
  },
  popper: {
    zIndex: theme.zIndex.appBar + 1,
    marginRight: theme.spacing.unit,
  },
  paper: {
    width: 520,
    maxHeight: 448,
    overflowY: 'auto',
    border: '1px solid #d9d9d9',
    borderBottomColor: 'rgba(153, 153, 153, 0.6)',
    boxShadow:
      '0 6px 12px -1px rgba(153, 153, 153, 0.2), 0 4px 4px -1px rgba(153, 153, 153, 0.2), 0 2px 2px 0 rgba(153, 153, 153, 0.2)',
  },
  padding: {
    paddingLeft: theme.spacing.unit * 10,
  },
});

class SearchIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      anchorEl: null,
      tooltipOpen: false,
      query: '',
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleTooltipOpen = this.handleTooltipOpen.bind(this);
    this.handleTooltipClose = this.handleTooltipClose.bind(this);
    this.handleListItemClick = this.handleListItemClick.bind(this);
    this.handleQueryChange = this.handleQueryChange.bind(this);
  }

  componentDidUpdate(_, prevState) {
    const { open } = this.state;

    if (prevState.open !== open) {
      this.inputField.focus();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleClick(event) {
    const { history, widthMobile } = this.props;
    const { open } = this.state;

    const { currentTarget } = event;

    if (widthMobile) {
      history.push('/search');
    } else if (!open) {
      this.setState({ open: true, anchorEl: currentTarget, tooltipOpen: false });
    } else {
      this.setState({ open: false, anchorEl: null, tooltipOpen: false });
    }
  }

  handleClose() {
    const { open } = this.state;

    if (!open) {
      return;
    }

    if (document.activeElement !== this.inputField) {
      // setTimeout to ensure a close event comes after a target click event
      this.timeout = setTimeout(() => {
        this.setState({ open: false, anchorEl: null, tooltipOpen: false });
      });
    }
  }

  handleTooltipOpen() {
    const { open } = this.state;

    this.setState({ tooltipOpen: !open });
  }

  handleTooltipClose() {
    this.setState({ tooltipOpen: false });
  }

  handleListItemClick(item) {
    const { history } = this.props;

    this.setState({ query: '' });

    if (item.type === 'Site') {
      history.push(`/site/${item.id}/floorplans/${item.defaultFloorplanId}`);
    } else {
      history.push(`/site/${item.siteId}/floorplans/${item.id}`);
    }

    this.handleClose();
  }

  handleQueryChange(event) {
    this.setState({ query: event.target.value });
  }

  render() {
    const { classes, color } = this.props;
    const {
      open, anchorEl, tooltipOpen, query,
    } = this.state;

    const results = filterSearch(query);

    return (
      <>
        <div className={classes.textFieldContainer}>
          <Tooltip
            title="Search WEDGE"
            variant="large"
            open={tooltipOpen}
            onOpen={this.handleTooltipOpen}
            onClose={this.handleTooltipClose}
          >
            <IconButton onClick={this.handleClick} color={color}>
              <MuiSearchIcon />
            </IconButton>
          </Tooltip>
          <FormControl className={open ? classes.textFieldFullWidth : classes.textFieldNoWidth} autoComplete="off">
            <Input
              classes={{
                root: classes.inputRoot,
                underline: classes.inputUnderline,
              }}
              id="search-query"
              inputRef={(node) => {
                if (node) {
                  this.inputField = node;
                }
              }}
              placeholder="Search WEDGE"
              value={query}
              autoComplete="off"
              onChange={event => this.handleQueryChange(event)}
            />
          </FormControl>
        </div>
        <Popper className={classes.popper} open={open} anchorEl={anchorEl}>
          <ClickAwayListener onClickAway={this.handleClose}>
            <Grow in={open} id="list" style={{ transformOrigin: '0 0 0' }}>
              <Paper className={classes.paper}>
                {query !== '' && (
                  <List disablePadding>
                    {results.length !== 0 ? (
                      results.map(item => (
                        <ListItem button divider key={item.id} onClick={() => this.handleListItemClick(item)}>
                          <ListItemIcon>{searchGetIcon(item.type)}</ListItemIcon>
                          <ListItemText
                            primary={item.name}
                            secondary={item.site ? `${item.type} • ${item.site}` : item.type}
                          />
                        </ListItem>
                      ))
                    ) : (
                      <ListItem className={classes.padding}>
                        <ListItemText primary="No results" />
                      </ListItem>
                    )}
                  </List>
                )}
              </Paper>
            </Grow>
          </ClickAwayListener>
        </Popper>
      </>
    );
  }
}

SearchIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
};

SearchIcon.defaultProps = {
  color: 'default',
};

export default compose(
  withRouter,
  withMobile(),
  withStyles(styles),
)(SearchIcon);
