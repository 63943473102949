import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { mixinHeightWithToolbar } from '../constants/theme';
import { Auth } from '../utils/auth';
import validEmail from '../utils/validEmail';
import ConfirmDialog from './ConfirmDialog';
import TextField from './TextField';

const styles = theme => ({
  content: {
    overflowY: 'auto',
    ...mixinHeightWithToolbar(),
  },
  buttonMargin: {
    margin: `${theme.spacing.unit}px 0`,
  },
});

class InvitationsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enabled: false,
      email: '',
      dialogText: '',
      dialogCancel: undefined,
      dialogConfirm: '',
      dialogHandleClick: this.handleCancelClick,
      open: false,
    };

    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSendClick = this.handleSendClick.bind(this);
    this.handleSendInvite = this.handleSendInvite.bind(this);
  }

  handleCancelClick() {
    this.setState({ email: '', enabled: false, open: false });
  }

  handleEmailChange(event) {
    const email = event.target.value.toLowerCase();
    this.setState({ email, enabled: validEmail(email) });
  }

  handleSendClick() {
    const { contacts, getNameFromEmail } = this.props;
    const { email } = this.state;

    const contact = contacts.find(item => item.email === email);
    if (contact) {
      this.setState({
        dialogText: `${contact.name} is already a team member for this site`,
        dialogCancel: undefined,
        dialogConfirm: 'OK',
        dialogHandleClick: this.handleCancelClick,
        open: true,
      });
    } else {
      getNameFromEmail(email).then((reply) => {
        const { name } = reply;
        if (name) {
          this.setState({
            dialogText: `Do you want to invite ${name} to this site?`,
            dialogCancel: 'Cancel',
            dialogConfirm: 'Invite',
            dialogHandleClick: this.handleSendInvite,
            open: true,
          });
        } else {
          this.setState({
            dialogText: `Do you want to invite new user ${email} to this site?`,
            dialogCancel: 'Cancel',
            dialogConfirm: 'Invite',
            dialogHandleClick: this.handleSendInvite,
            open: true,
          });
        }
      });
    }
  }

  handleSendInvite() {
    const { siteId, handleSendInvite } = this.props;
    const { email } = this.state;

    handleSendInvite(siteId, email)
      .then(() => this.setState({ email: '', enabled: false }))
      .catch(() => {});
    this.setState({ open: false });
  }

  render() {
    const { classes, invites, handleListItemClick } = this.props;
    const {
      email, enabled, dialogText, dialogCancel, dialogConfirm, dialogHandleClick, open,
    } = this.state;

    return (
      <div className={classes.content}>
        <Auth do="modify" on="invitations">
          <>
            <List>
              <ListItem>
                <ListItemText primary="Invite a person to the team by email." />
              </ListItem>
              <ListItem>
                <TextField
                  id="invitations-email"
                  label="Email address"
                  value={email}
                  fullWidth
                  InputProps={{ spellCheck: false }}
                  onChange={this.handleEmailChange}
                />
              </ListItem>
              <ListItem>
                <Button
                  className={classes.buttonMargin}
                  color="secondary"
                  variant="contained"
                  disabled={!enabled}
                  onClick={this.handleSendClick}
                >
                  Send Invitation
                </Button>
              </ListItem>
            </List>
            <Divider />
          </>
        </Auth>
        <List>
          <ListSubheader disableSticky>Pending invitations</ListSubheader>
          {invites.length > 0 ? (
            invites.map(item => (
              <ListItem onClick={() => handleListItemClick(item.id)} key={item.id} button>
                <ListItemText primary={item.email} />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText secondary="No pending invitations" />
            </ListItem>
          )}
        </List>
        <ConfirmDialog
          open={open}
          title="Invite new team member"
          body={dialogText}
          confirm={dialogConfirm}
          cancel={dialogCancel}
          handleConfirmClick={dialogHandleClick}
          handleCancelClick={this.handleCancelClick}
        />
      </div>
    );
  }
}

InvitationsList.propTypes = {
  classes: PropTypes.object.isRequired,
  siteId: PropTypes.string.isRequired,
  invites: PropTypes.arrayOf(PropTypes.object).isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  getNameFromEmail: PropTypes.func.isRequired,
  handleListItemClick: PropTypes.func.isRequired,
  handleSendInvite: PropTypes.func.isRequired,
};

export default withStyles(styles)(InvitationsList);
