import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import Select from './Select';
import TextField from './TextField';

class SitesAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      companyId: '',
    };

    this.handleChange = param => (event) => {
      this.setState({ [param]: event.target.value });
    };

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  handleAddClick() {
    const { handleAddClick: handleAddClickProp } = this.props;
    const { name, companyId } = this.state;

    if (name.length > 0) handleAddClickProp(name, companyId);
  }

  handleCancelClick() {
    const { handleCancelClick: handleCancelClickProp } = this.props;

    this.setState({
      name: '',
      companyId: '',
    });

    handleCancelClickProp();
  }

  render() {
    const { companies, open } = this.props;
    const { name, companyId } = this.state;

    return (
      <Dialog maxWidth="xs" fullWidth open={open} onClose={this.handleClose}>
        <>
          <DialogTitle>Create a site</DialogTitle>
        </>
        <DialogContent>
          <List>
            <ListItem disableGutters>
              <TextField id="site-name" label="Site name" value={name} fullWidth onChange={this.handleChange('name')} />
            </ListItem>
            <ListItem disableGutters>
              <Select
                id="settings-company"
                label="Company"
                value={companyId}
                fullWidth
                onChange={this.handleChange('companyId')}
              >
                {companies.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.handleCancelClick}>
            Cancel
          </Button>
          <Button color="secondary" autoFocus onClick={this.handleAddClick} disabled={name === '' || companyId === ''}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SitesAdd.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
  handleAddClick: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
};

export default SitesAdd;
