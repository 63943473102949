import PropTypes from 'prop-types';
import React from 'react';
import AppBar from './AppBar';
import CreateEditDialog from './CreateEditDialog';
import LocationsDetails from './LocationsDetails';
import LocationsEdit from './LocationsEdit';
import LocationsList from './LocationsList';
import SideSheet from './SideSheet';
import Spinner from './Spinner';

class Locations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      id: undefined,
      open: false,
      location: undefined,
      handling: false,
    };

    this.handleListItemClick = this.handleListItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { site } = nextProps;
    const { step, id } = prevState;

    if ([1, 3].includes(step) && !site.locations.some(item => item.id === id)) {
      // Go to list
      return { step: 0 };
    }

    return null;
  }

  handleListItemClick(id) {
    // Go to details
    this.setState({ step: 1, id });
  }

  handleBackClick() {
    // Go to list
    this.setState({ step: 0 });
  }

  handleAddClick() {
    // Go to add
    this.setState({
      step: 2,
      id: undefined,
      location: {
        name: '',
        notes: '',
        sensors: [],
        newSensors: [],
        heater: null,
      },
    });
  }

  handleEditClick() {
    const { site } = this.props;
    const { id } = this.state;

    // Go to edit
    this.setState({ step: 3, location: { ...site.locations.find(item => item.id === id), newSensors: [] } });
  }

  handleDeleteClick() {
    const { site, handleDeleteClick } = this.props;
    const { id, handling } = this.state;

    if (handling) return;

    this.setState({
      handling: true,
      open: false,
    });

    handleDeleteClick(site.id, id)
      .then(() => {
        // Go to list
        this.setState({ step: 0, handling: false });
      })
      .catch(() => {
        this.setState({ handling: false });
      });
  }

  handleSaveClick() {
    const { site, handleSaveClick } = this.props;
    const { id, location, handling } = this.state;

    if (handling) return;

    this.setState({ handling: true });

    handleSaveClick(
      {
        name: location.name,
        notes: location.notes,
        sensors: location.sensors
          .map(item => ({
            sensorType: item.sensorType,
            id: item.id,
            notes: item.notes,
            barcode: item.barcode,
            channel: item.channel,
            type: item.type,
          }))
          .concat(
            location.newSensors.map(item => ({
              sensorType: item.sensorType,
              notes: item.notes,
              barcode: item.barcode,
              channel: item.channel,
              type: item.type,
            })),
          ),
        heater: location.heater,
      },
      site.id,
      id,
    )
      .then(() => {
        // Go to list
        this.setState({
          step: 0,
          open: false,
          handling: false,
        });
      })
      .catch(() => {
        this.setState({
          open: false,
          handling: false,
        });
      });
  }

  handleCancelClick() {
    // Go to list
    this.setState({ step: 0, open: false });
  }

  handleChange(location) {
    this.setState({ location });
  }

  render() {
    const {
      site, devices, alerts, sideSheet, handleBackClick, handleClose,
    } = this.props;
    const {
      step, id, open, location, handling,
    } = this.state;

    return (
      <>
        {sideSheet ? (
          <>
            {step === 0 && (
              <SideSheet title="Locations" authSubject="locations" showSpinner={handling} handleClose={handleClose}>
                <LocationsList
                  locations={site.locations}
                  sideSheet
                  handleListItemClick={this.handleListItemClick}
                  handleAddClick={this.handleAddClick}
                />
              </SideSheet>
            )}
            {step === 1 && (
              <SideSheet
                title="Location details"
                authSubject="locations"
                showSpinner={handling}
                handleBackClick={this.handleBackClick}
                handleEditClick={this.handleEditClick}
                handleDeleteClick={this.handleDeleteClick}
                handleClose={handleClose}
              >
                <LocationsDetails location={site.locations.find(item => item.id === id)} />
              </SideSheet>
            )}
            {step === 2 && (
              <SideSheet
                title="Add location"
                authSubject="locations"
                showSpinner={handling}
                handleBackClick={this.handleBackClick}
                handleSaveClick={this.handleSaveClick}
              >
                <LocationsEdit
                  locations={site.locations}
                  location={location}
                  devices={devices}
                  onChange={this.handleChange}
                />
              </SideSheet>
            )}
            {step === 3 && (
              <SideSheet
                title="Edit location"
                authSubject="locations"
                showSpinner={handling}
                handleBackClick={this.handleBackClick}
                handleSaveClick={this.handleSaveClick}
              >
                <LocationsEdit
                  locations={site.locations}
                  location={location}
                  devices={devices}
                  onChange={this.handleChange}
                />
              </SideSheet>
            )}
          </>
        ) : (
          <Spinner show={handling}>
            {step === 0 && (
              <>
                <AppBar title="Locations" search alerts={alerts} handleBackClick={handleBackClick} />
                <LocationsList
                  locations={site.locations}
                  handleListItemClick={this.handleListItemClick}
                  handleAddClick={this.handleAddClick}
                />
              </>
            )}
            {step === 1 && (
              <>
                <AppBar
                  title="Location details"
                  handleBackClick={this.handleBackClick}
                  handleEditClick={this.handleEditClick}
                  handleDeleteClick={this.handleDeleteClick}
                />
                <LocationsDetails location={site.locations.find(item => item.id === id)} />
              </>
            )}
            {step === 2 && (
              <>
                <AppBar
                  title="Add location"
                  handleBackClick={this.handleBackClick}
                  handleSaveClick={this.handleSaveClick}
                />
                <LocationsEdit
                  locations={site.locations}
                  location={location}
                  devices={devices}
                  onChange={this.handleChange}
                />
              </>
            )}
            {step === 3 && (
              <>
                <AppBar
                  title="Edit location"
                  handleBackClick={this.handleBackClick}
                  handleSaveClick={this.handleSaveClick}
                />
                <LocationsEdit
                  locations={site.locations}
                  location={location}
                  devices={devices}
                  onChange={this.handleChange}
                />
              </>
            )}
          </Spinner>
        )}
        <CreateEditDialog
          title="Create a location"
          save="Create"
          cancel="Cancel"
          open={open}
          handleSaveClick={this.handleSaveClick}
          handleCancelClick={this.handleCancelClick}
        />
      </>
    );
  }
}

Locations.propTypes = {
  site: PropTypes.object.isRequired,
  devices: PropTypes.object.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.object),
  sideSheet: PropTypes.bool,
  handleBackClick: PropTypes.func,
  handleDeleteClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
};

Locations.defaultProps = {
  alerts: undefined,
  sideSheet: false,
  handleBackClick: undefined,
  handleClose: undefined,
};

export default Locations;
