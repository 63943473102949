import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import EventListener from 'react-event-listener';
import compose from 'recompose/compose';
import AppBar from '../components/AppBar';
import AppBarLogo from '../components/AppBarLogo';
import ConfirmDialog from '../components/ConfirmDialog';
import ProfileComponent from '../components/Profile';
import ProfileList from '../components/ProfileList';
import ProfileNav from '../components/ProfileNav';
import withMobile from '../components/withMobile';
import { mixinHeightWithToolbar } from '../constants/theme';
import {
  apiDeleteUser,
  apiGetSignedInUser,
  apiGetUserAlerts,
  apiModifyUser,
  apiSignOut,
  apiUpdatePassword,
} from '../utils/api';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: theme.sideSheet.width + theme.floorplan.width,
    margin: `${theme.spacing.unit * 3}px 0px`,
    ...mixinHeightWithToolbar(theme.spacing.unit * 6),
  },
  contentContainer: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    boxShadow: '0 2px 2px -1px rgba(153, 153, 153, 0.3), 0 1px 5px -2px rgba(153, 153, 153, 0.3)',
    boxSizing: 'border-box',
    padding: 1,
  },
  componentAppBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: theme.appBar.height,
    ...theme.mixins.gutters(),
  },
  componentContainer: {
    flexGrow: 1,
    height: '100%',
  },
  component: {
    width: '100%',
    height: `calc(100% - ${theme.bottomAppBar.height}px - 56px)`,
    marginTop: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100% - ${theme.bottomAppBar.height}px - 48px)`,
      marginTop: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${theme.bottomAppBar.height}px - 64px)`,
      marginTop: 64,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      ...mixinHeightWithToolbar(),
    },
  },
  sidebar: {
    width: theme.sidebar.width,
    height: '100%',
    paddingLeft: 1,
    flexShrink: 0,
  },
  divider: {
    marginRight: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 3,
  },
  button: {
    color: theme.profile.signOut,
    borderColor: theme.profile.signOut,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: fade(theme.profile.signOut, theme.palette.action.hoverOpacity),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
});

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      open: false,
      signingOut: false,
    };

    this.handleInformationClick = this.handleInformationClick.bind(this);
    this.handlePasswordClick = this.handlePasswordClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleDeleteConfirmClick = this.handleDeleteConfirmClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  handleInformationClick() {
    this.setState({ step: 1 });
  }

  handlePasswordClick() {
    this.setState({ step: 2 });
  }

  handleBackClick() {
    const { step } = this.state;

    if (step === 0) {
      const { history } = this.props;

      history.goBack();
    } else {
      this.setState({ step: 0 });
    }
  }

  handleDeleteClick() {
    this.setState({ open: true });
  }

  handleDeleteConfirmClick() {
    const user = apiGetSignedInUser();
    apiDeleteUser(user.id)
      .then(() => {})
      .catch(() => {});
    this.setState({ open: false, signingOut: true });
    apiSignOut();
  }

  handleCancelClick() {
    this.setState({ open: false });
  }

  render() {
    const { classes, widthMobile } = this.props;
    const { step, open, signingOut } = this.state;

    const user = apiGetSignedInUser();

    return (
      <>
        {!widthMobile ? (
          <>
            <EventListener target="window" onKeyDown={this.handleKeyDown} />
            <AppBarLogo alerts={apiGetUserAlerts()} />
            <div className={classes.root}>
              <div className={classes.container}>
                <div className={classes.sidebar}>
                  <ProfileNav handleBackClick={this.handleBackClick} />
                </div>
                <div className={classes.contentContainer}>
                  <div className={classes.componentContainer}>
                    <div className={classes.componentAppBar}>
                      <Typography variant="h6">Account profile</Typography>
                      <Button className={classes.button} variant="outlined" onClick={apiSignOut}>
                        Sign out
                      </Button>
                    </div>
                    <Divider className={classes.divider} />
                    <div className={classes.component}>
                      {signingOut === false && (
                        <ProfileComponent
                          user={user}
                          handleDeleteClick={this.handleDeleteClick}
                          handleSaveClick={apiModifyUser}
                          handleSavePassword={apiUpdatePassword}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {signingOut === false && (
              <>
                {step === 0 && (
                  <>
                    <AppBar title="Profile" search alerts={apiGetUserAlerts()} handleBackClick={this.handleBackClick} />
                    <List>
                      <ListItem key={1} onClick={this.handleInformationClick} divider button>
                        <ListItemText primary="Personal information" secondary="Update your information" />
                      </ListItem>
                      <ListItem key={2} onClick={this.handlePasswordClick} divider button>
                        <ListItemText primary="Password" secondary="Change your password" />
                      </ListItem>
                      <ListItem key={3} onClick={apiSignOut} divider button>
                        <ListItemText primary="Sign out" secondary={user.email} />
                      </ListItem>
                      <ListItem key={4} onClick={this.handleDeleteClick} divider button>
                        <ListItemText primary="Delete account" />
                      </ListItem>
                    </List>
                  </>
                )}
                {step === 1 && (
                  <>
                    <AppBar title="Personal information" handleBackClick={this.handleBackClick} />
                    <ProfileList user={user} step={step} handleSaveClick={apiModifyUser} />
                  </>
                )}
                {step === 2 && (
                  <>
                    <AppBar title="Change password" handleBackClick={this.handleBackClick} />
                    <ProfileList
                      step={step}
                      handleSaveClick={apiModifyUser}
                      handleSavePassword={apiUpdatePassword}
                      handleClose={this.handleBackClick}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
        <ConfirmDialog
          open={open}
          title="Delete your WEDGE account?"
          body="This action cannot be undone."
          confirm="Delete"
          cancel="Cancel"
          handleConfirmClick={this.handleDeleteConfirmClick}
          handleCancelClick={this.handleCancelClick}
        />
      </>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
};

export default compose(
  withMobile(),
  withStyles(styles),
)(Profile);
