import PropTypes from 'prop-types';
import React from 'react';
import TeamComponent from '../components/Team';
import {
  apiDeleteContact, apiGetCurrentSite, apiGetUserAlerts, apiModifyContact,
} from '../utils/api';

function Team(props) {
  const { handleTeamAddClick, handleBackClick } = props;

  return (
    <TeamComponent
      site={apiGetCurrentSite()}
      alerts={apiGetUserAlerts()}
      handleBackClick={handleBackClick}
      handleTeamDelete={apiDeleteContact}
      handleTeamUpdate={apiModifyContact}
      handleTeamAddClick={handleTeamAddClick}
    />
  );
}

Team.propTypes = {
  handleBackClick: PropTypes.func.isRequired,
  handleTeamAddClick: PropTypes.func,
};

Team.defaultProps = {
  handleTeamAddClick: undefined,
};

export default Team;
