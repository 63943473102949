import BusinessIcon from '@material-ui/icons/Business';
import LayersIcon from '@material-ui/icons/Layers';
import React from 'react';

export default function searchGetIcon(type) {
  if (type === 'Site') {
    return <BusinessIcon />;
  }
  if (type === 'Floorplan') {
    return <LayersIcon />;
  }
  return null;
}
