import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '../components/AppBar';
import SitesComponent from '../components/Sites';
import SitesAdd from '../components/SitesAdd';
import Spinner from '../components/Spinner';
import {
  apiGetCompanies,
  apiGetDefaultFloorplanId,
  apiGetRecentSiteIds,
  apiGetSites,
  apiGetUserAlerts,
  apiModifySite,
} from '../utils/api';

function getSitesInAlert(sites) {
  return sites.filter(item => item.summary.alerts.length !== 0);
}

function getRecentSites(sites, selectedSites) {
  const recentSites = apiGetRecentSiteIds().filter(item => !selectedSites.find(site => site.id === item.site.id));
  return recentSites.map(item => sites.find(site => site.id === item.site.id)).filter(item => item !== undefined);
}

function getOtherSites(sites, selectedSites) {
  const sortedSites = sites.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  return sortedSites.filter(item => !selectedSites.find(site => site.id === item.id));
}

class Sites extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      adding: false,
    };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleAddSiteClick = this.handleAddSiteClick.bind(this);
    this.handleSiteClick = this.handleSiteClick.bind(this);
  }

  handleBackClick() {
    const { history } = this.props;

    history.goBack();
  }

  handleAddClick(name, companyId) {
    const { adding } = this.state;

    if (adding) return;

    this.setState({ adding: true, open: false });

    const site = {
      name,
      company: companyId,
    };
    apiModifySite(site)
      .then((reply) => {
        const { history } = this.props;

        this.setState({ adding: false });

        const { id } = reply;
        history.push(`/site/${id}/floorplans/${apiGetDefaultFloorplanId(id)}`);
      })
      .catch(() => {
        this.setState({ open: false, adding: false });
      });
  }

  handleCancelClick() {
    this.setState({ open: false });
  }

  handleAddSiteClick() {
    this.setState({ open: true });
  }

  handleSiteClick(siteId) {
    const { history } = this.props;

    history.push(`/site/${siteId}/floorplans/${apiGetDefaultFloorplanId(siteId)}`);
  }

  render() {
    const { open, adding } = this.state;

    const sites = apiGetSites();
    const sitesInAlert = getSitesInAlert(sites);
    const recentSites = sites.length >= 10 ? getRecentSites(sites, sitesInAlert) : [];
    const otherSites = getOtherSites(sites, sitesInAlert.concat(recentSites));
    const companies = apiGetCompanies();

    return (
      <Spinner show={adding}>
        <AppBar
          title="Sites"
          search
          alerts={apiGetUserAlerts()}
          handleBackClick={this.handleBackClick}
          handleAddSiteClick={this.handleAddSiteClick}
        />
        <SitesComponent
          sitesInAlert={sitesInAlert}
          recentSites={recentSites}
          otherSites={otherSites}
          handleSiteClick={this.handleSiteClick}
        />
        <SitesAdd
          companies={companies}
          open={open}
          handleAddClick={this.handleAddClick}
          handleCancelClick={this.handleCancelClick}
        />
      </Spinner>
    );
  }
}

Sites.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Sites);
