import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import PropTypes from 'prop-types';
import React from 'react';
import Draggable from 'react-draggable';
import compose from 'recompose/compose';
import Chart from './Chart';

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 343,
    // Disable the focus ring for mouse, touch and keyboard users
    outline: 'none',
  },
  nonmodal: {
    position: 'absolute',
    left: 'calc(50% - 206px)',
    top: 'calc(50% - 149px)',
    width: 412,
  },
};

class Charts extends React.Component {
  constructor(props) {
    super(props);

    this.chartWrapperRef = null;

    this.handleChartWrapperRef = this.handleChartWrapperRef.bind(this);
  }

  componentWillUnmount() {
    if (this.chartWrapperRef) {
      enableBodyScroll(this.chartWrapperRef);
    }
  }

  handleChartWrapperRef(ref) {
    this.chartWrapperRef = ref;
    disableBodyScroll(this.chartWrapperRef);
  }

  render() {
    const {
      classes,
      width,
      charts,
      handleChartCloseClick,
      handleChartBackClick,
      handleChartForwardClick,
      handleChartDataRequest,
      handleChartClick,
    } = this.props;

    return (
      <>
        {isWidthDown('sm', width)
          ? charts.map(item => (
            <Modal key={item.location.id} open>
              <div className={classes.modal} ref={ref => this.handleChartWrapperRef(ref)}>
                <Chart
                  data={item.data}
                  location={item.location}
                  sensor={item.sensor}
                  handleClose={() => handleChartCloseClick(item.location)}
                  handleBack={handleChartBackClick}
                  handleForward={handleChartForwardClick}
                  handleDataRequest={handleChartDataRequest}
                  handleClick={() => handleChartClick(item.location)}
                />
              </div>
            </Modal>
          ))
          : charts.map(item => (
            <Draggable bounds="parent" handle={`#chart${item.location.id}`} key={item.location.id}>
              <div className={classes.nonmodal} style={{ zIndex: item.zIndex }}>
                <Fade in>
                  <div>
                    <Chart
                      data={item.data}
                      location={item.location}
                      sensor={item.sensor}
                      handleClose={() => handleChartCloseClick(item.location)}
                      handleBack={handleChartBackClick}
                      handleForward={handleChartForwardClick}
                      handleDataRequest={handleChartDataRequest}
                      handleClick={() => handleChartClick(item.location)}
                      dragAnchorId={`chart${item.location.id}`}
                    />
                  </div>
                </Fade>
              </div>
            </Draggable>
          ))}
      </>
    );
  }
}

Charts.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  charts: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChartCloseClick: PropTypes.func.isRequired,
  handleChartBackClick: PropTypes.func.isRequired,
  handleChartForwardClick: PropTypes.func.isRequired,
  handleChartDataRequest: PropTypes.func.isRequired,
  handleChartClick: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  withWidth(),
)(Charts);
