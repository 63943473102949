import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import Select from './Select';

class ZonesAddTeam extends React.Component {
  constructor(props) {
    super(props);

    const { contacts } = props;

    this.state = { userId: contacts.length === 1 ? contacts[0].userId : '' };

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleAddClick() {
    const { handleAddClick: handleAddClickProp } = this.props;
    const { userId } = this.state;

    handleAddClickProp(userId);
    this.handleClose();
  }

  handleClose() {
    const { handleClose: handleCloseProp } = this.props;

    handleCloseProp();
  }

  handleChange(event) {
    this.setState({ userId: event.target.value });
  }

  render() {
    const { contacts, open } = this.props;
    const { userId } = this.state;

    return (
      <Dialog maxWidth="xs" fullWidth open={open} onClose={this.handleClose}>
        <>
          <DialogTitle>Add a team member</DialogTitle>
        </>
        <DialogContent>
          <Select id="member-name" label="Name" value={userId} margin="normal" fullWidth onChange={this.handleChange}>
            {contacts.map(item => (
              <MenuItem key={item.userId} value={item.userId}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.handleClose}>
            Cancel
          </Button>
          <Button color="secondary" autoFocus onClick={this.handleAddClick} disabled={userId === ''}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ZonesAddTeam.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
  handleAddClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ZonesAddTeam;
