import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Error from '../components/Error';
import { apiSetCurrentSite } from '../utils/api';
import { AbilityContext } from '../utils/auth';
import { subRoutes } from './routes';
import SubRoute from './SubRoute';

const Site = (props) => {
  const { match } = props;
  const { siteId } = match.params;

  apiSetCurrentSite(siteId);

  return (
    <Switch>
      {subRoutes.map(item => (
        <Route
          path={`${match.url}${item.path}`}
          key={`${match.url}${item.path}`}
          render={routeProps => (
            <AbilityContext.Consumer>
              {ability => (item.authAccess(ability) ? (
                <SubRoute {...routeProps} siteId={siteId} Component={item.component} backArrow={item.backArrow} />
              ) : (
                <Error variant="forbidden" />
              ))
              }
            </AbilityContext.Consumer>
          )}
        />
      ))}
    </Switch>
  );
};

Site.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Site);
