import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import { AbilityContext } from '../utils/auth';
import MoreVertIcon from './MoreVertIcon';
import Spinner from './Spinner';
import Tooltip from './Tooltip';

const styles = theme => ({
  sideSheet: {
    height: '100%',
    borderLeftColor: 'rgba(0, 0, 0, 0.08)',
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
  },
  sideSheetHeader: {
    width: '100%',
    height: theme.appBar.height,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...theme.mixins.gutters(),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  backIcon: {
    marginLeft: -16,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: -12,
  },
  buttonSave: {
    textTransform: 'none',
  },
});

class SideSheet extends React.Component {
  constructor(props) {
    super(props);

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleMenuClick() {
    const { menuOpen } = this.state;

    this.setState({ menuOpen: !menuOpen });
  }

  handleMenuClose() {
    const { menuOpen } = this.state;

    if (!menuOpen) {
      return;
    }

    // setTimeout to ensure a close event comes after a target click event
    this.timeout = setTimeout(() => {
      this.setState({ menuOpen: false });
    });
  }

  handleMenuItemClick(item) {
    const { handleEditClick, handleMoveClick, handleDeleteClick } = this.props;

    if (item === 'Edit') {
      handleEditClick();
    } else if (item === 'Move') {
      handleMoveClick();
    } else if (item === 'Delete') {
      handleDeleteClick();
    }
  }

  render() {
    const {
      classes,
      children,
      title,
      authSubject,
      showSpinner,
      handleBackClick,
      handleEditClick,
      handleMoveClick,
      handleDeleteClick,
      handleSaveClick,
      handleClose,
    } = this.props;

    const menuItems = handleMoveClick !== undefined ? ['Edit', 'Delete', 'Move'] : ['Edit', 'Delete'];

    return (
      <div className={classes.sideSheet}>
        <Spinner show={showSpinner}>
          <div className={classes.sideSheetHeader}>
            <div className={classes.titleContainer}>
              {handleBackClick !== undefined && (
                <IconButton className={classes.backIcon} onClick={handleBackClick}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              <Typography variant="h6">{title}</Typography>
            </div>
            <div className={classes.iconContainer}>
              {handleEditClick !== undefined && handleDeleteClick !== undefined ? (
                <>
                  <AbilityContext.Consumer>
                    {ability => (authSubject === undefined || ability.can('modify', authSubject)) && (
                    <MoreVertIcon
                      menuItems={menuItems}
                      placement="bottom"
                      handleMenuItemClick={this.handleMenuItemClick}
                    />
                    )
                    }
                  </AbilityContext.Consumer>
                  <Tooltip title="Close right sidebar" placement="bottom-end">
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  {handleEditClick !== undefined && (
                    <AbilityContext.Consumer>
                      {ability => (authSubject === undefined || ability.can('modify', authSubject)) && (
                      <Tooltip title="Edit">
                        <IconButton onClick={handleEditClick}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      )
                      }
                    </AbilityContext.Consumer>
                  )}
                  {handleSaveClick !== undefined && (
                    <AbilityContext.Consumer>
                      {ability => (authSubject === undefined || ability.can('modify', authSubject)) && (
                      <Button className={classes.buttonSave} color="secondary" onClick={handleSaveClick}>
                            Save
                      </Button>
                      )
                      }
                    </AbilityContext.Consumer>
                  )}
                  {handleClose !== undefined && (
                    <Tooltip title="Close right sidebar" placement="bottom-end">
                      <IconButton onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          </div>
          {children}
        </Spinner>
      </div>
    );
  }
}

SideSheet.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  authSubject: PropTypes.string,
  showSpinner: PropTypes.bool,
  handleBackClick: PropTypes.func,
  handleEditClick: PropTypes.func,
  handleMoveClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleSaveClick: PropTypes.func,
  handleClose: PropTypes.func,
};

SideSheet.defaultProps = {
  children: null,
  title: null,
  authSubject: undefined,
  showSpinner: false,
  handleBackClick: undefined,
  handleEditClick: undefined,
  handleMoveClick: undefined,
  handleDeleteClick: undefined,
  handleSaveClick: undefined,
  handleClose: undefined,
};

export default withStyles(styles)(SideSheet);
