import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import has from '../utils/has';
import Charts from './Charts';
import FloorplanViewport from './FloorplanViewport';
import PanZoomSwipe from './PanZoomSwipe';
import withMobile from './withMobile';

const styles = theme => ({
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
  },
  navButton: {
    width: 64,
    height: '100%',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover svg': {
      fill: theme.palette.secondary.light,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hidden: {
    display: 'none',
  },
  icon: {
    fontSize: 36,
    transition: 'unset',
  },
  chartsContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
});

class Floorplan extends React.Component {
  constructor(props) {
    super(props);

    this.state = { viewportScale: 1 };

    this.handleViewportScaleChange = this.handleViewportScaleChange.bind(this);
  }

  handleViewportScaleChange(scale) {
    this.setState({ viewportScale: scale });
  }

  render() {
    const {
      classes,
      widthMobile,
      floorplan,
      floorplans,
      locations,
      charts,
      handleBackClick,
      handleForwardClick,
      handleLocationClick,
      handleChartCloseClick,
      handleChartBackClick,
      handleChartForwardClick,
      handleChartDataRequest,
      handleChartClick,
    } = this.props;

    const { viewportScale } = this.state;

    if (floorplan && has.call(floorplan, 'image')) {
      const scale = floorplan.scale * viewportScale;

      return (
        <div className={classes.container}>
          <IconButton
            className={classNames(classes.navButton, { [classes.hidden]: floorplans.length < 2 })}
            onClick={handleBackClick}
            disableRipple
          >
            <ChevronLeftIcon className={classes.icon} />
          </IconButton>
          <div className={classes.chartsContainer}>
            {widthMobile ? (
              <PanZoomSwipe handleSwipeBack={handleBackClick} handleSwipeForward={handleForwardClick}>
                <FloorplanViewport
                  image={floorplan.image}
                  floorplan={floorplan}
                  widgets={floorplan.widgets}
                  locations={locations}
                  scale={scale}
                  onScaleChange={this.handleViewportScaleChange}
                  handleWidgetClick={handleLocationClick}
                />
              </PanZoomSwipe>
            ) : (
              <FloorplanViewport
                image={floorplan.image}
                floorplan={floorplan}
                widgets={floorplan.widgets}
                locations={locations}
                scale={scale}
                onScaleChange={this.handleViewportScaleChange}
                handleWidgetClick={handleLocationClick}
              />
            )}
            <Charts
              charts={charts}
              handleChartCloseClick={handleChartCloseClick}
              handleChartBackClick={handleChartBackClick}
              handleChartForwardClick={handleChartForwardClick}
              handleChartDataRequest={handleChartDataRequest}
              handleChartClick={handleChartClick}
            />
          </div>
          <IconButton
            className={classNames(classes.navButton, { [classes.hidden]: floorplans.length < 2 })}
            onClick={handleForwardClick}
            disableRipple
          >
            <ChevronRightIcon className={classes.icon} />
          </IconButton>
        </div>
      );
    }

    return (
      <div className={classes.container}>
        <IconButton
          className={classNames(classes.navButton, { [classes.hidden]: floorplans.length < 1 })}
          onClick={handleBackClick}
          disableRipple
        >
          <ChevronLeftIcon className={classes.icon} />
        </IconButton>
        <div className={classes.chartsContainer}>
          {widthMobile && <PanZoomSwipe handleSwipeBack={handleBackClick} handleSwipeForward={handleForwardClick} />}
        </div>
        <IconButton
          className={classNames(classes.navButton, { [classes.hidden]: floorplans.length < 1 })}
          onClick={handleForwardClick}
          disableRipple
        >
          <ChevronRightIcon className={classes.icon} />
        </IconButton>
      </div>
    );
  }
}

Floorplan.propTypes = {
  classes: PropTypes.object.isRequired,
  widthMobile: PropTypes.bool.isRequired,
  floorplans: PropTypes.arrayOf(PropTypes.object).isRequired,
  floorplan: PropTypes.object,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  charts: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleForwardClick: PropTypes.func.isRequired,
  handleLocationClick: PropTypes.func.isRequired,
  handleChartCloseClick: PropTypes.func.isRequired,
  handleChartBackClick: PropTypes.func.isRequired,
  handleChartForwardClick: PropTypes.func.isRequired,
  handleChartDataRequest: PropTypes.func.isRequired,
  handleChartClick: PropTypes.func.isRequired,
};

Floorplan.defaultProps = {
  floorplan: undefined,
};

export default compose(
  withMobile(),
  withStyles(styles),
)(Floorplan);
